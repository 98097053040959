import actions from './actions';

const initState = {
  isFetchingSlots: false,
  isUpdatingSlots: false,
  slots: {}
};

const deliverySlotsReducer = (state = initState, { type, ...action}) => {
  switch (type) {
    case actions.GET_SLOTS_BY_DATE:
      return {
        ...state,
        isFetchingSlots: true,
      };
    case actions.GET_SLOTS_BY_DATE_SUCCESS:
      return {
        ...state,
        isFetchingSlots: false,
        slots: action.slots,
      };
    case actions.GET_SLOTS_BY_DATE_FAIL:
      return {
        ...state,
        isFetchingSlots: false,
      };
    case actions.UPDATE_SLOTS_BY_DATE_POSTCODE:
      return {
        ...state,
        isUpdatingSlots: true,
      };
    case actions.UPDATE_SLOTS_BY_DATE_POSTCODE_SUCCESS:
    case actions.UPDATE_SLOTS_BY_DATE_POSTCODE_FAIL:
      return {
        ...state,
        isUpdatingSlots: false,
      };
    default:
      return state;
  }
}

export default deliverySlotsReducer;