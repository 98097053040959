import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const LongDescriptionEditor = (props) => {
  const { editorState, setEditorState } = props;
  return (
    <div style={{ border: '1px solid grey', borderRadius: '2px' }}>
      <Editor
        editorState={editorState}
        editorStyle={{ fontSize: 14, height: '200px' }}
        wrapperClassName="border"
        editorClassName="border"
        onEditorStateChange={(editorState) => {
          setEditorState(editorState);
        }}
        toolbar={{
          options: ['inline', 'fontSize', 'blockType', 'list'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          fontSize: { options: [12, 14, 16, 18] },
        }}
      />
    </div>
  );
};

LongDescriptionEditor.propTypes = {
  editorState: PropTypes.any,
  setEditorState: PropTypes.func,
};

export default LongDescriptionEditor;