import React from 'react';
import { Table, Button, Tag, Typography } from 'antd';
import { FacebookFilled, GoogleSquareFilled, AppleFilled } from '@ant-design/icons';
import Pagination from './NoSQLPagination/index';
import 'antd/dist/antd.css';
import PropTypes from 'prop-types';


const CustomersTable = (props) => {
  const originData = props.data;
  const openCouponsDrawer = props.openCouponsDrawer;
  const { customers } = originData;
  if (customers) {
    customers.map(customer => customer.key = customer.uid)
  }

  const columns = [
    {
      title: 'Nickname',
      dataIndex: 'userInfo',
      width: '10%',
      render: (data) => {
        return data.nickName
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      copyable: true,
      width: '25%',
      render: (data, record) => {
        let icon;
        if (record.userInfo.provider) {
          switch (record.userInfo.provider) {
            case 'apple':
              icon = <AppleFilled style={{ marginLeft: '10px' }} />;
              break;
            case 'facebook':
              icon = <FacebookFilled style={{ marginLeft: '10px', color: 'blue'}} />;
              break;
            case 'google':
              icon = <GoogleSquareFilled style={{ marginLeft: '10px', color: 'red' }} />;
              break;
            default:
              icon = '';
          }
        }
        if (data) {
          return <span><Typography.Text>{data}</Typography.Text>{icon}</span>
        }
      }
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      width: '15%'
    },
    {
      title: 'SignUp Date',
      dataIndex: 'createdAt',
      width: '10%',
      render: (data) => {
        const result = new Date(parseInt(data.seconds, 10) * 1000)
        return result.toLocaleDateString()
      }
    },
    {
      title: 'Status',
      dataIndex: 'archived',
      width: '10%',
      // eslint-disable-next-line
      render: (data) => {
        return (data ?
          (<Tag color="#f50" >Archived</Tag>) :
          (<Tag color="#87d068" >Active</Tag>))
      }
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: '30%',
      // eslint-disable-next-line
      render: (_, record) => {
        return (
          <div>
            <Button type="link" onClick={(e) => onRowEdit(e, record)}>
              Edit
            </Button>
            <Button type="link" onClick={(e) => showAddresses(e, record)}>
              Show Addresses
            </Button>
            <Button type="link" onClick={() => {openCouponsDrawer(record.uid);}}>
              Assign Coupon
            </Button>
          </div>
        );
      }
    },
  ];

  const showAddresses = (_, record) => {
    props.openModal(record.uid);
  }

  const onRowEdit = (e, record) => {
    props.openDrawer(record.uid);
    // console.log(record);
  }

  return (
    <div>
      <Table
        size="small"
        dataSource={customers}
        columns={columns}
        bordered
        pagination={false}
      >
      </Table>

      <Pagination
        pageNumber={props.currentPage}
        batchSize={props.pageSize}
        total={props.total}
        onPrev={props.onPrev}
        onNext={props.onNext}
      />

    </div>
  );
};

CustomersTable.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.array,
  pageOnChange: PropTypes.func,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  openDrawer: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  openModal: PropTypes.func,
  openCouponsDrawer: PropTypes.func,
};
export default CustomersTable;