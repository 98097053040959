import Joi from "@hapi/joi";

export default Joi.object({
  sku: Joi.string().required(),
  // Determine the order of the product
  priority: Joi.number().integer().min(0).default(0).required(),
  archived: Joi.bool().default(false).required(),

  // Names and descriptions
  name: Joi.string().required(),
  chineseName: Joi.string(),
  koreanName: Joi.string(),
  shortDescription: Joi.string().required(),
  longDescription: Joi.string().required(),

  // Stock quantity, it could be negative if the seller delibrately wants some backorder feature
  stock: Joi.number().integer().required(),
  // Original price and the price after the discount
  price: Joi.number().min(0).required(),
  discountPrice: Joi.number().min(0),
  flashSaleLimit: Joi.number().min(1),
  expiryDate: Joi.object({
    seconds: Joi.number()
      .min(0)
      .integer()
      .message('Invalid expiry date, please check the value.'),
    nanoseconds: Joi.number().min(0).integer()
  }).allow(null),
  categories: Joi.array().items(Joi.string()).required(),
  images: Joi.array().items(Joi.string()),
  featurePicture: Joi.string().allow(''),

  // Long picture description
  productDescriptionImages: Joi.array().items(Joi.string()),

  // Attributes that relates to configurable products
  isChild: Joi.boolean().required(),
  type: Joi.string().valid('simple', 'configurable').required(),
  // maxPrice and lowPrice only exist when the product is configurable, which represent the range of prices
  minPrice: Joi.when('type', {
    is: 'configurable',
    then: Joi.number().min(0).required(),
    otherwise: Joi.forbidden()
  }),
  maxPrice: Joi.when('type', {
    is: 'configurable',
    then: Joi.number().min(0).required(),
    otherwise: Joi.forbidden()
  })
})
  .and('minPrice', 'maxPrice')
  .required();
