import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from './actions';
import usersActions from '../actions';
import userApi from '../../../apis/Users';


function* createUser(params) {
  const { success, data, error } = yield userApi.createUser(params);
  if (success) {
    yield put({ type: actions.CREATE_USER_SUCCESS, newUser: data });
    toast.success('New User Created!', {
      autoClose: 3000,
    });
    yield put({ type: usersActions.FETCH_USERS_LIST });
  } else {
    yield put({ type: actions.CREATE_USER_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000
    })
  }
}

export default function* newUserManagementSaga() {
  yield all([
    takeLatest(actions.CREATE_USER, createUser)
  ]);
}