import { all, put, select, debounce, takeLatest } from 'redux-saga/effects';
import actions from './action';
import { toast } from 'react-toastify';
import productApi from '../../../apis/Products';
import productSchema from '../../../schemas/product';

const getSku = (state) => state.Products.newProduct.sku;

function* checkSku() {
  let currentSku = yield select(getSku);
  if (currentSku) {
    yield put({ type: actions.CHECK_SKU });
    const { success, validity, error } = yield productApi.checkProductSkuExist({ SKU: currentSku });
    if (success) {
      yield put({ type: actions.CHECK_SKU_SUCCESS, skuValidity: validity });
    } else {
      yield put({ type: actions.CHECK_SKU_FAIL });
      console.log(error);
      toast.error(error, {
        autoClose: 3000,
      });
    }
  }
}

function* addProduct(params) {
  const { product } = params;
  const { error } = productSchema.validate(product);
  if (error) {
    yield put({ type: actions.ADD_PRODUCT_FAIL });
  }
  const { error: err, success, data } = yield productApi.createProduct(product);
  if (success) {
    yield put({ type: actions.ADD_PRODUCT_SUCCESS, product: data });
    toast.success('Product Created!', { autoClose: 3000 });
  }
  else {
    yield put({ type: actions.ADD_PRODUCT_FAIL });
    toast.error(err.toString(), { autoClose: 3000 });
  }
}

export default function* newProductSaga() {
  yield all([
    debounce(1000, actions.CHANGE_SKU, checkSku),
    takeLatest(actions.ADD_PRODUCT, addProduct),
  ]);
}
