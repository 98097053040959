import React from 'react';
import {
  Drawer,
  Select,
  Button,
  Spin,
  Form,
  Card,
  Typography,
  List,
  Descriptions,
  Popconfirm,
  Tag
} from 'antd';
import FirebaseImage from '../FirebaseImage';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import logo from '../../assets/logos/logo-dark.png';
import { addfont } from './chineseFont';
import addKoreanFont from './koreanFont';
import moment from 'moment';
const OrderEditViewDrawer = (props) => {
  const {
    drawerVisibility,
    closeDrawer,
    isFetchingSingleOrder,
    isUpdatingSingleOrder,
    orderData,
    updateOrderStatus,
    deleteOrder,
    isDeletingOrder
  } = props;

  const statusSubmitHandler = (values) => {
    if (values.status !== orderData.status) {
      updateOrderStatus({
        id: orderData.orderId,
        data: { status: values.status }
      });
    }
    closeDrawer();
  };

  const printDocument = () => {
    let doc = new jsPDF();
    addfont(doc);
    doc.addFont('bolds', 'chinese', 'normal');
    addKoreanFont(doc);
    doc.addFont('bolds', 'korean', 'normal');
    // doc.setFont('chinese');
    doc.setFont('korean');
    // doc.setLineDash([2.5]);

    let nextLineHeight = 80;
    const addTitle = (text) => {
      doc.setFont('chinese');
      doc.setFontSize(12);
      doc.text(text, 15, nextLineHeight, { maxWidth: 150 });
      nextLineHeight += 7;
      if (nextLineHeight >= 270) {
        doc.addPage();
        nextLineHeight = 30;
      }
    };

    const addText = (text) => {
      doc.setFont('chinese');
      doc.setFontSize(10);
      doc.text('- ' + text, 20, nextLineHeight, { maxWidth: 150 });
      nextLineHeight += 7;
      if (nextLineHeight >= 270) {
        doc.addPage();
        nextLineHeight = 30;
      }
    };

    const addLine = () => {
      doc.line(10, nextLineHeight, 200, nextLineHeight);
      nextLineHeight += 7;
      if (nextLineHeight >= 270) {
        doc.addPage();
        nextLineHeight = 30;
      }
    };

    const addDashLine = () => {
      doc.setLineDash([2.5]);
      doc.line(20, nextLineHeight, 190, nextLineHeight);
      nextLineHeight += 7;
      if (nextLineHeight >= 270) {
        doc.addPage();
        nextLineHeight = 30;
      }
      doc.setLineDash();
    };

    const addChineseName = (text) => {
      doc.setFont('chinese');
      doc.setFontSize(10);
      doc.text('  ' + text, 20, nextLineHeight, { maxWidth: 150 });
      nextLineHeight += 7;
      if (nextLineHeight >= 270) {
        doc.addPage();
        nextLineHeight = 30;
      }
    };

    const addKoreanName = (text) => {
      doc.setFont('korean');
      doc.setFontSize(10);
      doc.text('  ' + text, 20, nextLineHeight, { maxWidth: 150 });
      nextLineHeight += 7;
      if (nextLineHeight >= 270) {
        doc.addPage();
        nextLineHeight = 30;
      }
    };

    const addPriceQuantity = (
      quantity,
      price,
      discountPrice,
      firstLineHeight
    ) => {
      doc.text('Quantity:  ', 150, firstLineHeight, { maxWidth: 150 });
      doc.setFontSize(15);
      doc.text(quantity + '', 170, firstLineHeight, {
        maxWidth: 150
      });
      doc.setFontSize(10);
      discountPrice
        ? doc.text(
          'Discount Price:  $' + discountPrice,
          150,
          firstLineHeight + 7,
          { maxWidth: 150 }
        )
        : doc.text('Price:  $' + price, 150, firstLineHeight + 7, {
          maxWidth: 150
        });
    };

    const loadImage = (url) =>
      new Promise((resolve) => {
        let img = new Image();
        img.src = url;
        img.onload = () => {
          resolve(img);
        };
      });
    loadImage(logo).then((img) => {
      doc.setFont('chinese');
      doc.setFontSize(14);
      doc.text(
        `Order Index: ${orderData.deliveryTime.split(' ')[0] + ' '}`,
        10,
        12,
        { maxWidth: 150 }
      );
      doc.setFontSize(22);
      doc.rect(69, 7, 10, 7);
      doc.text(`${('0' + orderData.orderIndex).slice(-2)}`, 70, 13, {
        maxWidth: 150
      });
      doc.addImage(img, 'png', 35, 15, 130, 45);
      addTitle(`Order ID: ${orderData.orderId}`);
      doc.setFontSize(12);
      doc.text(
        `Order Time: ${moment(orderData.createdAt.seconds * 1000).format(
          'YYYY-MM-DD HH:mm:ss dddd'
        )}`,
        110,
        nextLineHeight - 7,
        { maxWidth: 150 }
      );
      addLine();
      doc.line(10, 70, 200, 70);
      addTitle('Customer Details');
      addText(`Phone Number: ${orderData.deliveryAddress.contactNumber}`);
      addText(`Contact Name: ${orderData.deliveryAddress.contactName}`);
      addLine();
      addTitle('Delivery Time');
      addText(orderData.deliveryTime);
      addLine();
      addTitle('Delivery Address');
      if (orderData.toUpStairs) {
        doc.setFontSize(12);
        doc.text('Delivery To Upstairs:', 130, nextLineHeight - 7, {
          maxWidth: 150
        });
      }
      addText(
        (orderData.deliveryAddress.unitNumber
          ? orderData.deliveryAddress.unitNumber + '/'
          : '') +
        orderData.deliveryAddress.address +
        ', ' +
        orderData.deliveryAddress.suburb +
        ' ' +
        orderData.deliveryAddress.postcode
      );
      if (orderData.toUpStairs) {
        doc.setFontSize(10);
        doc.text('YES', 150, nextLineHeight - 7, {
          maxWidth: 150
        });
      }
      addLine();
      addTitle('Order Items');
      for (let index in orderData.orderItems) {
        if (index !== 0) {
          // add a dashed line here
          addDashLine();
        }
        const item = orderData.orderItems[index];
        addText(item.sku);
        addPriceQuantity(
          item.quantity,
          item.price,
          item.discountPrice,
          nextLineHeight - 7
        );
        addChineseName(item.name);
        if (item.chineseName) {
          addChineseName(item.chineseName);
        }
        if (item.koreanName) {
          addKoreanName(item.koreanName);
        }
      }

      if (orderData.appliedCoupons && orderData.appliedCoupons.length > 0) {
        addLine();
        addTitle('Coupons Used');
        for (const coupon of orderData.appliedCoupons) {
          addText(coupon.code);
          doc.text(
            coupon.discountType === 'fix'
              ? `$${coupon.discountAmount}`
              : `${coupon.discountAmount}%`,
            150,
            nextLineHeight - 7,
            { maxWidth: 150 }
          );
        }
      }

      addLine();
      addTitle('Total Price');
      addText('$' + orderData.total);

      if (orderData.comment) {
        addLine();
        addTitle('Comment');
        addText(orderData.comment);
        addKoreanName(orderData.comment);
      }

      doc.save(orderData.orderId + '.pdf');
    });
  };

  return (
    <>
      <Drawer
        title="Order Details"
        width="50%"
        visible={drawerVisibility}
        bodyStyle={{ paddingBottom: 80 }}
        onClose={() => closeDrawer()}
        footer={[
          <Button
            key="submit"
            type="primary"
            form="editStatusForm"
            htmlType="submit"
          >
            Submit
          </Button>,
          <Button
            key="cancel"
            onClick={() => {
              closeDrawer();
            }}
            style={{ marginLeft: '15px' }}
          >
            Cancel
          </Button>,
          <Button
            key="print"
            style={{ marginLeft: '15px' }}
            onClick={printDocument}
          >
            Print Order
          </Button>
        ]}
      >
        <Spin
          spinning={
            isFetchingSingleOrder || isUpdatingSingleOrder || isDeletingOrder
          }
        >
          {orderData.status && (
            <Form
              initialValues={orderData}
              id="editStatusForm"
              onFinish={statusSubmitHandler}
            >
              <Form.Item name="status" label="Please Select New State:">
                <Select>
                  <Select.Option key="paid" value="paid">
                    Paid
                  </Select.Option>
                  <Select.Option
                    key="unpaid"
                    value="unpaid"
                    disabled={orderData.status === 'pending'}
                  >
                    Unpaid
                  </Select.Option>
                  <Select.Option
                    key="processing"
                    value="processing"
                    disabled={orderData.status === 'pending'}
                  >
                    Processing
                  </Select.Option>
                  <Select.Option key="pending" value="pending">
                    Pending
                  </Select.Option>
                  <Select.Option
                    key="delivering"
                    value="delivering"
                    disabled={orderData.status === 'pending'}
                  >
                    Delivering
                  </Select.Option>
                  <Select.Option
                    key="delivered"
                    value="delivered"
                    disabled={orderData.status === 'pending'}
                  >
                    Delivered
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          )}
          {orderData.customer && orderData.customer.userInfo && (
            <Card title="Customer Details">
              <Typography.Text strong>{`Name: `}</Typography.Text>
              <Typography.Text>
                {orderData.customer.userInfo.firstName +
                  ' ' +
                  orderData.customer.userInfo.lastName}
              </Typography.Text>
              <br />
              <Typography.Text strong>{`Number: `}</Typography.Text>
              <Typography.Text copyable>
                {orderData.customer.phoneNumber}
              </Typography.Text>
              <br />
              <Typography.Text strong>{`Email: `}</Typography.Text>
              <Typography.Text copyable>
                {orderData.customer.email}
              </Typography.Text>
            </Card>
          )}
          {orderData.deliveryAddress && (
            <Card title="Delivery Details" style={{ marginTop: '15px' }}>
              <Typography.Text strong>{`Name: `}</Typography.Text>
              <Typography.Text>
                {orderData.deliveryAddress.contactName}
              </Typography.Text>
              <br />
              <Typography.Text strong>{`Number: `}</Typography.Text>
              <Typography.Text copyable>
                {orderData.deliveryAddress.contactNumber}
              </Typography.Text>
              <br />
              <Typography.Text strong>{`Address: `}</Typography.Text>
              <Typography.Text>
                {orderData.deliveryAddress.unitNumber +
                  '/' +
                  orderData.deliveryAddress.address +
                  ', ' +
                  orderData.deliveryAddress.suburb +
                  ' ' +
                  orderData.deliveryAddress.postcode}
              </Typography.Text>
              <br />
              <Typography.Text>{`Comment: ${orderData.deliveryAddress.comment}`}</Typography.Text>
              <br />
              <Typography.Text>{`Delivery Time: ${orderData.deliveryTime}`}</Typography.Text>
              <br />
            </Card>
          )}
          {orderData.orderItems && (
            <Card style={{ marginTop: '15px' }} title="Order Items">
              <List
                size="small"
                // grid={{ gutter: 16, column: 2 }}
                itemLayout="vertical"
                dataSource={orderData.orderItems}
                renderItem={(item) => (
                  <List.Item
                    style={{ minHeight: '150px' }}
                    key={item.sku}
                    extra={[
                      <div
                        key={1}
                        style={{
                          width: '120px',
                          height: '120px',
                          textAlign: 'center',
                          alignItems: 'center',
                          verticalAlign: 'middle',
                          display: 'table-cell'
                        }}
                      >
                        <FirebaseImage
                          imageRef={item.featurePicture}
                          height="110px"
                        />
                      </div>
                    ]}
                  >
                    <Typography.Title level={4}>{item.name}</Typography.Title>
                    <Descriptions size="small">
                      <Descriptions.Item label="Product SKU" span={3}>
                        {item.sku}
                      </Descriptions.Item>
                      <Descriptions.Item label="Chinese Name" span={3}>
                        {item.chineseName}
                      </Descriptions.Item>
                      <Descriptions.Item label="korean Name" span={3}>
                        {item.koreanName}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Price"
                        span={2}
                      >{`$${item.price}`}</Descriptions.Item>
                      <Descriptions.Item label="Quantity" span={1}>
                        <Tag color="red">
                          <span style={{ fontSize: 16 }}>{item.quantity}</span>
                        </Tag>
                      </Descriptions.Item>
                      {item.discountPrice && (
                        <Descriptions.Item label="Discount Price" span={3}>
                          <Tag color="green">{`$${item.discountPrice}`}</Tag>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </List.Item>
                )}
              />
            </Card>
          )}
          <Card style={{ marginTop: '15px' }} title="Comment">
            <div>
              <Typography.Text strong>Comment:</Typography.Text>
              <Typography.Text strong style={{ float: 'right' }} type="danger">
                {orderData.comment ? orderData.comment : 'No comment left.'}
              </Typography.Text>
            </div>
          </Card>
          {orderData &&
            orderData.appliedCoupons &&
            orderData.appliedCoupons.length > 0 && (
            <Card style={{ marginTop: '15px' }} title="Coupons">
              {orderData.appliedCoupons.map((coupon) => (
                <div key={coupon.code}>
                  <Tag color="blue">
                    <Typography.Text>{coupon.code}</Typography.Text>
                  </Tag>
                  <Typography.Text strong style={{ float: 'right' }}>
                    {`$${coupon.discountAmount}`}
                  </Typography.Text>
                </div>
              ))}
            </Card>
          )}
          <Card style={{ marginTop: '15px' }} title="Price and Discount">
            <div>
              <Typography.Text strong>SubTotal:</Typography.Text>
              <Typography.Text strong style={{ float: 'right' }}>
                {`$${orderData.subtotal}`}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Delivery Fee:</Typography.Text>
              <Typography.Text strong style={{ float: 'right' }}>
                {`$${orderData.deliveryFee}`}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Discount Amount:</Typography.Text>
              <Typography.Text strong style={{ float: 'right' }}>
                {`$${
                  Math.round(
                    (orderData.subtotal +
                      orderData.deliveryFee -
                      orderData.total) *
                      100
                  ) / 100
                }`}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Total:</Typography.Text>
              <div style={{ float: 'right', fontSize: '28px', color: 'red' }}>
                {orderData.total}
              </div>
            </div>
          </Card>
          {(orderData.status === 'unpaid' ||
            orderData.status === 'pending') && (
            <Popconfirm
              title="Are you sure to delete this order?"
              onConfirm={() => {
                deleteOrder({ order: orderData });
              }}
            >
              <Button type="danger" style={{ marginTop: '25px' }}>
                Delete Order
              </Button>
            </Popconfirm>
          )}
        </Spin>
      </Drawer>
    </>
  );
};

OrderEditViewDrawer.propTypes = {
  drawerVisibility: PropTypes.bool,
  closeDrawer: PropTypes.func,
  isFetchingSingleOrder: PropTypes.bool,
  isDeletingOrder: PropTypes.bool,
  isUpdatingSingleOrder: PropTypes.bool,
  orderData: PropTypes.object,
  updateOrderStatus: PropTypes.func,
  deleteOrder: PropTypes.func
};

export default OrderEditViewDrawer;
