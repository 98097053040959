import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import orderAPI from '../../apis/Orders';
import unprocessedOrdersSaga from './UnprocessedOrders/saga';


function* fetchOrderList(params) {
  const { success, data, error } = yield orderAPI.getOrderList(params);
  if (success) {
    yield put({ type: actions.FETCH_ORDERS_LIST_SUCCESS, ordersData: data });
  } else {
    yield put({ type: actions.FETCH_ORDERS_LIST_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    })
  }
}

function* fetchSingleOrder(params) {
  const { success, data, error } = yield orderAPI.getOrder(params);
  if (success) {
    yield put({ type: actions.FETCH_SINGLE_ORDER_SUCCESS, orderData: data.order });
  } else {
    yield put({ type: actions.FETCH_SINGLE_ORDER_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    })
  }
}

function* updateOrder(params) {
  const { success, data, error } = yield orderAPI.updateOrder(params);
  if (success) {
    yield put({ type: actions.UPDATE_ORDER_STATUS_SUCCESS, orderData: data });
    // yield put({ type: actions.FETCH_ORDERS_LIST });
  } else {
    yield put({ type: actions.UPDATE_ORDER_STATUS_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

function* deleteOrder(orderRecord) {
  const { success, error } = yield orderAPI.deleteOrder(orderRecord);
  if (success) {
    yield put({ type: actions.DELETE_ORDER_SUCCESS });
    // yield put({ type: actions.FETCH_ORDERS_LIST });
    toast.success('Order Deleted!', {
      autoClose: 3000,
    })
  } else {
    toast.error(error.toString(), {
      autoClose: 3000,
    })
  }

}

export default function* ordersSaga() {
  yield all([
    unprocessedOrdersSaga(),
    takeLatest(actions.FETCH_ORDERS_LIST, fetchOrderList),
    takeLatest(actions.FETCH_SINGLE_ORDER, fetchSingleOrder),
    takeLatest(actions.UPDATE_ORDER_STATUS, updateOrder),
    takeLatest(actions.DELETE_ORDER, deleteOrder),
  ]);
}
