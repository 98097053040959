const actions = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAIL: 'GET_CATEGORIES_FAIL',
  EDIT_CATEGORY: 'EDIT_CATEGORIES',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORIES_SUCCESS',
  EDIT_CATEGORY_FAIL: 'EDIT_CATEGORIES_FAIL',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAIL: 'DELETE_CATEGORY_FAIL',

  getCategories: () => ({
    type: actions.GET_CATEGORIES,
  }),
  editCategory: ({ id, category }) => ({
    type: actions.EDIT_CATEGORY,
    id,
    category,
  }),
  deleteCategory: ({ id }) => ({
    type: actions.DELETE_CATEGORY,
    id,
  }),
};

export default actions;
