/* eslint-disable react/display-name */
import React from 'react';
import { Table, Tag, Button, Typography } from 'antd';
import Pagination from '../NoSQLPagination/index';
import PropTypes from 'prop-types';
import moment from 'moment';

const OrdersTable = (props) => {
  const { orders, fetchSingleOrder, openEditDrawer } = props;
  const columns = [
    {
      title: 'Order Index',
      dataIndex: 'orderIndex',
      render: (data, record) =>
        data ? (
          <>
            <span>
              {record.deliveryTime.split(' ')[0] +
                ' '}
            </span>
            <span style={{ color: 'blue', fontWeight: 'bold' }}>{data}</span>
          </>
        ) : (
          <Typography.Text copyable>{record.orderId}</Typography.Text>
        )
    },
    {
      title: 'Customer Name',
      dataIndex: ['customer', 'userInfo', 'nickName'],
      render: (data) => <Typography.Text>{data}</Typography.Text>
    },
    {
      title: 'Phone Number',
      dataIndex: ['customer', 'phoneNumber']
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (data) =>
        moment(data.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Delivery Time',
      dataIndex: 'deliveryTime'
    },
    {
      title: 'Delivery Address',
      // dataIndex: []
      render: (data) => {
        const {
          unitNumber,
          address,
          suburb,
          state,
          postcode
        } = data.deliveryAddress;
        return (
          (unitNumber ? unitNumber + '/' : '') +
          address +
          ', ' +
          suburb +
          ' ' +
          state +
          postcode
        );
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (data) => `$${data}`
    },
    {
      title: 'Payment Method',
      dataIndex: ['paymentInfo', 'method'], //eslint-disable-next-line
      render: (data) =>
        data ? (
          <Tag color="blue">{data && data.toUpperCase()}</Tag>
        ) : (
          <Tag color="red">PENDING PAYMENT</Tag>
        )
    },
    // {
    //   title: 'Payment ID',
    //   dataIndex: ['paymentInfo', 'detail', 'id'], //eslint-disable-next-line
    //   render: (data) =>
    //     data ? <Typography.Text copyable>{data}</Typography.Text> : ''
    // },
    {
      title: 'Status',
      dataIndex: 'status', // eslint-disable-next-line
      render: (data) => (
        <Tag color={data === 'paid' || data === 'delivered' ? 'green' : 'red'}>
          {data.toUpperCase()}
        </Tag>
      )
    },
    {
      title: 'Operation', // eslint-disable-next-line
      render: (_, record) => (
        <div>
          <Button type="link" onClick={(e) => onStatusEdit(e, record)}>
            View & Edit
          </Button>
          {/* <Button type="link" onClick={(e) => onCreditMemo(e, record)}>
            Refund
          </Button> */}
        </div>
      )
    }
  ];

  const onStatusEdit = (_, record) => {
    openEditDrawer();
    fetchSingleOrder({ id: record.orderId });
  };

  // const onCreditMemo = (_, record) => {
  //   //TODO: implement credit memo creation function
  //   console.log(record);
  //   openMemoModal();
  // };

  return (
    <div>
      <Table
        rowKey="orderId"
        size="small"
        columns={columns}
        dataSource={orders.orders}
        bordered
        pagination={false}
      />
      <Pagination
        pageNumber={orders.pageNumber}
        batchSize={orders.batchSize}
        total={orders.total}
        onPrev={props.onPrev}
        onNext={props.onNext}
        resultLength={orders.orders ? orders.orders.length : 0}
      />
    </div>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.object,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  fetchSingleOrder: PropTypes.func,
  openEditDrawer: PropTypes.func,
  openMemoModal: PropTypes.func
};

export default OrdersTable;
