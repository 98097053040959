import React, { useEffect, useState } from 'react';
import { Empty } from 'antd';
import Proptypes from 'prop-types';
import { storage } from '../../utils/firebase';

const FirebaseImage = (props) => {
  // props
  const { imageRef, width, height } = props;

  //state
  const [imageUrl, setImageUrl] = useState('');

  // useEffect
  useEffect(() => {
    if (imageRef && imageRef !== null && imageRef !== '') {
      getImage(imageRef)
    } else {
      setImageUrl('')
    }
  }, [imageRef])

  // custom fn
  const getImage = (ref) => {
    storage.ref().child(ref).getDownloadURL()
      .then((url) => {
        setImageUrl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // render
  return !imageUrl || imageUrl === '' ? (
    <Empty
      description="No Image"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        float: 'center',
        maxHeight: height ? height : '100px',
      }}
    />
  ) : (
    <img
      src={imageUrl}
      alt="avatar"
      style={{ maxWidth: width ? width : '50%', maxHeight: height ? height : '100px' }}
    />
  );
};

FirebaseImage.propTypes = {
  imageRef: Proptypes.string,
  width: Proptypes.string,
  height: Proptypes.string,
};

export default FirebaseImage
