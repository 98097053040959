import actions from './actions';

const initState = {
  isFetchingFees: false,
  isUploadingFees: false,
  deliveryFees: [],
  feeUploaded: 0,
  isFetchingUpstairsConfig: false,
  isUpdatingUpstairsConfig: false,
  deliveryToUpstairsConfig: {}
};

export default function deliveryFeeReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_DELIVERY_FEES:
      return {
        ...state,
        isFetchingFees: true,
      };
    case actions.FETCH_DELIVERY_FEES_SUCCESS:
      return {
        ...state,
        isFetchingFees: false,
        deliveryFees: action.deliveryFees,
      };
    case actions.FETCH_DELIVERY_FEES_FAIL:
      return {
        ...state,
        isFetchingFees: false,
      };
    case actions.UPLOAD_DELIVERY_FEES:
      return {
        ...state,
        isUploadingFees: true,
        feeUploaded: 0
      };
    case actions.UPLOAD_DELIVERY_FEES_SUCCESS:
      return {
        ...state,
        isUploadingFees: false,
        feeUploaded: action.feeUploaded,
      };
    case actions.UPLOAD_DELIVERY_FEES_FAIL:
      return {
        ...state,
        isUploadingFees: false,
      };
    case actions.RESET_UPLOAD_STATUS:
      return {
        ...state,
        isFetchingFees: false,
        isUploadingFees: false,
        feeUploaded: 0,
      };
    case actions.GET_UPSTAIRS_CONFIG:
      return {
        ...state,
        isFetchingUpstairsConfig: true
      };
    case actions.GET_UPSTAIRS_CONFIG_SUCCESS:
      return {
        ...state,
        isFetchingUpstairsConfig: false,
        deliveryToUpstairsConfig: action.config
      };
    case actions.GET_UPSTAIRS_CONFIG_FAIL:
      return {
        ...state,
        isFetchingUpstairsConfig: false,
      };
    case actions.UPDATE_UPSTAIRS_CONFIG:
      return {
        ...state,
        isUpdatingUpstairsConfig: true,
      };
    case actions.UPDATE_UPSTAIRS_CONFIG_SUCCESS:
      return {
        ...state,
        isUpdatingUpstairsConfig: false,
        deliveryToUpstairsConfig: action.config
      };
    case actions.UPDATE_UPSTAIRS_CONFIG_FAIL:
      return {
        ...state,
        isUpdatingUpstairsConfig: false
      };
    default: return state;
  }
}