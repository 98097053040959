const actions = {
  FETCH_WORKSPACE_DATA: 'FETCH_WORKSPACE_DATA',
  FETCH_WORKSPACE_DATA_SUCCESS: 'FETCH_WORKSPACE_DATA_SUCCESS',
  FETCH_WORKSPACE_DATA_FAIL: 'FETCH_WORKSPACE_DATA_FAIL',

  fetchData: () => ({
    type: actions.FETCH_WORKSPACE_DATA
  })
};

export default actions;
