/* eslint-disable */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import userActions from '../redux/User/actions';
import { firebaseAuth, firebase } from '../utils/firebase';

// Theme Options
import Homepage from './Homepage';
import Dashboard from './Dashboard';
import LoadingScreen from '../components/LoadingScreen';
import PageNotFound from './Exception/404';
import './index.css';

const LoginPage = lazy(() => import('./LoginPage'));
const ContactUs = lazy(() => import('./ContactUs'));
const Terms = lazy(() => import('./TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const ForgetPassword = lazy(() => import('./ForgetPassword'));

const Pages = (props) => {
  const {
    userInfo,
    retrivedCurrentUserData,
    signIn,
    signOut,
    retriveCurrentUserData
  } = props;
  const [hasCheckedLogin, setHasCheckedLogin] = useState(false);
  const [login, setLogin] = useState(false);

  let match = useRouteMatch();
  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        console.log(JSON.stringify(userInfo));
        if (JSON.stringify(userInfo) === '{}') {
          signIn({ userInfo: user });
        }
        setLogin(true);
        retriveCurrentUserData({ uid: user.uid });
      } else {
        setLogin(false);
      }
      setHasCheckedLogin(true);
    });
  }, []);
  let redirectLink;
  if (JSON.stringify(userInfo) === '{}') {
    redirectLink = 'login';
  } else {
    redirectLink = 'dashboard';
  }
  const message = ({ hasCheckedLogin, retrivedCurrentUserData }) => {
    if (!hasCheckedLogin) return 'Checking Login Status';
    if (!retrivedCurrentUserData) return 'Fetching Current User Data';
    return 'Rendering Components';
  };
  return (hasCheckedLogin && login && retrivedCurrentUserData) || //Checked Login and User has Login
    (hasCheckedLogin && !login) ? ( // Checked Login and user has not login
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route path={`${match.url}dashboard`}>
          <Dashboard userInfo={userInfo} signOutHandler={signOut} />
        </Route>
        <Route path={`${match.url}login`}>
          <LoginPage />
        </Route>
        <Route path={`${match.url}forgetpassword`}>
          <ForgetPassword />
        </Route>
        <Route path={`${match.url}contact`}>
          <ContactUs />
        </Route>
        <Route path={`${match.url}term`}>
          <Terms />
        </Route>
        <Route path={`${match.url}privacy`}>
          <PrivacyPolicy />
        </Route>
        <Route exact path={`${match.url}`} render={() => <Homepage />} />
        <Route render={() => <PageNotFound />} />
      </Switch>
    </Suspense>
  ) : (
    <LoadingScreen
      text={message({ hasCheckedLogin, retrivedCurrentUserData })}
    />
  );
};

const { signIn, retriveCurrentUserData, signOut } = userActions;
export default connect(
  (state) => {
    const { User } = state;
    const { userInfo, retrivedCurrentUserData } = User;
    return {
      userInfo,
      retrivedCurrentUserData
    };
  },
  {
    signIn,
    signOut,
    retriveCurrentUserData
  }
)(Pages);
