import { firestore } from '../utils/firebase';

const customerCoupnsApi = {
  /** List products by category
   * @param {{categoryName: string, archived: string, startAt: number, batchSize: number}} params
   * @returns {{success: boolean, data: object, error?: object}}
   */
  getCustomerCoupons: async (params) => {
    const { uid } = params;
    try {
      const couponList = {};
      const snapshot = await firestore.collection(`customers/${uid}/coupons`).get();
      snapshot.forEach((doc) => {
        couponList[doc.id] = doc.data();
      });
      return {
        success: true,
        data: couponList,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error,
      };
    }
  },
};

export default customerCoupnsApi;
