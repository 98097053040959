import actions from './actions';
import bannersApi from '../../apis/Banners';
import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

function* getBanners() {
  const { success, data, error } = yield bannersApi.getBanners();
  if (success) {
    yield put({type: actions.GET_BANNERS_SUCCESS, banners: data })
  } else {
    yield put({ type: actions.GET_BANNERS_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000
    })
  }
}

function* updateBannerImage(params) {
  const { success, data, error } = yield bannersApi.changeBannerPicture(params);
  if (success) {
    yield put({ type: actions.EDIT_BANNER_PICTURE_SUCCESS, banners: data });
  } else {
    yield put({ type: actions.EDIT_BANNER_PICTURE_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* updateBannerCategory(params) {
  const { success, error } = yield bannersApi.changeBannerCategory(params);
  if (success) {
    yield put({ type: actions.EDIT_BANNER_CATEGORY_SUCCESS });
    yield put({ type: actions.GET_BANNERS });
  } else {
    yield put({ type: actions.EDIT_BANNER_CATEGORY_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* deleteBanner(params) {
  const { success, error } = yield bannersApi.deleteBannerPicture(params);
  if (success) {
    yield put({ type: actions.DELETE_BANNER_SUCCESS });
    yield put({ type: actions.GET_BANNERS });
  } else {
    yield put({ type: actions.DELETE_BANNER_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

export default function* bannersSaga() {
  yield all([
    takeLatest(actions.GET_BANNERS, getBanners),
    takeLatest(actions.EDIT_BANNER_PICTURE, updateBannerImage),
    takeLatest(actions.EDIT_BANNER_CATEGORY, updateBannerCategory),
    takeLatest(actions.DELETE_BANNER, deleteBanner),
  ]);
}