import { firestore } from '../../utils/firebase';
import { dateTimeSlots, defaultTimeSlots } from '../../schemas/configuration';
import Joi from '@hapi/joi';

/**
 *
 * @type {{
 * setAreaPostage: dashboardConfigurationApi.setAreaPostage,
 * updateDateTimeSlot: dashboardConfigurationApi.updateDateTimeSlot,
 * createDefaultDeliveryTimeSlot: dashboardConfigurationApi.createDefaultDeliveryTimeSlot,
 * deleteDefaultDeliveryTimeSlot: dashboardConfigurationApi.deleteDefaultDeliveryTimeSlot,
 * getDateTimeSlot: dashboardConfigurationApi.getDateTimeSlot,
 * createDateTimeSlot: dashboardConfigurationApi.createDateTimeSlot,
 * getDefaultDeliveryTimeSlots: dashboardConfigurationApi.getDefaultDeliveryTimeSlots,
 * deleteDateTimeSlot: dashboardConfigurationApi.deleteDateTimeSlot,
 * getAreaPostage: dashboardConfigurationApi.getAreaPostage,
 * updateDefaultDeliveryTimeSlot: dashboardConfigurationApi.updateDefaultDeliveryTimeSlot,
 * getAllDateTimeSlot: dashboardConfigurationApi.getAllDateTimeSlot
 * }}
 */
const dashboardConfigurationApi = {
  /**
   *
   * @returns {Promise<{success: boolean, error: *}|{data: [], success: boolean}>}
   */
  getAreaPostage: async () => {
    let areaPostageList = [];
    try {
      const areaPostages = await firestore
        .collection('configs')
        .doc('deliveryConfig')
        .collection('suburbPostage')
        .get();

      if (areaPostages.size !== 0) {
        areaPostages.forEach((areaPostage) => {
          areaPostageList.push({
            ...areaPostage.data(),
            postcode: areaPostage.id
          });
        });
      }
      // console.log(areaPostageList);
      return {
        success: true,
        data: areaPostageList
      };
    } catch (error) {
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param areaPostages
   * @returns {Promise<{data: *, success: boolean}>}
   */
  setAreaPostage: async ({ areaPostages }) => {
    if (areaPostages.length === 0) {
      return {
        success: false,
        error: new Error('empty input provided')
      };
    }

    let writePromiseList = [];
    try {
      areaPostages.forEach((areaPostage) => {
        const { postcode: id } = areaPostage;
        writePromiseList.push(
          firestore
            .collection('configs/deliveryConfig/suburbPostage')
            .doc(id)
            .set(areaPostage)
        );
      });

      const result = await Promise.all(writePromiseList);
      console.log(result);
      return {
        success: true,
        data: areaPostages.length
      };
    } catch (error) {
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param timeSlots
   * - end: string, end time of delivery, format: HH:MM, 24hrs format
   * - start: string, start time of delivery, format: HH:MM, 24hrs format
   * - lastOrderTime: string, lastOrderTime time of delivery, format: HH:MM, 24hrs format
   * - orderCount: number, total number of order in that period
   * @returns {Promise<{success: boolean, error: *}>}
   */
  createDefaultDeliveryTimeSlot: async ({ timeSlots }) => {
    const { error, value } = defaultTimeSlots.validate(timeSlots);
    if (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }

    try {
      await firestore
        .collection('configs/deliveryConfig/time')
        .doc('default')
        .set({});
      await firestore
        .collection('configs/deliveryConfig/time')
        .doc('default')
        .set({
          timeSlots: value
        });
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @returns {Promise<firebase.firestore.DocumentData|{}>}
   */
  getDefaultDeliveryTimeSlots: async () => {
    try {
      const defaultTimeSlots = await firestore
        .collection('configs/deliveryConfig/time')
        .doc('default')
        .get();
      console.log(defaultTimeSlots.data());
      if (defaultTimeSlots.exists) {
        return defaultTimeSlots.get('timeSlots');
      }

      return {
        success: false,
        error: new Error('Empty from firestore')
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param timeSlots
   * @returns {Promise<{success: boolean, error: *}>}
   */
  updateDefaultDeliveryTimeSlot: async ({ timeSlots }) => {
    const { error, value } = defaultTimeSlots.validate(timeSlots);
    if (error) {
      return {
        error,
        success: false
      };
    }

    try {
      await firestore
        .collection('configs/deliveryConfig/time')
        .doc('default')
        .set({
          timeSlots: value
        });
    } catch (error) {
      console.log(error);
      return {
        error,
        success: false
      };
    }
  },

  /**
   *
   * @returns {{success: boolean, error: *}}
   */
  deleteDefaultDeliveryTimeSlot: () => {
    try {
      firestore
        .collection('configs/deliveryConfig/time')
        .doc('default')
        .delete();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   * @param timeSlots
   * Example: `[
   *   { id:'02-02-2020', timeSlots: [
   *     { end: '01:00', start: '02:00', lastOrderTime:'03:00', orderCount: 99 },
   *        ...
   *     ]},
   *   { id:'01-01-2020', timeSlots: [
   *     { end: '01:00', start: '02:00', lastOrderTime:'03:00', orderCount: 101 },
   *      ...
   *   ]}
   *  ]`
   * - id: string, date id, format dd-mm-yyyy
   * - timeSlots, array of object, format {end, start, lastOrderTime, orderCount}, {same as before}
   * - end: string, end time of delivery, format: HH:MM, 24hrs format
   * - start: string, start time of delivery, format: HH:MM, 24hrs format
   * - lastOrderTime: string, lastOrderTime time of delivery, format: HH:MM, 24hrs format
   * - orderCount: number, total number of order in that period
   * @returns {Promise<{success: boolean}>}
   */
  createDateTimeSlot: async (timeSlots) => {
    const { error, value } = dateTimeSlots.validate(timeSlots);
    if (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }

    const promiseList = [];
    try {
      value.forEach((date) => {
        const createdTimeSlot = firestore
          .collection('configs/deliveryConfig/deliverySlots')
          .doc(date.id)
          .set({
            timeSlots: date.timeSlots
          });
        promiseList.push(createdTimeSlot);
      });

      const result = await Promise.all(promiseList);
      console.log(result);
      return {
        success: true,
        data: value
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param date
   * string, format dd-mm-yyyy
   * @returns {Promise<{msg: string, success: boolean, error}|{data: any}|{msg: string, data: {}}>}
   */
  getDateTimeSlot: async (date) => {
    const { error, value } = Joi.string()
      .pattern(/^(\d{1,2})-(\d{1,2})-(\d{4})$/)
      .validate(date);
    if (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }

    const data = await firestore
      .collection('configs/deliveryConfig/deliverySlots')
      .doc(value)
      .get();
    if (!data.exists) {
      return {
        success: true,
        data: {
          date,
          slots: []
        }
      };
    }
    return {
      success: true,
      data: { date, slots: data.get('timeSlots') }
    };
  },

  /**
   *
   * @returns {Promise<{success: boolean, error: *}>}
   */
  getAllDateTimeSlot: async () => {
    const allDateTimeSlots = [];
    try {
      const allDate = await firestore
        .collection('configs/deliveryConfig/deliverySlots')
        .get();
      if (allDate.length === 0) {
        return {
          success: true,
          data: {}
        };
      }

      allDate.forEach((date) => {
        allDateTimeSlots[date.id] = date.data();
      });

      console.log(allDateTimeSlots);
      return {
        success: true,
        data: allDateTimeSlots
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param time
   * Example: `[
   *   { id:'02-02-2020', timeSlots: [
   *     { end: '01:00', start: '02:00', lastOrderTime:'03:00', orderCount: 99 },
   *        ...
   *     ]},
   *   { id:'01-01-2020', timeSlots: [
   *     { end: '01:00', start: '02:00', lastOrderTime:'03:00', orderCount: 101 },
   *      ...
   *   ]}
   *  ]`
   * - id: string, date id, format dd-mm-yyyy
   * - timeSlots, array of object, format {end, start, lastOrderTime, orderCount}, {same as before}
   * - end: string, end time of delivery, format: HH:MM, 24hrs format
   * - start: string, start time of delivery, format: HH:MM, 24hrs format
   * - lastOrderTime: string, lastOrderTime time of delivery, format: HH:MM, 24hrs format
   * - orderCount: number, total number of order in that period
   * @returns {Promise<{success: boolean, error: *}>}
   */
  updateDateTimeSlot: async (time) => {
    const { error, value } = dateTimeSlots.validate(time);
    if (error) {
      console.log(error);
      return {
        error,
        success: false
      };
    }

    const promiseList = [];
    try {
      value.forEach((date) => {
        const updatedDate = firestore
          .collection('configs/deliveryConfig/deliverySlots')
          .doc(date.id)
          .set({ timeSlots: date.timeSlots });
        promiseList.push(updatedDate);
      });

      await Promise.all(promiseList);
      return {
        success: true
      };
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  },

  /**
   *
   * @param dateId
   * - string, format dd-mm-yyyy
   * @returns {{data: {}, success: boolean}}
   */
  deleteDateTimeSlot: (dateId) => {
    if (typeof dateId !== 'string') {
      return {
        success: false,
        error: new Error('Invalid Input Data Type')
      };
    }

    const { error, value } = Joi.string()
      .pattern(/^(\d{1,2})-(\d{1,2})-(\d{4})$/)
      .validate(dateId);
    if (error) {
      console.log(error);
      return {
        success: false,
        msg: 'Invalid Input Data Format',
        data: {}
      };
    }
    firestore
      .collection('configs/deliveryConfig/deliverySlots')
      .doc(value)
      .delete();
    return {
      success: true,
      data: {}
    };
  },

  getDeliveryUpstairsConfig: async () => {
    try {
      const configSnapshot = await firestore
        .doc('configs/deliveryConfig')
        .get();
      const {
        deliveryToUpstairs,
        deliveryToUpstairsFee
      } = configSnapshot.data();
      return {
        success: true,
        data: { deliveryToUpstairs, deliveryToUpstairsFee }
      };
    } catch (error) {
      return { success: false, error };
    }
  },
  updateDeliveryUpstairsConfig: async ({
    deliveryToUpstairs,
    deliveryToUpstairsFee
  }) => {
    console.log({
      deliveryToUpstairs,
      deliveryToUpstairsFee
    });
    try {
      await firestore
        .doc('configs/deliveryConfig')
        .update({ deliveryToUpstairs, deliveryToUpstairsFee });
      return {
        success: true,
        data: { deliveryToUpstairs, deliveryToUpstairsFee }
      };
    } catch (error) {
      return { success: false, error };
    }
  }
};

export default dashboardConfigurationApi;
