import { categorySchema } from '../schemas/category';
import { firestore } from '../utils/firebase';
import Axios from '../utils/axios';
import { v4 as uuidv4 } from 'uuid';

const categoriesApi = {
  getCategories: async () => {
    try {
      const response = await Axios.get(
        'https://asia-northeast1-helen-ecommerce.cloudfunctions.net/resources/categories'
      );
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  },

  createCategory: async (params) => {
    const { newCategory } = params
    const { error, value } = categorySchema.validate(newCategory)
    if (error) {
      console.log(error)
      return {
        success: false,
        error
      }
    }

    try {
      value.parentCategory = firestore.doc(`categories/${value.parentCategory}`)
      let newCategory = await firestore.doc(`categories/${value.id}`).get()
      // Duplicate Id
      if (newCategory.exists) {
        value.id = value.id + '#' + uuidv4()
        console.log(`Category with Id: ${value.id} exist, name will be modified to ${value.name}`);
      }

      await firestore.doc(`categories/${value.id}`).set({ name: value.name, image: value.image, parentCategory: value.parentCategory, visible: value.visible})
      newCategory = await firestore.doc(`categories/${value.id}`).get()
      if (newCategory.data()) {
        return {
          success: true,
          data: newCategory.data()
        }
      }

      console.log('Untrack issue happen when create the new category');
      return {
        success: false,
        error: new Error ('Untrack issue happen when create the new category')
      }
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error,
      };
    }
  },

  updateCategory: async ({ id, category }) => {
    //return structure: {success: boolean, data? : modified category, error? : error}
    const { error, value } = categorySchema.validate(category)
    if (error) {
      console.log(error);
      return {
        success: false,
        error
      }
    }

    try {
      const newCategory = await firestore.doc(`categories/${id}`).get()
      if (!newCategory.exists) {
        console.log(`Category with Id: ${value.id} does not exist`);
        return {
          success: false,
          error: new Error(`Category with Id: ${value.id} does not exist`)
        }
      }

      await newCategory.ref.update(value)
      const updateCategory= await firestore.doc(`categories/${id}`).get()

      if (updateCategory.data()) {
        console.log(updateCategory.data());
        return {
          success: true,
          data: updateCategory.data()
        }
      }

      console.log('Untrack issue happen when create the new category');
      return {
        success: false,
        error: new Error ('Untrack issue happen when create the new category')
      }
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error,
      };
    }
  },

  deleteCategory: async ({ id }) => {
    //return structure: {success: boolean, error? : error}
    const promiseList = []
    try {
      if (id === 'root') {
        console.log('Delete Root is dangerous, action does not allowed.');
        return {
          success: false,
          error: new Error('Delete Root is dangerous, action does not allowed.')
        }
      }

      const categoryForDelete = await firestore.doc(`categories/${id}`).get()
      const rootCategory = firestore.doc('categories/root')
      const currentParentCategoryName = categoryForDelete.get('parentCategory')
      console.log(categoryForDelete.data());
      if (currentParentCategoryName.isEqual(rootCategory)) {
        // level 1
        const categories = await firestore.collection('categories').get();
        categories.forEach((category) => {
          const categoryParentCategory = category.get('parentCategory')
          if (categoryParentCategory !== undefined && categoryParentCategory.isEqual(categoryForDelete.ref)) {
            console.log('correct');
            promiseList.push(category.ref.delete())
          }
        })

        await Promise.all(promiseList)
        await categoryForDelete.ref.delete()
        return {
          success: true
        }
      } else {
        // level 2
        await categoryForDelete.ref.delete()
        return {
          success: true
        }
      }
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      }
    }
  }
};

export default categoriesApi;
