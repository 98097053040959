const actions = {
  FETCH_COUPON_LIST: 'FETCH_COUPON_LIST',
  FETCH_COUPON_LIST_SUCCESS: 'FETCH_COUPON_LIST_SUCCESS',
  FETCH_COUPON_LIST_FAIL: 'FETCH_COUPON_LIST_FAIL',

  UPDATE_INVITATION_COUPON_CONFIG: 'UPDATE_INVITATION_COUPON_CONFIG',
  UPDATE_INVITATION_COUPON_CONFIG_SUCCESS:
    'UPDATE_INVITATION_COUPON_CONFIG_SUCCESS',
  UPDATE_INVITATION_COUPON_CONFIG_FAIL: 'UPDATE_INVITATION_COUPON_CONFIG_FAIL',

  GET_INVITATION_COUPON_CONFIG: 'GET_INVITATION_COUPON_CONFIG',
  GET_INVITATION_COUPON_CONFIG_SUCCESS: 'GET_INVITATION_COUPON_CONFIG_SUCCESS',
  GET_INVITATION_COUPON_CONFIG_FAIL: 'GET_INVITATION_COUPON_CONFIG_FAIL',

  DELETE_COUPON: 'DELETE_COUPON',
  DELETE_COUPON_SUCCESS: 'DELETE_COUPON_SUCCESS',
  DELETE_COUPON_FAIL: 'DELETE_COUPON_FAIL',

  fetchCouponList: () => ({
    type: actions.FETCH_COUPON_LIST
  }),

  deleteCoupon: ({ id }) => ({
    type: actions.DELETE_COUPON,
    id
  }),

  getInvitationCouponConfig: () => ({
    type: actions.GET_INVITATION_COUPON_CONFIG
  }),

  updateInvitationCouponConfig: ({ code, toInviter, toInvitee }) => ({
    type: actions.UPDATE_INVITATION_COUPON_CONFIG,
    code,
    toInviter,
    toInvitee
  })
};

export default actions;