import { firestore, firebase } from '../utils/firebase';
import moment from 'moment';

const flashSaleApi = {
  getFlashSaleTimeConfig: async () => {
    try {
      const flashSaleConfigSnapshot = await firestore
        .doc('configs/flashSaleConfig')
        .get();
      const { nextStartTime, nextEndTime } = flashSaleConfigSnapshot.data();
      return { success: true, data: { nextStartTime, nextEndTime } };
    } catch (error) {
      return { success: false, error };
    }
  },

  setFlashSaleTime: async ({ start, end }) => {
    try {
      const flashSaleConfigSnapshot = await firestore
        .doc('configs/flashSaleConfig')
        .get();
      const { nextStartTime } = flashSaleConfigSnapshot.data();
      start = start || nextStartTime;
      end = end || moment(start, 'YYYY-MM-DD HH:mm').add(7, 'days').format('YYYY-MM-DD HH:mm');
      flashSaleConfigSnapshot.ref.update({
        nextStartTime: start,
        nextEndTime: end
      });
      return {
        success: true,
        data: { nextStartTime: start, nextEndTime: end }
      };
    } catch (error) {
      return { success: false, error };
    }
  },

  getFlashSaleProducts: async () => {
    try {
      const productList = [];
      const snapshot = await firestore
        .collection('products')
        .where('categories', 'array-contains', 'flash-sale')
        .where('archived', '==', false)
        .get();
      if (snapshot.empty) {
        console.log('empty');
        return { success: true, data: [] };
      }
      snapshot.forEach((doc) => {
        productList.push(doc.data());
      });
      return { success: true, data: productList };
    } catch (error) {
      return { success: false, error };
    }
  },

  getPreFlashSale: async () => {
    try {
      const flashSaleConfigSnapshot = await firestore
        .doc('configs/flashSaleConfig')
        .get();
      const preFlashSaleSkus = flashSaleConfigSnapshot.data().preFlashSale;
      const preFlashSaleProductsSnapshot = await Promise.all(
        preFlashSaleSkus.map((sku) => {
          return firestore.collection('products').doc(sku).get();
        })
      );
      return {
        success: true,
        data: preFlashSaleProductsSnapshot.map((snapshot) => snapshot.data())
      };
    } catch (error) {
      return { success: false, error };
    }
  },

  setPreFlashSale: async ({ sku, actionType }) => {
    try {
      const configRef = firestore.doc('configs/flashSaleConfig');
      if (actionType === 'add')
        await configRef.update({
          preFlashSale: firebase.firestore.FieldValue.arrayUnion(sku)
        });
      else
        await configRef.update({
          preFlashSale: firebase.firestore.FieldValue.arrayRemove(sku)
        });
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  startFlashSale: async () => {
    try {
      // step 0: get flashSaleConfig data
      const flashSaleConfigSnapshot = await firestore
        .doc('configs/flashSaleConfig')
        .get();
      // step 1: remove products from 'flash-sale' category
      const falshSaleProductsSnapshot = await firestore
        .collection('products')
        .where('categories', 'array-contains', 'flash-sale')
        .get();
      if (!falshSaleProductsSnapshot.empty) {
        falshSaleProductsSnapshot.forEach((product) => {
          product.ref.update({
            categories: firebase.firestore.FieldValue.arrayRemove('flash-sale'),
            discountPrice: null
          });
        });
      }
      // setp 2: set products in 'pre-flash-sale' with category 'flash-sale'
      const preFlashSaleArray = flashSaleConfigSnapshot.data().preFlashSale;
      await Promise.all(
        preFlashSaleArray.map((sku) => {
          return firestore.doc(`products/${sku}`).update({
            categories: firebase.firestore.FieldValue.arrayUnion('flash-sale')
          });
        })
      );
      await flashSaleConfigSnapshot.ref.update({ preFlashSale: [] });
      // step 3: set 'nextStartTimee' and 'nextEndTime'
      const newNextStartTime = moment().add(7, 'days').format('YYYY-MM-DD 00:00');
      const newNextEndTime = moment().add(14, 'days').format('YYYY-MM-DD 00:00');
      await flashSaleConfigSnapshot.ref.update({
        nextStartTime: newNextStartTime,
        nextEndTime: newNextEndTime
      });
      // return value
      // console.log('success now');
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  }
};


export default flashSaleApi;