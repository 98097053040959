const actions = {
  GET_DELIVERY_COUNT: 'GET_DELIVERY_COUNT',
  GET_DELIVERY_COUNT_SUCCESS: 'GET_DELIVERY_COUNT_SUCCESS',
  GET_DELIVERY_COUNT_FAIL: 'GET_DELIVERY_COUNT_FAIL',

  getDeliveryCount: () => ({
    type: actions.GET_DELIVERY_COUNT
  })
};

export default actions;