import { firestore } from '../utils/firebase';
import Axios from '../utils/axios';

export const getMessageList = async () => {
  try {
    const messagesSnapshot = await firestore
      .collection('messageHistory')
      .orderBy('sendAt', 'desc')
      .limit(40)
      .get();
    const messages = [];
    if (messagesSnapshot.empty) {
      return { success: true, data: [] };
    }
    messagesSnapshot.forEach((message) => {
      const messageData = message.data();
      messages.push(messageData);
    });
    return { success: true, data: messages };
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};

export const sendMessage = async ({ message, title }) => {
  const MESSAGE_URL =
    'https://asia-northeast1-helen-ecommerce.cloudfunctions.net/resources/message';
  // const MESSAGE_URL = 'http://localhost:5001/helen-ecommerce/asia-northeast1/resources/message';
  try {
    const result = await Axios.post(MESSAGE_URL, { message, title });
    if (result.status === 200) {
      return {
        success: true,
        data: result.data
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};
