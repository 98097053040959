const actions = {
  SEND_SMS: 'SEND_SMS',
  SEND_SMS_SUCCESS: 'SEND_SMS_SUCCESS',
  SEND_SMS_FAIL: 'SEND_SMS_FAIL',
  USER_PASSWORD_LOGIN: 'USER_PASSWORD_LOGIN',
  USER_PHONE_LOGIN: 'USER_PHONE_LOGIN',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAIL: 'SIGN_OUT_FAIL',
  FETCH_CURRENT_USER_DATA: 'FETCH_CURRENT_USER_DATA',
  FETCH_CURRENT_USER_DATA_SUCCESS: 'FETCH_CURRENT_USER_DATA_SUCCESS',
  FETCH_CURRENT_USER_DATA_FAIL: 'FETCH_CURRENT_USER_DATA_FAIL',
  CHECK_VERIFICATION_CODE: 'CHECK_VERIFICATION_CODE',
  CHECK_VERIFICATION_CODE_SUCCESS: 'CHECK_VERIFICATION_CODE_SUCCESS',
  CHECK_VERIFICATION_CODE_FAIL: 'CHECK_VERIFICATION_CODE_FAIL',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',

  signIn: ({ userInfo }) => ({
    type: actions.SIGN_IN,
    userInfo,
  }),
  signOut: () => ({
    type: actions.SIGN_OUT,
  }),
  passwordLogin: ({ email, password }) => ({
    type: actions.USER_PASSWORD_LOGIN,
    email,
    password,
  }),
  retriveCurrentUserData: ({ uid }) => ({
    type: actions.FETCH_CURRENT_USER_DATA,
    uid,
  }),
  sendSMS: ({ phoneNumber }) => ({
    type: actions.SEND_SMS,
    phoneNumber,
  }),
  checkVerificationCode: ({ verificationCode }) => ({
    type: actions.CHECK_VERIFICATION_CODE,
    verificationCode,
  }),
  changePassword: (params) => ({
    type: actions.CHANGE_PASSWORD,
    ...params,
  })
};

export default actions;
