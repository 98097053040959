import User from './User/reducer';
import Customers from './Customers/reducers';
import Products from './Product/reducer';
import Categories from './Categories/reducer';
import Orders from './Orders/reducers';
import Deliveries from './DeliveryConfig/reducer'
import DeliveryTimeSlots from './DeliveryTimeSlots/reducer';
import Coupons from './Coupon/reducer';
import Banners from './Banners/reducer';
import UserManagement from './UserManagement/reducer'
import Workspace from './Workspace/reducers';
import PostcodeDelivery from './PostcodeDelivery/reducer';
import CustomizeDeliveryTime from './EditDeliveryTime/reducer';
import HotSearch from './HotSearch/reducer';
import DeliveryCount from './DeliveryCount/reducer';
import Message from './Message/reducer';
import SplashScreen from './SplashScreen/reducer';

export default {
  User,
  Customers,
  Products,
  Categories,
  Orders,
  Deliveries,
  DeliveryTimeSlots,
  Coupons,
  Banners,
  UserManagement,
  Workspace,
  PostcodeDelivery,
  CustomizeDeliveryTime,
  HotSearch,
  DeliveryCount,
  Message,
  SplashScreen
};
