import { firebase, firestore } from '../utils/firebase';
import { newCoupons, coupons } from '../schemas/coupon';
import moment from 'moment';

const MILLISECOND_IN_ONE_DAY = 60 * 60 * 24 * 1000;

/**
 *
 * @type {{
 * assignCouponsToCustomer: couponApi.assignCouponsToCustomer,
 * deleteCoupon: couponApi.deleteCoupon,
 * getCoupons: couponApi.getCoupons,
 * createCoupon: couponApi.createCoupon,
 * getCoupon: couponApi.getCoupon,
 * updateCoupon: couponApi.updateCoupon,
 * removeCouponsFromCustomer:
 * couponApi.removeCouponsFromCustomer
 * }}
 */
const couponApi = {
  /**
   * Create New Coupon
   * @param input, array of object
   * - [{
   *   code: string,
   *   discountType: string
   *   discountAmount: number
   *   ttl: number
   * }, ...]
   * @returns {{data: {}, success: boolean, error: *}|{data: Promise<unknown[]>, success: boolean}}
   */
  createCoupon: async ({ input }) => {
    const { error, value } = newCoupons.validate(input);

    if (error) {
      return {
        success: false,
        error,
        data: {}
      };
    }

    try {
      const promiseList = [];
      value.forEach((coupon) => {
        const addedCoupon = firestore.collection('coupons').add(coupon);
        promiseList.push(addedCoupon);
      });

      const result = await Promise.all(promiseList);
      console.log(result);
      return {
        success: true,
        data: result
      };
    } catch (error) {
      console.log(error);
      return {
        error,
        data: {},
        success: false
      };
    }
  },

  /**
   *
   * @param couponId, string
   * @returns {Promise<{data: {}, success: boolean, error: string}|{data: ((T&{id: *})|undefined), success: boolean}|{data: {}, success: boolean, error: *}>}
   */
  getCoupon: async (couponId) => {
    if (!couponId) {
      return {
        success: false,
        error: new Error('coupon Id should not be null'),
        data: {}
      };
    }

    try {
      const coupon = await firestore.collection('coupons').doc(couponId).get();
      if (!coupon.exists) {
        return {
          data: {},
          error: new Error(`coupon with id ${couponId} does not exist`),
          success: false
        };
      }

      return {
        success: true,
        data: {
          ...coupon.data(),
          id: coupon.id
        }
      };
    } catch (error) {
      console.log(error);
      return {
        error,
        success: false,
        data: {}
      };
    }
  },

  /**
   *
   * @returns {Promise<{data: {}, success: boolean, error: *}|{data: {}, success: boolean}|{data: {coupons: []}, success: boolean}>}
   */
  getCoupons: async () => {
    const data = [];
    try {
      const coupons = await firestore.collection('coupons').get();
      if (coupons.empty) {
        return {
          success: true,
          data: {}
        };
      }

      coupons.forEach((coupon) => {
        data.push({ ...coupon.data(), id: coupon.id });
      });
      return {
        success: true,
        data: {
          coupons: data
        }
      };
    } catch (error) {
      return {
        success: false,
        data: {},
        error
      };
    }
  },

  /**
   *
   * @param input, array of object
   * - [{
   *   id: string,
   *   code: string,
   *   discountType: string
   *   discountAmount: number,
   *   ttl: number
   * }, ...]
   * @returns {Promise<{success: boolean, error: *}|{data: *, success: boolean}>}
   */
  updateCoupon: async ({ input }) => {
    // TODO update Corresponding Product
    const { error, value } = coupons.validate(input);
    if (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }

    try {
      const promiseList = [];
      value.forEach((coupon) => {
        const { id, ...res } = coupon;
        const updatedCoupon = firestore.collection('coupons').doc(id).set(res);
        promiseList.push(updatedCoupon);
      });

      await Promise.all(promiseList);
      return {
        success: true,
        data: value
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param couponId, string
   * @returns {Promise<{success: boolean, error: *}|{success: boolean, error: Error}|{data: *, success: boolean}>}
   */
  deleteCoupon: async (couponId) => {
    if (!couponId) {
      return {
        success: false,
        error: new Error('Invalid Input Type of Null')
      };
    }

    try {
      await firestore.collection('coupons').doc(couponId).delete();
      return {
        success: true,
        data: couponId
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param input
   * - [{
   *   code: string,
   *   discountType: string,
   *   discountAmount: number,
   *   ttl: number,
   *   number: number
   * }, ...]
   * @param customerId, string
   * @returns {Promise<{success: boolean, error: *}|{success: boolean, error: Error}|{data: [], success: boolean}>}
   */
  assignCouponsToCustomer: async ({ input, customerId }) => {
    const { error, value } = newCoupons.validate(input);
    if (error) {
      console.log(error);
      return {
        error,
        success: false
      };
    }

    if (!customerId || typeof customerId !== 'string') {
      return {
        success: false,
        error: new Error('Invalid Null Customer Id ')
      };
    }

    try {
      const customer = await firestore
        .collection('customers')
        .doc(customerId)
        .get();
      if (!customer.exists) {
        return {
          success: false,
          error: new Error(`No Customer Found with Id ${customerId}`)
        };
      }
      const promiseList = [];
      const now = firebase.firestore.Timestamp.now().toMillis();
      value.forEach((coupon) => {
        let counter = 0;
        while (counter < coupon.number) {
          const expiryDate = firebase.firestore.Timestamp.fromMillis(
            moment(now + MILLISECOND_IN_ONE_DAY * coupon.ttl)
              .endOf('day')
              .valueOf()
          );
          const newCoupon = {
            code: coupon.code,
            discountAmount: coupon.discountAmount,
            discountType: coupon.discountType,
            ttl: coupon.ttl,
            status: 'unused',
            expiryDate
          };

          promiseList.push(customer.ref.collection('coupons').add(newCoupon));
          counter++;
        }
      });

      await Promise.all(promiseList);

      const data = [];
      const currentCoupons = await customer.ref.collection('coupons').get();
      if (currentCoupons.empty) {
        return {
          success: false,
          error: new Error('Coupons are empty, the new Coupon is not added')
        };
      }

      currentCoupons.forEach((currentCoupon) => {
        data.push({ id: currentCoupon.id, ...currentCoupon });
      });

      return {
        success: true,
        data
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param couponIds, array of string, ['asdf09878s90s8d7f', ...]
   * @param customerId, string
   * @returns {Promise<{success: boolean, error: *}|{success: boolean, error: Error}|{data: *, success: boolean}>}
   */
  removeCouponsFromCustomer: async ({ couponIds, customerId }) => {
    if (!couponIds || couponIds.length === 0) {
      return {
        success: false,
        error: new Error('Invalid Input Fields')
      };
    }

    if (!customerId || typeof customerId !== 'string') {
      return {
        success: false,
        error: new Error('Customer Id should not be null')
      };
    }

    try {
      const customer = await firestore
        .collection('customers')
        .doc(customerId)
        .get();
      if (!customer.exists) {
        return {
          success: false,
          error: new Error(`No Customer Found with Id ${customerId}`)
        };
      }

      const promiseList = [];
      couponIds.forEach((couponId) => {
        promiseList.push(
          customer.ref.collection('coupons').doc(couponId).delete()
        );
      });

      await Promise.all(promiseList);
      return {
        success: true,
        data: couponIds
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  getInvitationCouponConfig: async () => {
    try {
      const configSnapshot = await firestore.doc('configs/signUpConfig').get();
      const {
        InvitationCoupon,
        assignCouponToInviter,
        assignCouponToInvitee
      } = configSnapshot.data();
      return {
        success: true,
        data: {
          coupon: InvitationCoupon,
          toInviter: assignCouponToInviter,
          toInvitee: assignCouponToInvitee
        }
      };
    } catch (error) {
      return { success: false, error };
    }
  },

  updateInvitationCouponConfig: async ({ code, toInviter, toInvitee }) => {
    try {
      const configRef = firestore.doc('configs/signUpConfig');
      const newConfig = {
        InvitationCoupon: code,
        assignCouponToInviter: toInviter,
        assignCouponToInvitee: toInvitee
      };
      await configRef.update(newConfig);
      return { success: true, data: { coupon: code, toInviter, toInvitee } };
    } catch (error) {
      return { success: false, error };
    }
  }
};

export default couponApi;
