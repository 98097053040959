import React, { useRef, useEffect } from 'react';
import { Button, Drawer, Form, Col, Row, Input, Spin, Select, Avatar } from 'antd';
import propTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
// import customer from '../schemas/customer';

const { Option } = Select;

const CustomerEdittingDrawer = (props) => {
  const { fetchingSingleCustomer, customerDetails, saveEdit } = props;
  const formRef = useRef();

  useEffect(() => {
    formRef.current && !fetchingSingleCustomer && formRef.current.resetFields();
  }, [customerDetails, fetchingSingleCustomer]);

  const hideDrawerHandler = () => {
    props.closeDrawer();
  };

  const flatObject = (data) => {
    const result = {
      email: data.email,
      phoneNumber: data.phoneNumber,
      ...data.userInfo,
    };
    return result;
  };

  const submitEdit = (values) => {
    const newDetails = { ...customerDetails };
    newDetails.userInfo = {
      ...newDetails.userInfo,
      firstName: values.firstName,
      lastName: values.lastName,
      nickName: values.nickName,
      gender: values.gender,
    };
    props.closeDrawer();
    saveEdit({ uid: newDetails.uid, data: newDetails });
  };

  return (
    <Drawer
      title="Edit Customer Details"
      width={540}
      onClose={hideDrawerHandler}
      visible={props.visibility}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div>
          <Button onClick={hideDrawerHandler} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button form="customerEditForm" key="submit" type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      }
    >
      <Spin spinning={fetchingSingleCustomer} styles="height: 100%">
        {customerDetails.uid && (
          <Form
            layout="vertical"
            id="customerEditForm"
            onFinish={submitEdit}
            ref={formRef}
            hideRequiredMark
            initialValues={flatObject(customerDetails)}
          >
            <div style={styles.avatarContainer}>
              <Avatar src={customerDetails.userInfo.avatar} icon={<UserOutlined />} size={200} />
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true, message: 'Please enter First Name' }]}
                >
                  <Input placeholder="Please enter First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true, message: 'Please enter Last Name' }]}
                >
                  <Input style={{ width: '100%' }} placeholder="Please enter Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[{ required: true, message: 'Please select gender' }]}
                >
                  <Select placeholder="Select a option and change input text above">
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="nickName"
                  label="Nick Name"
                  rules={[{ required: true, message: 'Please enter Last Name' }]}
                >
                  <Input style={{ width: '100%' }} placeholder="Please enter Nick Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  rules={[{ required: true, message: 'Please enter user name' }]}
                >
                  <Input
                    disabled
                    defaultValue={customerDetails.email}
                    value={customerDetails.email}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  rules={[{ required: true, message: 'Please enter Phone Number' }]}
                >
                  <Input
                    defaultValue={customerDetails.phoneNumber}
                    value={customerDetails.phoneNumber}
                    style={{ width: '100%' }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Spin>
    </Drawer>
  );
};

const styles = {
  avatarContainer: {
    width: '100%',
    textAlign: 'center',
  },
};

CustomerEdittingDrawer.propTypes = {
  data: propTypes.any,
  fetchingSingleCustomer: propTypes.bool,
  customerDetails: propTypes.object,
  closeDrawer: propTypes.func,
  visibility: propTypes.bool,
  saveEdit: propTypes.func,
};

export default CustomerEdittingDrawer;
