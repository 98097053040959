import actions from './actions';

const initState = {
  isFetchingHotSearchKeywords: false,
  isUpdatingRecommendation: false,
  isFetchingRecommendation: false,
  keywords: [],
  recommendation: []
};

export default function (state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_HOT_SEARCH_KEYWORDS:
      return {
        ...state,
        isFetchingHotSearchKeywords: true,
        keywords: []
      };
    case actions.GET_HOT_SEARCH_KEYWORDS_SUCCESS:
      return {
        ...state,
        isFetchingHotSearchKeywords: false,
        keywords: action.keywords
      };
    case actions.GET_HOT_SEARCH_KEYWORDS_FAIL:
      return {
        ...state,
        isFetchingHotSearchKeywords: false
      };
    case actions.GET_RECOMMENDATION:
      return {
        ...state,
        isFetchingRecommendation: true,
      };
    case actions.GET_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        isFetchingRecommendation: false,
        recommendation: action.recommendation
      };
    case actions.GET_RECOMMENDATION_FAIL:
      return {
        ...state,
        isFetchingRecommendation: false,
      };
    case actions.UPDATE_RECOMMENDATION:
      return {
        ...state,
        isUpdatingRecommendation: true,
      };
    case actions.UPDATE_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        isUpdatingRecommendation: false,
        recommendation: action.recommendation
      };
    case actions.UPDATE_RECOMMENDATION_FAIL:
      return {
        ...state,
        isUpdatingRecommendation: false,
      };
    default: return state;
  }
}