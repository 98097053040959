import React from 'react';
import { TreeSelect } from 'antd';
import PropTypes from 'prop-types';
const { TreeNode } = TreeSelect;

export default function generateTreeNode(categories, level=null) {
  const newLevel = level ? level + 1 : null;
  if (categories.length !== 0)
    return Array.from(categories).map((entry) => {
      return (
        <TreeNode key={entry.id} value={entry.id} title={entry.name} disabled={level && level >= 3}>
          {generateTreeNode(entry.children, newLevel)}
        </TreeNode>
      );
    });
  return <></>;
}

generateTreeNode.propTypes = {
  categories: PropTypes.array,
  level: PropTypes.any,
  form: PropTypes.any,
}