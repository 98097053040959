const actions = {
  FETCH_PRODUCT_LIST: 'FETCH_PRODUCT_LIST',
  FETCH_PRODUCT_LIST_SUCCESS: 'FETCH_PRODUCT_LIST_SUCCESS',
  FETCH_PRODUCT_LIST_FAIL: 'FETCH_PRODUCT_LIST_FAIL',

  // Fetch product detail by SKU
  FETCH_PRODUCT_DETAIL: 'FETCH_PRODUCT_DETAIL',
  FETCH_PRODUCT_DETAIL_SUCCESS: 'FETCH_PRODUCT_DETAIL_SUCCESS',
  FETCH_PRODUCT_DETAIL_FAIL: 'FETCH_PRODUCT_DETAIL_FAIL',

  // Edit product detail by SKU and new Product object
  EDIT_PRODUCT_DETAIL: 'EDIT_PRODUCT_DETAIL',
  EDIT_PRODUCT_DETAIL_SUCCESS: 'EDIT_PRODUCT_DETAIL_SUCCESS',
  EDIT_PRODUCT_DETAIL_FAIL: 'EDIT_PRODUCT_DETAIL_FAIL',

  // Batch operation
  VALIDATE_PRODUCTS: 'VALIDATE_PRODUCTS',
  VALIDATE_PRODUCTS_SUCCESS: 'VALIDATE_PRODUCTS_SUCCESS',
  VALIDATE_PRODUCTS_FAIL: 'VALIDATE_PRODUCTS_FAIL',
  UPLOAD_PRODUCTS: 'UPLOAD_PRODUCTS',
  UPLOAD_PRODUCTS_SUCCESS: 'UPLOAD_PRODUCTS_SUCCESS',
  UPLOAD_PRODUCTS_FAIL: 'UPLOAD_PRODUCTS_FAIL',
  BATCH_DELETE_PRODUCTS: 'BATCH_DELETE_PRODUCTS',
  BATCH_DELETE_PRODUCTS_SUCCESS: 'BATCH_DELETE_PRODUCTS_SUCCESS',
  BATCH_DELETE_PRODUCTS_FAIL: 'BATCH_DELETE_PRODUCTS_FAIL',
  RESET_BATCH_OPERATION_RESULTS : 'RESET_BATCH_OPERATION_RESULTS',

  fetchProductDetail: ({ sku }) => ({
    type: actions.FETCH_PRODUCT_DETAIL,
    sku,
  }),

  fetchProductList: ({ searchPattern, categories, pageNumber, batchSize, archived }) => ({
    type: actions.FETCH_PRODUCT_LIST,
    searchPattern,
    categories,
    pageNumber,
    batchSize,
    archived,
  }),

  editProductDetail: ({ sku, product }) => ({
    type: actions.EDIT_PRODUCT_DETAIL,
    sku,
    product,
  }),

  validateProducts: () => ({
    type: actions.VALIDATE_PRODUCTS,
  }),

  validateProductsSuccess: () => ({
    type: actions.VALIDATE_PRODUCTS_SUCCESS,
  }),

  validateProductsFail: () => ({
    type: actions.VALIDATE_PRODUCTS_FAIL,
  }),

  uploadProducts: ({ newProductList }) => ({
    type: actions.UPLOAD_PRODUCTS,
    newProductList,
  }),

  batchDeleteProducts: ({ products }) => ({
    type: actions.BATCH_DELETE_PRODUCTS,
    products,
  }),

  resetBatchOperationResults: () => ({
    type: actions.RESET_BATCH_OPERATION_RESULTS
  })
};

export default actions;
