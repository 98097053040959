import React, { useRef, useEffect } from 'react';
import { Drawer, Input, Button, Spin, Form, Radio } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const { Item } = Form;

const UserEditViewDrawer = (props) => {
  // const, var, let
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  // props
  const {
    user,
    isUpdatingSingleUser,
    visibility,
    closeDrawer,
    updateUser,
    resetPassword,
    currentUser,
  } = props;

  // state
  useEffect(() => {
    formRef && formRef.current && formRef.current.resetFields();
  }, [user]);
  // hook
  const formRef = useRef();
  // custom fn
  const submitEditHander = (values) => {
    updateUser({ uid: user.uid, authority: values.authority });
    closeDrawer();
  };

  const resetPasswordHandler = () => {
    resetPassword({ email: user.email });
  };

  // render
  return (
    <>
      <Drawer
        title="Edit User Details"
        width={540}
        onClose={closeDrawer}
        visible={visibility}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div>
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button form="userEditForm" key="submit" type="primary" htmlType="submit">
              Submit
            </Button>
            {user.uid !== currentUser.uid && (
              <Button
                danger
                key="resetPassword"
                onClick={resetPasswordHandler}
                style={{ marginLeft: 8 }}
              >
                Reset Password
              </Button>
            )}
          </div>
        }
      >
        <Spin spinning={isUpdatingSingleUser}>
          {JSON.stringify(user) !== '{}' && (
            <Form
              {...formItemLayout}
              ref={formRef}
              id="userEditForm"
              initialValues={{
                ...user,
                emailVerified: user.emailVerified ? 'Yes' : 'No',
                authority: user.authority ? user.authority : 'developer',
                metadata: {
                  createdAt: moment(user.metadata.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                  lastSignedInTime: moment(user.metadata.lastSignedInTime).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  lastRefreshTime: moment(user.metadata.lastRefreshTime).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                },
              }}
              onFinish={submitEditHander}
            >
              <Item name="uid" label="UID">
                <Input disabled />
              </Item>
              <Item name="displayName" label="Name">
                <Input disabled />
              </Item>
              <Item name="email" label="Email">
                <Input disabled />
              </Item>
              <Item name="emailVerified" label="Email Verified">
                <Input disabled />
              </Item>
              <Item name="phoneNumber" label="Phone Number">
                <Input disabled />
              </Item>
              <Item name={['metadata', 'createdAt']} label="Created At">
                <Input disabled />
              </Item>
              <Item name={['metadata', 'lastSignedInTime']} label="Last Signed In">
                <Input disabled />
              </Item>
              <Item name={['metadata', 'lastRefreshTime']} label="Last Refresh">
                <Input disabled />
              </Item>
              <Item name="authority" label="Authority Type">
                <Radio.Group>
                  <Radio value="admin">Admin</Radio>
                  <Radio value="developer">Developer</Radio>
                </Radio.Group>
              </Item>
            </Form>
          )}
        </Spin>
      </Drawer>
    </>
  );
};

// PropTypes
UserEditViewDrawer.propTypes = {
  user: PropTypes.object,
  currentUser: PropTypes.object,
  isFetchingSingleUser: PropTypes.bool,
  isUpdatingSingleUser: PropTypes.bool,
  visibility: PropTypes.bool,
  closeDrawer: PropTypes.func,
  updateUser: PropTypes.func,
  resetPassword: PropTypes.func,
};

export default UserEditViewDrawer;
