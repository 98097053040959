import actions from './actions';
import newCategoryReducer, { newCategoryInitState } from './NewCategory/reducer';

const initState = {
  categories: {},
  isFetchingCategories: false,
  newCategory: newCategoryInitState,
  categoryModified: null,
  isEditingCategory: false,
  isDeletingCategory: false,
};

function categoriesReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_CATEGORIES:
      return {
        ...state,
        isFetchingCategories: true,
      };
    case actions.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        isFetchingCategories: false,
      };
    case actions.GET_CATEGORIES_FAIL:
      return {
        ...state,
        isFetchingCategories: false,
      };
    case actions.EDIT_CATEGORY:
      return {
        ...state,
        categoryModified: null,
        isEditingCategory: true,
      };
    case actions.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        isEditingCategory: false,
        categoryModified: action.category,
      };
    case actions.EDIT_CATEGORY_FAIL:
      return {
        ...state,
        isEditingCategory: false,
      };
    case actions.DELETE_CATEGORY:
      return {
        ...state,
        isDeletingCategory: true,
      };
    case actions.DELETE_CATEGORY_SUCCESS:
    case actions.DELETE_CATEGORY_FAIL:
      return {
        ...state,
        isDeletingCategory: false,
      }
    default:
      return state;
  }
}

export default (state = initState, { type, ...action }) => {
  return {
    ...categoriesReducer(state, { type, ...action }),
    newCategory: newCategoryReducer(state.newCategory, { type, ...action }),
  };
}
