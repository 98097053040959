import actions from './action';
export const newCategoryInitState = {
  newCategory: {},
  isAddingNewCategory: false,
  isNewCategoryAdded: false,
};

export default function newCategoryReducer(state = newCategoryInitState, { type, ...action }) {
  switch (type) {
    case actions.CREATE_CATEGORY:
      return {
        ...state,
        isAddingNewCategory: true
      };
    case actions.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isAddingNewCategory: false,
        isNewCategoryAdded: true,
        newCategory: action.newCategory
      };
    case actions.CREATE_CATEGORY_FAIL:
      return {
        ...state,
        isAddingNewCategory: false,
      };
    default:
      return state;
  }
}