import newUserActions from './NewUserManagement/actions';
const actions = {
  // fetch users list
  ...newUserActions,
  FETCH_USERS_LIST: 'FETCH_USERS_LIST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAIL: 'FETCH_USERS_FAIL',

  // reset password
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',

  //update user auth type
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',

  fetchUserList: () => ({
    type: actions.FETCH_USERS_LIST,
  }),

  resetPassword: ({ email }) => ({
    type: actions.RESET_PASSWORD,
    email,
  }),

  updateUser: ({ uid, authority }) => ({
    type: actions.UPDATE_USER,
    uid,
    authority,
  })
};

export default actions