import React from 'react';
import { Table, Button, Tag, Popconfirm } from 'antd';
import PropTypes from 'prop-types';

const CouponTable = (props) => {
  const { coupons, deleteCoupon } = props;
  const onRowDelete = (record) => {
    console.log(record);
    deleteCoupon({ id: record.id });
  };
  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: '20%',
    },
    {
      title: 'Discount Type',
      dataIndex: 'discountType',
      width: '15%', // eslint-disable-next-line
      render: (type) => <Tag color={type === 'fix' ? 'blue' : 'volcano'}>{type.toUpperCase()}</Tag>,
      filters: [
        {
          text: 'Fix',
          value: 'fix',
        },
        {
          text: 'Percent',
          value: 'percent',
        },
      ],
      onFilter: (value, record) => record.discountType.indexOf(value) === 0,
    },
    {
      title: 'Discount Amount',
      dataIndex: 'price',
      width: '15%',
      filters: [
        {
          text: '0 - 5',
          value: [0, 5],
        },
        {
          text: '5 - 20',
          value: [5, 20],
        },
        {
          text: '20 - 50',
          value: [20, 50],
        },
        {
          text: '50 or more',
          value: [50, 9999],
        },
      ],
      onFilter: (value, record) =>
        record.discountAmount >= value[0] && record.discountAmount < value[1],
      sorter: (a, b) => a.discountAmount - b.discountAmount,
      render: (_, record) =>
        record.discountType === 'fix' ? `$${record.discountAmount}` : `${record.discountAmount}%`,
    },
    {
      title: 'Period of Validity',
      dataIndex: 'ttl',
      filters: [
        {
          text: '0 - 10 days',
          value: [0, 10],
        },
        {
          text: '10 - 30 days',
          value: [10, 30],
        },
        {
          text: '30 - 90 days',
          value: [30, 90],
        },
        {
          text: '90 - 180 days',
          value: [90, 180],
        },
        {
          text: '180 - 365 days',
          value: [180, 365],
        },
        {
          text: 'One year or more',
          value: [365, 9999],
        },
      ],
      onFilter: (value, record) =>
        record.ttl >= value[0] && record.ttl < value[1],
      sorter: (a, b) => a.ttl - b.ttl,
      width: '15%',
      render: (ttl) => `${ttl} Days`,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: '10%',
      // eslint-disable-next-line
      render: (_, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete this coupon?"
            onConfirm={() => onRowDelete(record)}
          >
            <Button danger type="link">
              Delete
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div style={{marginTop: '15px'}}>
      <Table
        rowKey="id"
        size="small"
        dataSource={coupons}
        columns={columns}
        bordered
      />
    </div>
  );
};

CouponTable.propTypes = {
  coupons: PropTypes.array,
  total: PropTypes.number,
  pageNumber: PropTypes.number,
  batchSize: PropTypes.number,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  deleteCoupon: PropTypes.func,
};

export default CouponTable;