import actions from './actions'
import newUserReducer, { newUserInitState } from './NewUserManagement/reducer';
const initState = {
  users: [],
  isFetchingUserList: false,
  isUpdatingSingleUser: false,
  resetEmailSent: false,
  newUser: newUserInitState,
};

function userManagementReducer(state = initState, {type, ...action}) {
  switch (type) {
    case actions.FETCH_USERS_LIST:
      return {
        ...state,
        isFetchingUserList: true
      }
    case actions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetchingUserList: false,
        users: action.users
      }
    case actions.FETCH_USERS_FAIL:
      return {
        ...state,
        isFetchingUserList: false
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        isUpdatingSingleUser: true,
      };
    case actions.UPDATE_USER_SUCCESS:
    case actions.UPDATE_USER_FAIL:
      return {
        ...state,
        isUpdatingSingleUser: false,
      };
    case actions.RESET_PASSWORD:
    case actions.RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetEmailSent: false,
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetEmailSent: true,
      };    
    default:
      return state
  }
}

export default (state = initState, { type, ...action }) => {
  return {
    ...userManagementReducer(state, { type, ...action }),
    newUser: newUserReducer(state.newUser, { type, ...action }),
  };
};