import actions from './actions';

export const newUserInitState = {
  isCreatingUser: false,
  userCreated: {}
};

export default function newUserReducer(state = newUserInitState, { type, ...action }) {
  switch (type) {
    case actions.CREATE_USER:
      return {
        ...state,
        isCreatingUser: true,
        userCreated: {},
      };
    case actions.CREATE_USER_SUCCESS:
      return {
        ...state,
        isCreatingUser: false,
        userCreated: action.newUser,
      };
    case actions.CREATE_USER_FAIL:
      return {
        ...state,
        isCreatingUser: false,
      };
    default: return state;
  }
}

