/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  TimePicker,
  Empty,
  Select,
  InputNumber,
  Modal,
  Form,
  Input,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Pagination from '../NoSQLPagination';
import { toast } from 'react-toastify';
const { RangePicker } = TimePicker;
const { Option } = Select;
const { Item } = Form;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const getSlots = (record) => {
  const result = [];
  for (const day of DAYS) {
    if (record[day] && record[day].length > 0) {
      for (const slot of record[day]) {
        result.push({ day, ...slot });
      }
    }
  }
  return result;
};

const DefaultSlotTable = (props) => {
  const { timeslots, getList, updateSingle } = props;
  const { slots, total, batchSize, pageNumber } = timeslots;
  useEffect(() => {
    getList({}); // eslint-disable-next-line
  }, []);
  const [record, setRecord] = useState(null);
  const [slotsDraft, setSlotsDraft] = useState(null);
  const [searchPattern, setSearchPattern] = useState(null);
  const [newSlotModalVisibility, setNewSlotModalVisibility] = useState(false);

  const newSlotFormRef = useRef();

  const addNewSlot = (values) => {
    if (values) {
      const slot = {
        day: values.day,
        last: values.lastOrderTime.format('HH:mm'),
        start: values.deliveryTimeRange[0].format('HH:mm'),
        end: values.deliveryTimeRange[1].format('HH:mm'),
        maxOrderNumber: values.maxOrderNumber,
      };
      let newSlot = true;
      for (const existedSlot of slotsDraft) {
        if (
          existedSlot.day === slot.day &&
          existedSlot.end === slot.end &&
          existedSlot.start === slot.start
        ) {
          // inform of existed slot
          toast.error('Slot with same time existed!', {
            autoClose: 5000,
          })
          newSlot = false;
          break;
        }
      }
      if (newSlot) {
        newSlotFormRef.current.resetFields();
        setNewSlotModalVisibility(false);
        setSlotsDraft([...slotsDraft, slot]);
      }
    }
  };

  const saveEdit = () => {
    const existedSlots = {};
    let noDuplication = true;
    for (const slot of slotsDraft) {
      const key = slot.day + slot.start + slot.end;
      if (existedSlots[key]) {
        toast.error('Slot with same time existed!', {
          autoClose: 5000,
        });
        noDuplication = false;
        break;
      } else {
        existedSlots[key] = slot;
      }
    }
    if (noDuplication) {
      updateSingle({ postcode: record.postcode, slots: slotsDraft });
    }
  }

  const lastOrderTimeOnchange = (value, index) => {
    const newSlotsValue = [...slotsDraft];
    newSlotsValue[index].last = value.format('HH:mm');
    setSlotsDraft(newSlotsValue);
  };

  const slotDayChange = (value, index) => {
    const newSlotsValue = [...slotsDraft];
    newSlotsValue[index].day = value;
    setSlotsDraft(newSlotsValue);
  };

  const deliveryTimeOnChange = (value, index) => {
    const newSlotsValue = [...slotsDraft];
    newSlotsValue[index].start = value[0].format('HH:mm');
    newSlotsValue[index].end = value[1].format('HH:mm');
    setSlotsDraft(newSlotsValue);
  };

  const maxOrderNumberChange = (value, index) => {
    const newSlotsValue = [...slotsDraft];
    newSlotsValue[index].maxOrderNumber = value;
    setSlotsDraft(newSlotsValue);
  };

  const deleteTimeSlot = (index) => {
    const newSlotsValue = [...slotsDraft];
    newSlotsValue.splice(index, 1);
    setSlotsDraft(newSlotsValue);
  };

  const dataColumns = [
    {
      title: 'Post Code',
      dataIndex: 'postcode',
      sorter: (a, b) => parseInt(a.postcode) - parseInt(b.postcode),
    },
    {
      title: 'Operations',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setRecord(record);
              setSlotsDraft(getSlots(record));
            }}
          >
            View & Edit
          </Button>
        );
      },
    },
  ];

  const slotColumns = [
    {
      title: 'Day',
      dataIndex: 'day',
      render: (data, _, index) => (
        <Select
          value={data}
          style={{ width: 120 }}
          onChange={(value) => slotDayChange(value, index)}
        >
          {DAYS.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Last Order Time',
      dataIndex: 'last',
      render: (data, _, index) => (
        <TimePicker
          format="HH:mm"
          allowClear={false}
          minuteStep={15}
          value={moment(data, 'HH:mm')}
          onChange={(value) => lastOrderTimeOnchange(value, index)}
        />
      ),
    },
    {
      title: 'Time Period',
      render: (_, record, index) => (
        <RangePicker
          value={[moment(record.start, 'HH:mm'), moment(record.end, 'HH:mm')]}
          format="HH:mm"
          allowClear={false}
          minuteStep={15}
          onChange={(value) => deliveryTimeOnChange(value, index)}
        />
      ),
    },
    {
      title: 'Max Order Number',
      dataIndex: 'maxOrderNumber',
      render: (data, _, index) => (
        <InputNumber
          value={data}
          min={0}
          precision={0}
          onChange={(value) => maxOrderNumberChange(value, index)}
        />
      ),
    },
    {
      title: 'Operation',
      render: (_, record, index) => (
        <Button type="link" danger onClick={() => deleteTimeSlot(index)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Row gutter={12}>
      <Col span={10}>
        <div style={{ marginBottom: '20px' }}>
          <span style={{ fontWeight: 'bolder' }}>Search By Postcode: </span>
          <span style={{ marginLeft: '15px' }}>
            <Input
              style={{ width: '60%' }}
              onChange={(e) => setSearchPattern(e.target.value)}
              onPressEnter={() => {
                getList({ searchPattern });
                setRecord(null);
                setSlotsDraft(null);
              }}
              placeholder="Enter postcode and press Enter"
            />
          </span>
          <span style={{ marginLeft: '15px' }}>
            <Button
              type="primary"
              onClick={() => {
                getList({});
                setRecord(null);
                setSlotsDraft(null);
              }}
            >
              Reset
            </Button>
          </span>
        </div>
        {slots && (
          <Table
            size="small"
            rowKey="postcode"
            bordered
            dataSource={slots}
            columns={dataColumns}
            pagination={false}
          />
        )}
        <Pagination
          onPrev={() => {
            getList({ pageNumber: pageNumber - 1 });
          }}
          onNext={() => {
            getList({ pageNumber: pageNumber + 1 });
          }}
          total={total}
          pageNumber={pageNumber}
          batchSize={batchSize}
        />
      </Col>
      <Col span={14}>
        {slotsDraft === null ? (
          <Empty description={<span>Click a table row to view and edit details.</span>} />
        ) : (
          <>
            <Table
              title={() => (
                <div style={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'center' }}>
                  Default delivery time for Postcode {record.postcode}
                </div>
              )}
              style={{ marginBottom: 10 }}
              size="small"
              rowKey="day"
              bordered
              dataSource={slotsDraft}
              columns={slotColumns}
            />
            <div style={{ textAlign: 'end' }}>
              <Button
                style={{ marginRight: '20px' }}
                onClick={() => setNewSlotModalVisibility(true)}
              >
                Add New Delivery Time
              </Button>
              <Button
                type="primary"
                onClick={saveEdit}
              >
                Save
              </Button>
            </div>
            <Modal
              title="New Delivery Time Period"
              visible={newSlotModalVisibility}
              onOk={() => {}}
              onCancel={() => {
                setNewSlotModalVisibility(false);
              }}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    setNewSlotModalVisibility(false);
                  }}
                >
                  Cancel
                </Button>,
                <Button key="submit" type="primary" form="newSlotForm" htmlType="submit">
                  Submit
                </Button>,
              ]}
            >
              <Form {...formItemLayout} id="newSlotForm" ref={newSlotFormRef} onFinish={addNewSlot}>
                <Item name="day" label="Day of Delivery" rules={[{ required: true }]}>
                  <Select>
                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Item>
                <Item name="lastOrderTime" label="Last Order Time" rules={[{ required: true }]}>
                  <TimePicker format="HH:mm" minuteStep={15} allowClear={false} />
                </Item>
                <Item
                  name="deliveryTimeRange"
                  label="Delivery Time Range"
                  rules={[{ required: true }]}
                >
                  <RangePicker format="HH:mm" minuteStep={15} allowClear={false} />
                </Item>
                <Item
                  name="maxOrderNumber"
                  label="Max Number of Orders"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} precision={0} />
                </Item>
              </Form>
            </Modal>
          </>
        )}
      </Col>
    </Row>
  );
};

DefaultSlotTable.propTypes = {
  timeslots: PropTypes.shape({
    total: PropTypes.number,
    pageNumber: PropTypes.number,
    batchSize: PropTypes.number,
    slots: PropTypes.array,
  }),
  getList: PropTypes.func,
  updateSingle: PropTypes.func,
};

export default DefaultSlotTable;
