import actions from './actions';
import newCouponReducer, { newCouponInitState } from './NewCoupon/reducer';

const initState = {
  couponsData: [],
  isFetchingCoupons: false,
  isDeletingCoupon: false,
  newCoupon: newCouponInitState,
  isFetchingInvitationCouponConfig: false,
  isUpdatingInvitationCouponConfig: false,
  invitationCouponConfig: {}
}

function couponsReducer (state = initState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_COUPON_LIST:
      return {
        ...state,
        isFetchingCoupons: true,
      };
    case actions.FETCH_COUPON_LIST_SUCCESS:
      return {
        ...state,
        isFetchingCoupons: false,
        couponsData: action.couponsData
      };
    case actions.FETCH_COUPON_LIST_FAIL:
      return {
        ...state,
        isFetchingCoupons: false,
      };
    case actions.DELETE_COUPON:
      return {
        ...state,
        isDeletingCoupon: true,
      };
    case actions.DELETE_COUPON_SUCCESS:
    case actions.DELETE_COUPON_FAIL:
      return {
        ...state,
        isDeletingCoupon: false,
      };
    case actions.GET_INVITATION_COUPON_CONFIG:
      return {
        ...state,
        isFetchingInvitationCouponConfig: true
      };
    case actions.GET_INVITATION_COUPON_CONFIG_SUCCESS:
      return {
        ...state,
        isFetchingInvitationCouponConfig: false,
        invitationCouponConfig: action.config
      };
    case actions.GET_INVITATION_COUPON_CONFIG_FAIL:
      return {
        ...state,
        isFetchingInvitationCouponConfig: false
      };
    case actions.UPDATE_INVITATION_COUPON_CONFIG:
      return {
        ...state,
        isUpdatingInvitationCouponConfig: true
      };
    case actions.UPDATE_INVITATION_COUPON_CONFIG_SUCCESS:
      return {
        ...state,
        isUpdatingInvitationCouponConfig: false,
        invitationCouponConfig: action.config
      };
    case actions.UPDATE_INVITATION_COUPON_CONFIG_FAIL:
      return {
        ...state,
        isUpdatingInvitationCouponConfig: false
      };
    default: return state;
  }
}

export default (state = initState, { type, ...action }) => {
  return {
    ...couponsReducer(state, { type, ...action }),
    newCoupon: newCouponReducer(state.newCoupon, {type, ...action})
  }
};