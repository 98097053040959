import Axios from '../utils/axios';
import { firebaseAuth, firestore } from '../utils/firebase';

const USER_COLLECTION =
  'https://asia-northeast1-helen-ecommerce.cloudfunctions.net/resources/users';

const usersApi = {
  loginViaGoogleToken: (params) => {
    const { token } = params;
    console.log('here!');
    return Axios.get('scripts/dashboard/phonelogin', {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },

  createUser: async ({ mobile, email, nickname, password }) => {
    if (!mobile || !email || !nickname || !password) {
      return { success: false, error: new Error('Invalid data fields!')};
    }
    try {
      /**
       * Uncomment the following lines if cloud function is deployed
       */
      const token = await firebaseAuth.currentUser.getIdToken();
      const result = await Axios.post(
        USER_COLLECTION,
        { mobile, email, nickname, password },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return { success: true, data: result.data };
    } catch (error) {
      return { success: false, error };
    }
  },

  getUsers: async () => {
    const ids = {};
    const uids = await firestore.collection('users').get();
    uids.forEach((doc) => (ids[doc.id] =  doc.data().authority ));
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const result = await Axios.get(USER_COLLECTION, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        const data = result.data;
        data.forEach(user => user.authority = ids[user.uid])
        return { success: true, data: data };
      }        
      else
        return { success: false, error: new Error(result.data.message, result.data.detail) };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error,
      };
    }
  },

  resetPassword: async ({ email }) => {
    try {
      await firebaseAuth.sendPasswordResetEmail(email);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  updateUser: async ({ uid, authority }) => {
    if (!uid || !authority) {
      return { success: false, error: new Error('Invalid data fields!') };
    } 
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const result = await Axios.put(
        `${USER_COLLECTION}/${uid}`,
        { authority },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) return { success: true };
      else return { success: false, error: new Error(result.data.message, result.data.detail) };
    } catch (error) {
      return { success: false, error}
    }
  },

  disableUser: () => {
    // TODO: disable user instead of remove it
  },

  removeUser: () => {
    // TODO: delete user, but not sure if it has enough permission to remove from authentication
  },
};

export default usersApi;
