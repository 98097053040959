import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { connect } from 'react-redux';
import actions from '../../redux/Orders/actions';
import PropTypes from 'prop-types';
import OrderEditViewDrawer from '../../components/OrderEditViewDrawer';
import Sound from '../../assets/newOrder.mp3';

const Notification = (props) => {
  const {
    newOrders,
    checkNewOrder,
    updateOrderStatus,
    deleteOrder,
    isDeletingOrder,
    isUpdatingSingleOrder,
  } = props;

  const audio = new Audio(Sound);

  const [editDrawerVisibility, setEditDrawerVisibility] = useState(false);
  const [newOrderdetail, setNewOrderDetail] = useState(null);

  const hideOrderDetails = () => {
    const { orderId } = newOrderdetail;
    const orders = { ...newOrders };
    delete orders[orderId];
    checkNewOrder({ orders });
    setNewOrderDetail(null);
  }

  let newOrdersIds = [];

  useEffect(() => {
    if (
      (newOrdersIds.length > 0 && Object.keys(newOrders).length > newOrdersIds.length) ||
      newOrdersIds.length === 0
    ) {
      if (JSON.stringify(newOrders) !== '{}') {
        // get the new order  that comes in 
        const newOrderId = Object.keys(newOrders).filter(key => !newOrdersIds.includes(key))[0];
        // notify and change state
        const { deliveryTime, deliveryAddress, customer, orderId } = newOrders[newOrderId];
        const { phoneNumber } = customer;
        const notificationContent = (
          <div>
            <div style={{ fontWeight: 'bolder' }}>New Order!</div>
            <div>Delivery Time: {deliveryTime}</div>
            <div>Delivery Postcode: {deliveryAddress.state + deliveryAddress.postcode}</div>
            <div>Customer Phone Number: {phoneNumber}</div>
          </div>
        );
        notification.info({
          key: orderId,
          message: 'You have a new order!',
          duration: 0,
          description: notificationContent,
          placement: 'topLeft',
          onClick: () => {
            setNewOrderDetail(newOrders[newOrderId]);
            setEditDrawerVisibility(true);
            notification.close(orderId);
          },
        });
        audio.play();
      }
    } //eslint-disable-next-line
    newOrdersIds = Object.keys(newOrders);
  }, [newOrders]);

  return (
    <>
      {newOrderdetail && (
        <OrderEditViewDrawer
          drawerVisibility={editDrawerVisibility || isUpdatingSingleOrder || isDeletingOrder}
          closeDrawer={() => {
            setEditDrawerVisibility(false);
            hideOrderDetails();
          }}
          isFetchingSingleOrder={false}
          isUpdatingSingleOrder={isUpdatingSingleOrder}
          isDeletingOrder={isDeletingOrder}
          orderData={newOrderdetail}
          updateOrderStatus={(params) => {
            updateOrderStatus(params);
            // closeDrawer is called in the component, so no need to call 'hideOrderDetails'
          }}
          deleteOrder={(record) => {
            deleteOrder(record);
            setEditDrawerVisibility(false);
            hideOrderDetails();
          }}
        />
      )}
    </>
  );
};

Notification.propTypes = {
  newOrders: PropTypes.object,
  receiveNewOrder: PropTypes.func,
  checkNewOrder: PropTypes.func,
  updateOrderStatus: PropTypes.func,
  deleteOrder: PropTypes.func,
  isDeletingOrder: PropTypes.bool,
  isUpdatingSingleOrder: PropTypes.bool,
};

const { receiveNewOrder, checkNewOrder, updateOrderStatus, deleteOrder } = actions;

export default connect(
  (state) => {
    const { Orders } = state;
    const { newOrders, isDeletingOrder, singleOrder } = Orders;
    const { isUpdatingSingleOrder } = singleOrder;
    return { newOrders, isDeletingOrder, isUpdatingSingleOrder };
  },
  { receiveNewOrder, checkNewOrder, updateOrderStatus, deleteOrder }
)(Notification);
