const actions = {
  FETCH_DELIVERY_FEES: 'FETCH_DELIVERY_FEES',
  FETCH_DELIVERY_FEES_SUCCESS: 'FETCH_DELIVERY_FEES_SUCCEESS',
  FETCH_DELIVERY_FEES_FAIL: 'FETCH_DELIVERY_FEES_FAIL',

  UPLOAD_DELIVERY_FEES: 'UPLOAD_DELIVERY_FEES',
  UPLOAD_DELIVERY_FEES_SUCCESS: 'UPLOAD_DELIVERY_FEES_SUCCESS',
  UPLOAD_DELIVERY_FEES_FAIL: 'UPLOAD_DELIVERY_FEES_FAIL',

  GET_UPSTAIRS_CONFIG: 'GET_UPSTAIRS_CONFIG',
  GET_UPSTAIRS_CONFIG_SUCCESS: 'GET_UPSTAIRS_CONFIG_SUCCESS',
  GET_UPSTAIRS_CONFIG_FAIL: 'GET_UPSTAIRS_CONFIG_FAIL',

  UPDATE_UPSTAIRS_CONFIG: 'UPDATE_UPSTAIRS_CONFIG',
  UPDATE_UPSTAIRS_CONFIG_SUCCESS: 'UPDATE_UPSTAIRS_CONFIG_SUCCESS',
  UPDATE_UPSTAIRS_CONFIG_FAIL: 'UPDATE_UPSTAIRS_CONFIG_FAIL',

  RESET_UPLOAD_STATUS: 'RESET_UPLOAD_STATUS',

  getDeliveryFees: () => ({
    type: actions.FETCH_DELIVERY_FEES
  }),

  uploadDeliveryFees: ({ areaPostages }) => ({
    type: actions.UPLOAD_DELIVERY_FEES,
    areaPostages
  }),

  resetUploadStatus: () => ({
    type: actions.RESET_UPLOAD_STATUS
  }),

  getUpstairsConfig: () => ({
    type: actions.GET_UPSTAIRS_CONFIG
  }),

  updateUpstairsConfig: ({ deliveryToUpstairs, deliveryToUpstairsFee }) => ({
    type: actions.UPDATE_UPSTAIRS_CONFIG,
    deliveryToUpstairs,
    deliveryToUpstairsFee
  })

};

export default actions;