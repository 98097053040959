import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  TreeSelect,
  PageHeader,
  Tooltip,
} from 'antd';
import propTypes from 'prop-types';
import PictureWall from '../../components/FirebaseStoragePictureWall/index';
import getFeaturePicture from '../../utils/updateFeaturePicture';
import newProductActions from '../../redux/Product/NewProduct/action';
import CategoriesActions from '../../redux/Categories/actions';
import generateTreeNode from '../../utils/generateTreeNode';
import moment from 'moment';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import './index.scss';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import Editor from '../../components/LongDescriptionEditor';
const { Option } = Select;
const { Item } = Form;

function getStatus(sku, isCheckingSkuValidity, skuValidity) {
  if (sku === null) return false;
  else if (sku === '') return 'error';
  else if (!sku.match('^[0-9a-zA-Z-_.]{1,}$')) return 'error';
  else if (isCheckingSkuValidity === null) return false;
  else if (isCheckingSkuValidity) return 'validating';
  else if (!skuValidity) return 'error';
  else return 'success';
}

function getImageObjects(images) {
  const result = images.map((image) => {
    const pathEntries = image.split('/');
    const uid = image;
    const name = pathEntries[pathEntries.length - 1];
    const status = 'done';
    const url = image;
    return { uid, name, status, url };
  });
  return result;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'products',
    breadcrumbName: 'Products',
  },
  {
    path: 'newproduct',
    breadcrumbName: 'New Product',
  },
];

const AddNewProduct = (props) => {
  const { setSku, addProduct, getCategories } = props;
  const { sku, isCheckingSkuValidity, skuValidity } = props.newProduct;
  const { categories: allCategories } = props.Categories;

  const skuChangeHandler = (e) => {
    setSku({ SKU: e.target.value ? e.target.value : '' });
  };
  const [fileList, setFileList] = useState([]);
  const [formStep, setFormStep] = useState(1);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const formRef = useRef();
  const history = useHistory();

  useEffect(() => {
    getCategories(); // eslint-disable-next-line
  }, []);

  const removeUndefinedValues = (obj) =>
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

  const submitNewProductForm = (values) => {
    const rteValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (
      rteValue
        .replace(/&nbsp;/g, '')
        .replace(/<p><\/p>/g, '')
        .trim() === ''
    )
      return toast.error('Long Description is required!', { autoClose: 3000 });
    if (
      values.price < 0 ||
      values.priority < 0 ||
      values.discountPrice < 0 ||
      values.stock < 0 ||
      values.stock % 1 !== 0 ||
      values.priority % 1 !== 0
    )
      return toast.error('Price, stock, discount price or priority invalid!', { autoClose: 3000 });
    if (values.discountPrice >= values.price)
      return toast.error('Discount price should be less than price!', { autoClose: 3000 });
    if (!values.priority) values.priority = 0;
    // if (!values.expiryDate) values.expiryDate = moment("2099-12-31 23:59:59");
    removeUndefinedValues(values);
    if (skuValidity) {
      values = {
        ...values,
        type: 'simple',
        archived: false,
        isChild: false,
        expiryDate: values.expiryDate ? { seconds: parseInt(moment(values.expiryDate).format('X')), nanoseconds: 0 } : null,
        longDescription: rteValue,
        images: fileList
      };
      setSku({ SKU: null });
      setFormStep(1);
      addProduct({ product: values });
      formRef.current.resetFields();
      setEditorState(EditorState.createEmpty());
    } else {
      console.log('invalid sku');
    }
  };

  const resetFormHandler = () => {
    formRef.current.resetFields();
    setEditorState(EditorState.createEmpty());
    history.goBack();
    setSku({ SKU: null });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment().endOf('day');
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
    const currentFeaturePicture = formRef.current.getFieldValue('featurePicture');
    formRef.current.setFieldsValue({ featurePicture: getFeaturePicture(fileList, currentFeaturePicture)});
  };
  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="New Product"
        breadcrumb={{ routes }}
      ></PageHeader>
      {/* <Spin spinning={isFecthingCategories}> */}
      <Form
        {...formItemLayout}
        onFinish={submitNewProductForm}
        // hideRequiredMark
        ref={formRef}
        initialValues={{ sku }}
      >
        <div
          className={`add-product-form first-step ${
            formStep > 1 ? 'deactive' : 'active'
          }`}
        >
          <Item
            label="New Product SKU"
            name="sku"
            help={
              skuValidity === null || skuValidity
                ? null
                : 'This product SKU has been used.'
            }
            validateStatus={getStatus(sku, isCheckingSkuValidity, skuValidity)}
            hasFeedback
            onChange={skuChangeHandler}
            rules={[
              { required: true, message: 'Product SKU cannot be empty.' },
              {
                pattern: '^[0-9a-zA-Z-_.]{1,}$',
                message:
                  'Product SKU can only contain number, letter or "._-" symbols.'
              }
            ]}
          >
            <Input
              placeholder="Please enter product SKU"
              disabled={formStep > 1}
            />
          </Item>
          <Button
            onClick={() => setFormStep(2)}
            disabled={!skuValidity}
            type="primary"
            ghost
          >
            Next
          </Button>
        </div>
        <div
          className={`add-product-form second-step ${
            formStep > 1 ? 'active' : 'deactive'
          }`}
        >
          <Item
            name="name"
            label="Product Name"
            rules={[
              { required: true, message: "Product name can't be empty." }
            ]}
          >
            <Input placeholder="Please enter Product Name" />
          </Item>
          <Item name="chineseName" label="Chinese Name">
            <Input placeholder="Please enter Product Chinese Name" />
          </Item>
          <Item name="koreanName" label="Korean Name">
            <Input placeholder="Please enter Product Korean Name" />
          </Item>
          {sku && (
            <Item
              label="Product Pictures"
              style={{
                border: '1px solid red',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px'
              }}
            >
              <PictureWall
                sku={sku}
                storageLocation="productImages"
                onFileChange={(fileList) => handleChange({ fileList })}
              />
            </Item>
          )}
          {fileList.length > 0 && (
            <Item
              name="featurePicture"
              label="Feature Picture"
              rules={[
                { required: true, message: 'Please select feature picture' }
              ]}
            >
              <Select>
                {getImageObjects(fileList).map((file) => (
                  <Option key={file.uid} value={file.url}>
                    {file.name}
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          <Item
            name="price"
            label="Price"
            rules={[{ required: true, message: 'Please enter Product Price' }]}
          >
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              placeholder="Please enter Product Price"
            />
          </Item>
          <Item name="discountPrice" label="Discount Price">
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              placeholder="Please enter discount price"
            />
          </Item>
          <Item
            name="flashSaleLimit"
            label="Flash Sale Limit"
            rules={[{ required: true, message: 'Please enter Flash Sale Limit' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Please enter Flash Sale Limit"
              precision={0}
              min={1}
            />
          </Item>
          <Item
            name="stock"
            label="Stock"
            rules={[{ required: true, message: 'Please enter Stock Number' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Please enter Stock Number"
              step={1}
              min={0}
            />
          </Item>
          <Tooltip
            placement="top"
            title="Product with higher priority will be displayed first."
          >
            <Item
              name="priority"
              label="Priority"
              rules={[{ required: true, message: 'Please enter Priority' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Please enter Priority"
                min={0}
                step={1}
              />
            </Item>
          </Tooltip>
          <Item
            name="categories"
            label="Categories"
            rules={[
              { required: true, message: 'Please select at least one category' }
            ]}
          >
            <TreeSelect
              style={{ width: '100%' }}
              multiple
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Fruit..."
              treeDefaultExpandAll
            >
              {allCategories ? generateTreeNode(allCategories) : <></>}
            </TreeSelect>
          </Item>
          <Item name="expiryDate" label="Expiry Date">
            <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
          </Item>
          <Item
            name="shortDescription"
            label="Short Description"
            rules={[
              {
                required: true,
                message: "Product short description can't be empty."
              }
            ]}
          >
            <Input placeholder="Please enter Product Short Description" />
          </Item>
          <Item
            name="longDescription"
            label="Long Description"
            rules={[{ message: "Product long description can't be empty." }]}
          >
            <Editor editorState={editorState} setEditorState={setEditorState} />
          </Item>
          {sku && (
            <Item
              label="Description Pictures"
              style={{
                border: '1px solid red',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px'
              }}
            >
              <PictureWall
                sku={sku}
                storageLocation="productDescriptionImages"
                onFileChange={(fileList) => handleChange({ fileList })}
              />
            </Item>
          )}
          <Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 5 }
            }}
          >
            <Button
              style={{ marginRight: '20px' }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>

            <Button danger onClick={resetFormHandler}>
              Cancel
            </Button>
          </Item>
          <Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 5 }
            }}
          >
            <div
              style={{
                fontSize: '120%',
                color: 'red'
              }}
            >
              Please check values entered above before submitting.
            </div>
          </Item>
        </div>
      </Form>
      {/* </Spin> */}
    </div>
  );
};
AddNewProduct.propTypes = {
  allCategories: propTypes.array,
  setSku: propTypes.func,
  newProduct: propTypes.object,
  addProduct: propTypes.func,
  Categories: propTypes.object,
  getCategories: propTypes.func,
};

const { setSku, addProduct } = newProductActions;
const { getCategories } = CategoriesActions;

export default connect(
  (state) => {
    const { Products, Categories } = state;
    const { newProduct } = Products;
    return {
      newProduct,
      Categories,
    };
  },
  { setSku, addProduct, getCategories }
)(AddNewProduct);
