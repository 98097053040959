import actions from './actions';

const initState = {
  date: null,
  slots: [],
  isFetchingSlots: false,
  isUpdatingSlots: false,
}; 

const deliveryTimeSlotsReducer = (state = initState, { type, ...action }) => {
  switch (type) {
    case actions.FETCH_DELIVERY_TIMESLOTS:
      return {
        ...state,
        isFetchingSlots: true,
      };
    case actions.FETCH_DELIVERY_TIMESLOTS_SUCCESS:
      return {
        ...state,
        isFetchingSlots: false,
        date: action.date,
        slots: action.slots,
      };
    case actions.FETCH_DELIVERY_TIMESLOTS_FAIL:
      return {
        ...state,
        isFetchingSlots: false,
      };
    case actions.UPDATE_DELIVERY_TIMESLOTS:
      return {
        ...state,
        isUpdatingSlots: true,
      };
    case actions.UPDATE_DELIVERY_TIMESLOTS_SUCCESS:
    case actions.UPDATE_DELIVERY_TIMESLOTS_FAIL:
      return {
        ...state,
        isUpdatingSlots: false,
      };
    default:
      return state;
  }
}

export default deliveryTimeSlotsReducer;