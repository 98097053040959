import Joi from "@hapi/joi";

const categorySchema = Joi.object({
  id: Joi.string().optional(),
  name: Joi.string().required(),
  parentCategory: Joi.any().optional(),
  image: Joi.any().optional(),
  visible: Joi.bool().optional(),
})

export {
  categorySchema
}
