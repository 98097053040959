import { firestore, firebaseAuth } from '../utils/firebase';
import algoliasearch from 'algoliasearch';
import { createNullCache } from '@algolia/cache-common';
import { searchClient } from '../config/';
import customerSchema from '../schemas/customer';
import axios from '../utils/axios';

const client = algoliasearch(searchClient.appId, searchClient.searchApiKey, {
  responsesCache: createNullCache()
});
const customerIndex = client.initIndex('CUSTOMERS_prod');

/**
 * 	Timestamp returned from Algolia Search have a underline '_' before 'seconds' and
 *  'nanoseconds' compared to timestamp returned from firestore.
 *  The adapter below is to process this data inconsistence
 *  */
let paginationCache = {};
const dateFormatAdapter = (record) => {
  return (record = {
    ...record,
    createdAt: {
      seconds: record.createdAt._seconds,
      nanoseconds: record.createdAt._nanoseconds,
    },
  });
};
const pageJumpValidator = (previousRequestCache, currentRequest) => {
  if (
    previousRequestCache.pageNumber !== undefined &&
    previousRequestCache.batchSize === currentRequest.batchSize &&
    previousRequestCache.archived === currentRequest.archived
  ) {
    if (
      previousRequestCache.pageNumber - currentRequest.pageNumber === 1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === -1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === 0
    ) {
      return true;
    }
  }
  return false;
};

const customersApi = {
  /**
   * @param {{searchPattern: string, archived: string, pageNumber: number, batchSize: number}} params
   * - searchPattern: searchPattern(default empty)
   * - archived: 'all', 'true', 'false'
   * - pageNumber: pageNumber(default 1)
   * - batchSize: batchSize (default 20)
   *
   * @returns {{success: boolean, total: number, pageNumber: number, batchSize:number, customers: Array }}
   * - success: success status,
   * - total: total number of records
   * - pageNumber: pageNumber(default 1)
   * - batchSize: batchSize (default 20)
   * - custoemrs: list of Customer data
   */
  getCustomers: async (params) => {
    let { searchPattern, archived, pageNumber, batchSize } = params;
    // apply default value
    pageNumber = pageNumber ? pageNumber : 1;
    batchSize = batchSize ? batchSize : 20;
    archived = archived === undefined ? 'all' : archived;
    try {
      if (searchPattern !== '') {
        let filter;
        if (archived === 'all') {
          filter = '';
        } else {
          if (archived === 'true') {
            filter = 'archived=1';
          } else {
            filter = 'archived=0';
          }
        }
        const { hits } = await customerIndex.search(searchPattern, {
          filters: filter,
          page: pageNumber - 1,
          hitsPerPage: batchSize,
        });
        const results = hits.map(dateFormatAdapter);
        return {
          success: true,
          data: {
            total: hits.length,
            pageNumber: pageNumber,
            batchSize: batchSize,
            customers: results,
          },
        };
      } else {
        const customersList = [];
        const collectionRef = firestore.collection('customers');
        let query;
        // archived filter
        if (archived === 'true') {
          query = collectionRef.where('archived', '==', true);
        } else if (archived === 'false') {
          query = collectionRef.where('archived', '==', false);
        } else {
          query = collectionRef;
        }
        // add orderBy create time
        query = query.orderBy('createdAt', 'desc');

        // add pagination
        if (pageNumber > 1) {
          if (!pageJumpValidator(paginationCache, params)) {
            throw new Error('Pagination Sequence Incorrect');
          }
          if (pageNumber - paginationCache.pageNumber === 1) {
            query = query.startAfter(paginationCache.lastItemRef).limit(batchSize);
          } else if (pageNumber - paginationCache.pageNumber === -1) {
            query = query.endBefore(paginationCache.firstItemRef).limitToLast(batchSize);
          } else {
            query = query.startAt(paginationCache.firstItemRef).limit(batchSize);
          }
        } else if (pageNumber === 1) {
          query = query.limit(batchSize);
        } else {
          throw new Error('Pagination Sequence Incorrect');
        }
        const snapshot = await query.get();
        snapshot.forEach((doc) => {
          customersList.push(doc.data());
        });

        // update Pagination cache
        const docs = snapshot.docs;
        paginationCache = {
          firstItemRef: docs[0],
          lastItemRef: docs.slice(-1)[0],
          pageNumber,
          batchSize,
          archived,
        };
        const counter = await firestore.doc('counters/customers').get();
        const { total, archived: archivedUserNumber } = counter.data();
        let totalNumberOfRecord;
        if (archived === 'true') {
          totalNumberOfRecord = archivedUserNumber;
        } else if (archived === 'false') {
          totalNumberOfRecord = total - archivedUserNumber;
        } else {
          totalNumberOfRecord = total;
        }
        return {
          success: true,
          data: {
            total: totalNumberOfRecord,
            pageNumber: pageNumber,
            batchSize: batchSize,
            customers: customersList,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return { success: false, data: {}, error: error };
    }
  },
  /**
   * @param {{ uid: string }} params
   * uid of the user to be retrieved
   * @return {{ success: boolean, customer: customer }}
   * - success: boolean to indicate result of the operation,
   * - the customer data after updated
   */
  getSingleCustomer: async (params) => {
    const { uid } = params;
    try {
      const snapshot = await firestore.doc(`customers/${uid}`).get();
      const { error, value: customerData } = customerSchema.validate(snapshot.data());
      if (error) throw new Error(error);
      // throw {
      //   error: 'VALIDATION_FAIL',
      //   errorMessage: error,
      // };
      // }
      return {
        success: true,
        customer: customerData,
      };
    } catch (error) {
      console.log(error);
      return { success: false, customer: {}, error: error };
    }
  },
  /**
   * @param {{ uid: string, customer: Object }} params
   * - uid of the user to be retrieved
   * - custoemr data which would overwrite old data
   * @return {{ success: Bool, customer: Object }}
   * - success: boolean to indicate result of the operation,
   * - the customer data after updated
   */
  updateSingleCustomer: async (params) => {
    const { customer } = params;
    try {
      if (customer.uid !== params.uid) {
        throw new Error([
          'VALIDATION_ERROR',
          'Submitted data invalid',
        ]);
      }
      let { error: updateDataError, value } = customerSchema.validate(customer);
      if (updateDataError) {
        throw new Error([
          'VALIDATION_FAIL',
          'Data in server side is invalid, please contact admin',
        ]);
      }
      // Method 1:
      // const docRef = firestore.doc(`customers/${params.uid}`);
      // await docRef.update(updateData);
      const { userInfo } = value;
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await axios.put(
        `resources/customers/${customer.uid}/info`,
        { userInfo },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // const resultData = await docRef.get();
      if (response.status === 200) {
        return {
          success: true,
          customer: { ...customer, userInfo },
        };
      }
      return {
        success: false,
        customer: {}
      }
    } catch (error) {
      return { success: false, customer: {}, error: error };
    }
  },

  getCustomerCoupons: async (params) => {
    try {
      const couponList = []
      const { uid } = params;
      if (!uid) {
        return {
          success: false,
          error: new Error(`Invalid Input of input ${uid}`)
        }
      }

      const customer = await firestore.collection('customers').doc(uid).get()
      if (!customer.exists) {
        return {
          success: false,
          error: new Error(`Customer with id ${uid} does not exist`)
        }
      }

      const coupons = await customer.ref.collection('coupons').get()
      if (coupons.empty) {
        return {
          success: true,
          data: []
        }
      }

      coupons.forEach((coupon) => {
        couponList.push({id: coupon.id, ...coupon.data()})
      })

      console.log(couponList);
      return {
        success: true,
        data: couponList
      }
    } catch (error) {
      console.log(error)
      return {
        success: false,
        error
      }
    }

  }
};

export default customersApi;
