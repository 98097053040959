import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import customerAddressesApi from '../../../apis/CustomerAddresses';


function* getCustomerAddresses(params) {
  const { success, data, error } = yield customerAddressesApi.getCustomerAddresses({ uid: params.uid });
  if (success) {
    yield put({ type: actions.GET_CUSTOMER_ADDRESSES_SUCCESS, addresses: data });
  } else {
    yield put({ type: actions.GET_CUSTOMER_ADDRESSES_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000
    })
  }
}

export default function* customerAddressesSaga() {
  yield all([
    takeLatest(actions.GET_CUSTOMER_ADDRESSES, getCustomerAddresses)
  ]);
}