import React, { useState } from 'react';
import { Button, Drawer, Typography, List, Spin, Card, Row, Col } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import moment from 'moment';

function generateCouponList(coupons) {
  const result = []
  for (let [k, v] of Object.entries(coupons)) {
    const expiryDate = moment(v.expiryDate.toMillis()).format("MMMM Do YYYY")
    result.push({...v, id: k, expiryDate})
  }

  return result
}

const CustomerCouponEditingDrawer = (props) => {
  // state
  const [newCouponsDraft, setNewCouponsDraft] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  
  // props
  const {
    closeDrawer,
    visibility,
    customerCoupons,
    couponsData,
    isFetchingCustomerCoupons,
    isEditingCustomerCoupons,
    isFetchingCoupons,
    saveCouponEdit,
  } = props;

  // custom fn
  const countChangeHandler = (item, type) => {
    setIsEditing(true);
    let draft;
    if (newCouponsDraft.length === 0) {
      draft = [];
      for (const coupon of couponsData) {
        draft.push({
          ttl: coupon.ttl,
          discountType: coupon.discountType,
          discountAmount: coupon.discountAmount,
          code: coupon.code,
          number: 0,
        });
      }
    } else {
      draft = [...newCouponsDraft];
    }
    for (const coupon of draft) {
      if (coupon.code === item.code) {
        if (type === 'add') coupon.number++;
        else if (coupon.number > 0) coupon.number--;
        break;
      }
    }
    setNewCouponsDraft(draft);
  };
  const submitEditHandler = () => {
    const newCoupons = newCouponsDraft.filter((coupon) => coupon.number > 0);
    saveCouponEdit(newCoupons);
    setIsEditing(false);
    closeDrawer();
  };

  return (
    <Drawer
      title="Edit Customer Coupons"
      width={1080}
      onClose={() => {
        closeDrawer();
        setIsEditing(false);
      }}
      visible={visibility}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div>
          <Button
            onClick={() => {
              closeDrawer();
              setIsEditing(false);
            }}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button key="submit" type="primary" onClick={submitEditHandler} disabled={!isEditing}>
            Submit
          </Button>
        </div>
      }
    >
      <Spin spinning={isFetchingCustomerCoupons || isEditingCustomerCoupons || isFetchingCoupons}>
        <Row>
          <Col span={11} >
            <Card title="Current Coupons">
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={generateCouponList(customerCoupons)}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.code}
                      style={{whiteSpace: 'pre-wrap'}}
                      description={`Discount Type: ${item.discountType} \nDiscount Amount: ${item.discountAmount} \nExpiryDate: ${item.expiryDate} \nStatus: ${item.status}`}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
          <Col span={11} offset={1}>
            <Card title="Available Coupons">
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={isEditing ? newCouponsDraft : couponsData}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        key="reduce"
                        type="danger"
                        shape="circle"
                        icon={<MinusOutlined/>}
                        disabled={!item.number}
                        onClick={() => {
                          countChangeHandler(item, 'remove');
                        }}
                      />,
                      <Typography.Text key="count">{item.number ? item.number : 0}</Typography.Text>,
                      <Button
                        key="plus"
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined/>}
                        onClick={() => {
                          countChangeHandler(item, 'add');
                        }}
                      />
                    ]}
                  >
                    <List.Item.Meta
                      title={item.code}
                      style={{whiteSpace: 'pre-wrap'}}
                      description={`Discount Type: ${item.discountType} \nDiscount Amount: ${item.discountAmount} \nDuration: ${item.ttl} Day(s)`}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  );
};

CustomerCouponEditingDrawer.propTypes = {
  closeDrawer: PropTypes.func,
  visibility: PropTypes.bool,
  customerCoupons: PropTypes.any,
  isFetchingCustomerCoupons: PropTypes.bool,
  isEditingCustomerCoupons: PropTypes.bool,
  isFetchingCoupons: PropTypes.bool,
  couponsData: PropTypes.array,
  saveCouponEdit: PropTypes.func,
};

export default CustomerCouponEditingDrawer;
