import Joi from '@hapi/joi';

const coupon = Joi.object({
  id: Joi.string().required(),
  code: Joi.string().required(),
  discountType: Joi.string().valid("percent", "fix").required(),
  discountAmount: Joi.number().required(),
  ttl: Joi.number().required(),
  number: Joi.number().optional()
})

const coupons = Joi.array().items(coupon)

const newCoupon = Joi.object({
  code: Joi.string().required(),
  discountType: Joi.string().valid("percent", "fix").required(),
  discountAmount: Joi.number().required(),
  ttl: Joi.number().required(),
  number: Joi.number().optional()
})

const newCoupons = Joi.array().items(newCoupon)

export {
  coupons,
  newCoupons
}
