export const firebaseConfig = {
  apiKey: 'AIzaSyAfu9SlsDzIvwTlAX9o88oyWCT_1WM9AmA',
  authDomain: 'helen-ecommerce.firebaseapp.com',
  databaseURL: 'https://helen-ecommerce.firebaseio.com',
  projectId: 'helen-ecommerce',
  storageBucket: 'helen-ecommerce.appspot.com',
  messagingSenderId: '1033287583444',
  appId: '1:1033287583444:web:2327a37ea77603e97e1d41',
  measurementId: 'G-J4W5X0FMHD'
};

export const searchClient = {
  appId: 'V2520JCW5Y',
  searchApiKey: '3d4c2167ef5aaa8b702f8a05ec21be90',
  'X-Algolia-API-Key': '96d52fc45eab455bdc11dd3cdc5dcdba'
};
export const server = {
  production: 'https://asia-northeast1-helen-ecommerce.cloudfunctions.net/',
  local: 'http://localhost:5000/helen-ecommerce/asia-northeast1/'
};

export const host = {
  local: 'http://localhost:3000'
};
