import { firestore } from '../utils/firebase';

let paginationCache = {};
const pageJumpValidator = (previousRequestCache, currentRequest) => {
  if (
    previousRequestCache.pageNumber !== undefined &&
    previousRequestCache.batchSize === currentRequest.batchSize
  ) {
    if (
      previousRequestCache.pageNumber - currentRequest.pageNumber === 1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === -1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === 0
    ) {
      return true;
    }
  }
  return false;
};

const PostcodeDeliveryApi = {
  getSuburbDeliveryTime: async (params) => {
    let { searchPattern, batchSize, pageNumber } = params;
    const collectionRef = firestore.collection(
      'configs/deliveryConfig/time/default/suburbDeliverySlots'
    );
    try {
      const defaultValue = {
        pageNumber: 1,
        batchSize: 20,
        searchPattern: '',
      };
      pageNumber = pageNumber || defaultValue.pageNumber;
      batchSize = batchSize || defaultValue.batchSize;
      searchPattern = searchPattern || defaultValue.searchPattern;
      if (searchPattern !== '') {
        const resultSnapshot = await collectionRef.where('postcode', '==', searchPattern).get();
        const result = [];
        resultSnapshot.forEach((doc) => {
          result.push(doc.data());
        });
        return {
          success: true,
          data: {
            total: 1,
            pageNumber: 1,
            batchSize,
            slots: result,
          },
        };
      } else {
        const suburbList = [];
        let query = collectionRef.orderBy('postcode');
        if (pageNumber > 1) {
          if (!pageJumpValidator(paginationCache, { pageNumber, batchSize, searchPattern })) {
            throw new Error('Pagination Sequence Incorrect');
          }
          if (pageNumber - paginationCache.pageNumber === 1) {
            query = query.startAfter(paginationCache.lastItemRef).limit(batchSize);
          } else if (pageNumber - paginationCache.pageNumber === -1) {
            query = query.endBefore(paginationCache.firstItemRef).limitToLast(batchSize);
          } else {
            query = query.startAt(paginationCache.firstItemRef).limit(batchSize);
          }
        } else if (pageNumber === 1) {
          query = query.limit(batchSize);
        } else {
          throw new Error('Pagination Sequence Incorrect');
        }
        const snapshot = await query.get();
        snapshot.forEach((doc) => {
          suburbList.push(doc.data());
        });
        // update pagination cache
        const docs = snapshot.docs;
        paginationCache = {
          firstItemRef: docs[0],
          lastItemRef: docs.slice(-1)[0],
          pageNumber,
          batchSize,
        };
        // get counter
        const counter = await firestore.doc('counters/postcodedelivery').get();
        const { total } = counter.data();
        return {
          success: true,
          data: {
            total,
            pageNumber: pageNumber,
            batchSize: batchSize,
            slots: suburbList,
          },
        };
      }
    } catch (error) {
      return { success: false, error };
    }
  },

  getSingleSuburbDeliveryDetail: async (params) => {
    const { postcode } = params;
    try {
      const snapshot = await firestore
        .doc(`configs/deliveryConfig/time/default/suburbDeliverySlots/${postcode}`)
        .get();
      const data = snapshot.data();
      return {
        success: true,
        data,
      };
    } catch (error) {
      return { success: false, error };
    }
  },

  updateSingleSuburbDeliveryDetail: async (params) => {
    const { postcode, slots } = params;
    try {
      const reducer = (acc, cur) => {
        const { day, last, start, end, maxOrderNumber } = cur;
        if (acc[postcode]) {
          if (acc[postcode][day]) {
            acc[postcode][day].push({ last, start, end, maxOrderNumber });
          } else acc[postcode][day] = [{ last, start, end, maxOrderNumber }];
        } else {
          acc[postcode] = { [day]: [{ last, start, end, maxOrderNumber }] };
        }
        return acc;
      };
      const result = slots.reduce(reducer, {})[postcode];
      const snapshot = await firestore
        .doc(`configs/deliveryConfig/time/default/suburbDeliverySlots/${postcode}`)
        .get();
      await snapshot.ref.set({ ...result, postcode });
      return { success: true, data: result };
    } catch (error) {
      return { success: false, error };
    }
  },

  batchUploadSuburbDelivery: async (params) => {
    const { timeslots } = params;
    let newSlotsCount = 0;
    const taskList = [];
    const uploadSingle = (postcode, slots) => {
      const snapshot = firestore.doc(
        `configs/deliveryConfig/time/default/suburbDeliverySlots/${postcode}`
      );
      snapshot.get().then((snapshot) => {
        if (!snapshot.exists) {
          newSlotsCount++;
          snapshot.ref.set({ postcode, ...slots });
        } else {
          snapshot.ref.update({ ...slots });
        }
      });
    };
    for (const key of Object.keys(timeslots)) {
      taskList.push(uploadSingle(key, timeslots[key]));
    }
    try {
      await Promise.all(taskList);
      //increase total suburb count
      const counterRef = await firestore.doc('counters/postcodedelivery').get();
      const { total } = counterRef.data();
      await counterRef.ref.set({ total: total + newSlotsCount });
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
};

export default PostcodeDeliveryApi;
