import jsPDF from 'jspdf';
import moment from 'moment';
import logo from '../assets/logos/logo-dark.png';
import { addfont } from '../components/OrderEditViewDrawer/chineseFont';
import addKoreanFont from '../components/OrderEditViewDrawer/koreanFont';
// import ordersApi from '../apis/Orders';

async function generatePdf(ordersData) {
  let doc = new jsPDF();
  addfont(doc);
  doc.addFont('bolds', 'chinese', 'normal');
  addKoreanFont(doc);
  doc.addFont('bolds', 'korean', 'normal');
  // doc.setFont('chinese');
  doc.setFont('korean');
  // doc.setLineDash([2.5]);
  const loadImage = (url) =>
    new Promise((resolve) => {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(img);
      };
    });
  // add each order to pdf doc
  return loadImage(logo).then((img) => {
    ordersData.forEach((orderData, index) => {
      addOrder(img, orderData, 80, doc);
      index !== ordersData.length - 1 && doc.addPage();
    });
    return doc;
  });
}

function addOrder(img, orderData, nextLineHeight, doc) {
  const addTitle = (text) => {
    doc.setFont('chinese');
    doc.setFontSize(12);
    doc.text(text, 15, nextLineHeight, { maxWidth: 150 });
    nextLineHeight += 7;
    if (nextLineHeight >= 270) {
      doc.addPage();
      nextLineHeight = 30;
    }
  };

  const addText = (text) => {
    doc.setFont('chinese');
    doc.setFontSize(10);
    doc.text('- ' + text, 20, nextLineHeight, { maxWidth: 150 });
    nextLineHeight += 7;
    if (nextLineHeight >= 270) {
      doc.addPage();
      nextLineHeight = 30;
    }
  };

  const addDashLine = () => {
    doc.setLineDash([2.5]);
    doc.line(20, nextLineHeight, 190, nextLineHeight);
    nextLineHeight += 7;
    if (nextLineHeight >= 270) {
      doc.addPage();
      nextLineHeight = 30;
    }
    doc.setLineDash();
  };

  const addLine = () => {
    doc.line(10, nextLineHeight, 200, nextLineHeight);
    nextLineHeight += 7;
    if (nextLineHeight >= 270) {
      doc.addPage();
      nextLineHeight = 30;
    }
  };

  const addChineseName = (text) => {
    doc.setFont('chinese');
    doc.setFontSize(10);
    doc.text('  ' + text, 20, nextLineHeight, { maxWidth: 150 });
    nextLineHeight += 7;
    if (nextLineHeight >= 270) {
      doc.addPage();
      nextLineHeight = 30;
    }
  };

  const addKoreanName = (text) => {
    doc.setFont('korean');
    doc.setFontSize(10);
    doc.text('  ' + text, 20, nextLineHeight, { maxWidth: 150 });
    nextLineHeight += 7;
    if (nextLineHeight >= 270) {
      doc.addPage();
      nextLineHeight = 30;
    }
  };

  const addPriceQuantity = (
    quantity,
    price,
    discountPrice,
    firstLineHeight
  ) => {
    doc.text('Quantity:  ', 150, firstLineHeight, { maxWidth: 150 });
    doc.setFontSize(15);
    doc.text(quantity + '', 170, firstLineHeight, {
      maxWidth: 150
    });
    doc.setFontSize(10);
    discountPrice
      ? doc.text(
        'Discount Price:  $' + discountPrice,
        150,
        firstLineHeight + 7,
        { maxWidth: 150 }
      )
      : doc.text('Price:  $' + price, 150, firstLineHeight + 7, {
        maxWidth: 150
      });
  };
  doc.setFont('chinese');
  doc.setFontSize(14);
  doc.text(
    `Order Index: ${orderData.deliveryTime.split(' ')[0] + ' '}`,
    10,
    12,
    { maxWidth: 150 }
  );
  doc.setFontSize(22);
  doc.rect(69, 7, 10, 7);
  doc.text(`${('0' + orderData.orderIndex).slice(-2)}`, 70, 13, {
    maxWidth: 150
  });

  doc.addImage(img, 'png', 35, 15, 130, 45);
  addTitle(`Order ID: ${orderData.orderId}`);
  doc.setFontSize(12);
  doc.text(
    `Order Time: ${moment(orderData.createdAt.seconds * 1000).format(
      'YYYY-MM-DD HH:mm:ss dddd'
    )}`,
    110,
    nextLineHeight - 7,
    { maxWidth: 150 }
  );
  addLine();
  doc.line(10, 70, 200, 70);
  addTitle('Customer Details');
  addText(`Phone Number: ${orderData.deliveryAddress.contactNumber}`);
  addText(`Contact Name: ${orderData.deliveryAddress.contactName}`);
  addLine();
  addTitle('Delivery Time');
  addText(orderData.deliveryTime);
  addLine();
  addTitle('Delivery Address');
  if (orderData.toUpStairs) {
    doc.setFontSize(12);
    doc.text('Delivery To Upstairs:', 130, nextLineHeight - 7, {
      maxWidth: 150
    });
  }
  addText(
    (orderData.deliveryAddress.unitNumber
      ? orderData.deliveryAddress.unitNumber + '/'
      : '') +
      orderData.deliveryAddress.address +
      ', ' +
      orderData.deliveryAddress.suburb +
      ' ' +
      orderData.deliveryAddress.postcode
  );
  if (orderData.toUpStairs) {
    doc.setFontSize(10);
    doc.text('YES', 150, nextLineHeight - 7, {
      maxWidth: 150
    });
  }
  addLine();
  addTitle('Order Items');
  for (let index in orderData.orderItems) {
    if (index !== 0) {
      // add a dashed line here
      addDashLine();
    }
    const item = orderData.orderItems[index];
    addText(item.sku);
    addPriceQuantity(
      item.quantity,
      item.price,
      item.discountPrice,
      nextLineHeight - 7
    );
    addChineseName(item.name);
    if (item.chineseName) {
      addChineseName(item.chineseName);
    }
    if (item.koreanName) {
      addKoreanName(item.koreanName);
    }
  }

  if (orderData.appliedCoupons && orderData.appliedCoupons.length > 0) {
    addLine();
    addTitle('Coupons Used');
    for (const coupon of orderData.appliedCoupons) {
      addText(coupon.code);
      doc.text(
        coupon.discountType === 'fix'
          ? `$${coupon.discountAmount}`
          : `${coupon.discountAmount}%`,
        150,
        nextLineHeight - 7,
        { maxWidth: 150 }
      );
    }
  }

  addLine();
  addTitle('Total Price');
  addText('$' + orderData.total);
  if (orderData.comment) {
    addLine();
    addTitle('Comment');
    addText(orderData.comment);
    addKoreanName(orderData.comment);
  }
}

export default function (ordersData, days) {
  return generatePdf(ordersData)
    .then((pdf) => {
      return pdf.save(moment().add(days, 'days').format('YYYY-MM-DD') + '.pdf');
    })
    .catch((error) => {
      console.log(error);
    });
}
