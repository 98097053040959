import actions from './actions';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ordersApi from '../../../apis/Orders';

function* getOrders({ days }) {
  const { success, data, error } = yield ordersApi.getUnprocessedOrderDetails(
    days
  );
  if (success) {
    yield put({
      type: actions.FETCH_UNPROCESSED_ORDERS_SUCCESS,
      unprocessedOrders: data
    });
  } else {
    yield put({ type: actions.FETCH_UNPROCESSED_ORDERS_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}

function* batchProcessOrders() {
  const { Orders } = yield select();
  const { unprocessedOrders } = Orders.unprocessedOrder;
  const { success, error } = yield ordersApi.batchUpdateOrders(
    unprocessedOrders
  );
  if (success) {
    yield put({ type: actions.BATCH_PROCESS_ORDERS_SUCCESS });
  } else {
    yield put({ type: actions.BATCH_PROCESS_ORDERS_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}

export default function* customerAddressesSaga() {
  yield all([
    takeLatest(actions.FETCH_UNPROCESSED_ORDERS, getOrders),
    takeLatest(actions.BATCH_PROCESS_ORDERS, batchProcessOrders)
  ]);
}
