import React, { useState, useRef } from 'react';
import { Card, Table, Row, Col, InputNumber, Button, Input, Form, Spin, Empty } from 'antd';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
const { Item } = Form;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const DeliveryFeeRUD = (props) => {
  const { deliveryFees, isFetchingFees, uploadDeliveryFees, resetUploadStatus } = props;
  const [selectedRecord, setSelectedRecord] = useState({});
  const formRef = useRef();
  const columns = [
    {
      title: 'Post Code',
      dataIndex: 'postcode',
      sorter: (a, b) => parseInt(a.postcode) - parseInt(b.postcode)
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Delivery Fee',
      sorter: (a, b) => a.fee - b.fee,
      dataIndex: 'fee',
    },
    {
      title: 'Free Threshold',
      dataIndex: 'freeThreshold',
      sorter: (a, b) => a.freeThreshold - b.freeThreshold,
    },
  ];

  const submitEditHandler = (values) => {
    let modified = false;
    for (const key of Object.keys(values)) {
      if (values[key] !== selectedRecord[key]) {
        modified = true;
        break;
      }
    }
    if (modified) {
      uploadDeliveryFees({ areaPostages: [values] });
      formRef.current.setFieldsValue({});
    } else {
      toast.warn('No field is changed.', {
        autoClose: 2000
      })
    }
  };

  return (
    <Spin spinning={isFetchingFees}>
      <Row gutter={16} style={{ paddingTop: '15px' }}>
        <Col span={14}>
          <Table
            size="small"
            rowKey="postcode"
            bordered
            dataSource={deliveryFees}
            columns={columns}
            pagination={{ position: ['bottom', 'left'] }}
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedRecord(record);
                  formRef.current && formRef.current.setFieldsValue({ ...record });
                  resetUploadStatus();
                },
              };
            }}
          />
        </Col>
        <Col span={10}>
          <Card title="Edit Delivery Fee">
            {selectedRecord.postcode ? (
              <Form
                {...formItemLayout}
                ref={formRef}
                initialValues={selectedRecord}
                onFinish={submitEditHandler}
              >
                <Item name="postcode" label="Post Code">
                  <Input disabled />
                </Item>
                <Item
                  name="name"
                  label="Suburb Name"
                  rules={[{ required: true, message: 'Suburb Name cannot be empty.' }]}
                >
                  <Input />
                </Item>
                <Item
                  name="fee"
                  label="Delivery Fee"
                  rules={[{ required: true, message: 'Delivery Fee cannot be empty.' }]}
                >
                  <InputNumber min={0} />
                </Item>
                <Item
                  name="freeThreshold"
                  label="Free Threshold"
                  rules={[{ required: true, message: 'Free Threshold cannot be empty.' }]}
                >
                  <InputNumber min={0} />
                </Item>
                <Item
                  wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 16, offset: 8 },
                  }}
                >
                  <Button style={{ marginRight: '20px' }} type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button
                    danger
                    onClick={() => {
                      formRef.current.setFieldsValue({});
                      setSelectedRecord({});
                      resetUploadStatus();
                    }}
                  >
                    Cancel
                  </Button>
                </Item>
              </Form>
            ) : (
              <Empty description={<span>Click a table row to edit details.</span>} />
            )}
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

DeliveryFeeRUD.propTypes = {
  isFetchingFees: PropTypes.bool,
  deliveryFees: PropTypes.array,
  uploadDeliveryFees: PropTypes.func,
  isUploadingFees: PropTypes.bool,
  resetUploadStatus: PropTypes.func,
};

export default DeliveryFeeRUD;
