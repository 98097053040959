import Joi from "@hapi/joi";

const AU_STATES = ["VIC", "NSW", "QLD", "NT", "ACT", "TAS", "WA", "SA"];

export default Joi.object({
  /* Nickname of the address */
  name: Joi.string().required(),

  /* Latitute and longtitude */
  lat: Joi.number(),
  lon: Joi.number(),

  /* Detail of the address */
  address: Joi.string().required(),
  unitNumber: Joi.string(), // Optional field for townhouses or apartments
  suburb: Joi.string().required(),
  state: Joi.string()
    // Currently accept other strings as well, change to .validate(AU_STATES) to only whitelist excusively
    .allow(...AU_STATES)
    .required(),
  nationality: Joi.string().required(),
  postcode: Joi.string().required(),

  /* Contact details */
  contactName: Joi.string().required(),
  contactNumber: Joi.string().required(),

  /* Whether the address is the default address of the customer */
  isPrimary: Joi.boolean().required()
}).required();
