import actions from './actions';

export const initAddressesState = {
  customerAddresses: {},
  isFetchingCustomerAddresses: false,
};

export default function customerAddressesReducer(state = initAddressesState, { type, ...action }) {
  switch (type) {
    case actions.GET_CUSTOMER_ADDRESSES:
      return {
        ...state,
        isFetchingCustomerAddresses: true,
      };
    case actions.GET_CUSTOMER_ADDRESSES_SUCCESS:
      return {
        ...state,
        isFetchingCustomerAddresses: false,
        customerAddresses: action.addresses,
      };
    case actions.GET_CUSTOMER_ADDRESSES_FAIL:
      return {
        ...state,
        isFetchingCustomerAddresses: false,
      };
    default:
      return state;
  }
}
