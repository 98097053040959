const actions = {
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAIL: 'CREATE_CATEGORY_FAIL',

  createCategory: ({ id, newCategory }) => ({
    type: actions.CREATE_CATEGORY,
    id,
    newCategory
  })
};

export default actions;