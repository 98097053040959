/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Button, message, Select } from 'antd';
import moment from 'moment';
import { firestore } from '../../utils/firebase';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import jsonexport from 'jsonexport/dist';
import { SyncOutlined } from '@ant-design/icons';

const SaveCSV = () => {
  const [loading, setLoading] = useState(false);
  const [filedownloadlink, setFiledownloadlink] = useState('');
  const [daysFromNow, setDaysFromNow] = useState(1);

  const getDeliveryInfo = async () => {
    const startTimeString =
      moment().add(daysFromNow, 'days').format('YYYY-MM-DD') + ' 00:00';
    const endTimeString =
      moment().add(daysFromNow, 'days').format('YYYY-MM-DD') + ' 23:59';
    try {
      const deliveryOrders = await firestore
        .collection('orders')
        .where('status', 'in', ['paid', 'processing', 'delivering'])
        .where('deliveryTime', '>=', startTimeString)
        .where('deliveryTime', '<=', endTimeString)
        .orderBy('deliveryTime', 'asc')
        .orderBy('createdAt')
        .get();
      if (deliveryOrders.empty) {
        // do nothing here, return empty array
        return [];
      }
      let orderList = [];
      deliveryOrders.forEach((order) => {
        let orderInfo = order.data();
        const { total, deliveryTime, deliveryAddress, orderIndex } = orderInfo;
        orderList.push({
          Index: orderIndex,
          Name: deliveryAddress.contactName,
          'Phone Number': deliveryAddress.contactNumber,
          Address:
            (deliveryAddress.unitNumber
              ? deliveryAddress.unitNumber + '/'
              : '') +
            deliveryAddress.address +
            ' ' +
            deliveryAddress.suburb +
            ' ' +
            deliveryAddress.state +
            deliveryAddress.postcode,
          'Delivery Time': deliveryTime,
          'Total Price': '$' + total,
          Comment: orderInfo.comment,
          'Note 1': '',
          'Note 2': ''
        });
      });
      message.success('Data is loaded, you can download now.');
      return orderList;
    } catch (error) {
      console.log(error);
      // toast error message
      toast.error(error.toString(), {
        autoClose: 5000
      });
      return [];
    }
  };

  const exportCSV = async () => {
    setLoading(true);
    const orderList = await getDeliveryInfo();
    jsonexport(orderList, function (err, csv) {
      if (err) return console.log(err);
      const myURL = window.URL || window.webkitURL; //window.webkitURL works in Chrome and window.URL works in Firefox
      const blob = new Blob([csv], { type: 'text/csv' });
      const csvUrl = myURL.createObjectURL(blob);
      setFiledownloadlink(csvUrl);
    });
    setLoading(false);
  };

  return (
    <>
      <Select value={daysFromNow} onChange={setDaysFromNow}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day) => (
          <Select.Option key={day} value={day}>
            {moment().add(day, 'days').format('YYYY-MM-DD')}
          </Select.Option>
        ))}
      </Select>
      <Button onClick={exportCSV} shape="round" style={{ marginLeft: '15px' }}>
        <SyncOutlined spin={loading} />
        {`Generate ${moment()
          .add(daysFromNow, 'days')
          .format('YYYY-MM-DD')}'s Delivery Details`}
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: '15px' }}
        shape="round"
        disabled={!filedownloadlink}
      >
        {filedownloadlink && (
          <a
            download={`${moment()
              .add(daysFromNow, 'days')
              .format('YYYY-MM-DD')}.csv`}
            href={filedownloadlink}
          >
            Download CSV
          </a>
        )}
      </Button>
    </>
  );
};

SaveCSV.propTypes = {
  days: PropTypes.number,
  disabled: PropTypes.bool
};

export default SaveCSV;
