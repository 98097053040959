import React from 'react';
import { Alert, Modal, Typography, Result } from 'antd';
import PropTypes from 'prop-types';

const UploadMessage = (props) => {
  const { slotObjects, errorMessage, uploadSuccess, resetUploadStatus } = props;

  const getResultStatus = () => {
    switch (uploadSuccess) {
      case true:
        return {
          status: 'success',
          title: 'Operation Success!',
        };
      case false:
        return {
          status: 'error',
          title: 'Oops, something went wrong.',
        };
      default:
        return {
          status: 'info',
          title: 'Your operation has been executed.',
        };
    }
  };

  return (
    <>
      {uploadSuccess !== true && (
        <div>
          <div style={{ paddingTop: '15px' }}>
            {slotObjects.length > 0 && (
              <Alert
                message={`${slotObjects.length} time slot${
                  slotObjects.length === 1 ? ' is' : 's are'
                } ready to upload:`}
                type="success"
                closable
                showIcon
                description={slotObjects
                  .map((slot) => `${slot.postcode}: ${slot.day} ${slot.start} - ${slot.end}`)
                  .join(', ')}
              />
            )}
          </div>
          <div style={{ paddingTop: '15px' }}>
            {JSON.stringify(errorMessage) !== '[]' && (
              <Alert
                message="Data validation error:"
                type="error"
                closable
                showIcon
                description={
                  errorMessage.length !== 0 &&
                  errorMessage.map((message) => (
                    <div key={message}>
                      <Typography.Text type="danger">{message}</Typography.Text>
                    </div>
                  ))
                }
              />
            )}
          </div>
        </div>
      )}
      <Modal
        visible={uploadSuccess !== null}
        title="Upload Result"
        onOk={resetUploadStatus}
        onCancel={resetUploadStatus}
      >
        <Result
          {...getResultStatus()}
        />
      </Modal>
    </>
  );
};

UploadMessage.propTypes = {
  slotObjects: PropTypes.array,
  errorMessage: PropTypes.array,
  isUploading: PropTypes.bool,
  uploadSuccess: PropTypes.bool,
  resetUploadStatus: PropTypes.func,
};

export default UploadMessage;
