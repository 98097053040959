/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from 'react';
import { Typography, Button, Spin, Select } from 'antd';
import csvToJson from 'csvtojson/v2';
import PropTypes from 'prop-types';
import UploadMessage from './UploadMessage';
import GenerateSlotsButton from './generateTimeSlots';

function removeEmpty(object) {
  const keys = Object.keys(object).filter(
    (key) => object[key] && object[key] !== ''
  );
  const newObject = {};
  for (const key of keys) {
    newObject[key] = object[key];
  }
  return newObject;
}

const DeliveryTimeUpload = (props) => {
  const {
    uploadTimeslots,
    isUploading,
    uploadSuccess,
    resetUploadStatus
  } = props;
  let fileReader;
  const fileRef = useRef();

  const [slotsObjects, setSlotsObjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [dayFromNow, setDayFromNow] = useState(null);

  const handleTimeUpload = async () => {
    fileRef.current.value = null;
    const reducer = (acc, cur) => {
      const { postcode, day, last, start, end, maxOrderNumber } = cur;
      if (acc[postcode]) {
        if (acc[postcode][day]) {
          acc[postcode][day].push({ last, start, end, maxOrderNumber });
        } else acc[postcode][day] = [{ last, start, end, maxOrderNumber }];
      } else {
        acc[postcode] = { [day]: [{ last, start, end, maxOrderNumber }] };
      }
      return acc;
    };
    const result = slotsObjects.reduce(reducer, {});
    uploadTimeslots({ timeslots: result });
    console.log(result);
    setSlotsObjects([]);
    setErrorMessage([]);
    fileRef.current = null;
  };

  async function csvJSON(csv) {
    const result = [];
    const records = await csvToJson().fromString(csv);
    records.forEach((obj) => result.push(removeEmpty(obj)));
    return result;
  }

  const handleFileRead = async () => {
    const jsonobj = await csvJSON(fileReader.result);
    validateDeliverFeeObjects(jsonobj);
  };

  const validateDeliverFeeObjects = (timeslotsObjects) => {
    const objectsRead = {};
    let index = 1;
    const keys = ['postcode', 'day', 'last', 'start', 'end', 'maxOrderNumber'];
    const newErrorMessage = [...errorMessage];
    const newSlotsObjects = [...slotsObjects];
    for (const slotObj of timeslotsObjects) {
      let objValid = true;
      for (const key of keys) {
        if (!slotObj[key]) {
          newErrorMessage.push(`Row ${index}: "${key}" is required!`);
          objValid = false;
        }
      }
      if (slotObj.postcode) {
        if (
          slotObj.postcode.length !== 4 ||
          !slotObj.postcode.match('^[1-9][0-9]{3}')
        ) {
          newErrorMessage.push(
            `Row ${index}: Postcode "${slotObj.postcode}" should be 4 digits!`
          );
          objValid = false;
        }
        if (objectsRead[JSON.stringify(slotObj)]) {
          newErrorMessage.push(`Row ${index} duplidated, should be removed.`);
          objValid = false;
        } else {
          objectsRead[JSON.stringify(slotObj)] = slotObj;
        }
      }
      if (slotObj.day) {
        if (
          ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].indexOf(
            slotObj.day
          ) === -1
        ) {
          newErrorMessage.push(
            `Row ${index}: Day "${slotObj.day}" invalid, must be one of 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'.`
          );
          objValid = false;
        }
      }
      for (const key of ['last', 'start', 'end']) {
        if (slotObj[key]) {
          if (!slotObj[key].match('^[0-2][0-9]:[0-5][0-9]$')) {
            newErrorMessage.push(
              `Row ${index}: ${key} "${slotObj[key]}" invalid!`
            );
            objValid = false;
          }
        }
      }
      if (slotObj.maxOrderNumber) {
        if (slotObj.maxOrderNumber.match('[^0-9]')) {
          newErrorMessage.push(
            `Row ${index}: Max number of order "${slotObj.maxOrderNumber}" should be a number.`
          );
          objValid = false;
        } else if (parseInt(slotObj.maxOrderNumber) < 0) {
          newErrorMessage.push(
            `Row ${index}: Max number of order "${slotObj.maxOrderNumber}" should be greater than 0.`
          );
          objValid = false;
        }
        slotObj.maxOrderNumber = parseInt(slotObj.maxOrderNumber);
      }
      if (objValid) newSlotsObjects.push(slotObj);
      index++;
    }
    setErrorMessage(newErrorMessage);
    setSlotsObjects(newSlotsObjects);
  };

  const handleFileChosen = (file) => {
    try {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin spinning={isUploading}>
      <Typography.Text strong style={{ paddingLeft: '20px' }}>
        Click 'Chose file' to upload default delivery time for suburbs:
      </Typography.Text>
      <input
        ref={fileRef}
        type="file"
        id="file"
        accept=".csv"
        style={{ paddingLeft: '15px' }}
        onChange={(e) => {
          handleFileChosen(e.target.files[0]);
        }}
        onClick={(event) => {
          event.target.value = null;
          setSlotsObjects([]);
          setErrorMessage([]);
        }}
      />
      <Button
        style={{ paddingLeft: '15px' }}
        type="primary"
        onClick={handleTimeUpload}
        disabled={errorMessage.length !== 0 || slotsObjects.length === 0}
      >
        Submit
      </Button>
      <div style={{ paddingTop: '15px' }}>
        <Typography.Text strong style={{ paddingLeft: '20px' }}>
          Re-generate delivery timeslots:
        </Typography.Text>
        <Select
          style={{ paddingLeft: '15px', width: '300px' }}
          placeholder="Please select day from now."
          value={dayFromNow}
          onChange={setDayFromNow}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day) => (
            <Select.Option
              key={day}
              value={day}
            >{`${day} days from today`}</Select.Option>
          ))}
        </Select>
        <GenerateSlotsButton
          disabled={dayFromNow === null}
          days={dayFromNow}
        />
      </div>

      <UploadMessage
        slotObjects={slotsObjects}
        errorMessage={errorMessage}
        isUploading={isUploading}
        uploadSuccess={uploadSuccess}
        resetUploadStatus={resetUploadStatus}
      />
    </Spin>
  );
};

DeliveryTimeUpload.propTypes = {
  uploadTimeslots: PropTypes.func,
  isUploading: PropTypes.bool,
  uploadSuccess: PropTypes.any,
  resetUploadStatus: PropTypes.func
};

export default DeliveryTimeUpload;
