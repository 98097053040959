import actions from './actions';

const initState = {
  isFetchingBanners: false,
  isModifyingBanner: false,
  isDeletingBanner: false,
  banners: {}
};

export default function bannersReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_BANNERS:
      return {
        ...state,
        isFetchingBanners: true,
      };
    case actions.GET_BANNERS_SUCCESS:
      return {
        ...state,
        isFetchingBanners: false,
        banners: action.banners,
      };
    case actions.GET_BANNERS_FAIL:
      return {
        ...state,
        isFetchingBanners: false,
      };
    case actions.EDIT_BANNER_PICTURE:
    case actions.EDIT_BANNER_CATEGORY:
      return {
        ...state,
        isModifyingBanner: true,
      };
    case actions.EDIT_BANNER_PICTURE_SUCCESS:
      return {
        ...state,
        isModifyingBanner: false,
        banners: action.banners,
      }
    case actions.EDIT_BANNER_CATEGORY_SUCCESS:
    case actions.EDIT_BANNER_PICTURE_FAIL:
    case actions.EDIT_BANNER_CATEGORY_FAIL:
      return {
        ...state,
        isModifyingBanner: false,
      };
    case actions.DELETE_BANNER:
      return {
        ...state,
        isDeletingBanner: true,
      };
    case actions.DELETE_BANNER_SUCCESS:
    case actions.DELETE_BANNER_FAIL:
      return {
        ...state,
        isDeletingBanner: false,
      };
    default: return state;
  }
}