import React from 'react';
import { Modal, Tag, Button, Spin, List, Typography } from 'antd';
import PropTypes from 'prop-types';

const generateListTitle = (label) => {
  if (!label || label === '') return '';
  return <Tag color="#108ee9">{label}</Tag>;
};

const generateAddress = (addresses) => {
  return (
    <List
      itemLayout="vertical"
      size="small"
      dataSource={Object.keys(addresses).map((add) => addresses[add])}
      renderItem={(item) => (
        <List.Item extra={generateListTitle(item.label)}>
          <List.Item.Meta
            title={
              <div style={{ fontWeight: 'bold', fontSize: '15' }}>
                {[
                  (item.unitNumber ? item.unitNumber + '/' : '') + item.address,
                  item.suburb + ',',
                  item.state + item.postcode,
                ].join(' ')}
              </div>
            }
            description={
              <>
                <> Name: </>
                <Typography.Text strong>{item.contactName}</Typography.Text>
                <br />
                <> Phone: </>
                <Typography.Text strong copyable>{item.contactNumber}</Typography.Text>
                {item.isPrimary ? (
                  <Tag color="#2db7f5" style={{ float: 'right' }}>
                    Primary
                  </Tag>
                ) : (
                  ''
                )}
              </>
            }
          />
        </List.Item>
      )}
    ></List>
  );
};

const AddressModal = (props) => {
  const { customerAddresses, isFetchingCustomerAddresses } = props;
  return (
    <>
      <Modal
        title="Addresses"
        visible={props.visibility}
        onCancel={props.closeModalHandler}
        footer={[
          <Button key="close" onClick={props.closeModalHandler}>
            OK
          </Button>,
        ]}
      >
        <Spin spinning={isFetchingCustomerAddresses}>{generateAddress(customerAddresses)}</Spin>
      </Modal>
    </>
  );
};

AddressModal.propTypes = {
  visibility: PropTypes.bool,
  closeModalHandler: PropTypes.func,
  customerId: PropTypes.string,
  customerAddresses: PropTypes.object,
  isFetchingCustomerAddresses: PropTypes.bool,
};

export default AddressModal;
