import React, { useState, useRef } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
const { Item } = Form;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const AddNewUserModal = (props) => {
  const { visibility, createUser, closeModal } = props;
  const [password, setPassword] = useState('');
  const formRef = useRef();
  const createUserHandler = (values) => {
    const newUser = {
      email: values.email,
      nickname: values.displayName,
      password: values.password,
      mobile: '+61' + values.phoneNumber
    };
    // console.log(newUser);
    // Uncomment the following line if firebase cloud functions are deployed.
    createUser({...newUser});
    formRef.current.resetFields();
    closeModal();
  };

  return (
    <>
      <Modal
        title="New User"
        visible={visibility}
        onOk={() => {}}
        onCancel={() => {
          closeModal();
          formRef.current.resetFields();
        }}
        footer={[
          <Button key="back" onClick={() => { closeModal(); formRef.current.resetFields();}}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" form="newUserForm" htmlType="submit">
            Submit
          </Button>,
        ]}
      >
        <Form {...formItemLayout} id="newUserForm" ref={formRef} onFinish={createUserHandler}>
          <Item
            name="displayName"
            label="New Name"
            rules={[{ required: true, message: 'User name is required!' }]}
          >
            <Input placeholder="Please enter user name" />
          </Item>
          <Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Email is required!' },
              { pattern: '@{1}[a-z0-9]+', message: 'Email address invalid!' },
            ]}
          >
            <Input placeholder="Please enter email" />
          </Item>
          <Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: 'Phone number is required!' },
              { pattern: '^[0-9]{9}$', message: 'Phone number should be 9 digits!' },
            ]}
          >
            <Input addonBefore="+61" placeholder="4XXXXXXXX" />
          </Item>
          <Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please enter password.' }, {pattern: '.{6,}', message: 'Password should be at least 6 characters!'}]}
          >
            <Input.Password
              placeholder="Please enter password."
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Item>
          <Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              { required: true, message: 'Please enter password.' },
              { pattern: `^${password}$`, message: 'Password inconsistent.' }//cannot use enum rule here because it is password input
            ]}
          >
            <Input.Password placeholder="Please confirm password." />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

AddNewUserModal.propTypes = {
  visibility: PropTypes.bool,
  createUser: PropTypes.func,
  closeModal: PropTypes.func,
};

export default AddNewUserModal;
