import { all, put, takeLatest } from 'redux-saga/effects';
import newCouponActions from './actions';
import couponActions from '../actions';
import { toast } from 'react-toastify';
import couponsApi from '../../../apis/Coupons';

function* createCoupon({ newCoupon }) {
  const { success, error } = yield couponsApi.createCoupon({input: [newCoupon]});
  if (success) {
    yield put({ type: newCouponActions.CREATE_COUPON_SUCCESS });
    yield put({ type: couponActions.FETCH_COUPON_LIST });
  } else {
    yield put({ type: newCouponActions.CREATE_COUPON_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    })
  }
}

export default function* newProductSaga() {
  yield all([
    takeLatest(newCouponActions.CREATE_COUPON, createCoupon),
  ]);
}