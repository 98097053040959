const actions = {
  FETCH_CUTSOMER_COUPONS: 'FETCH_CUSTOMER_COUPONS',
  FETCH_CUTSOMER_COUPONS_SUCCESS: 'FETCH_CUSTOMER_COUPONS_SUCCESS',
  FETCH_CUTSOMER_COUPONS_FAIL: 'FETCH_CUSTOMER_COUPONS_FAIL',

  EDIT_CUSTOMER_COUPONS: 'EDIT_CUSTOMER_COUPONS',
  EDIT_CUSTOMER_COUPONS_SUCCESS: 'EDIT_CUSTOMER_COUPONS_SUCCESS',
  EDIT_CUSTOMER_COUPONS_FAIL: 'EDIT_CUSTOMER_COUPONS_FAIL',

  getCustomerCoupons: ({ uid }) => ({
    type: actions.FETCH_CUTSOMER_COUPONS,
    uid
  }),

  assignCustomerCoupons: ({ uid, coupons }) => ({
    type: actions.EDIT_CUSTOMER_COUPONS,
    uid,
    coupons
  }),
};

export default actions;