import actions from './actions';

const initState = {
  isFetchingData: false,
  workspaceData: {},
};

function workspaceReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_WORKSPACE_DATA:
      return {
        ...state,
        isFetchingData: true,
      };
    case actions.FETCH_WORKSPACE_DATA_SUCCESS:
      return {
        ...state,
        isFetchingData: false,
        workspaceData: action.data,
      };
    case actions.FETCH_WORKSPACE_DATA_FAIL:
      return {
        ...state,
        isFetchingData: false,
      };
    default: return state;
  }
}

export default workspaceReducer;