import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from './actions';
import deliveryFeeApi from '../../apis/Configuration/DeliveryConfiguration';

function* getDeliveryFees() {
  const { success, data, error } = yield deliveryFeeApi.getAreaPostage();
  if (success) {
    yield put({ type: actions.FETCH_DELIVERY_FEES_SUCCESS, deliveryFees: data });
  } else {
    yield put({ type: actions.FETCH_DELIVERY_FEES_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

function* uploadDeliveryFees({ areaPostages }) {
  const { success, data, error } = yield deliveryFeeApi.setAreaPostage({ areaPostages });
  if (success) {
    yield put({ type: actions.UPLOAD_DELIVERY_FEES_SUCCESS, feeUploaded: data });
    yield put({ type: actions.FETCH_DELIVERY_FEES });
  } else {
    yield put({ type: actions.UPLOAD_DELIVERY_FEES_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

function* getDeliveryToUpstairsConfig() {
  const { success, data, error } = yield deliveryFeeApi.getDeliveryUpstairsConfig();
  if (success) {
    yield put({ type: actions.GET_UPSTAIRS_CONFIG_SUCCESS, config: data });
  } else {
    yield put({ type: actions.GET_UPSTAIRS_CONFIG_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}

function* updateDeliveryToUpstairsConfig({ deliveryToUpstairs, deliveryToUpstairsFee }) {
  const { success, data, error } = yield deliveryFeeApi.updateDeliveryUpstairsConfig({ deliveryToUpstairs, deliveryToUpstairsFee });
  if (success) {
    yield put({ type: actions.UPDATE_UPSTAIRS_CONFIG_SUCCESS, config: data });
  } else {
    yield put({ type: actions.UPDATE_UPSTAIRS_CONFIG_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}

export default function* deliveryFeeSaga() {
  yield all([
    takeLatest(actions.FETCH_DELIVERY_FEES, getDeliveryFees),
    takeLatest(actions.UPLOAD_DELIVERY_FEES, uploadDeliveryFees),
    takeLatest(actions.GET_UPSTAIRS_CONFIG, getDeliveryToUpstairsConfig),
    takeLatest(actions.UPDATE_UPSTAIRS_CONFIG, updateDeliveryToUpstairsConfig)
  ]);
}
