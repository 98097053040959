import React, { useState } from 'react';
import { Button } from 'antd';
import moment from 'moment';
import { SyncOutlined } from '@ant-design/icons';
import { firestore } from '../../utils/firebase';
import PropTypes from 'prop-types';

const ShowTimeSlots = (props) => {
  const { days, disabled } = props;
  const [generating, setGenerating] = useState(false);
  
  const getDateAndDay = () => {
    const fiveDaysLatter = moment().add(days, 'days').format('YYYY-MM-DD ddd');
    const [date, day] = fiveDaysLatter.split(' ');
    return [date, day];
  };

  const getDefaultSlots = async () => {
    const [date, day] = getDateAndDay();
    let query = firestore.collection(
      'configs/deliveryConfig/time/default/suburbDeliverySlots'
    );
    query = query.orderBy(day);
    const result = await query.get();
    const data = { date, slots: {} };
    result.forEach((doc) => {
      const postcode = doc.data().postcode;
      const slotDetail = doc.data()[day];
      slotDetail.forEach((entry) => {
        entry.available = true;
        entry.ordersLeft = entry.maxOrderNumber;
      });
      data.slots[postcode] = slotDetail;
    });
    return data;
  };

  const setDeliverySlots = async () => {
    setGenerating(true);
    const data = await getDefaultSlots();
    const { date, slots } = data;
    const docRef = firestore.doc(
      `configs/deliveryConfig/deliverySlotsByPostcode/${date}`
    );
    const snapShot = await docRef.get();
    await snapShot.ref.set({ ...slots });
    setGenerating(false)
  };

  return (
    <Button
      type="primary"
      style={{ marginLeft: '15px' }}
      onClick={setDeliverySlots}
      disabled={disabled}
    >
      <SyncOutlined spin={generating} />
      Generate Slots
    </Button>
  );
};

ShowTimeSlots.propTypes = {
  days: PropTypes.number,
  disabled: PropTypes.bool,
};

export default ShowTimeSlots;
