import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import orderApi from '../../apis/Orders';


function* getDeliveryCount() {
  const { success, data, error } = yield orderApi.getDeliveryCountByDate();
  if (success) {
    yield put({ type: actions.GET_DELIVERY_COUNT_SUCCESS, deliveryCount: data });
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.GET_DELIVERY_COUNT_FAIL });
  }
}

export default function* deliveryCountSaga() {
  yield all([
    takeLatest(actions.GET_DELIVERY_COUNT, getDeliveryCount),
  ])
}