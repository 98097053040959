/* eslint-disable react/no-unescaped-entities */
import React, { useState, useRef, useEffect } from 'react';
import { PageHeader, Button, Typography, Spin, Card } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DeliveryFeeMessage from '../../components/DeliveryFeeMessage';
import deliveryActions from '../../redux/DeliveryConfig/actions';
import DeliveryFeeRUD from '../../components/DeliveryFeeRUD';
import DeliveryAccessPoint from '../../components/DeliveryAccessPointConfig';
import DeliveryToUpstairs from '../../components/DeliveryToUpstairs';
import DeliveryFeeTemplate from '../../assets/DeliveryFeeUpload.zip';
function removeEmpty(object) {
  const keys = Object.keys(object).filter((key) => object[key] !== '');
  const newObject = {};
  for (const key of keys) {
    newObject[key] = object[key];
  }
  return newObject;
}

function csvJSON(csv) {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');
  for (var i = 1; i < lines.length; i++) {
    const obj = {};
    if (lines[i] !== '') {
      const currentline = lines[i].split(',');
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].trim()] = currentline[j].trim();
      }
      result.push(removeEmpty(obj));
    }
  }
  return result;
}

const DeliverFeeUpload = (props) => {
  const {
    isFetchingFees,
    deliveryFees,
    getDeliveryFees,
    isUploadingFees,
    feeUploaded,
    uploadDeliveryFees,
    resetUploadStatus,
    isFetchingUpstairsConfig,
    isUpdatingUpstairsConfig,
    deliveryToUpstairsConfig,
    getUpstairsConfig,
    updateUpstairsConfig
  } = props;
  const [errorMessage, setErrorMessage] = useState([]);
  const [feeObjects, setFeeObjects] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const fileRef = useRef();

  useEffect(() => {
    getDeliveryFees();
    getUpstairsConfig();
  }, [getDeliveryFees, getUpstairsConfig]);

  let fileReader;
  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard'
    },
    {
      path: 'deliverfeeupload',
      breadcrumbName: 'Delivery Fee Upload'
    }
  ];

  const validateDeliverFeeObjects = (deliveryFeeObjects) => {
    const objectsRead = {};
    let index = 1;
    const keys = ['postcode', 'fee', 'freeThreshold'];
    const newErrorMessage = [...errorMessage];
    const newFeeObjects = [...feeObjects];
    for (const feeObj of deliveryFeeObjects) {
      let objValid = true;
      for (const key of keys) {
        if (!feeObj[key]) {
          newErrorMessage.push(`Row ${index}: "${key}" is required!`);
          objValid = false;
        }
      }
      if (feeObj.postcode) {
        if (
          feeObj.postcode.length !== 4 ||
          !feeObj.postcode.match('^[1-9][0-9]{3}')
        ) {
          newErrorMessage.push(
            `Row ${index}: Postcode "${feeObj.postcode}" should be 4 digits!`
          );
          objValid = false;
        }
        if (objectsRead[feeObj.postcode]) {
          newErrorMessage.push(
            `Row ${index}: Postcode "${feeObj.postcode}" duplidated!`
          );
          objValid = false;
        } else {
          objectsRead[feeObj.postcode] = feeObj;
        }
      }
      for (const key of ['fee', 'freeThreshold']) {
        if (feeObj[key]) {
          if (
            feeObj[key].match('[^0-9.]') ||
            !feeObj[key].match('(^[0-9]*\\.?[0-9]*)|0')
          ) {
            newErrorMessage.push(
              `Row ${index}: ${key} "${feeObj[key]}" invalid!`
            );
            objValid = false;
          } else {
            feeObj[key] = parseFloat(feeObj[key]);
            if (feeObj[key] < 0) {
              newErrorMessage.push(
                `Row ${index}: ${key} "${feeObj[key]}" invalid!`
              );
              objValid = false;
            }
          }
        }
      }
      if (
        feeObj.fee >= 0 &&
        feeObj.freeThreshold >= 0 &&
        feeObj.fee > feeObj.freeThreshold
      ) {
        newErrorMessage.push(
          `Row ${index}: Fee "${feeObj.fee}" should not exceed free threshold "${feeObj.freeThreshold}"!`
        );
        objValid = false;
      }
      if (objValid) newFeeObjects.push(feeObj);
      index++;
    }
    setErrorMessage(newErrorMessage);
    setFeeObjects(newFeeObjects);
  };

  const handleFeeUpload = () => {
    fileRef.current.value = null;
    uploadDeliveryFees({ areaPostages: feeObjects });
    setModalVisibility(true);
    console.log(feeObjects);
    console.log(errorMessage);
  };

  const handleFileRead = () => {
    const jsonobj = csvJSON(fileReader.result);
    validateDeliverFeeObjects(jsonobj);
  };

  const handleFileChosen = (file) => {
    try {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Delivery Fees"
        breadcrumb={{ routes }}
        extra={[
          <Button
            style={{ float: 'right' }}
            key="downloadTemplate"
            type="primary"
            shape="round"
          >
            <a href={DeliveryFeeTemplate} download="DeliveryFeeTemplate.zip">
              Download Template
            </a>
          </Button>
        ]}
      ></PageHeader>
      <Spin spinning={isUploadingFees}>
        <Typography.Text strong style={{ paddingLeft: '20px' }}>
          Click 'Chose file' to upload a CSV file:
        </Typography.Text>
        <input
          ref={fileRef}
          type="file"
          id="file"
          accept=".csv"
          style={{ paddingLeft: '15px' }}
          onChange={(e) => {
            handleFileChosen(e.target.files[0]);
          }}
          onClick={(event) => {
            event.target.value = null;
            setFeeObjects([]);
            setErrorMessage([]);
          }}
        />
        <Button
          style={{ paddingLeft: '15px' }}
          type="primary"
          onClick={handleFeeUpload}
          disabled={errorMessage.length !== 0 || feeObjects.length === 0}
        >
          Submit
        </Button>
        <DeliveryFeeRUD
          deliveryFees={deliveryFees}
          isFetchingFees={isFetchingFees}
          uploadDeliveryFees={uploadDeliveryFees}
          resetUploadStatus={resetUploadStatus}
        />
        <DeliveryFeeMessage
          errorMessage={errorMessage}
          feeObjects={feeObjects}
          resetErrorMessage={() => setErrorMessage([])}
          resetFeeObjects={() => setFeeObjects([])}
          feeUploaded={feeUploaded}
          closeModal={() => {
            setModalVisibility(false);
            resetUploadStatus();
          }}
          modalVisibility={modalVisibility && feeUploaded > 0}
        />
        <Card
          title="Delivery Access Point Configuration"
          style={{ marginTop: '15px' }}
        >
          <DeliveryAccessPoint />
        </Card>
        <Card title="Delivery To Upstairs" style={{ marginTop: '15px' }}>
          <DeliveryToUpstairs
            config={deliveryToUpstairsConfig}
            updateConfig={updateUpstairsConfig}
            loading={isUpdatingUpstairsConfig || isFetchingUpstairsConfig}
          />
        </Card>
      </Spin>
    </>
  );
};
const {
  getDeliveryFees,
  uploadDeliveryFees,
  resetUploadStatus,
  getUpstairsConfig,
  updateUpstairsConfig
} = deliveryActions;
DeliverFeeUpload.propTypes = {
  isFetchingFees: PropTypes.bool,
  isUploadingFees: PropTypes.bool,
  feeUploaded: PropTypes.number,
  deliveryFees: PropTypes.array,
  getDeliveryFees: PropTypes.func,
  uploadDeliveryFees: PropTypes.func,
  resetUploadStatus: PropTypes.func,
  isFetchingUpstairsConfig: PropTypes.bool,
  isUpdatingUpstairsConfig: PropTypes.bool,
  deliveryToUpstairsConfig: PropTypes.object,
  getUpstairsConfig: PropTypes.func,
  updateUpstairsConfig: PropTypes.func
};

export default connect(
  (state) => {
    const { Deliveries } = state;
    const {
      isFetchingFees,
      isUploadingFees,
      feeUploaded,
      deliveryFees,
      isFetchingUpstairsConfig,
      isUpdatingUpstairsConfig,
      deliveryToUpstairsConfig
    } = Deliveries;
    return {
      isFetchingFees,
      isUploadingFees,
      feeUploaded,
      deliveryFees,
      isFetchingUpstairsConfig,
      isUpdatingUpstairsConfig,
      deliveryToUpstairsConfig
    };
  },
  {
    getDeliveryFees,
    uploadDeliveryFees,
    resetUploadStatus,
    getUpstairsConfig,
    updateUpstairsConfig
  }
)(DeliverFeeUpload);
