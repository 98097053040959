import { firebaseAuth, firestore, firebase } from '../utils/firebase';
import ProductSchema from '../schemas/product';
import algoliasearch from 'algoliasearch';
import { searchClient } from '../config/';
import { createNullCache } from '@algolia/cache-common';

const client = algoliasearch(searchClient.appId, searchClient.searchApiKey, { responsesCache: createNullCache() });
const productIndex = client.initIndex('PRODUCTS_prod');

let paginationCache = {};
const pageJumpValidator = (previousRequestCache, currentRequest) => {
  if (
    previousRequestCache.pageNumber !== undefined &&
    previousRequestCache.batchSize === currentRequest.batchSize &&
    previousRequestCache.archived === currentRequest.archived
  ) {
    if (
      previousRequestCache.pageNumber - currentRequest.pageNumber === 1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === -1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === 0
    ) {
      return true;
    }
  }
  return false;
};

const searchProduct = async (params) => {
  const { searchPattern, categories, archived, pageNumber, batchSize } = params;
  // Assume searchPattern !== ''
  try {
    if (searchPattern !== '') {
      let filter;
      if (archived === 'all') {
        filter = '';
      } else {
        if (archived === 'true') {
          filter = 'archived=1';
        } else {
          filter = 'archived=0';
        }
      }
      if (categories.length > 0) {
        const categeryFilters = categories
          .map((category) => `categories:${category}`)
          .join(' OR ');
        if (!filter) {
          filter = categeryFilters;
        } else {
          filter += ` AND (${categeryFilters})`;
        }
      }
      const { hits } = await productIndex.search(searchPattern, {
        filters: filter,
        page: pageNumber - 1,
        hitsPerPage: batchSize
      });
      const results = hits;
      return {
        success: true,
        data: {
          total: hits.length,
          pageNumber: pageNumber,
          batchSize: batchSize,
          products: results
        }
      };
    } else {
      return {
        success: false,
        data: {
          total: 0,
          products: []
        }
      };
    }
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: {
        total: 0,
        products: []
      },
      error: err
    };
  }
};

/** Archive a product, not actually delete it, but update state and counter
 * @param {{sku: string}} params
 */
const archiveProduct = async (params) => {
  const { sku } = params;
  const docRef = firestore.doc(`products/${sku}`);
  const counter = firestore.doc(`counters/products`);
  const increment = firestore.FieldValue.increment(1);
  // Archive the product
  await docRef.update('archived', true);
  await counter.update('archived', increment);
};

const productApi = {
  checkProductSkuExist: async (params) => {
    const { SKU } = params;
    try {
      const docRef = firestore.collection('products').doc(SKU);
      const doc = await docRef.get();
      if (doc.exists) {
        // document exist
        return { success: true, validity: false };
      } else {
        // No such a document
        return { success: true, validity: true };
      }
    } catch (error) {
      return { success: false, error: 'Cannot check SKU validity' };
    }
  },

  /** Create product in DB
   * @param product The product object to create
   */
  createProduct: async (product) => {
    const { error: validationError, value } = ProductSchema.validate(product);
    if (validationError) {
      // throw validationError;
      return {
        success: false,
        error: validationError
      };
    }
    try {
      await firestore.collection('products').doc(value.sku).set(value);
      return { success: true, data: value };
    } catch (err) {
      console.error(err);
      return { success: false, err };
    }
  },

  /** List products by category
   * @param {{categoryName: string, archived: string, startAt: number, batchSize: number}} params
   * @returns {{success: boolean, data: object, error?: object}}
   */
  listProductsByCategory: async (params) => {
    const { categoryName, archived, startAt, batchSize } = params;
    try {
      const prodcutList = [];
      const snapshot = await firestore
        .collection('products')
        .where('categories', 'array-contains', categoryName)
        .where('archived', '==', archived)
        .orderBy('priority', 'desc')
        // .startAt(startAt)
        // .limit(batchSize ? batchSize : 20)
        .get();
      console.log(snapshot.size);
      snapshot.forEach((doc) => {
        prodcutList.push(doc.data());
      });
      const counter = await firestore.doc('counters/categories').get();
      const total = counter.data()[categoryName];
      return {
        success: true,
        data: {
          total: total,
          startAt: startAt,
          batchSize: batchSize,
          products: prodcutList
        }
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: {},
        error
      };
    }
  },


  /**
   * @param {{searchPattern: string, categories: [string], archived: string, includeChildren: boolean, pageNumber: number, batchSize: number}} params
   * - searchPattern: A search string
   * - categories: A category name to fetch
   * - archived: 'all', 'true' (only show archived products), 'false' (only show active products)
   * - includeChildren: If `true`, it will filter out the child items in the list
   * - pageNumber: The page number to fetch
   * - batchSize: batchSize (default 20)
   *
   * @returns {{success: boolean, data:{ total: number, pageNumber: number, batchSize:number, products: Array }}}
   * - success: success status,
   * - total: total number of records
   * - pageNumber: pageNumber(default 1)
   * - batchSize: batchSize (default 20)
   * - products: list of Customer data
   *
   *  return example:
   * {
   *  success: true,
   *  data: {
   *    total: 15,
   *    pageNumber: 1,
   *    batchSize: 20,
   *    products: [...]
   * }
   * }
   */
  getProductList: async (params) => {
    let {
      searchPattern,
      categories,
      archived,
      includeChildren,
      pageNumber,
      batchSize
    } = params;
    const defaultValues = {
      categories: [],
      archived: 'all',
      includeChildren: true,
      pageNumber: 1,
      batchSize: 20
    };
    // Apply default values
    pageNumber = pageNumber || defaultValues.pageNumber;
    batchSize = batchSize || defaultValues.batchSize;
    archived = archived === undefined ? defaultValues.archived : archived;
    // For admin dashborad, get child products by default
    includeChildren = includeChildren || defaultValues.includeChildren;
    if (searchPattern) {
      return searchProduct({
        searchPattern,
        categories,
        archived,
        includeChildren,
        pageNumber,
        batchSize
      });
    }
    try {
      let query = firestore.collection('products').orderBy('priority', 'desc');
      if (!includeChildren) {
        // filter out children products
        query = query.where('isChild', '==', false);
      }

      // Filter archived product
      if (archived && archived !== 'all') {
        if (archived === 'false') {
          query = query.where('archived', '==', false);
        } else if (archived === 'true') {
          query = query.where('archived', '==', true);
        }
      } // show both by default

      if (Array.isArray(categories) && categories.length > 0) {
        query = query.where('categories', 'array-contains-any', categories);
      }

      if (pageNumber > 1) {
        if (!pageJumpValidator(paginationCache, params)) {
          throw new Error('Error: Pagination Sequence Incorrect');
        }
        if (pageNumber - paginationCache.pageNumber === 1) {
          query = query
            .startAfter(paginationCache.lastItemRef)
            .limit(batchSize);
        } else if (pageNumber - paginationCache.pageNumber === -1) {
          query = query
            .endBefore(paginationCache.firstItemRef)
            .limitToLast(batchSize);
        } else {
          query = query.startAt(paginationCache.firstItemRef).limit(batchSize);
        }
      } else {
        query = query.limit(batchSize);
      }

      const productList = [];
      const collectionSnapshot = await query.get();
      collectionSnapshot.forEach((doc) => {
        productList.push(doc.data());
      });

      // update Pagination cache
      const docRefs = collectionSnapshot.docs;
      if (docRefs.length !== 0) {
        paginationCache = {
          firstItemRef: docRefs[0],
          lastItemRef: docRefs.slice(-1)[0],
          pageNumber,
          batchSize,
          archived,
          includeChildren
        };
      }

      let totalNumberOfRecords = undefined;
      if (categories && categories.length > 0) {
        // No need to set total number of records
      } else {
        const counter = await firestore.doc('counters/products').get();
        const { total, archived: archivedProductNumber } = counter.data();
        // In all other cases, hide the total number
        totalNumberOfRecords = undefined;
        if (archived === 'true') {
          totalNumberOfRecords = archivedProductNumber;
        } else if (archived === 'false') {
          totalNumberOfRecords = total - archivedProductNumber;
        } else if (archived === 'all') {
          totalNumberOfRecords = total;
        }
      } // when category name is a list, it is not possible to get a total

      return {
        success: true,
        data: {
          total: totalNumberOfRecords,
          pageNumber: pageNumber,
          batchSize: batchSize,
          products: productList
        }
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        data: {
          total: 0,
          products: []
        },
        error
      };
    }
  },

  /**
   * @param {{sku: string}}
   * @returns {{success: boolean, product: object, error? object | null}}
   */
  getProductDetail: async (params) => {
    const { sku } = params;
    try {
      const snapshot = await firestore.collection('products').doc(sku).get();
      if (!snapshot.exists) throw new Error('Incorrect Product ID!');
      return {
        success: true,
        product: snapshot.data()
      };
    } catch (error) {
      return {
        success: false,
        product: {},
        error
      };
    }
  },

  /** Update product detail
   * @param {{sku: string, product: Object}}
   * @returns {{success: boolean, newProduct: object, error? object | null}}
   */
  editProductDetail: async (params) => {
    const { sku, product } = params;
    const { error, value } = ProductSchema.validate(product);
    if (error) {
      return {
        success: false,
        error
      };
    }
    // Remove attributes that are undefined
    const newProductInstance = {};
    Object.keys(value).forEach((key) => {
      if (value[key] !== undefined) {
        if (key === 'stock') {
          // the given stock number is the difference between the actual and target, not the exact value
          newProductInstance[key] = firebase.firestore.FieldValue.increment(
            value[key]
          );
        } else {
          newProductInstance[key] = value[key];
        }
      }
    });
    if (!newProductInstance.discountPrice) {
      newProductInstance.discountPrice = null;
    }
    try {
      await firestore.doc(`products/${sku}`).update(newProductInstance);
      const newProduct = await firestore.doc(`products/${sku}`).get();
      return {
        success: true,
        newProduct: newProduct.data()
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        newProduct: null,
        error: error
      };
    }
  },
  /** Delete a product by sku, by default it archives the product
   * @param params
   */
  deleteProduct: async (params = { realDelete: false, sku: '' }) => {
    if (params.realDelete) {
      const { sku } = params;
      try {
        const docRef = firestore.doc(`products/${sku}`);
        const uid = firebaseAuth.currentUser.uid;
        if (uid) {
          const data = (await firestore.doc(`users/${uid}`).get()).data();
          if (data.authority === 'developer' || data.authority === 'admin') {
            await docRef.delete();
          }
        }
        return null;
      } catch (error) {
        console.log(error);
      }
    }
    archiveProduct(params);
  },

  bacthDeleteProducts: async ({ products }) => {
    const productsDeleted = [];
    const uid = firebaseAuth.currentUser.uid;
    if (uid) {
      const data = (await firestore.doc(`users/${uid}`).get()).data();
      if (data.authority === 'developer' || data.authority === 'admin') {
        for (let product of products) {
          try {
            const snapshot = await firestore
              .collection('products')
              .doc(product.sku)
              .get();
            if (snapshot.exists) {
              const docRef = firestore.doc(`products/${product.sku}`);
              await docRef.delete();
              productsDeleted.push(product.sku);
            }
          } catch (error) {
            return {
              success: false,
              error: 'Operation Failed.'
            };
          }
        }
        // product number deduct productsDeleted.length
        return {
          success: true,
          data: productsDeleted
        };
      } else {
        return {
          success: false,
          error: 'Operation not permitted.'
        };
      }
    }
  },

  uploadProducts: async ({ newProductList }) => {
    try {
      const uploadTasks = [];
      for (let product of newProductList) {
        const { sku } = product;
        const snapshot = await firestore.collection('products').doc(sku).get();
        if (!snapshot.exists) {
          //create a new product object
          uploadTasks.push(
            firestore.collection('products').doc(sku).set(product)
          );
        } else {
          //edit current product
          const existedData = snapshot.data();
          ['categories', 'images', 'productDescriptionImages'].forEach(
            (key) => {
              if (product[key].length === 0)
                // if the field in csv is empty, remain the field unchanged
                product[key] = existedData[key];
              // if the field is not empty, replace data in database
            }
          );
          uploadTasks.push(firestore.doc(`products/${sku}`).update(product));
        }
      }
      return Promise.all(uploadTasks).then((results) => {
        return {
          success: true,
          data: results.length
        };
      });
    } catch (error) {
      return {
        success: false,
        error
      };
    }
  },

  /**
   *
   * @param sku
   * - product sku: string, sample: test-fruit-1
   * @param images
   * - array of string, ['products/${sku}/location', 'products/${sku}/location']
   * @returns {Promise<{data: {}, success: boolean, error: string}|{data: *, success: boolean, error: null}|{data: {}, success: boolean, error: *}>}
   */
  updateProductImageField: async ({ sku, images, storageLocation }) => {
    if (!sku) {
      return {
        success: false,
        error: 'sku is null',
        data: {}
      };
    }

    if (!images) {
      return {
        success: false,
        error: 'image should not be null!',
        data: {}
      };
    }

    try {
      await firestore
        .collection('products')
        .doc(sku)
        .update({ [storageLocation]: images });

      return {
        success: true,
        error: null,
        data: images
      };
    } catch (error) {
      return {
        success: false,
        error,
        data: {}
      };
    }
  }
};

export default productApi;
