import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  PageHeader,
  Card,
  Form,
  Input,
  Button,
  Upload,
  TreeSelect,
  Popconfirm,
  message,
  Checkbox,
} from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import transformPathToRoutes from '../../utils/transformPathToRoutes';
import categoriesActions from '../../redux/Categories/actions';
import { toast } from 'react-toastify';
import { storage } from '../../utils/firebase';
import FirebaseImage from '../../components/FirebaseImage';
import generateTreeNode from '../../utils/generateTreeNode';

const { Item } = Form;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const getCategoryData = (key, data) => {
  for (let entry of data) {
    if (entry.id === key) return entry;
  }
  for (let entry of data) {
    if (entry.children.length > 0) {
      const subresult = getCategoryData(key, entry.children);
      if (subresult) return subresult;
      continue;
    }
  }
  return null;
};

const Categories = (props) => {

  // props
  const {
    categories,
    isFetchingCategories,
    getCategories,
    editCategory,
    deleteCategory,
    isDeletingCategory,
    isEditingCategory,
  } = props;

  // state
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [editFormNameValidateStatus, setEditFormNameValidateStatus] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  // const [imageUrl, setImageUrl] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  //const
  const uploadButton = (
    <Button disabled={selectedCategory === undefined}>
      <UploadOutlined /> Click to Upload
    </Button>
  );
  // useEffect
  useEffect(() => {
    getCategories();
    //eslint-disbale-next-line
  }, [getCategories]);
  useEffect(() => {
    if (selectedCategory !== undefined) {
      setImageRef(selectedCategory.image);
    }
  }, [selectedCategory]);

  // useRef
  const editFormRef = useRef();

  // custom fn
  let match = useRouteMatch();
  const routes = transformPathToRoutes(match.path);
  const validateName = (e) => {
    const value = e.target.value;
    if (value.trim() === '') return 'error';
    if (!value) return 'error';
    // Have to use '\\' for '\' and '\\\\' for '\\' (escaped \) in regex, 
    // otherwise it won't escape correctly
    if (!value.match('[^a-zA-Z /\\-()%,.&\\\\]')) return 'success';
    return 'error';
  };
  const editKeyOnChange = (value) => {
    const category = getCategoryData(value, categories);
    setSelectedCategory(category);
    editFormRef.current.setFieldsValue({ ...category });
  };
  const submitCategoryEditHandler = (values) => {
    if (editFormNameValidateStatus !== 'error') console.log(values);
    editCategory({
      id: values.currentId,
      category: {
        image: imageRef,
        name: values.name,
        visible: values.visible,
      },
    });
  };
  const deleteCategoryHandler = () => {
    deleteCategory({ id: selectedCategory.id });
    setSelectedCategory(undefined);
    editFormRef.current.setFieldsValue({
      currentId: undefined,
      parent: undefined,
      id: undefined,
      name: undefined,
      image: undefined,
    });
    setImageRef(null);
    // setImageUrl(null);
  };
  const handleFileChange = (info) => {
    if (info.file.status === 'uploading') {
      setFileUploading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, () => {
        // setImageUrl(imageUrl);
        setFileUploading(false);
      });
    }

    if (info.file.status === 'removed') {
      // setImageUrl(null);
      setFileUploading(false);
    }

    if (info.file.status === 'error') {
      // setImageUrl(null);
      setFileUploading(false);
      toast.error('Upload Failed With Error:', {
        position: 'top-center',
      });
    }
  };
  const customRequest = async (params) => {
    const { file, onSuccess } = params;
    // Upload banner
    const imageRef = storage.ref().child(`categories/${file.name}`);
    return imageRef
      .put(file)
      .then((snapshot) => {
        // Update local imageRef which is used for firestore category image data field
        setSelectedCategory({
          ...selectedCategory,
          image: `categories/${file.name}`,
        });
        return snapshot.ref.getDownloadURL();
      })
      .then(() => {
        // setImageUrl(url);
        // onSuccess() is a function, which make the status change of uploader
        // It will invoke the onChange(), which is handleFileChange in this file
        onSuccess();
      })
      .catch((err) => console.log(err));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const removeImage = () => {
    // only remove the image from local state, but image still in storage
    setSelectedCategory({
      ...selectedCategory,
      image: '',
    });
  };

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Edit Category"
        breadcrumb={{ routes }}
      ></PageHeader>
      <Spin
        spinning={isFetchingCategories || isDeletingCategory || isEditingCategory || fileUploading}
      >
        <Card title="Edit/Delete Category" style={{ width: '90%' }}>
          <Form
            ref={editFormRef}
            {...formItemLayout}
            onFinish={submitCategoryEditHandler}
            initialValues={selectedCategory}
          >
            <Item label="Category" name="currentId">
              <TreeSelect
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select category"
                treeDefaultExpandAll
                onChange={editKeyOnChange}
              >
                {categories ? generateTreeNode(categories) : <></>}
              </TreeSelect>
            </Item>
            <Item label="Parent Category" name="parent">
              <Input disabled placeholder="Please select Category" />
            </Item>
            <Item
              name="id"
              label="ID"
              rules={[{ required: true, message: 'Category ID cannot be empty.' }]}
            >
              <Input disabled={true} placeholder="Please enter category ID" />
            </Item>
            <Item
              name="name"
              label="Name"
              validateStatus={editFormNameValidateStatus}
              help={
                editFormNameValidateStatus === 'error' &&
                "Category name must not be empty and can only contain letter, white space and following characters: .,/\\-()%&"
              }
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Please enter category name"
                onChange={(e) => {
                  setEditFormNameValidateStatus(validateName(e));
                }}
                disabled={selectedCategory === undefined}
              />
            </Item>
            <Item name="visible" label="Visible" valuePropName="checked">
              <Checkbox />
            </Item>
            <Item label="Image" valuePropName="fileList">
              <Upload
                disabled={selectedCategory === undefined}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleFileChange}
                customRequest={(params) =>
                  customRequest({
                    ...params,
                  })
                }
              >
                {uploadButton}
              </Upload>
              <Button
                disabled={imageRef === null || imageRef === ''}
                danger
                onClick={removeImage}
                style={{ marginLeft: '15px' }}
              >
                Remove Image
              </Button>
            </Item>
            <Item
              wrapperCol={
                imageRef && {
                  span: 12,
                  offset: 6,
                }
              }
            >
              <FirebaseImage imageRef={imageRef} />
            </Item>
            <Item
              wrapperCol={{
                span: 12,
                offset: 6,
              }}
            >
              <Button
                disabled={selectedCategory === undefined || editFormNameValidateStatus === 'error'}
                type="primary"
                htmlType="submit"
                style={{ marginRight: '20px' }}
              >
                Submit
              </Button>
              <Popconfirm
                title={'Delete category will delete all of its sub-categories. Are you sure?'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={deleteCategoryHandler}
                disabled={selectedCategory === undefined}
              >
                <Button danger disabled={selectedCategory === undefined}>
                  Delete Category
                </Button>
              </Popconfirm>
            </Item>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

Categories.propTypes = {
  getCategories: PropTypes.func,
  categories: PropTypes.any,
  isFetchingCategories: PropTypes.bool,
  createCategory: PropTypes.func,
  editCategory: PropTypes.func,
  deleteCategory: PropTypes.func,
  isDeletingCategory: PropTypes.bool,
  isEditingCategory: PropTypes.bool,
  getImageByCategory: PropTypes.func,
};

const { getCategories, editCategory, deleteCategory } = categoriesActions;
export default connect(
  (state) => {
    const { Categories } = state;
    const { categories, isFetchingCategories, isDeletingCategory, isEditingCategory } = Categories;
    return {
      categories,
      isFetchingCategories,
      isDeletingCategory,
      isEditingCategory,
    };
  },
  { getCategories, editCategory, deleteCategory }
)(Categories);
