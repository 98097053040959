/* eslint-disable react/display-name */
import React, { useState, useRef } from 'react';
import { Table, Button, TimePicker, InputNumber, Input, Tag, Empty, Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

const { Item } = Form;
const { RangePicker } = TimePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditDeliveryTimeTable = (props) => {
  const { slots, postcode, date, addSlot, updateCurrentSlots } = props;

  const [newSlotModalVisibility, setNewSlotModalVisibility] = useState(false);
  const newSlotFormRef = useRef();

  const addNewSlot = (values) => {
    if (values) {
      const slot = {
        last: values.lastOrderTime.format('HH:mm'),
        start: values.deliveryTimeRange[0].format('HH:mm'),
        end: values.deliveryTimeRange[1].format('HH:mm'),
        maxOrderNumber: values.maxOrderNumber,
        ordersLeft: values.maxOrderNumber,
        available: true,
      };
      // check if same time exist
      if (slots) {
        for (const existedSlot of slots) {
          if (existedSlot.start === slot.start && existedSlot.end === slot.end) {
            toast.error(`Slot with same time existed.`, {
              autoClose: 5000,
            });
            return;
          }
        }
      }
      newSlotFormRef.current.resetFields();
      setNewSlotModalVisibility(false);
      addSlot({ postcode: values.postcode, slot });
    }
  };

  const lastOrderTimeOnchange = (value, index) => {
    const newSlotsValue = [...slots];
    newSlotsValue[index].last = value.format('HH:mm');
    updateCurrentSlots({ postcode, slots: newSlotsValue });
  };

  const deliveryTimeOnChange = (value, index) => {
    const newSlotsValue = [...slots];
    newSlotsValue[index].start = value[0].format('HH:mm');
    newSlotsValue[index].end = value[1].format('HH:mm');
    updateCurrentSlots({ postcode, slots: newSlotsValue });
  };

  const maxOrderNumberChange = (value, index) => {
    const newSlotsValue = [...slots];
    const previousMaxOrderNumber = slots[index].maxOrderNumber;
    const numberChanged = value - previousMaxOrderNumber;

    newSlotsValue[index].maxOrderNumber = value;
    newSlotsValue[index].ordersLeft += numberChanged;
    updateCurrentSlots({ postcode, slots: newSlotsValue });
  };

  const switchSlotStatus = (index) => {
    const newSlotsValue = [...slots];
    newSlotsValue[index].available = !slots[index].available;
    updateCurrentSlots({ postcode, slots: newSlotsValue });
  };

  const deleteTimeSlot = (index) => {
    const newSlotsValue = [...slots];
    newSlotsValue.splice(index, 1);
    updateCurrentSlots({ postcode, slots: newSlotsValue });
  };

  const columns = [
    {
      title: 'Last Order Time',
      dataIndex: 'last',
      render: (data, _, index) => (
        <TimePicker
          format="HH:mm"
          allowClear={false}
          minuteStep={15}
          value={moment(data, 'HH:mm')}
          onChange={(value) => {
            lastOrderTimeOnchange(value, index);
          }}
        />
      ),
    },
    {
      title: 'Time Period',
      render: (_, record, index) => (
        <RangePicker
          value={[moment(record.start, 'HH:mm'), moment(record.end, 'HH:mm')]}
          format="HH:mm"
          allowClear={false}
          minuteStep={15}
          onChange={(value) => {
            deliveryTimeOnChange(value, index);
          }}
        />
      ),
    },
    {
      title: 'Max Order Number',
      dataIndex: 'maxOrderNumber',
      render: (data, _, index) => (
        <InputNumber
          value={data}
          min={0}
          precision={0}
          onChange={(value) => {
            maxOrderNumberChange(value, index);
          }}
        />
      ),
    },
    {
      title: 'Available',
      dataIndex: 'ordersLeft',
    },
    {
      title: 'Status',
      dataIndex: 'available',
      render: (data) =>
        data ? <Tag color="#87d068">AVAILABLE</Tag> : <Tag color="#f50">CLOSED</Tag>,
    },
    {
      title: 'Operation',
      render: (_, record, index) => (
        <>
          <Button
            type="link"
            onClick={() => {
              switchSlotStatus(index);
            }}
          >
            {record.available ? 'Close' : 'Open'}
          </Button>
          <Button
            type="link"
            danger
            onClick={() => {
              deleteTimeSlot(index);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {slots ? (
        <>
          <Table
            title={() => (
              <div style={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'center' }}>
                Delivery slots to {postcode} on {date}
              </div>
            )}
            size="small"
            rowKey={(record) => record.start + record.end + record.last}
            bordered
            dataSource={slots}
            columns={columns}
            pagination={false}
          />
          <div style={{ textAlign: 'end', marginTop: '15px' }}>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() => {
                setNewSlotModalVisibility(true);
              }}
            >
              Add more slots
            </Button>
          </div>
        </>
      ) : (
        <Empty description={<span>Click a date to view and edit details.</span>}>
          <Button
            onClick={() => {
              setNewSlotModalVisibility(true);
            }}
          >
            Add a new slot
          </Button>
        </Empty>
      )}
      <Modal
        title="New Delivery Time Period"
        visible={newSlotModalVisibility}
        onOk={() => {}}
        onCancel={() => {
          setNewSlotModalVisibility(false);
          newSlotFormRef.current.resetFields();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setNewSlotModalVisibility(false);
              newSlotFormRef.current.resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button key="submit" type="primary" form="newSlotForm" htmlType="submit">
            Submit
          </Button>,
        ]}
      >
        <Form {...formItemLayout} id="newSlotForm" ref={newSlotFormRef} onFinish={addNewSlot}>
          <Item label="Date">
            <Input value={date} disabled />
          </Item>
          <Item
            name="postcode"
            label="Postcode"
            rules={[
              { required: true },
              { pattern: '^[0-9]{4}$', message: 'Postcode should be 4 digits.' },
            ]}
          >
            <Input placeholder={'Enter Postcode'} />
          </Item>

          <Item name="lastOrderTime" label="Last Order Time" rules={[{ required: true }]}>
            <TimePicker format="HH:mm" minuteStep={15} allowClear={false} />
          </Item>
          <Item name="deliveryTimeRange" label="Delivery Time Range" rules={[{ required: true }]}>
            <RangePicker format="HH:mm" minuteStep={15} allowClear={false} />
          </Item>
          <Item name="maxOrderNumber" label="Max Number of Orders" rules={[{ required: true }]}>
            <InputNumber min={0} precision={0} />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

EditDeliveryTimeTable.propTypes = {
  slots: PropTypes.array,
  postcode: PropTypes.string,
  date: PropTypes.string,
  updateSlots: PropTypes.func,
  setPostcode: PropTypes.func,
  addSlot: PropTypes.func,
  saveEdit: PropTypes.func,
  updateCurrentSlots: PropTypes.func,
};

export default EditDeliveryTimeTable;
