const actions = {
  GET_MESSAGE_LIST: 'GET_MESSAGE_LIST',
  GET_MESSAGE_LIST_SUCCESS: 'GET_MESSAGE_LIST_SUCCESS',
  GET_MESSAGE_LIST_FAIL: 'GET_MESSAGE_LIST_FAIL',

  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAIL: 'SEND_MESSAGE_FAIL',

  getMessages: () => ({
    type: actions.GET_MESSAGE_LIST
  }),

  sendMessage: ({ message, title }) => ({
    type: actions.SEND_MESSAGE,
    message,
    title
  })
};

export default actions;