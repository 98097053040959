import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import couponsApi from '../../apis/Coupons';
import newCouponSaga from './NewCoupon/saga';

function* fetchCouponList() {
  const { success, data, error } = yield couponsApi.getCoupons();
  if (success) {
    yield put({ type: actions.FETCH_COUPON_LIST_SUCCESS, couponsData: data.coupons });
  } else {
    yield put({ type: actions.FETCH_COUPON_LIST_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

function* deleteCoupon({ id }) {
  const { success, error } = yield couponsApi.deleteCoupon(id);
  if (success) {
    yield put({ type: actions.DELETE_COUPON_SUCCESS });
    yield put({ type: actions.FETCH_COUPON_LIST });
  } else {
    yield put({ type: actions.DELETE_COUPON_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

function* getInvitationCouponConfig() {
  const { success, data, error } = yield couponsApi.getInvitationCouponConfig();
  if (success) {
    yield put({ type: actions.GET_INVITATION_COUPON_CONFIG_SUCCESS, config: data });
  } else {
    yield put({ type: actions.GET_INVITATION_COUPON_CONFIG_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}

function* updateInvitationCouponConfig ({ code, toInviter, toInvitee }) {
  const { success, data, error } = yield couponsApi.updateInvitationCouponConfig({ code, toInviter, toInvitee });
  if (success) {
    yield put({ type: actions.UPDATE_INVITATION_COUPON_CONFIG_SUCCESS, config: data });
  } else {
    yield put({ type: actions.UPDATE_INVITATION_COUPON_CONFIG_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}

export default function* couponsSaga() {
  yield all([
    newCouponSaga(),
    takeLatest(actions.FETCH_COUPON_LIST, fetchCouponList),
    takeLatest(actions.DELETE_COUPON, deleteCoupon),
    takeLatest(actions.GET_INVITATION_COUPON_CONFIG, getInvitationCouponConfig),
    takeLatest(actions.UPDATE_INVITATION_COUPON_CONFIG, updateInvitationCouponConfig)
  ]);
}
