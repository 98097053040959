import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import flashSaleApi from '../../../apis/FlashSale';

function* getFlashSaleProducts() {
  const { success, data, error } = yield flashSaleApi.getFlashSaleProducts();
  if (success) {
    yield put({
      type: actions.GET_FLASH_SALE_PRODUCTS_SUCCESS,
      products: data
    });
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.GET_FLASH_SALE_PRODUCTS_FAIL });
  }
}

function* getPreFlashSaleProducts() {
  const { success, data, error } = yield flashSaleApi.getPreFlashSale();
  if (success) {
    yield put({
      type: actions.GET_PRE_FLASH_SALE_PRODUCTS_SUCCESS,
      products: data
    });
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.GET_PRE_FLASH_SALE_PRODUCTS_FAIL });
  }
}

function* getFlashSaleTimeConfig() {
  const { success, data, error } = yield flashSaleApi.getFlashSaleTimeConfig();
  if (success) {
    yield put({
      type: actions.GET_FLASH_SALE_TIME_CONFIG_SUCCESS,
      timeConfig: data
    });
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.GET_FLASH_SALE_TIME_CONFIG_FAIL });
  }
}

function* updateFlashSaleTimeConfig({ start, end }) {
  const { success, data, error } = yield flashSaleApi.setFlashSaleTime({
    start,
    end
  });
  if (success) {
    yield put({
      type: actions.UPDATE_FLASH_SALE_TIME_CONFIG_SUCCESS,
      timeConfig: data
    });
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.UPDATE_FLASH_SALE_TIME_CONFIG_FAIL });
  }
}

function* udpatePreFlashSaleProducts({ sku, actionType }) {
  const { success, error } = yield flashSaleApi.setPreFlashSale({
    sku,
    actionType
  });
  if (success) {
    yield put({ type: actions.UPDATE_PRE_FLASH_SALE_PRODUCTS_SUCCESS });
    yield put({ type: actions.GET_PRE_FLASH_SALE_PRODUCTS });
    toast.success('Operation Success!', {
      autoClose: 5000
    })
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.UPDATE_PRE_FLASH_SALE_PRODUCTS_FAIL });
  }
}

function* startFlashSale() {
  const { success, error } = yield flashSaleApi.startFlashSale();
  if (success) {
    yield put({ type: actions.START_FLASH_SALE_SUCCESS });
    toast.success('Flash Sale Started!', { autoClose: 5000 });
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.START_FLASH_SALE_FAIL });
  }
}

export default function* flashSaleSaga() {
  yield all([
    takeLatest(actions.GET_FLASH_SALE_PRODUCTS, getFlashSaleProducts),
    takeLatest(actions.GET_PRE_FLASH_SALE_PRODUCTS, getPreFlashSaleProducts),
    takeLatest(actions.GET_FLASH_SALE_TIME_CONFIG, getFlashSaleTimeConfig),
    takeLatest(
      actions.UPDATE_FLASH_SALE_TIME_CONFIG,
      updateFlashSaleTimeConfig
    ),
    takeLatest(
      actions.UPDATE_PRE_FLASH_SALE_PRODUCTS,
      udpatePreFlashSaleProducts
    ),
    takeLatest(actions.START_FLASH_SALE, startFlashSale)
  ]);
}
