import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import customersApi from '../../apis/Customers';
import customerAddressSaga from './CustomerAddresses/saga';
import customerCouponsSaga from './CustomerCoupons/saga';

function* getCustomers(params) {
  const { searchPattern, pageNumber, archived, batchSize } = params;
  const { success, data, error } = yield customersApi.getCustomers({ searchPattern, pageNumber, archived, batchSize });
  if (success) {
    yield put({ type: actions.GET_CUSTOMERS_SUCCESS, customersData: data });
  } else {
    yield put({ type: actions.GET_CUSTOMERS_FAIL });
    toast.error(error, {
      autoClose: 3000,
    });
  }
}

function* getSingleCustomer(params) {
  const { uid } = params;
  const { success, customer, error } = yield customersApi.getSingleCustomer({ uid });
  if (success) {
    yield put({ type: actions.GET_SINGLE_CUSTOMER_SUCCESS, customerData: customer });
  } else {
    yield put({ type: actions.GET_SINGLE_CUSTOMER_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* editCustomer(params) {
  const { uid, data } = params;
  const { success, customer, error } = yield customersApi.updateSingleCustomer({ uid, customer: data });
  if (success) {
    yield put({ type: actions.EDIT_CUSTOMER_SUCCESS, customerData: customer });
    yield put({ type: actions.GET_CUSTOMERS });
    toast.success('Customer details updated!', {
      autoClose: 3000,
    });
  } else {
    yield put({ type: actions.EDIT_CUSTOMER_FAIL })
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

export default function* customersSaga() {
  yield all([
    customerAddressSaga(),
    customerCouponsSaga(),
    takeLatest(actions.GET_CUSTOMERS, getCustomers),
    takeLatest(actions.GET_SINGLE_CUSTOMER, getSingleCustomer),
    takeLatest(actions.EDIT_CUSTOMER, editCustomer)
  ]);
}
