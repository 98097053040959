import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  PageHeader,
  Row,
  Col,
  Button,
  Select,
  Spin,
  InputNumber,
  Input,
  message,
  Popover,
  Card
} from 'antd';
import OrdersTable from '../../components/OrdersTable/index';
import SaveCSVButton from '../../components/SaveCSVButton';
// import OrderEditModals from '../../components/OrderEditModals/index';
import OrderEditViewDrawer from '../../components/OrderEditViewDrawer/index';
import ordersActions from '../../redux/Orders/actions';
import PropTypes from 'prop-types';
const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard'
  },
  {
    path: 'orders',
    breadcrumbName: 'Orders'
  }
];

const OrdersContainer = (props) => {
  // const, var, let
  const batchSize = 20;

  // props
  const {
    ordersData,
    isFetchingOrdersList,
    singleOrder,
    isDeletingOrder
  } = props.Orders;
  const {
    isFetchingSingleOrder,
    isUpdatingSingleOrder,
    orderData
  } = singleOrder;
  const {
    fetchOrderList,
    fetchSingleOrder,
    updateOrderStatus,
    deleteOrder
  } = props;

  // state
  const [statusPattern, setStatusPattern] = useState('all');
  const [editDrawerVisibility, setEditDrawerVisibility] = useState(false);
  // const [memoModalVisibility, setMemoModalVisibility] = useState(false);
  const [maxPrice, setMaxPrice] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  // useEffect
  useEffect(() => {
    fetchOrderList({
      status: 'all',
      batchSize,
      pageNumber: 1,
      searchFieldsValues: {}
    });
  }, [fetchOrderList]);

  // custom fn
  const onPrev = () => {
    const searchFieldsValues = {};
    if (phoneNumber) searchFieldsValues.phoneNumber = phoneNumber;
    if (maxPrice !== null) searchFieldsValues.maxPrice = maxPrice;
    if (minPrice !== null) searchFieldsValues.minPrice = minPrice;
    if (maxPrice !== null && minPrice !== null && maxPrice < minPrice) {
      message.error('Invalid search!');
      return;
    }
    fetchOrderList({
      status: statusPattern,
      batchSize,
      pageNumber: ordersData.pageNumber - 1,
      searchFieldsValues
    });
  };
  const onNext = () => {
    const searchFieldsValues = {};
    if (phoneNumber) searchFieldsValues.phoneNumber = phoneNumber;
    if (maxPrice !== null) searchFieldsValues.maxPrice = maxPrice;
    if (minPrice !== null) searchFieldsValues.minPrice = minPrice;
    if (maxPrice !== null && minPrice !== null && maxPrice < minPrice) {
      message.error('Invalid search!');
      return;
    }
    fetchOrderList({
      status: statusPattern,
      batchSize,
      pageNumber: ordersData.pageNumber + 1,
      searchFieldsValues
    });
  };
  const statusPatternChangeHandler = (value) => {
    setStatusPattern(value);
  };
  const resetSearchHandler = () => {
    setStatusPattern('all');
    setMaxPrice(null);
    setMinPrice(null);
    setPhoneNumber(null);
    fetchOrderList({
      status: 'all',
      batchSize,
      pageNumber: 1,
      searchFieldsValues: {}
    });
  };

  const searchOrders = () => {
    const searchFieldsValues = {};
    if (phoneNumber) searchFieldsValues.phoneNumber = phoneNumber;
    if (maxPrice !== null) searchFieldsValues.maxPrice = maxPrice;
    if (minPrice !== null) searchFieldsValues.minPrice = minPrice;
    if (maxPrice !== null && minPrice !== null && maxPrice < minPrice) {
      message.error('Invalid search!');
      return;
    }
    fetchOrderList({
      status: statusPattern,
      batchSize,
      pageNumber: 1,
      searchFieldsValues
    });
  };

  // render
  return (
    <div>
      <PageHeader
        ghost={false}
        breadcrumb={{ routes }}
        onBack={() => window.history.back()}
        title="Orders"
        extra={[<SaveCSVButton key={1} />]}
      />
      <Card
        title="Search Orders"
        style={{ marginBottom: '20px', marginTop: '10px' }}
        extra={[
          <Button type="primary" onClick={searchOrders} key="search">
            Search / Refresh
          </Button>,
          <Button style={{marginLeft: 15 }} onClick={resetSearchHandler} key="reset">
            Reset
          </Button>
        ]}
      >
        <Row gutter={16}>
          <Col span={8}>
            <span>Status:</span>
            <Select
              style={{ marginLeft: '15px', width: '70%' }}
              placeholder="Order Status"
              value={statusPattern}
              onChange={statusPatternChangeHandler}
            >
              <Select.Option key="all" value="all">
                Show All
              </Select.Option>
              <Select.Option key="paid" value="paid">
                Paid
              </Select.Option>
              <Select.Option key="unpaid" value="unpaid">
                Unpaid
              </Select.Option>
              <Select.Option key="processing" value="processing">
                Processing
              </Select.Option>
              <Select.Option key="pending" value="pending">
                Pending
              </Select.Option>
              <Select.Option key="delivering" value="delivering">
                Delivering
              </Select.Option>
              <Select.Option key="delivered" value="delivered">
                Delivered
              </Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <span>Price Range: </span>
            <InputNumber
              placeholder="Min Price"
              style={{ marginLeft: '15px', width: '30%' }}
              value={minPrice}
              onChange={(value) => setMinPrice(value)}
              precision={2}
              min={0}
              maxPrice={9999}
            ></InputNumber>{' '}
            <span>{' - '}</span>
            <InputNumber
              placeholder="Max Price"
              style={{ width: '30%' }}
              value={maxPrice}
              onChange={(value) => setMaxPrice(value)}
              precision={2}
              min={0}
              max={9999}
            ></InputNumber>
          </Col>
          <Col span={8}>
            <span>Phone Number: </span>
            <Popover
              content={
                <>
                  <div>This is the phone number in delivery details.</div>
                  <div>Enter 9 digits number: 4XXXXXXXX</div>
                </>
              }
              title="Attention"
              trigger="focus"
            >
              <Input
                style={{ marginLeft: '15px', width: '60%' }}
                placeholder="Enter phone number..."
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Popover>
          </Col>
        </Row>
      </Card>
      <Spin spinning={isFetchingOrdersList} style={{ marginTop: '20px' }}>
        <OrdersTable
          orders={ordersData}
          onPrev={onPrev}
          onNext={onNext}
          fetchSingleOrder={fetchSingleOrder}
          openEditDrawer={() => setEditDrawerVisibility(true)}
        />
        <OrderEditViewDrawer
          drawerVisibility={
            editDrawerVisibility || isUpdatingSingleOrder || isDeletingOrder
          }
          closeDrawer={() => setEditDrawerVisibility(false)}
          isFetchingSingleOrder={isFetchingSingleOrder}
          isUpdatingSingleOrder={isUpdatingSingleOrder}
          isDeletingOrder={isDeletingOrder}
          orderData={orderData}
          updateOrderStatus={(params) => {
            updateOrderStatus(params);
            setStatusPattern('all');
          }}
          deleteOrder={(record) => {
            deleteOrder(record);
            setStatusPattern('all');
            setEditDrawerVisibility(false);
          }}
        />
      </Spin>
    </div>
  );
};

const {
  fetchOrderList,
  fetchSingleOrder,
  updateOrderStatus,
  deleteOrder
} = ordersActions;

OrdersContainer.propTypes = {
  Orders: PropTypes.object,
  fetchOrderList: PropTypes.func,
  isFetchingOrdersList: PropTypes.bool,
  fetchSingleOrder: PropTypes.func,
  updateOrderStatus: PropTypes.func,
  deleteOrder: PropTypes.func
};

export default connect(
  (state) => {
    const { Orders } = state;
    return {
      Orders
    };
  },
  { fetchOrderList, fetchSingleOrder, updateOrderStatus, deleteOrder }
)(OrdersContainer);
