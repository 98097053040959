import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import categoriesApi from '../../apis/Categories';
import newCategorySaga from './NewCategory/saga';

function* getCategories() {
  const { success, data, error } = yield categoriesApi.getCategories();
  if (success) {
    yield put({ type: actions.GET_CATEGORIES_SUCCESS, categories: data });
  } else {
    yield put({ type: actions.GET_CATEGORIES_FAIL });
    console.log(error);
    toast.error(`Categories fetching error: ${error.toString()}`, {
      autoClose: 3000,
    });
  }
}

function* editCategory({ id, category }) {
  const { success, data, error } = yield categoriesApi.updateCategory({ id, category });
  if (success) {
    yield put({ type: actions.EDIT_CATEGORY_SUCCESS, category: data });
    yield put({ type: actions.GET_CATEGORIES });
    toast.success('Category Edit Success!', {
      autoClose: 2000,
    });
  } else {
    yield put({ type: actions.EDIT_CATEGORY_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000
    })
  }
}

function* deleteCategory({ id }) {
  const { success, error } = yield categoriesApi.deleteCategory({ id });
  if (success) {
    yield put({ type: actions.DELETE_CATEGORY_SUCCESS });
    yield put({ type: actions.GET_CATEGORIES });
  } else {
    yield put({ type: actions.DELETE_CATEGORY_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

export default function* categoriesSaga() {
  yield all([
    takeLatest(actions.GET_CATEGORIES, getCategories),
    newCategorySaga(),
    takeLatest(actions.EDIT_CATEGORY, editCategory),
    takeLatest(actions.DELETE_CATEGORY, deleteCategory),
  ]);
}
