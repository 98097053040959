import actions from './actions';

const initState = {
  userInfo: {},
  authority: null,
  loginAuthenticating: false,
  smsSending: false,
  smsSent: false,
  smsConfirmation: null,
  checkingVerficationCode: false,
  retrivedCurrentUserData: false,
  isChangingPassword: false,
};

export default function userReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SIGN_IN:
      return {
        ...state,
        userInfo: {
          email: action.userInfo.email,
          name: action.userInfo.displayName,
          photoUrl: action.userInfo.photoURL,
          emailVerified: action.userInfo.emailVerified,
          uid: action.userInfo.uid,
          phoneNumber: action.userInfo.phoneNumber,
        },
      };
    case actions.FETCH_CURRENT_USER_DATA_SUCCESS:
      return {
        ...state,
        authority: action.currentUserData.authority,
        retrivedCurrentUserData: true,
      };
    case actions.FETCH_CURRENT_USER_DATA_FAIL:
      return {
        ...state,
        retrivedCurrentUserData: false,
      };
    case actions.SIGN_OUT_SUCCESS:
      return initState;
    case actions.USER_PASSWORD_LOGIN:
      return {
        ...state,
        loginAuthenticating: true,
      };
    case actions.USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        todo: action.todo,
        loginAuthenticating: false,
      };
    case actions.USER_LOGIN_FAIL:
      return {
        ...state,
        loginAuthenticating: false,
      };
    case actions.SEND_SMS:
      return {
        ...state,
        smsSending: true,
      };
    case actions.SEND_SMS_SUCCESS:
      return {
        ...state,
        smsSending: false,
        smsSent: true,
        smsConfirmation: action.smsConfirmation,
      };
    case actions.SEND_SMS_FAIL:
      return {
        ...state,
        smsSending: false,
      };
    case actions.CHECK_VERIFICATION_CODE:
      return {
        ...state,
        checkingVerficationCode: true,
      };
    case actions.CHECK_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        checkingVerficationCode: false,
      };
    case actions.CHECK_VERIFICATION_CODE_FAIL:
      return {
        ...state,
        checkingVerficationCode: false,
      };
    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        isChangingPassword: true,
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
    case actions.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isChangingPassword: false,
      }
    default:
      return state;
  }
}
