import actions from './actions';
import newProductReducer, { newProductInitState } from './NewProduct/reducer';
import flashSaleReducer, { flashSaleInitState } from './FlashSaleProducts/reducer';

const initState = {
  productsData: {},
  isFetchingProductList: false,
  productDetail: {
    isFetchingProductDetail: false,
    isEditingProductDetail: false,
    product: {}
  },
  isValidatingProducts: false,
  isUploadingProducts: false,
  productsUploaded: 0,
  isDeletingBatchProducts: false,
  productsDeleted: [],
  newProduct: newProductInitState,
  flashSaleProducts: flashSaleInitState
};

const updateProductList = (productList, newProduct) => {
  const newProductList = productList.map((product) => {
    if (product.sku === newProduct.sku) {
      return newProduct;
    }
    return product;
  });
  return newProductList;
};

const productReducer = (state = initState, { type, ...action }) => {
  switch (type) {
    // Product list
    case actions.FETCH_PRODUCT_LIST:
      return {
        ...state,
        isFetchingProductList: true,
      };
    case actions.FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productsData: action.productList,
        isFetchingProductList: false,
      };
    case actions.FETCH_PRODUCT_LIST_FAIL:
      return {
        ...state,
        isFetchingProductList: false,
      };
    // Product detail, get by SKU
    case actions.FETCH_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          product: {},
          isFetchingProductDetail: true,
        },
      };
    case actions.FETCH_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          isFetchingProductDetail: false,
          product: action.product,
        },
      };
    case actions.FETCH_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          isFetchingProductDetail: false,
        },
      };

    case actions.EDIT_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          isEditingProductDetail: true,
        },
      };

    case actions.EDIT_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productsData: {
          ...state.productsData,
          products: updateProductList(state.productsData.products, action.product),
        },
        productDetail: {
          ...state.productDetail,
          isEditingProductDetail: false,
          product: action.product,
        },
      };
    case actions.EDIT_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          isEditingProductDetail: false,
        },
      };
    case actions.VALIDATE_PRODUCTS:
      return {
        ...state,
        isValidatingProducts: true
      };
    case actions.VALIDATE_PRODUCTS_SUCCESS:
    case actions.VALIDATE_PRODUCTS_FAIL:
      return {
        ...state,
        isValidatingProducts: false,
      };
    case actions.UPLOAD_PRODUCTS:
      return {
        ...state,
        isUploadingProducts: true,
        productsUploaded: 0,
      };
    case actions.UPLOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        isUploadingProducts: false,
        productsUploaded: action.numberOfProductUploaded,
      };
    case actions.UPLOAD_PRODUCTS_FAIL:
      return {
        ...state,
        isUploadingProducts: false,
      };
    case actions.BATCH_DELETE_PRODUCTS:
      return {
        ...state,
        isDeletingBatchProducts: true,
        productsDeleted: [],
      };
    case actions.BATCH_DELETE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isDeletingBatchProducts: false,
        productsDeleted: action.productsDeleted,
      };
    case actions.BATCH_DELETE_PRODUCTS_FAIL:
      return {
        ...state,
        isDeletingBatchProducts: false,
      };
    case actions.RESET_BATCH_OPERATION_RESULTS:
      return {
        ...state,
        productsUploaded: 0,
        productsDeleted: [],
      }
    default:
      return state;
  }
}

export default (state = initState, { type, ...action }) => {
  return {
    ...productReducer(state, { type, ...action }),
    flashSaleProducts: flashSaleReducer(state.flashSaleProducts, { type, ...action}),
    newProduct: newProductReducer(state.newProduct, {type, ...action})
  }
}
