const actions = {

  FETCH_UNPROCESSED_ORDERS: 'FETCH_UNPROCESSED_ORDERS',
  FETCH_UNPROCESSED_ORDERS_SUCCESS: 'FETCH_UNPROCESSED_ORDERS_SUCCESS',
  FETCH_UNPROCESSED_ORDERS_FAIL: 'FETCH_UNPROCESSED_ORDERS_FAIL',

  BATCH_PROCESS_ORDERS: 'BATCH_PROCESS_ORDERS',
  BATCH_PROCESS_ORDERS_SUCCESS: 'BATCH_PROCESS_ORDERS_SUCCESS',
  BATCH_PROCESS_ORDERS_FAIL: 'BATCH_PROCESS_ORDERS_FAIL',

  getUnprocessedOrders: ({ days }) => ({
    type: actions.FETCH_UNPROCESSED_ORDERS,
    days
  }),

  batchProcessOrders: () => ({
    type: actions.BATCH_PROCESS_ORDERS
  })
};

export default actions;