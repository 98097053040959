import actions from './actions';
import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getMessageList, sendMessage } from '../../apis/Message';

function* getMessages() {
  const { success, data, error } = yield getMessageList();
  if (success) {
    yield put({ type: actions.GET_MESSAGE_LIST_SUCCESS, messageList: data });
  } else {
    yield put({ type: actions.GET_MESSAGE_LIST_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    })
  }
}

function* sendMessageToDevices({ message, title }) {
  const { success, data, error } = yield sendMessage({ message, title });
  if (success) {
    yield put({ type: actions.SEND_MESSAGE_SUCCESS, newMessage: data });
  } else {
    yield put({ type: actions.SEND_MESSAGE_FAIL });
    toast.error(error, {
      autoClose: 5000
    })
  }
}

export default function* ordersSaga() {
  yield all([
    takeLatest(actions.GET_MESSAGE_LIST, getMessages),
    takeLatest(actions.SEND_MESSAGE, sendMessageToDevices)
  ]);
}
