import React from "react";
import { Layout, Card } from "antd";
import PropTypes from 'prop-types';
import Logo from "../Logo";
import "./index.css";


const { Content, Footer } = Layout;

const LoadingScreen = props => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="loadingScreen" style={styles.content}>
        <Card>
          <Logo dark />
          <div className="text" style={styles.text}>
            {props.text} <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </Card>
      </Content>
      <Footer style={{ textAlign: "center" }}>AIBuild © 2020</Footer>
    </Layout>
  );
};

const styles = {
  content: {
    margin: "10px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    textAlign: "center"
  },
  text: {
    fontSize: 18,
    color: "#000"
  }
};

LoadingScreen.propTypes = {
  text: PropTypes.string
}

export default LoadingScreen;
