const actions = {
  FETCH_DELIVERY_TIMESLOTS: 'FETCH_DELIVERY_TIMESLOTS',
  FETCH_DELIVERY_TIMESLOTS_SUCCESS: 'FETCH_DELIVERY_TIMESLOTS_SUCCESS',
  FETCH_DELIVERY_TIMESLOTS_FAIL: 'FETCH_DELIVERY_TIMESLOTS_FAIL',

  UPDATE_DELIVERY_TIMESLOTS: 'UPDATE_DELIVERY_TIMESLOTS',
  UPDATE_DELIVERY_TIMESLOTS_SUCCESS: 'UPDATE_DELIVERY_TIMESLOTS_SUCCESS',
  UPDATE_DELIVERY_TIMESLOTS_FAIL: 'UPDATE_DELIVERY_TIMESLOTS_FAIL',

  getSlots: ({ date }) => ({
    type: actions.FETCH_DELIVERY_TIMESLOTS,
    date,
  }),

  updateSlots: ({ date, slots }) => ({
    type: actions.UPDATE_DELIVERY_TIMESLOTS,
    date,
    slots,
  })
};

export default actions;