import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  message,
  PageHeader,
  Select,
  Upload,
  Empty,
  Button,
  Spin,
  Card,
  TreeSelect,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import transformPathToRoutes from '../../utils/transformPathToRoutes';
import categoriesActions from '../../redux/Categories/actions';
import bannersActions from '../../redux/Banners/actions';
import { connect } from 'react-redux';
import generateTreeNode from '../../utils/generateTreeNode';
const { Option } = Select;

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const BannerUploader = (props) => {
  const {
    categories,
    isFetchingCategories,
    getCategories,
    isFetchingBanners,
    isModifyingBanner,
    isDeletingBanner,
    banners,
    getBanners,
    changeImage,
    changeCategory,
    deleteBanner,
  } = props;

  const match = useRouteMatch();
  const routes = transformPathToRoutes(match.path);

  const [picturePosition, setPicturePosition] = useState(0);
  const [bannerCategory, setBannerCategory] = useState(undefined);

  useEffect(() => {
    if (JSON.stringify(categories) === '{}') getCategories();
    if (JSON.stringify(banners) === '{}') getBanners();
  }, [categories, getCategories, banners, getBanners]);

  const handleSelectChange = (value) => {
    setPicturePosition(value);
    setBannerCategory(banners[value] ? banners[value].redirect : undefined);
  };

  const sumitCategoryChangeHandler = () => {
    changeCategory({ bannerCategory, picturePosition, banners });
  };

  const uploadButton = (
    <Button style={{ marginLeft: '15px' }}>
      <UploadOutlined /> {`Click to ${banners[picturePosition] ? 'Replace' : 'Upload'}`}
    </Button>
  );

  const customRequest = async (params) => {
    const { file, onSuccess, onError, index } = params;
    try {
      changeImage({ file, index, picturePosition, banners });
      setBannerCategory(undefined);
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={routes[routes.length - 1]['breadcrumbName']}
        breadcrumb={{ routes }}
      ></PageHeader>
      <Typography.Title level={3}>Home page banners</Typography.Title>
      <Spin
        spinning={
          isFetchingCategories || isFetchingBanners || isModifyingBanner || isDeletingBanner
        }
      >
        <Card
          title={
            <>
              <Select defaultValue={0} value={picturePosition} onSelect={handleSelectChange}>
                <Option value={0} key={0}>
                  1st Picture
                </Option>
                <Option value={1} key={1} disabled={!banners[0]}>
                  2nd Picture
                </Option>
                <Option value={2} key={2} disabled={!banners[1]}>
                  3rd Picture
                </Option>
                <Option value={3} key={3} disabled={!banners[2]}>
                  4th Picture
                </Option>
                <Option value={4} key={4} disabled={!banners[3]}>
                  5th Picture
                </Option>
                <Option value={5} key={5} disabled={!banners[4]}>
                  6th Picture
                </Option>
                <Option value={6} key={6} disabled={!banners[5]}>
                  7th Picture
                </Option>
                <Option value={7} key={7} disabled={!banners[6]}>
                  8th Picture
                </Option>
                <Option value={8} key={8} disabled={!banners[7]}>
                  9th Picture
                </Option>
                <Option value={9} key={9} disabled={!banners[8]}>
                  10th Picture
                </Option>
              </Select>
              <Upload
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={(params) => {
                  return customRequest({
                    ...params,
                    index: picturePosition,
                  });
                }}
              >
                {uploadButton}
              </Upload>

              <Button
                danger
                style={{ marginLeft: '15px' }}
                onClick={() => {
                  deleteBanner({ picturePosition });
                  setBannerCategory(undefined);
                }}
              >
                Delete
              </Button>
            </>
          }
        >
          {/* <Spin spinning={loading}> */}
          <div style={{ color: 'red' }}>
            Attention: Resolution of banner pictures should be 750x685.{' '}
          </div>
          {banners[picturePosition] ? (
            <>
              <img
                src={banners[picturePosition].url}
                alt="avatar"
                style={{ margin: '15px 0px', height: '400px' }}
              />
              <div>
                <Typography.Text>Please select correlated category: </Typography.Text>
                <TreeSelect
                  style={{ marginTop: '15px', width: '300px' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select category"
                  treeDefaultExpandAll
                  value={bannerCategory || banners[picturePosition].redirect}
                  onChange={(value) => setBannerCategory(value)}
                >
                  {categories ? generateTreeNode(categories) : <></>}
                </TreeSelect>
                <Button
                  style={{ marginLeft: '15px' }}
                  type="primary"
                  onClick={sumitCategoryChangeHandler}
                >
                  Confirm
                </Button>
                {bannerCategory && banners[picturePosition].redirect && (
                  <Typography.Text strong style={{ marginLeft: '15px' }}>
                    {`Redirect Category ID: ${bannerCategory || banners[picturePosition].redirect}`}
                  </Typography.Text>
                )}
              </div>
            </>
          ) : (
            <Empty description="No Image" />
          )}
          {/* </Spin> */}
        </Card>
      </Spin>
    </div>
  );
};

BannerUploader.propTypes = {
  handleFileChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  loading: PropTypes.bool,
  bannerPictures: PropTypes.object,
  currentBannerPicture: PropTypes.string,
  categories: PropTypes.any,
  isFetchingCategories: PropTypes.bool,
  getCategories: PropTypes.func,
  isFetchingBanners: PropTypes.bool,
  isModifyingBanner: PropTypes.bool,
  isDeletingBanner: PropTypes.bool,
  banners: PropTypes.any,
  getBanners: PropTypes.func,
  changeImage: PropTypes.func,
  changeCategory: PropTypes.func,
  deleteBanner: PropTypes.func,
};

const { getCategories } = categoriesActions;
const { getBanners, changeImage, changeCategory, deleteBanner } = bannersActions;
export default connect(
  (state) => {
    const { Categories, Banners } = state;
    const { categories, isFetchingCategories } = Categories;
    const { isFetchingBanners, isModifyingBanner, isDeletingBanner, banners } = Banners;
    return {
      categories,
      isFetchingCategories,
      isFetchingBanners,
      isModifyingBanner,
      isDeletingBanner,
      banners,
    };
  },
  { getCategories, getBanners, changeImage, changeCategory, deleteBanner }
)(BannerUploader);
