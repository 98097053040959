import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import deliveryTimeSlotsApi from '../../apis/Configuration/DeliveryConfiguration';

function* fetchTimeSlots({ date }) {
  const { success, data, error } = yield deliveryTimeSlotsApi.getDateTimeSlot(date);
  if (success) {
    yield put({
      type: actions.FETCH_DELIVERY_TIMESLOTS_SUCCESS,
      date: data.date,
      slots: data.slots,
    });
  } else {
    yield put({ type: actions.FETCH_DELIVERY_TIMESLOTS_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

function* updateTimeSlots({ date, slots }) {
  const { success, error } = yield deliveryTimeSlotsApi.updateDateTimeSlot([{id: date, timeSlots: slots}]);
  if (success) {
    yield put({ type: actions.UPDATE_DELIVERY_TIMESLOTS_SUCCESS });
    yield put({ type: actions.FETCH_DELIVERY_TIMESLOTS, date });
  } else {
    yield put({ type: actions.UPDATE_DELIVERY_TIMESLOTS_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

export default function* deliveryTimeSlotsSaga() {
  yield all([
    takeLatest(actions.FETCH_DELIVERY_TIMESLOTS, fetchTimeSlots),
    takeLatest(actions.UPDATE_DELIVERY_TIMESLOTS, updateTimeSlots),
  ])
}
