/* eslint-disable react/display-name */
import React from 'react';
import { Table, Button, Tag } from 'antd';
import Pagination from './NoSQLPagination/index';
import 'antd/dist/antd.css';
import propTypes from 'prop-types';
import Image from './FirebaseImage';

const ProductTable = (props) => {
  const originData = props.productsData;
  const { products } = originData;

  const columns = [
    {
      title: 'Feature Picture',
      dataIndex: 'featurePicture',
      width: '5%',
      render: (data) => (
        <div style={{ height: '10%', textAlign: 'center' }}>
          <Image imageRef={data} width="80%" />
        </div>
      ),
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: '10%'
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      width: '10%',
    },
    {
      title: 'Chinese Name',
      dataIndex: 'chineseName',
      width: '10%',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: '10%',
      render: (_, record) => {
        return record.discountPrice ? 'Under Discount' : `$${record.price}`;
      },
    },
    {
      title: 'Discount Price',
      dataIndex: 'discountPrice',
      width: '10%',
      render: (data) => {
        return data ? `$${data}` : 'No Discount';
      },
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      width: '5%',
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      width: '15%', // eslint-disable-next-line
      render: (tags) => (
        <div style={{ padding: 'grid' }}>
          <span>
            {tags.map((tag) => {
              let color = tag.length > 5 ? 'geekblue' : 'green';
              if (tag === 'snack') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'archived',
      width: '10%',
      // eslint-disable-next-line
      render: (data) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {data ? <Tag color="#f50">Archived</Tag> : <Tag color="#87d068">Active</Tag>}
          </div>
        );
      },
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: '10%',
      // eslint-disable-next-line
      render: (_, record) => {
        return (
          <Button type="link" onClick={(e) => onRowEdit(e, record)}>
            Edit
          </Button>
        );
      },
    },
  ];

  const onRowEdit = (e, record) => {
    props.openDrawer(record.sku);
    // console.log(record);
  };

  return (
    <div>
      <Table
        rowKey="sku"
        size="small"
        dataSource={products}
        columns={columns}
        bordered
        pagination={false}
      ></Table>

      <Pagination
        pageNumber={props.pageNumber}
        batchSize={props.pageSize}
        total={props.total}
        onPrev={props.onPrev}
        onNext={props.onNext}
        resultLength={products ? products.length : 0}
      />
    </div>
  );
};

ProductTable.propTypes = {
  productsData: propTypes.any,
  columns: propTypes.array,
  pageOnChange: propTypes.func,
  pageSize: propTypes.number,
  total: propTypes.number,
  currentPage: propTypes.number,
  openDrawer: propTypes.func,
  pageNumber: propTypes.number,
  onPrev: propTypes.func,
  onNext: propTypes.func,
};
export default ProductTable;
