import actions from './actions';

const initState = {
  isFetchingMessageList: false,
  isSendingMessage: false,
  messageList: []
};

const messageReducer = (state = initState, { type, ...action }) => {
  switch (type) {
    case actions.GET_MESSAGE_LIST:
      return {
        ...state,
        isFetchingMessageList: true,
      };
    case actions.GET_MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        isFetchingMessageList: false,
        messageList: action.messageList,
      };
    case actions.GET_MESSAGE_LIST_FAIL:
      return {
        ...state,
        isFetchingMessageList: false,
      };
    case actions.SEND_MESSAGE:
      return {
        ...state,
        isSendingMessage: true
      };
    case actions.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        isSendingMessage: false,
        messageList: [action.newMessage, ...state.messageList]
      };
    case actions.SEND_MESSAGE_FAIL:
      return {
        ...state,
        isSendingMessage: false
      };
    default: return state;
  }
}

export default messageReducer;