import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Spin, Input, Button, Form, Select, PageHeader } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import CustomersTable from '../../components/CustomerTable';
import AddressModal from '../../components/AddressModal';
import CustomersEdittingDrawer from '../../components/CustomerEdittingDrawer';
import CustomerCouponsEditingDrawer from '../../components/CustomerCouponEditingDrawer';
import customersActions from '../../redux/Customers/actions';
import customerAddressesActions from '../../redux/Customers/CustomerAddresses/actions';
import customerCouponsActions from '../../redux/Customers/CustomerCoupons/actions';
import couponsActions from '../../redux/Coupon/actions';
import PropTypes from 'prop-types';

const CustomersList = (props) => {
  const { pageNumber, total } = props.customersData;
  const batchSize = 20; // Change this line if batchSize is mutable
  const {
    getCustomers,
    fetchingCustomers,
    getSingleCustomer,
    fetchingSingleCustomer,
    editingSingleCustomer,
    editCustomer,
    getCustomerAddresses,
    customerAddresses,
    isFetchingCustomerAddresses,
    getCustomerCoupons,
    assignCustomerCoupons,
    customerCoupons,
    isFetchingCustomerCoupons,
    isEditingCustomerCoupons,
    couponsData,
    isFetchingCoupons,
    fetchCouponList,
  } = props;
  const [searchPattern, setSearchPattern] = useState('');
  const [archived, setArchived] = useState('all');
  const [editingDrawerVisibility, setEditingDrawerVisibility] = useState(false);
  const [addressModalVisibility, setAddressModalVisibility] = useState(false);
  const [couponDrawerVisibility, setCouponDrawerVisibility] = useState(false);
  const [couponUid, setCouponUid] = useState(undefined);
  useEffect(() => {
    if (!props.customersData.customers) {
      getCustomers({ searchPattern: '', pageNumber: 1, batchSize, archived });
    } // eslint-disable-next-line
  }, []);
  const [form] = Form.useForm();
  const formRef = useRef();

  const openEditingDrawerHandler = (uid) => {
    if (uid !== props.singleCustomer.customerData.uid) {
      getSingleCustomer({ uid });
    }
    setEditingDrawerVisibility(true);
  };

  const openCouponsDrawerHandler = (uid) => {
    setCouponUid(uid);
    if (couponsData.length === 0) fetchCouponList();
    getCustomerCoupons({ uid });
    setCouponDrawerVisibility(true);
  };

  const saveCouponEdit = (newCoupons) => {
    assignCustomerCoupons({ uid: couponUid, coupons: newCoupons });
  };

  const closeEditingDrawerHandler = () => {
    setEditingDrawerVisibility(false);
  };
  const saveSingleCustomerData = ({ uid, data }) => {
    editCustomer({ uid, data });
    getCustomers({ searchPattern, pageNumber, batchSize, archived });
  };

  const onPrev = () => {
    console.log('current page is', pageNumber);
    getCustomers({ searchPattern, pageNumber: pageNumber - 1, batchSize, archived });
  };

  const onNext = () => {
    console.log('current page is', pageNumber);
    getCustomers({ searchPattern, pageNumber: pageNumber + 1, batchSize, archived });
  };
  const searchHandler = (values) => {
    const newSearchPattern = values.searchPattern ? values.searchPattern : '';
    getCustomers({ searchPattern: newSearchPattern, pageNumber: 1, batchSize, archived });
    setSearchPattern(newSearchPattern);
  };

  const archivedChangeHandler = (newArchivedStatus) => {
    getCustomers({ searchPattern, pageNumber: 1, batchSize, archived: newArchivedStatus });
    setArchived(newArchivedStatus);
  };

  const searchFailHandler = (error) => {
    console.log(error);
  };

  const resetSearchHandler = () => {
    formRef.current.resetFields();
    console.log('Search conditions reset!');
    getCustomers({ searchPattern: '', pageNumber: 1, batchSize, archived: 'all' });
    setSearchPattern('');
    setArchived('all');
  };

  const refreshHandler = () => {
    getCustomers({ searchPattern, pageNumber, batchSize, archived });
  };

  const openModalHandler = (uid) => {
    setAddressModalVisibility(true);
    getCustomerAddresses({ uid });
  }

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'customers',
      breadcrumbName: 'customers',
    },
  ];
  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Customer"
          breadcrumb={{ routes }}
          extra={[
            <Button
              style={{ float: 'right' }}
              key="refresh"
              type="primary"
              onClick={refreshHandler}
              shape="round"
            >
              <SyncOutlined spin={fetchingCustomers} />
              Refresh
            </Button>,
          ]}
        ></PageHeader>
      </div>
      <Form
        title="Search Customers"
        layout="inline"
        form={form}
        ref={formRef}
        initialValues={{ layout: 'inline' }}
        onFinish={searchHandler}
        onFinishFailed={searchFailHandler}
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          flex: 1,
        }}
      >
        <Form.Item label="Status">
          <Select style={{ width: 150 }} onChange={archivedChangeHandler} defaultValue="all">
            <Select.Option value="all">Show All</Select.Option>
            <Select.Option value="false">Show Active</Select.Option>
            <Select.Option value="true">Show Archived</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Search Customers" name="searchPattern">
          <Input placeholder="Email or Phone Number" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            Search
          </Button>
        </Form.Item>
        <Form.Item>
          <Button htmlType="button" onClick={resetSearchHandler} shape="round">
            Reset
          </Button>
        </Form.Item>
      </Form>
      <Spin spinning={fetchingCustomers || editingSingleCustomer}>
        <CustomersTable
          data={props.customersData}
          currentPage={pageNumber}
          total={total}
          pageSize={batchSize}
          openDrawer={(uid) => openEditingDrawerHandler(uid)}
          onPrev={onPrev}
          onNext={onNext}
          openModal={(uid) => {
            openModalHandler(uid);
          }}
          openCouponsDrawer={(uid) => openCouponsDrawerHandler(uid)}
        />
      </Spin>
      {editingDrawerVisibility ? (
        <CustomersEdittingDrawer
          customerDetails={props.singleCustomer.customerData}
          visibility={editingDrawerVisibility || editingSingleCustomer}
          closeDrawer={closeEditingDrawerHandler}
          fetchingSingleCustomer={fetchingSingleCustomer}
          saveEdit={({ uid, data }) => saveSingleCustomerData({ uid, data })}
        />
      ) : (
        ''
      )}
      {couponDrawerVisibility ? (
        <CustomerCouponsEditingDrawer
          customerCoupons={customerCoupons}
          couponsData={couponsData}
          isFetchingCustomerCoupons={isFetchingCustomerCoupons}
          isEditingCustomerCoupons={isEditingCustomerCoupons}
          isFetchingCoupons={isFetchingCoupons}
          saveCouponEdit={(newCoupons) => saveCouponEdit(newCoupons)}
          visibility={couponDrawerVisibility || isEditingCustomerCoupons}
          closeDrawer={() => {
            setCouponDrawerVisibility(false);
          }}
        />
      ) : (
        ''
      )}
      {addressModalVisibility ? (
        <AddressModal
          visibility={addressModalVisibility}
          closeModalHandler={() => setAddressModalVisibility(false)}
          customerAddresses={customerAddresses}
          isFetchingCustomerAddresses={isFetchingCustomerAddresses}
        />
      ) : (
        ''
      )}
    </>
  );
};
const { getCustomers, getSingleCustomer, editCustomer } = customersActions;
const { getCustomerAddresses } = customerAddressesActions;
const { getCustomerCoupons, assignCustomerCoupons } = customerCouponsActions;
const { fetchCouponList } = couponsActions;

CustomersList.propTypes = {
  Customers: PropTypes.object,
  customersData: PropTypes.any,
  fetchingCustomers: PropTypes.bool,
  fetchingSingleCustomer: PropTypes.bool,
  getCustomers: PropTypes.func,
  singleCustomer: PropTypes.object,
  getSingleCustomer: PropTypes.func,
  editCustomer: PropTypes.func,
  editingSingleCustomer: PropTypes.bool,
  customerAddresses: PropTypes.object,
  isFetchingCustomerAddresses: PropTypes.bool,
  getCustomerAddresses: PropTypes.func,
  getCustomerCoupons: PropTypes.func,
  customerCoupons: PropTypes.any,
  isFetchingCustomerCoupons: PropTypes.bool,
  isEditingCustomerCoupons: PropTypes.bool,
  couponsData: PropTypes.array,
  isFetchingCoupons: PropTypes.bool,
  fetchCouponList: PropTypes.func,
  assignCustomerCoupons: PropTypes.func,
};

export default connect(
  (state) => {
    const { Customers, Coupons } = state;
    const { CustomerAddresses, CustomerCoupons } = Customers;
    const { customerAddresses, isFetchingCustomerAddresses } = CustomerAddresses;
    const { couponsData, isFetchingCoupons } = Coupons;
    const {
      customerCoupons,
      isFetchingCustomerCoupons,
      isEditingCustomerCoupons,
    } = CustomerCoupons;
    const { customersData, fetchingCustomers, singleCustomer } = Customers;
    const { fetchingSingleCustomer, editingSingleCustomer } = singleCustomer;
    return {
      fetchingSingleCustomer,
      editingSingleCustomer,
      customersData,
      fetchingCustomers,
      singleCustomer,
      customerAddresses,
      isFetchingCustomerAddresses,
      customerCoupons,
      isFetchingCustomerCoupons,
      isEditingCustomerCoupons,
      couponsData,
      isFetchingCoupons,
    };
  },
  {
    getCustomers,
    getSingleCustomer,
    editCustomer,
    getCustomerAddresses,
    getCustomerCoupons,
    fetchCouponList,
    assignCustomerCoupons,
  }
)(CustomersList);
