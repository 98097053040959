/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Spin,
  Button,
  Table,
  Select,
  message,
  Card,
  Empty,
  Tag,
  Typography,
  Result
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import batchPrintPDF from '../../utils/batchPrintInvoice';
import unprocessedOrdersActions from '../../redux/Orders/UnprocessedOrders/actions';

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard'
  },
  {
    path: 'unprocessed',
    breadcrumbName: 'Unprocessed Orders'
  }
];

const columns = [
  {
    title: 'Order Index',
    dataIndex: 'orderIndex',
    render: (data, record) =>
      data ? (
        <span style={{ color: 'blue', fontWeight: 'bold' }}>{data}</span>
      ) : (
        <Typography.Text copyable>{record.orderId}</Typography.Text>
      )
  },
  {
    title: 'Contact Name',
    dataIndex: ['deliveryAddress', 'contactName'],
    render: (data) => <Typography.Text>{data}</Typography.Text>
  },
  {
    title: 'Contact Number',
    dataIndex: ['deliveryAddress', 'contactNumber']
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (data) => moment(data.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')
  },
  {
    title: 'Delivery Time',
    dataIndex: 'deliveryTime'
  },
  {
    title: 'Delivery Address',
    // dataIndex: []
    render: (data) => {
      const {
        unitNumber,
        address,
        suburb,
        state,
        postcode
      } = data.deliveryAddress;
      return (
        (unitNumber ? unitNumber + '/' : '') +
        address +
        ', ' +
        suburb +
        ' ' +
        state +
        postcode
      );
    }
  },
  {
    title: 'Total',
    dataIndex: 'total',
    render: (data) => `$${data}`
  },
  {
    title: 'Payment Method',
    dataIndex: ['paymentInfo', 'method'],
    render: (data) =>
      data ? (
        <Tag color="blue">{data && data.toUpperCase()}</Tag>
      ) : (
        <Tag color="red">PENDING PAYMENT</Tag>
      )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (data) => (
      <Tag color={data === 'paid' || data === 'delivered' ? 'green' : 'red'}>
        {data.toUpperCase()}
      </Tag>
    )
  }
];

const BatchProcessOrders = (props) => {
  const {
    isFetchingUnprocessedOrders,
    unprocessedOrders,
    isProcessingOrders,
    processOrdersSuccess,
    getUnprocessedOrders,
    batchProcessOrders
  } = props;

  const [daysFromNow, setDaysFromNow] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    if (processOrdersSuccess) {
      message.success('Process orders success! Please save the PDF file!');
    }
  }, [processOrdersSuccess]);

  const processOrders = () => {
    setHasMore(unprocessedOrders.length === 20);
    batchPrintPDF(unprocessedOrders, daysFromNow);
    batchProcessOrders();
  };

  return (
    <Spin spinning={isFetchingUnprocessedOrders || isProcessingOrders}>
      <PageHeader
        ghost={false}
        breadcrumb={{ routes }}
        onBack={() => window.history.back()}
        title="Unprocessed Orders"
      />
      <div>
        <Card>
          <span style={{ fontWeight: 'bolder', fontSize: 16 }}>
            Select Date to Search Unprocessed Orders:{' '}
          </span>
          <Select
            // value={daysFromNow}
            placeholder="Select date to search..."
            onChange={setDaysFromNow}
            style={{ marginLeft: 15 }}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day) => (
              <Select.Option key={day} value={day}>
                {moment().add(day, 'days').format('YYYY-MM-DD')}
              </Select.Option>
            ))}
          </Select>
          <Button
            shape="round"
            disabled={daysFromNow === null}
            type="primary"
            onClick={() => getUnprocessedOrders({ days: daysFromNow })}
            style={{ marginLeft: 15 }}
          >
            Search
          </Button>
          <Button
            shape="round"
            disabled={unprocessedOrders.length === 0}
            type="primary"
            onClick={processOrders}
            style={{ marginLeft: 15 }}
          >
            Process & Print
          </Button>
        </Card>
        <Card style={{ marginTop: 15 }}>
          {unprocessedOrders.length > 0 ? (
            <Table
              rowKey="orderId"
              bordered
              size="small"
              dataSource={unprocessedOrders}
              columns={columns}
              pagination={false}
            />
          ) : processOrdersSuccess && hasMore ? (
            <Result
              status="success"
              title="Successfully processed orders."
              subTitle=" Click next page button to see more unprocessed orders on the same date."
              extra={[
                <Button
                  key={1}
                  type="primary"
                  onClick={() => getUnprocessedOrders({ days: daysFromNow })}
                >
                  Next Page
                </Button>
              ]}
            />
          ) : (
            <Empty description="No records for now. Search by date to check unprocessed orders." />
          )}
        </Card>
      </div>
    </Spin>
  );
};

BatchProcessOrders.propTypes = {
  isFetchingUnprocessedOrders: PropTypes.bool,
  unprocessedOrders: PropTypes.array,
  isProcessingOrders: PropTypes.bool,
  processOrdersSuccess: PropTypes.bool,
  getUnprocessedOrders: PropTypes.func,
  batchProcessOrders: PropTypes.func
};

const { getUnprocessedOrders, batchProcessOrders } = unprocessedOrdersActions;
export default connect(
  (state) => {
    const { Orders } = state;
    const {
      isFetchingUnprocessedOrders,
      unprocessedOrders,
      isProcessingOrders,
      processOrdersSuccess
    } = Orders.unprocessedOrder;
    return {
      isFetchingUnprocessedOrders,
      unprocessedOrders,
      isProcessingOrders,
      processOrdersSuccess
    };
  },
  {
    getUnprocessedOrders,
    batchProcessOrders
  }
)(BatchProcessOrders);
