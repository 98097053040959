import actions from './actions';

export const newCouponInitState = {
  isCreatingCoupon: false,
  newCoupon: {},
};

export default function newCouponReducer (state = newCouponInitState, { type, ...action }) {
  switch (type) {
    case actions.CREATE_COUPON:
      return {
        ...state,
        isCreatingCoupon: true,
        newCoupon: {}
      };
    case actions.CREATE_COUPON_SUCCESS:
      return {
        ...state,
        isCreatingCoupon: false,
        newCoupon: action.newCoupon
      };
    case actions.CREATE_COUPON_FAIL:
      return {
        ...state,
        isCreatingCoupon: false,
      };
    default: return state;
  }
}
