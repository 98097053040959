const actions = {
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',

  createUser: ({ mobile, email, nickname, password }) => ({
    type: actions.CREATE_USER,
    mobile,
    email,
    nickname,
    password,
  }),
};

export default actions;
