import React from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import './index.scss';
import { Link } from 'react-router-dom';

export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: 'My Mart Application is coming soon',
  },
  content: {
    className: 'banner0-content',
  },
  button: { className: 'banner0-button', children: 'Log in to dashboard' },
};

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim key="QueueAnim" type={['bottom', 'top']} delay={200} {...dataSource.textWrapper}>
          <div key="title" className="banner0-title">
            <h1>
              <em>My Mart</em>
            </h1>
            <h2>
              <em>is coming soon</em>
            </h2>
          </div>
          <div key="content" {...dataSource.content}>
            One tap order for everything.
            <br />
            Subscribe to get our latest news
          </div>
          <Button ghost key="button" {...dataSource.button}>
            <Link to="/dashboard/workspace">{dataSource.button.children}</Link>
          </Button>
        </QueueAnim>
      </div>
    );
  }
}

Banner.defaultProps = {
  dataSource: Banner00DataSource,
};

export default Banner;
