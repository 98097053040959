import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

const NotFound = () => (
  <Result
    status="404"
    title="404"
    style={{
      background: 'none',
    }}
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link to="/dashboard/workspace">
        <Button type="primary">Back Home</Button>
      </Link> 
    }
  />
);

export default NotFound;
