import actions from './actions';

export const flashSaleInitState = {
  isFetchingFlashSaleProducts: false,
  isFetchingPreFlashSaleProducts: false,
  isFetchingFlashSaleTimeConfig: false,
  isUpdatingFlashSaleTimeConfig: false,
  isUpdatingPreFlashSaleProducts: false,
  isStartingFlashSale: false,
  startFlashSaleSuccess: null,
  flashSaleTimeConfig: {},
  flashSaleProducts: [],
  preFlashSaleProducts: [],
};

export default function flashSaleProductsReducer (state = flashSaleInitState,  { type, ...action }) {
  switch (type) {
    case actions.GET_FLASH_SALE_PRODUCTS:
      return {
        ...state,
        isFetchingFlashSaleProducts: true
      };
    case actions.GET_FLASH_SALE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetchingFlashSaleProducts: false,
        flashSaleProducts: action.products
      };
    case actions.GET_FLASH_SALE_PRODUCTS_FAIL:
      return {
        ...state,
        isFetchingFlashSaleProducts: false
      };
    case actions.GET_PRE_FLASH_SALE_PRODUCTS:
      return {
        ...state,
        isFetchingPreFlashSaleProducts: true
      };
    case actions.GET_PRE_FLASH_SALE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetchingPreFlashSaleProducts: false,
        preFlashSaleProducts: action.products
      };
    case actions.GET_PRE_FLASH_SALE_PRODUCTS_FAIL:
      return {
        ...state,
        isFetchingPreFlashSaleProducts: false
      };
    case actions.GET_FLASH_SALE_TIME_CONFIG:
      return {
        ...state,
        isFetchingFlashSaleTimeConfig: true
      };
    case actions.GET_FLASH_SALE_TIME_CONFIG_SUCCESS:
      return {
        ...state,
        isFetchingFlashSaleTimeConfig: false,
        flashSaleTimeConfig: action.timeConfig
      };
    case actions.GET_FLASH_SALE_TIME_CONFIG_FAIL:
      return {
        ...state,
        isFetchingFlashSaleTimeConfig: false
      };
    case actions.UPDATE_FLASH_SALE_TIME_CONFIG:
      return {
        ...state,
        isUpdatingFlashSaleTimeConfig: true
      };
    case actions.UPDATE_FLASH_SALE_TIME_CONFIG_SUCCESS:
      return {
        ...state,
        isUpdatingFlashSaleTimeConfig: false,
        flashSaleTimeConfig: action.timeConfig,
      };
    case actions.UPDATE_FLASH_SALE_TIME_CONFIG_FAIL:
      return {
        ...state,
        isUpdatingFlashSaleTimeConfig: false,
      };
    case actions.UPDATE_PRE_FLASH_SALE_PRODUCTS:
      return {
        ...state,
        isUpdatingPreFlashSaleProducts: true
      };
    case actions.UPDATE_PRE_FLASH_SALE_PRODUCTS_SUCCESS:
    case actions.UPDATE_PRE_FLASH_SALE_PRODUCTS_FAIL:
      return {
        ...state,
        isUpdatingPreFlashSaleProducts: false
      };
    case actions.START_FLASH_SALE:
      return {
        ...state,
        isStartingFlashSale: true,
        startFlashSaleSuccess: null,
      };
    case actions.START_FLASH_SALE_SUCCESS:
      return {
        ...state,
        isStartingFlashSale: false,
        startFlashSaleSuccess: true
      };
    case actions.START_FLASH_SALE_FAIL:
      return {
        ...state,
        isStartingFlashSale: false,
        startFlashSaleSuccess: false
      };
    default:
      return state;
  }
}