import { all, put, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from './actions';
// import api from '../../apis/Users';
import { firebaseAuth, firebase, rsfFirestore } from '../../utils/firebase';

function* sendSMS(params) {
  const { phoneNumber } = params;
  const appVerifier = window.recaptchaVerifier;
  let confirmationResult;
  try {
    yield firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    confirmationResult = yield firebaseAuth.signInWithPhoneNumber(phoneNumber, appVerifier);
    yield put({
      type: actions.SEND_SMS_SUCCESS,
      smsConfirmation: confirmationResult,
    });
  } catch (error) {
    console.log(error);
    toast.error('SMS send fail.', { autoClose: 3000 });
    yield put({ type: actions.SEND_SMS_FAIL });
  }
}

function* checkVerficationCode(params) {
  const { User } = yield select();
  const { smsConfirmation } = User;
  const { verificationCode } = params;
  try {
    const credential = firebase.auth.PhoneAuthProvider.credential(
      smsConfirmation.verificationId,
      verificationCode
    );
    yield firebaseAuth.signInWithCredential(credential);
    yield put({ type: actions.CHECK_VERIFICATION_CODE_SUCCESS });
  } catch (error) {
    console.log(error);
    toast.error('Check verification code fail.', { autoClose: 3000 });
    yield put({ type: actions.CHECK_VERIFICATION_CODE_FAIL });
  }
}

function* fetchCurrentUserData(params) {
  const { uid } = params;
  try {
    const snapshot = yield rsfFirestore.getDocument(`users/${uid}`);
    const currentUserData = snapshot.data();
    yield put({
      type: actions.FETCH_CURRENT_USER_DATA_SUCCESS,
      currentUserData,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CURRENT_USER_DATA_FAIL });
    toast.error('You are not authorized to access dashboard', {
      autoClose: 3000,
    });
    yield put({ type: actions.SIGN_OUT });
  }
}

function* userPasswordLogin(params) {
  const { email, password } = params;
  try {
    yield firebaseAuth.signInWithEmailAndPassword(email, password);
    yield put({ type: actions.USER_LOGIN_SUCCESS });
  } catch (error) {
    toast.error(error.message, { autoClose: 3000 });
    yield put({ type: actions.USER_LOGIN_FAIL });
  }
}

function* changePassword(params) {
  const { password, newPassword } = params;
  const { User } = yield select();
  const { email } = User.userInfo;
  const user = firebaseAuth.currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(email, password);
  try {
    yield user.reauthenticateWithCredential(credential);
    yield user.updatePassword(newPassword);
    yield put({ type: actions.CHANGE_PASSWORD_SUCCESS });
    toast.success('Password Changed. Please Sign In', {
      autoClose: 3000,
    });
    yield put({ type: actions.SIGN_OUT });
  } catch (error) {
    yield put({ type: actions.CHANGE_PASSWORD_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    })
  }
}

function* signOut() {
  try {
    yield firebaseAuth.signOut();
    yield put({ type: actions.SIGN_OUT_SUCCESS });
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SIGN_OUT_FAIL });
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(actions.USER_PASSWORD_LOGIN, userPasswordLogin),
    takeLatest(actions.SEND_SMS, sendSMS),
    takeLatest(actions.CHECK_VERIFICATION_CODE, checkVerficationCode),
    takeLatest(actions.FETCH_CURRENT_USER_DATA, fetchCurrentUserData),
    takeLatest(actions.SIGN_OUT, signOut),
    takeLatest(actions.CHANGE_PASSWORD, changePassword),
  ]);
}
