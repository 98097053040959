const actions = {
  //fetch orders list
  FETCH_ORDERS_LIST: 'FETCH_ORDERS_LIST',
  FETCH_ORDERS_LIST_SUCCESS: 'FETCH_ORDERS_LIST_SUCCESS',
  FETCH_ORDERS_LIST_FAIL: 'FETCH_ORDERS_LIST_FAIL',

  FETCH_SINGLE_ORDER: 'FETCH_SINGLE_ORDER',
  FETCH_SINGLE_ORDER_SUCCESS: 'FETCH_SINGLE_ORDER_SUCCESS',
  FETCH_SINGLE_ORDER_FAIL: 'FETCH_SINGLE_ORDER_FAIL',

  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
  UPDATE_ORDER_STATUS_SUCCESS: 'UPDATE_ORDER_STATUS_SUCCESS',
  UPDATE_ORDER_STATUS_FAIL: 'UPDATE_ORDER_STATUS_FAIL',

  DELETE_ORDER: 'DELETE_ORDER',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAIL: 'DELETE_ORDER_FAIL',

  RECEIVE_NEW_ORDER: 'RECEIVE_NEW_ORDER',
  CHECK_NEW_ORDER: 'CHECK_NEW_ORDER',

  fetchOrderList: (params) => ({
    type: actions.FETCH_ORDERS_LIST,
    ...params,
  }),

  fetchSingleOrder: (params) => ({
    type: actions.FETCH_SINGLE_ORDER,
    ...params,
  }),

  updateOrderStatus: (params) => ({
    type: actions.UPDATE_ORDER_STATUS,
    ...params,
  }),

  deleteOrder: ({ order }) => ({
    type: actions.DELETE_ORDER,
    order,
  }),

  receiveNewOrder: ({ order }) => ({
    type: actions.RECEIVE_NEW_ORDER,
    order,
  }),

  checkNewOrder: ({ orders }) => ({
    type: actions.CHECK_NEW_ORDER,
    orders,
  }),
};

export default actions;