import React, { useMemo } from 'react';
import { Table, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

const aWeekAgo = moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 7).format('YYYY-MM-DD');
const aMonthAgo = moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 30).format('YYYY-MM-DD');

const BestSellerTable = (props) => {
  const columns = [
    {
      title: 'Product SKU',
      dataIndex: 'sku',
    },
    {
      title: 'Sold in last 7 days',
      dataIndex: 'sevenDaysSold',
      sorter: (a, b) => a.sevenDaysSold - b.sevenDaysSold,
    },
    {
      title: 'Sold in last 30 days',
      dataIndex: 'thirtyDaysSold',
      sorter: (a, b) => a.thirtyDaysSold - b.thirtyDaysSold,
    },
  ];

  const { salesData } = props;

  const data = useMemo(() => {
    function compare(a, b) {
      if (a.date > b.date) return 1;
      return -1;
    }
    if (salesData) {
      salesData.sort(compare);
      const soldProducts = {};
      for (const entry of salesData) {
        for (const sku of Object.keys(entry.productsSold)) {
          if (soldProducts[sku]) {
            if (entry.date >= aWeekAgo) soldProducts[sku].sevenDaysSold += entry.productsSold[sku];
            if (entry.date >= aMonthAgo)
              soldProducts[sku].thirtyDaysSold += entry.productsSold[sku];
          } else {
            soldProducts[sku] = {};
            soldProducts[sku].sevenDaysSold = 0;
            soldProducts[sku].thirtyDaysSold = 0;
            if (entry.date >= aWeekAgo) soldProducts[sku].sevenDaysSold += entry.productsSold[sku];
            if (entry.date >= aMonthAgo)
              soldProducts[sku].thirtyDaysSold += entry.productsSold[sku];
          }
        }
      }
      return Object.keys(soldProducts).map((key) => {
        return {
          sku: key,
          sevenDaysSold: soldProducts[key].sevenDaysSold,
          thirtyDaysSold: soldProducts[key].thirtyDaysSold,
        };
      });
    }
    return [];
  }, [salesData]);

  return (
    <Table
      title={() => <Typography.Title level={4}>Best Sellers</Typography.Title>}
      rowKey="sku"
      size="small"
      columns={columns}
      dataSource={data}
    />
  );
};

BestSellerTable.propTypes = {
  salesData: PropTypes.array,
};

export default BestSellerTable;
