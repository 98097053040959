/* eslint-disable */
import React from 'react';
import { Layout, Menu, Card, Tabs, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import Banner from './Banner';

const { Header, Content, Footer } = Layout;

const Homepage = (props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header>
        <div className="logo" style={styles.logo}>
        <Logo light/> 
          <em>My Mart</em>
        </div>
        <Menu theme="dark" style={{ textAlign: 'right' }} mode="horizontal">
          <Menu.Item key="dashboard">
            <Link to="/dashboard/workspace">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="contact">
            <Link to="/contact">Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="termsandconditions">
            <Link to="/term">Terms and Conditions</Link>
          </Menu.Item>
          <Menu.Item key="privacypolicy">
            <Link to="/privacy">Privacy Policy</Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content>
        <Row>
          <Banner />
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        My Mart Pty Ltd. Designed by AIBuild Pty Ltd © 2020
      </Footer>
    </Layout>
  );
};

const styles = {
  content: {
    margin: '10px 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    background: '#e0ddd7',
  },
  tabs: {
    width: '320px',
  },
  logo: {
    width: '165px',
    height: '0px',
    background: '#222',
    margin: '0px 28px 16px -30px',
    float: 'left',
  },
};

export default connect((state) => {
  const { User } = state;
  return {
    userInfo: User.userInfo,
  };
}, null)(Homepage);
