const actions = {
  GET_POSTCODE_DELIVERY: 'GET_POSTCODE_DELIVERY',
  GET_POSTCODE_DELIVERY_SUCCESS: 'GET_POSTCODE_DELIVERY_SUCCESS',
  GET_POSTCODE_DELIVERY_FAIL: 'GET_POSTCODE_DELIVERY_FAIL',

  GET_SINGLE_POSTCODE_DELIVERY: 'GET_SINGLE_POSTCODE_DELIVERY',
  GET_SINGLE_POSTCODE_DELIVERY_SUCCESS: 'GET_SINGLE_POSTCODE_DELIVERY_SUCCESS',
  GET_SINGLE_POSTCODE_DELIVERY_FAIL: 'GET_SINGLE_POSTCODE_DELIVERY_FAIL',

  UPDATE_SINGLE_POSTCODE_DELIVERY: 'UPDATE_SINGLE_POSTCODE_DELIVERY',
  UPDATE_SINGLE_POSTCODE_DELIVERY_SUCCESS: 'UPDATE_SINGLE_POSTCODE_DELIVERY_SUCCESS',
  UPDATE_SINGLE_POSTCODE_DELIVERY_FAIL: 'UPDATE_SINGLE_POSTCODE_DELIVERY_FAIL',

  BATCH_UPLOAD_POSTCODE_DELIVERY: 'BATCH_UPLOAD_POSTCODE_DELIVERY',
  BATCH_UPLOAD_POSTCODE_DELIVERY_SUCCESS: 'BATCH_UPLOAD_POSTCODE_DELIVERY_SUCCESS',
  BATCH_UPLOAD_POSTCODE_DELIVERY_FAIL: 'BATCH_UPLOAD_POSTCODE_DELIVERY_FAIL',
  RESET_UPLOAD_STATUS: 'RESET_UPLOAD_STATUS',

  getPostcodeDelivery: ({ searchPattern, batchSize, pageNumber }) => ({
    type: actions.GET_POSTCODE_DELIVERY,
    searchPattern,
    batchSize,
    pageNumber
  }),

  getSinglePostcodeDelivery: ({ postcode }) => ({
    type: actions.GET_SINGLE_POSTCODE_DELIVERY,
    postcode
  }),

  updateSinglePostcodeDelivery: ({ postcode, slots }) => ({
    type: actions.UPDATE_SINGLE_POSTCODE_DELIVERY,
    postcode,
    slots,
  }),

  batchUploadPostcodeDelivery: ({ timeslots }) => ({
    type: actions.BATCH_UPLOAD_POSTCODE_DELIVERY,
    timeslots
  }),

  resetUploadStatus: () => ({
    type: actions.RESET_UPLOAD_STATUS
  })
};

export default actions;