import React from 'react';
import { Card, PageHeader, Spin, Button } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DefaultTimeSlotTable from '../../components/PostcodeDelivery/DefaultSlotTable';
import DeliveryTimeUpload from '../../components/PostcodeDelivery/DeliveryTimeUpload';
import actions from '../../redux/PostcodeDelivery/actions';
import DeliveryTimeZip from '../../assets/DeliveryTime.zip';

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'deliverybypostcode',
    breadcrumbName: 'Delivery Time Settings',
  },
];

const PostcodeDeliveryContainer = (props) => {
  const {
    getPostcodeDelivery,
    getSinglePostcodeDelivery,
    updateSinglePostcodeDelivery,
    batchUploadPostcodeDelivery,
    resetUploadStatus,
    PostcodeDelivery,
  } = props;

  const {
    isFetchingSlots,
    isFetchingSinglePostcodeSlots,
    isUpdatingSinglePostcodeSlots,
    isUploadingSlots,
    uploadSuccess,
    timeslots,
    singlePostcodeSlots,
  } = PostcodeDelivery;

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Default Delivery Time"
        breadcrumb={{ routes }}
        extra={[
          <Button style={{ float: 'right' }} key="downloadTemplate" type="primary" shape="round">
            <a href={DeliveryTimeZip} download="DeliveryTimeByPostcode.zip">
              Download Template
            </a>
          </Button>
        ]}
      ></PageHeader>
      <DeliveryTimeUpload
        uploadTimeslots={batchUploadPostcodeDelivery}
        isUploading={isUploadingSlots}
        uploadSuccess={uploadSuccess}
        resetUploadStatus={resetUploadStatus}
      />
      <Card style={{ marginTop: '15px' }} title="Default Delivery Time">
        <Spin
          spinning={
            isFetchingSlots || isFetchingSinglePostcodeSlots || isUpdatingSinglePostcodeSlots
          }
        >
          <DefaultTimeSlotTable
            timeslots={timeslots}
            singlePostcodeSlots={singlePostcodeSlots}
            getList={getPostcodeDelivery}
            getSingle={getSinglePostcodeDelivery}
            updateSingle={updateSinglePostcodeDelivery}
          />
        </Spin>
      </Card>
    </>
  );
};

PostcodeDeliveryContainer.propTypes = {
  getPostcodeDelivery: PropTypes.func,
  getSinglePostcodeDelivery: PropTypes.func,
  updateSinglePostcodeDelivery: PropTypes.func,
  batchUploadPostcodeDelivery: PropTypes.func,
  resetUploadStatus: PropTypes.func,
  PostcodeDelivery: PropTypes.shape({
    isFetchingSlots: PropTypes.bool,
    isFetchingSinglePostcodeSlots: PropTypes.bool,
    isUpdatingSinglePostcodeSlots: PropTypes.bool,
    isUploadingSlots: PropTypes.bool,
    uploadSuccess: PropTypes.any,
    timeslots: PropTypes.any,
    singlePostcodeSlots: PropTypes.any,
  }),
};

const {
  getPostcodeDelivery,
  getSinglePostcodeDelivery,
  updateSinglePostcodeDelivery,
  batchUploadPostcodeDelivery,
  resetUploadStatus,
} = actions;

export default connect(
  (state) => {
    const { PostcodeDelivery } = state;
    return { PostcodeDelivery };
  },
  {
    getPostcodeDelivery,
    getSinglePostcodeDelivery,
    updateSinglePostcodeDelivery,
    batchUploadPostcodeDelivery,
    resetUploadStatus,
  }
)(PostcodeDeliveryContainer);
