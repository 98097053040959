import actions from './actions';

const initState = {
  isFetchingImageUrl: false,
  imageUrl: null,
};

export default function (state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_SPLASH_SCREEN_IMAGE:
      return {
        ...state,
        isFetchingImageUrl: true,
      };
    case actions.GET_SPLASH_SCREEN_IMAGE_SUCCESS:
      return {
        ...state,
        isFetchingImageUrl: false,
        imageUrl: action.url,
      };
    case actions.GET_SPLASH_SCREEN_IMAGE_FAIL:
      return {
        ...state,
        isFetchingImageUrl: false
      };
    case actions.UPDATE_SPLASH_SCREEN_IMAGE:
      return {
        ...state,
        imageUrl: action.ref
      };
    default: return state;
  }
}