import actions from './actions';

const initState = {
  isFetchingSlots: false,
  isFetchingSinglePostcodeSlots: false,
  isUpdatingSinglePostcodeSlots: false,
  isUploadingSlots: false,
  uploadSuccess: null,
  timeslots: {},
  singlePostcodeSlots: {},
};

const PostcodeDeliveryReducer = (state = initState, { type, ...action }) => {
  switch (type) {
    case actions.GET_POSTCODE_DELIVERY:
      return {
        ...state,
        isFetchingSlots: true,
      };
    case actions.GET_POSTCODE_DELIVERY_SUCCESS:
      return {
        ...state,
        isFetchingSlots: false,
        timeslots: action.slots,
      };
    case actions.GET_POSTCODE_DELIVERY_FAIL:
      return {
        ...state,
        isFetchingSlots: false,
      };
    case actions.GET_SINGLE_POSTCODE_DELIVERY:
      return {
        ...state,
        isFetchingSinglePostcodeSlots: true,
      };
    case actions.GET_SINGLE_POSTCODE_DELIVERY_SUCCESS:
      return {
        ...state,
        isFetchingSinglePostcodeSlots: false,
        singlePostcodeSlots: action.slots
      };
    case actions.GET_SINGLE_POSTCODE_DELIVERY_FAIL:
      return {
        ...state,
        isFetchingSinglePostcodeSlots: false
      };
    case actions.UPDATE_SINGLE_POSTCODE_DELIVERY:
      return {
        ...state,
        isUpdatingSinglePostcodeSlots: true,
      };
    case actions.UPDATE_SINGLE_POSTCODE_DELIVERY_SUCCESS:
      return {
        ...state,
        isUpdatingSinglePostcodeSlots: false,
        singlePostcodeSlots: action.slots,
      };
    case actions.UPDATE_SINGLE_POSTCODE_DELIVERY_FAIL:
      return {
        ...state,
        isUpdatingSinglePostcodeSlots: false,
      };
    case actions.BATCH_UPLOAD_POSTCODE_DELIVERY:
      return {
        ...state,
        uploadSuccess: null,
        isUploadingSlots: true
      };
    case actions.BATCH_UPLOAD_POSTCODE_DELIVERY_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        isUploadingSlots: false,
      };
    case actions.BATCH_UPLOAD_POSTCODE_DELIVERY_FAIL:
      return {
        ...state,
        uploadSuccess: false,
        isUploadingSlots: false,
      };
    case actions.RESET_UPLOAD_STATUS:
      return {
        ...state,
        uploadSuccess: null,
        isUploadingSlots: false,
      };
    default:
      return state;
  }
};

export default PostcodeDeliveryReducer;