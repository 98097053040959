import React, { useState } from 'react';
import { Button, TreeSelect } from 'antd';
import moment from 'moment';
import { firestore } from '../../utils/firebase';
import generateTreeNode from '../../utils/generateTreeNode';
import jsonexport from 'jsonexport/dist';
import { SyncOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const DownloadProductCSV = (props) => {

  const [loading, setLoading] = useState(false);
  const [fileDownloadLink, setFileDownloadLink] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showProductLabel, setShowProductLabel] = useState('');

  // eslint-disable-next-line react/prop-types
  const categories = props.categories

  const onChangeCategory = (value,label) => {
    setSelectedCategory(value);
    setShowProductLabel(label);
  }

  const getProductsByCategory = async () => {
    try {
      const result = [];
      const productSnapshot = await firestore.collection('products').where('categories', 'array-contains', selectedCategory).get();
      productSnapshot.forEach((doc) => {
        result.push(doc.data());
      });
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const convertFormat = (result) => {
    const productList = []
    console.log(result)
    result.forEach(product => {
      const newProduct = {
        sku: product.sku,
        name: product.name,
        price: product.price,
        discountPrice: product.discountPrice,
        priority: product.priority,
        stock: product.stock,
        categories: product.categories,
        shortDescription: product.shortDescription,
        longDescription: product.longDescription,
        chineseName: product.chineseName,
        koreanName: product.koreanName,
        expiryDate: product.expiryDate ? moment( product.expiryDate.seconds * 1000).format('DD/MM/YYYY') : null,
        images: product.images,
        productDescriptionImages: product.productDescriptionImages,
        archived: product.archived,
        flashSaleLimit: product.flashSaleLimit
      }
      productList.push(newProduct)
    })
    return productList;
  }

  const exportCSV = async () => {
    setLoading(true);
    const result = await getProductsByCategory();
    console.log(result);
    jsonexport(convertFormat(result), function (err, csv) {
      if (err) return console.log(err);
      const myURL = window.URL || window.webkitURL; //window.webkitURL works in Chrome and window.URL works in Firefox
      const blob = new Blob([csv], { type: 'text/csv' });
      const csvUrl = myURL.createObjectURL(blob);
      setFileDownloadLink(csvUrl);
      console.log(csvUrl);
    });
    setLoading(false);
  };


  return (
    <>
      <TreeSelect
        style={{ width: '30%', marginTop: '10px'}}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="Please select category"
        treeDefaultExpandAll
        onChange={onChangeCategory}
      >
        {categories ? generateTreeNode(categories) : <></>}
      </TreeSelect>
      <Button onClick={exportCSV} 
        shape="round" style={{ marginLeft: '15px' }} disabled={!selectedCategory}>
        <SyncOutlined spin={loading} />
        {`Generate ${showProductLabel} Product List`}
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: '15px' }}
        shape="round"
        disabled={!fileDownloadLink}
      >
        {fileDownloadLink && (
          <a
            download={`${showProductLabel} Product List.csv`}
            href={fileDownloadLink}
          >
            Download CSV
          </a>
        )}
      </Button>
    </>
  )

};
DownloadProductCSV.propTypes = {
  categories: PropTypes.any
};
export default DownloadProductCSV;

