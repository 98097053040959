/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import hotSearchActions from '../../redux/HotSearch/actions';
import moment from 'moment';
import {
  PageHeader,
  Button,
  Card,
  Spin,
  Select,
  Table,
  Col,
  Row,
  Form,
  DatePicker,
  Input,
  Tag,
  message
} from 'antd';

const { RangePicker } = DatePicker;
const { Item } = Form;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard'
  },
  {
    path: 'hotsearch',
    breadcrumbName: 'Hot Search'
  }
];

const HotSearch = (props) => {
  const {
    isFetchingHotSearchKeywords,
    keywords,
    getHotSearchKeywords,
    isFetchingRecommendation,
    isUpdatingRecommendation,
    recommendation,
    getRecommendation,
    updateRecommendation
  } = props;

  const [recommendationDraft, setRecommendationDraft] = useState([]);
  const [customKeywords, setCustomKeywords] = useState(null);

  const addToRecommendation = (word) => {
    if (!recommendationDraft.includes(word))
      setRecommendationDraft([...recommendationDraft, word]);
    else 
      message.info('This keyword is already in recommendations!')
  };

  const removeFromRecommendation = (word) => {
    const newList = recommendationDraft.filter((keyword) => keyword !== word);
    setRecommendationDraft(newList);
  };

  const saveKeywords = () => {
    updateRecommendation({ recommendation: recommendationDraft });
  }

  useEffect(() => {
    getHotSearchKeywords({});
    getRecommendation();// eslint-disable-next-line
  }, []);

  useEffect(() => {
    setRecommendationDraft([...recommendation])
  }, [recommendation])

  const submitSearch = (values) => {
    const startDate = values.dateRange[0].format('YYYY-MM-DD');
    const endDate = values.dateRange[1].format('YYYY-MM-DD');
    const limit = values.limit;
    getHotSearchKeywords({ startDate, endDate, limit });
  };

  const disabledDate = (current) => {
    return current && current > moment();
  };

  return (
    <Spin spinning={isFetchingHotSearchKeywords || isFetchingRecommendation || isUpdatingRecommendation }>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Hot Search"
        breadcrumb={{ routes }}
      />
      <Row gutter={16} style={{ marginTop: '15px ' }}>
        <Col span={12}>
          <Card title="Get Hot Search Keywords">
            <Form
              {...formItemLayout}
              onFinish={submitSearch}
              initialValues={{
                dateRange: [moment().subtract(7, 'days'), moment()],
                limit: 30
              }}
            >
              <Item label="Date Range" name="dateRange">
                <RangePicker disabledDate={disabledDate} />
              </Item>
              <Item label="Record Limit" name="limit">
                <Select style={{ width: '50%' }}>
                  {[30, 50, 100].map((limit) => (
                    <Option key={limit} value={limit}>{`Top ${limit}`}</Option>
                  ))}
                </Select>
              </Item>
              <Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Item>
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Search Recommendations">
            <div>
              {recommendationDraft.map((word) => (
                <Tag
                  key={word}
                  closable={true}
                  onClose={() => removeFromRecommendation(word)}
                >
                  {word}
                </Tag>
              ))}
            </div>
            <div style={{ marginTop: '30px' }}>
              <span>Add Recommendation Keyword:</span>
              <span style={{ marginLeft: '30px' }}>
                <Input
                  placeholder="Type and hit enter"
                  value={customKeywords}
                  onChange={(e) => setCustomKeywords(e.target.value)}
                  onPressEnter={() => {
                    if (customKeywords) addToRecommendation(customKeywords);
                    setCustomKeywords(null);
                  }}
                  style={{ width: '40%' }}
                />
              </span>
            </div>
            <div style={{ marginTop: '30px' }}>
              <Button type="primary" onClick={saveKeywords}>
                Save
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <Card title="Customer Hot Search Keywords" style={{ marginTop: '15px ' }}>
        <Table
          rowKey="search"
          dataSource={keywords}
          size="small"
          bordered
          pagination={{ pageSize: 20 }}
          columns={[
            {
              title: 'Keyword',
              dataIndex: 'search'
            },
            {
              title: 'Search Count',
              dataIndex: 'count'
            },
            {
              title: 'Operation',
              render: (data) => (
                <>
                  <Button
                    type="link"
                    onClick={() => {
                      addToRecommendation(data.search);
                    }}
                    disabled={recommendationDraft.includes(data.search)}
                  >
                    Add to Recommendations
                  </Button>
                  <Button
                    type="link"
                    danger
                    onClick={() => {
                      removeFromRecommendation(data.search);
                    }}
                    disabled={!recommendationDraft.includes(data.search)}
                  >
                    Remove from Recommendations
                  </Button>
                </>
              )
            }
          ]}
        />
      </Card>
    </Spin>
  );
};

HotSearch.propTypes = {
  isFetchingHotSearchKeywords: PropTypes.bool,
  keywords: PropTypes.array,
  recommendation: PropTypes.array,
  getHotSearchKeywords: PropTypes.func,
  getRecommendation: PropTypes.func,
  updateRecommendation: PropTypes.func,
  isUpdatingRecommendation: PropTypes.bool,
  isFetchingRecommendation: PropTypes.bool
};

const { getHotSearchKeywords, getRecommendation, updateRecommendation } = hotSearchActions;
export default connect(
  (state) => ({
    isFetchingHotSearchKeywords: state.HotSearch.isFetchingHotSearchKeywords,
    keywords: state.HotSearch.keywords,
    recommendation: state.HotSearch.recommendation,
    isFetchingRecommendation: state.HotSearch.isFetchingRecommendation,
    isUpdatingRecommendation: state.HotSearch.isUpdatingRecommendation
  }),
  { getHotSearchKeywords, getRecommendation, updateRecommendation }
)(HotSearch);
