import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import api from '../../apis/PostcodeDelivery';

function* getPostcodeSlots({ searchPattern, batchSize, pageNumber }) {
  const { success, data, error } = yield api.getSuburbDeliveryTime({
    searchPattern,
    batchSize,
    pageNumber,
  });
  if (success) yield put({ type: actions.GET_POSTCODE_DELIVERY_SUCCESS, slots: data });
  else {
    yield put({ type: actions.GET_POSTCODE_DELIVERY_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* getSinglePostcodeSlots({ postcode }) {
  const { success, data, error } = yield api.getSingleSuburbDeliveryDetail({ postcode });
  if (success) yield put({ type: actions.GET_SINGLE_POSTCODE_DELIVERY_SUCCESS, slots: data });
  else {
    yield put({ type: actions.GET_SINGLE_POSTCODE_DELIVERY_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* updateSinglePostcodeSlots({ postcode, slots }) {
  const { success, data, error } = yield api.updateSingleSuburbDeliveryDetail({ postcode, slots });
  if (success) {
    yield put({ type: actions.UPDATE_SINGLE_POSTCODE_DELIVERY_SUCCESS, slots: data });
    yield put({ type: actions.GET_POSTCODE_DELIVERY, searchPattern: '', batchSize: 20, pageNumber: 1 });
  } else {
    yield put({ type: actions.UPDATE_SINGLE_POSTCODE_DELIVERY_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* batchUploadPostcodeSlots({ timeslots }) {
  const { success, error } = yield api.batchUploadSuburbDelivery({ timeslots });
  if (success) {
    yield put({ type: actions.BATCH_UPLOAD_POSTCODE_DELIVERY_SUCCESS });
    yield put({ type: actions.GET_POSTCODE_DELIVERY, searchPattern: '', batchSize: 20, pageNumber: 1 });
  } else {
    yield put({ type: actions.BATCH_UPLOAD_POSTCODE_DELIVERY_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

export default function* productsSaga() {
  yield all([
    takeLatest(actions.GET_POSTCODE_DELIVERY, getPostcodeSlots),
    takeLatest(actions.GET_SINGLE_POSTCODE_DELIVERY, getSinglePostcodeSlots),
    takeLatest(actions.UPDATE_SINGLE_POSTCODE_DELIVERY, updateSinglePostcodeSlots),
    takeLatest(actions.BATCH_UPLOAD_POSTCODE_DELIVERY, batchUploadPostcodeSlots),
  ]);
}
