import actions from './actions';

export const unprocessedOrdersInitState = {
  isFetchingUnprocessedOrders: false,
  unprocessedOrders: [],
  isProcessingOrders: false,
  processOrdersSuccess: null
}

export default function (state = unprocessedOrdersInitState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_UNPROCESSED_ORDERS:
      return {
        ...state,
        processOrdersSuccess: null,
        isFetchingUnprocessedOrders: true
      };
    case actions.FETCH_UNPROCESSED_ORDERS_SUCCESS:
      return {
        ...state,
        isFetchingUnprocessedOrders: false,
        unprocessedOrders: action.unprocessedOrders
      };
    case actions.FETCH_UNPROCESSED_ORDERS_FAIL:
      return {
        ...state,
        isFetchingUnprocessedOrders: false
      };
    case actions.BATCH_PROCESS_ORDERS:
      return {
        ...state,
        isProcessingOrders: true,
        processOrdersSuccess: null
      };
    case actions.BATCH_PROCESS_ORDERS_SUCCESS:
      return {
        ...state,
        unprocessedOrders: [],
        isProcessingOrders: false,
        processOrdersSuccess: true
      };
    case actions.BATCH_PROCESS_ORDERS_FAIL:
      return {
        ...state,
        isProcessingOrders: false,
        processOrdersSuccess: false
      };
    default: return state;
  }
}