import React, { useRef } from 'react';
import { Form, Input, Card, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import userActions from '../../redux/User/actions';
import { toast } from 'react-toastify';
import 'antd/dist/antd.css';

const { Item } = Form;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const ChangePassword = (props) => {
  const { changePassword, isChangingPassword } = props;
  const submitChangePassword = (values) => {
    if (values.newPassword !== values.confirmNewPassword) {
      toast.error('New password inconsistent!', {
        autoClose: 3000,
      });
    } else {
      changePassword(values);
    }
  };
  const formRef = useRef();
  return (
    <Card title="Change Password">
      <Spin spinning={isChangingPassword}>
        <Form {...formItemLayout} onFinish={submitChangePassword}>
          <Item
            name="password"
            label="Old Password"
            rules={[{ required: true, message: 'Please enter old password!' }]}
          >
            <Input.Password />
          </Item>
          <Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: 'Please enter new password!' },
              { pattern: '^.{6,}$', message: 'Password should be at least 6 characters!' },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="confirmNewPassword"
            label="Confirm New Password"
            rules={[{ required: true, message: 'Please confirm new password!' }]}
          >
            <Input.Password />
          </Item>
          <Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 5 },
            }}
          >
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
            <Button
              style={{ marginLeft: '15px' }}
              onClick={() => {
                formRef && formRef.current && formRef.current.resetFields();
                window.history.back();
              }}
            >
              Cancel
            </Button>
          </Item>
        </Form>
      </Spin>
    </Card>
  );
};

ChangePassword.propTypes = {
  changePassword: PropTypes.func,
  isChangingPassword: PropTypes.bool,
};

const { changePassword } = userActions;
export default connect(
  (state) => {
    const { User } = state;
    return {
      isChangingPassword: User.isChangingPassword,
    };
  },
  { changePassword }
)(ChangePassword);
