import Axios from 'axios';
import { host, server } from '../config';

export const serverUrl = server.production;
export const hostUrl = host.local;

Axios.defaults.baseURL = serverUrl;

Axios.interceptors.request.use((config) => {
  const { method, url, data /** headers */ } = config;

  console.log(
    `${method.toUpperCase()} ${url}`,
    data || ''
    // headers.authorization || ''
  );

  return config;
});

Axios.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    console.log(status, data);

    return response;
  },
  (error) => {
    const { status, data } = error.response;

    console.log(status, data);

    if (status === 403) {
      //   toast.error("Token invalid or expired", {
      //     toastId: "nonActive",
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: false,
      //     pauseOnHover: true,
      //     draggable: true
      //   });
      localStorage.clear('token');
      localStorage.clear('instantToken');
      // TODO receive redirect url from response
      window.location.href = `${hostUrl}/#/instants`;
    } else if (status === 401) {
      //   toast.error("Missing access token", {
      //     toastId: "nonActive",
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: false,
      //     pauseOnHover: true,
      //     draggable: true
      //   });
      localStorage.clear('token');
      localStorage.clear('instantToken');
      window.location.href = `${hostUrl}/#/instants`;
    }

    return Promise.reject(error);
  }
);

export const getToken = () => {
  return `Bearer ${localStorage.getItem('token')}`;
};

export default Axios;
