import actions from './actions';
import customerCouponsReducer, { initCouponsState } from './CustomerCoupons/reducer';
import customerAddressesReducer, { initAddressesState } from './CustomerAddresses/reducer';
const initState = {
  customersData: [],
  fetchingCustomers: false,
  singleCustomer: {
    customerData: {},
    fetchingSingleCustomer: false,
    editingSingleCustomer: false,
  },
  CustomerCoupons: initCouponsState,
  CustomerAddresses: initAddressesState,
};

function customersReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_CUSTOMERS:
      return {
        ...state,
        fetchingCustomers: true,
      };
    case actions.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customersData: action.customersData,
        fetchingCustomers: false,
      };
    case actions.GET_CUSTOMERS_FAIL:
      return {
        ...state,
        fetchingCustomers: false,
      };
    case actions.GET_SINGLE_CUSTOMER:
      return {
        ...state,
        singleCustomer: {
          ...state.singleCustomer,
          fetchingSingleCustomer: true,
        },
      };
    case actions.GET_SINGLE_CUSTOMER_SUCCESS:
      return {
        ...state,
        singleCustomer: {
          ...state.singleCustomer,
          fetchingSingleCustomer: false,
          customerData: action.customerData,
        },
      };
    case actions.GET_SINGLE_CUSTOMER_FAIL:
      return {
        ...state,
        singleCustomer: {
          ...state.singleCustomer,
          fetchingSingleCustomer: false,
        },
      };
    case actions.EDIT_CUSTOMER:
      return {
        ...state,
        singleCustomer: {
          ...state.singleCustomer,
          editingSingleCustomer: true,
        },
      };
    case actions.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        singleCustomer: {
          ...state.singleCustomer,
          editingSingleCustomer: false,
          customerData: action.customerData,
        },
      };
    case action.EDIT_CUSTOMER_FAIL:
      return {
        ...state,
        singleCustomer: {
          ...state.singleCustomer,
          editingSingleCustomer: false,
        },
      };
    default:
      return state;
  }
}


export default (state = initState, { type, ...action }) => {
  return {
    ...customersReducer(state, { type, ...action }),
    CustomerCoupons: customerCouponsReducer(state.CustomerCoupons, { type, ...action }),
    CustomerAddresses: customerAddressesReducer(state.CustomerAddresses, { type, ...action }),
  };
};
