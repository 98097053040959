import { getHotSearch, updateKeywords, getKeywords } from '../../apis/HotSearch';
import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';

function* getHotSearchKeywords({ startDate, endDate, limit }) {
  const { success, data, error } = yield getHotSearch({ startDate, endDate, limit });
  if (success) {
    yield put({ type: actions.GET_HOT_SEARCH_KEYWORDS_SUCCESS, keywords: data.searches });
  } else {
    yield put({ type: actions.GET_HOT_SEARCH_KEYWORDS_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    })
  }
}

function* getKeywordsConfig() {
  const { success, data, error } = yield getKeywords();
  if (success) {
    yield put({ type: actions.GET_RECOMMENDATION_SUCCESS, recommendation: data });
  } else {
    yield put({ type: actions.GET_RECOMMENDATION_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}

function* updateKeywordsConfig({ recommendation }) {
  const { success, data, error } = yield updateKeywords({ recommendation });
  if (success) {
    yield put({
      type: actions.UPDATE_RECOMMENDATION_SUCCESS,
      recommendation: data
    });
  } else {
    yield put({ type: actions.UPDATE_RECOMMENDATION_FAIL });
    toast.error(error.toString(), {
      autoClose: 5000
    });
  }
}


export default function* hotSearchSaga() {
  yield all([
    takeLatest(actions.GET_HOT_SEARCH_KEYWORDS, getHotSearchKeywords),
    takeLatest(actions.GET_RECOMMENDATION, getKeywordsConfig),
    takeLatest(actions.UPDATE_RECOMMENDATION, updateKeywordsConfig)
  ]);
}