import { firestore } from '../utils/firebase';
const collection = firestore.collection('configs/deliveryConfig/deliverySlotsByPostcode');

const CustomizeDeliveryTimeApi = {
  getSlotsByDate: async ({ date }) => {
    try {
      const snapshot = await collection.doc(date).get();
      if (snapshot.exists) {
        return {
          success: true,
          data: snapshot.data(),
        };
      } else {
        return {
          success: true,
          data: {},
        };
      }
    } catch (error) {
      return { success: false, error };
    }
  },

  updateSlots: async ({ date, slots }) => {
    try {
      const snapshot = await collection.doc(date).get();
      await snapshot.ref.set({ ...slots });
      return { success: true };
    } catch(error) {
      return { success: false, error };
    }
  },
};

export default CustomizeDeliveryTimeApi;