import Joi from "@hapi/joi";
import AddressSchema from "./address";

export default Joi.object({
  email: Joi.string().email({ tlds: false }).allow(''),
  phoneNumber: Joi.string().allow(''),
  uid: Joi.string().required(),
  archived: Joi.bool().required(),
  createdAt: Joi.any(),
  /* Additional information of the customer */

  /* Customer ID in Stripe, some test customers may not have that attribute */
  stripeCustomerId: Joi.string(),

  userInfo: Joi.object({
    firstName: Joi.string().allow(''),
    lastName: Joi.string().allow(''),
    nickName: Joi.string().allow(''),
    gender: Joi.string().valid("male", "female", "other").allow(''),
    avatar: Joi.string().allow(''),
    provider: Joi.string(),
    birthday: Joi.string()
  }),

  /* The current cart of the customer */
  // TODO: decide the schema for this attribute
  cart: Joi.array(),

  /* The orders made by the customer */
  // TODO: decide the schema for this attribute
  orders: Joi.array(),

  /* The products that are favourited by the customer */
  // TODO: decide the schema for this attribute
  favourites: Joi.array(),

  /* For any key name, the value must follow the address schema */
  addresses: Joi.object().pattern(/.+/, AddressSchema),

  // TODO: decide the schema for this attribute
  authentication: Joi.any()


}).required();
