import React, { useState, useEffect } from 'react';
import {
  Card,
  PageHeader,
  Select,
  Calendar,
  Row,
  Col,
  Spin,
  Button,
  List
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditDeliveryTimeTable from '../../components/EditDeliveryTimeTable';
import BatchCloseSlots from '../../components/BatchCloseSlots';
import actions from '../../redux/EditDeliveryTime/actions';
import deliveryCountActions from '../../redux/DeliveryCount/actions';
import { toast } from 'react-toastify';

const { Option } = Select;

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard'
  },
  {
    path: 'customizedelivery',
    breadcrumbName: 'Customize Delivery'
  }
];

const CustomizeDeliveryContainer = (props) => {
  const {
    getSlots,
    updateSlots,
    isFetchingDeliveryCount,
    deliveryCount,
    getDeliveryCount,
    CustomizeDeliveryTime
  } = props;
  const { slots, isFetchingSlots, isUpdatingSlots } = CustomizeDeliveryTime;

  const [postcode, setPostcode] = useState(null);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [slotsDraft, setSlotsDraft] = useState(null);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    getSlots({ date: moment().format('YYYY-MM-DD') });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDeliveryCount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (JSON.stringify(slots) !== '{}') {
      setSlotsDraft({ ...slots });
      for (const key of Object.keys(slots)) {
        setPostcode(key);
        break;
      }
    } else {
      setSlotsDraft({});
      setPostcode(null);
    }
  }, [slots]);

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days');
  };

  const dateValueOnChange = (value) => {
    getSlots({ date: value.format('YYYY-MM-DD') });
    setDate(value.format('YYYY-MM-DD'));
    setModified(false);
  };

  const addSlot = ({ postcode, slot }) => {
    const newSlots = { ...slotsDraft };
    if (!slotsDraft) {
      newSlots[postcode] = [slot];
    } else if (slotsDraft[postcode]) {
      //push new slot into draft
      newSlots[postcode] = [...newSlots[postcode], slot];
    } else {
      newSlots[postcode] = [slot];
    }
    setSlotsDraft(newSlots);
    setPostcode(postcode);
    setModified(true);
  };

  const saveEdit = () => {
    // check if any two slots are the same
    for (const [key, value] of Object.entries(slotsDraft)) {
      const existedSlots = {};
      for (const slot of value) {
        const identifier = slot.start + slot.end;
        if (existedSlots[identifier]) {
          toast.error(`Postcode ${key} has duplicated slots!`, {
            autoClose: 5000
          });
          return;
        } else {
          existedSlots[identifier] = slot;
        }
      }
    }
    // set entire data of date
    updateSlots({ date, slots: slotsDraft });
    setModified(false);
  };

  const updateCurrentSlots = ({ postcode, slots }) => {
    const newSlotsDraft = { ...slotsDraft };
    if (slots.length !== 0) {
      newSlotsDraft[postcode] = slots;
    } else {
      // delete entry of this postcode
      delete newSlotsDraft[postcode];
    }
    setSlotsDraft(newSlotsDraft);
    setModified(true);
  };

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Delivery Time Settings"
        breadcrumb={{ routes }}
      ></PageHeader>
      <Spin
        spinning={isFetchingSlots || isUpdatingSlots || isFetchingDeliveryCount}
      >
        <Card
          style={{ marginTop: '15px' }}
          title="Delivery Count By Date"
          extra={[
            <Button key="1" onClick={getDeliveryCount} type="primary">
              Refresh
            </Button>
          ]}
        >
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 4,
              xl: 4,
              xxl: 4
            }}
            rowKey="date"
            dataSource={deliveryCount}
            renderItem={(item) => (
              <List.Item key={item.date}>
                <Card type="inner" title={item.date}>
                  <div>
                    <span style={{ float: 'left' }}>Total:</span>
                    <span style={{ float: 'right' }}>{item.total}</span>
                  </div>
                  <br />
                  {Object.keys(item)
                    .filter((key) => key !== 'date' && key !== 'total')
                    .map((key) => (
                      <div key={key}>
                        <div>
                          <span style={{ float: 'left' }}>{key}:</span>
                          <span style={{ float: 'right' }}>{item[key]}</span>
                        </div>
                        <br />
                      </div>
                    ))}
                </Card>
              </List.Item>
            )}
          />
        </Card>
        <Card style={{ marginTop: '15px' }} title="Customize Delivery Time">
          <Row gutter={12}>
            <Col span={8}>
              <div style={{ border: '1px solid #f0f0f0', borderRadius: '3px' }}>
                <Calendar
                  fullscreen={false}
                  onChange={dateValueOnChange}
                  disabledDate={disabledDate}
                  defaultValue={moment()}
                />
              </div>
            </Col>
            <Col span={16}>
              <div style={{ marginBottom: '20px', textAlign: 'Left' }}>
                <span style={{ fontWeight: 'bolder' }}>
                  Search By Postcode:{' '}
                </span>
                <span style={{ marginLeft: '15px' }}>
                  <Select
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    style={{ width: '30%' }}
                    onChange={(value) => setPostcode(value)}
                    placeholder="Select Postcode"
                    value={postcode}
                  >
                    {slotsDraft &&
                      Object.keys(slotsDraft).map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                  </Select>
                </span>
                {modified && (
                  <span style={{ marginLeft: '15px' }}>
                    <Button type="primary" onClick={saveEdit}>
                      Save
                    </Button>
                  </span>
                )}
              </div>
              <EditDeliveryTimeTable
                date={date}
                postcode={postcode}
                slots={slotsDraft && slotsDraft[postcode]}
                updateCurrentSlots={updateCurrentSlots}
                addSlot={addSlot}
              />
            </Col>
          </Row>
        </Card>
        {JSON.stringify(slots) !== '{}' && (
          <Card style={{ marginTop: '15px' }} title="Close / Open Slots">
            <div style={{ padding: 10 }}>
              <span style={{ color: 'green' }}>Green</span> postcode: Still
              available, <span style={{ color: 'red' }}>Red</span> postcode:
              Closed.
            </div>
            <BatchCloseSlots
              slots={slots}
              updateSlots={updateSlots}
              date={date}
            />
          </Card>
        )}
      </Spin>
    </>
  );
};

CustomizeDeliveryContainer.propTypes = {
  CustomizeDeliveryTime: PropTypes.shape({
    isFetchingSlots: PropTypes.bool,
    isUpdatingSlots: PropTypes.bool,
    slots: PropTypes.any
  }),
  getSlots: PropTypes.func,
  updateSlots: PropTypes.func,
  isFetchingDeliveryCount: PropTypes.bool,
  deliveryCount: PropTypes.array,
  getDeliveryCount: PropTypes.func
};

const { getSlots, updateSlots } = actions;
const { getDeliveryCount } = deliveryCountActions;
export default connect(
  (state) => {
    const { CustomizeDeliveryTime, DeliveryCount } = state;
    const { isFetchingDeliveryCount, deliveryCount } = DeliveryCount;
    return { CustomizeDeliveryTime, isFetchingDeliveryCount, deliveryCount };
  },
  { getSlots, updateSlots, getDeliveryCount }
)(CustomizeDeliveryContainer);
