import React, { useRef, useEffect, useState } from 'react';
import PictureWall from '../FirebaseStoragePictureWall';
import productApi from '../../apis/Products';
import moment from 'moment';
import {
  Button,
  Drawer,
  Form,
  Input,
  Spin,
  Switch,
  Select,
  InputNumber,
  DatePicker,
  TreeSelect,
  Typography,
  Tooltip
} from 'antd';
import { toast } from 'react-toastify';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import getFeatureImage from '../../utils/updateFeaturePicture';
import {
  convertToRaw,
  convertFromHTML,
  EditorState,
  ContentState
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Editor from '../../components/LongDescriptionEditor';

const { Option } = Select;
const { Item } = Form;

function getDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const dtf = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(
    date
  );

  return `${ye}-${mo}-${da}`;
}

function getImageObjects(images) {
  const result = images.map((image) => {
    const pathEntries = image.split('/');
    const uid = image;
    const name = pathEntries[pathEntries.length - 1];
    const status = 'done';
    const url = image;
    return { uid, name, status, url };
  });
  return result;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};
const ProductEditingDrawer = (props) => {
  const {
    fetchProductDetail,
    isFetchingProductDetail,
    isEditingProductDetail,
    product,
    saveEdit,
    allCategories,
    generateTreeNode,
    updatePreFlashSaleProducts
  } = props;
  const formRef = useRef();
  const [productImageList, setProductImageList] = useState([]);
  const [descriptionImageList, setDescriptionImageList] = useState([]);
  const [editorState, setEditorState] = useState('');
  useEffect(() => {
    formRef.current && !product.sku && formRef.current.resetFields();
    if (product.sku) {
      setProductImageList(
        getImageObjects(product.images ? product.images : [])
      );
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(product.longDescription)
          )
        )
      );
    }
  }, [product, fetchProductDetail]);

  /** Remove all undefined or null fields except 'expiryDate' field, as that field is nullable */
  const removeUndefinedValues = (obj) =>
    Object.keys(obj).forEach(
      (key) =>
        key !== 'expiryDate' &&
        (obj[key] === undefined || obj[key] === null) &&
        delete obj[key]
    );
  const submitEdit = (values) => {
    const rteValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (
      rteValue
        .replace(/&nbsp;/g, '')
        .replace(/<p><\/p>/g, '')
        .trim() === ''
    )
      return toast.error('Long Description is required!', { autoClose: 3000 });
    if (
      values.price < 0 ||
      values.priority < 0 ||
      values.discountPrice < 0 ||
      values.stock < 0 ||
      values.stock % 1 !== 0 ||
      values.priority % 1 !== 0
    )
      return toast.error('Price, stock, discount price or priority invalid!', {
        autoClose: 3000
      });
    if (values.discountPrice >= values.price)
      return toast.error('Discount price should be less than price!', {
        autoClose: 3000
      });
    const newProduct = {
      ...product,
      ...values,
      stock: values.stock - product.stock,
      longDescription: rteValue,
      expiryDate: values.expiryDate
        ? { seconds: parseInt(values.expiryDate.format('X')), nanoseconds: 0 }
        : null,
      images: productImageList.map((image) => image.url),
      productDescriptionImages: descriptionImageList.map((image) => image.url)
    };
    removeUndefinedValues(newProduct);
    saveEdit({ sku: product.sku, product: newProduct });
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current <= moment().endOf('day');
  };

  const productImageChangeHandler = async ({
    fileList: fileListInput,
    storageLocation
  }) => {
    const newFileList = getImageObjects(fileListInput);
    const currentFeaturePicture = formRef.current.getFieldValue(
      'featurePicture'
    );
    formRef.current.setFieldsValue({
      featurePicture: getFeatureImage(
        newFileList.map((file) => file.url),
        currentFeaturePicture
      )
    });
    if (newFileList.map((file) => file.url).includes(product.featurePicture)) {
      formRef.current.setFieldsValue({
        featurePicture: product.featurePicture
      });
    }

    const { success, error } = await productApi.updateProductImageField({
      sku: product.sku,
      images: newFileList.map((file) => file.url),
      storageLocation
    });
    if (success) {
      setProductImageList(newFileList);
    } else {
      toast.error(error, {
        autoClose: 3000
      });
    }
  };

  const descriptionImageChangeHandler = async ({
    fileList: fileListInput,
    storageLocation
  }) => {
    const newFileList = getImageObjects(fileListInput);
    const { success, error } = await productApi.updateProductImageField({
      sku: product.sku,
      images: newFileList.map((file) => file.url),
      storageLocation
    });
    if (success) {
      setDescriptionImageList(newFileList);
    } else {
      toast.error(error, {
        autoClose: 3000
      });
    }
  };

  const getNodesOnPath = ({ key, categories, path }) => {
    for (let entry of categories) {
      if (entry.id === key) {
        return [...path, key];
      }
    }
    for (let entry of categories) {
      if (entry.children.length > 0) {
        const subresult = getNodesOnPath({
          key,
          categories: entry.children,
          path: [...path, entry.id]
        });
        if (subresult) return subresult;
        continue;
      }
    }
    return null;
  };

  const categoriesChangeHandler = (selectedValues, _, extra) => {
    const preCategories = extra.preValue.map((category) => category.value);
    const rootCategory = [
      { id: 'root', name: 'root', image: '', children: allCategories }
    ];
    if (selectedValues.length <= preCategories.length) {
      //if the operation is remove a category
      formRef.current.setFieldsValue({ categories: selectedValues });
    } else {
      const newValue = selectedValues[selectedValues.length - 1]; //find out the new selected category
      const nodesOnPath = getNodesOnPath({
        key: newValue,
        categories: rootCategory,
        path: []
      }); //get all the ancestors of this category node
      nodesOnPath.shift(); //remove 'root' node from the ancestors
      let newCategories = [...preCategories, ...nodesOnPath];
      newCategories = [...new Set(newCategories)];
      formRef.current.setFieldsValue({ categories: newCategories });
    }
  };

  return (
    <Drawer
      title="Edit Product Details"
      width="70%"
      onClose={() => props.closeDrawer()}
      visible={props.visibility}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div>
          <Button
            onClick={() => props.closeDrawer()}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            form="productEditForm"
            key="preFlashSale"
            type="primary"
            onClick={() => {
              updatePreFlashSaleProducts({
                sku: product.sku,
                actionType: 'add'
              });
            }}
            style={{ marginRight: 8 }}
          >
            Add To Next Flash Sale
          </Button>
          <Button
            form="productEditForm"
            key="submit"
            type="primary"
            htmlType="submit"
            style={{ marginRight: 8 }}
          >
            Submit
          </Button>
          <Typography.Text type="danger">
            Please check the values above before submitting.
          </Typography.Text>
        </div>
      }
    >
      <Spin
        spinning={isFetchingProductDetail || isEditingProductDetail}
        styles="height: 100%"
      >
        {product.sku && (
          <Form
            id="productEditForm"
            {...formItemLayout}
            onFinish={submitEdit}
            ref={formRef}
            initialValues={{
              ...product,
              expiryDate: product.expiryDate
                ? moment(getDate(product.expiryDate.seconds), 'YYYY-MM-DD')
                : null
            }}
          >
            <Typography.Title level={3}>{`${product.sku}`}</Typography.Title>
            <Item
              name="name"
              label="Product Name"
              rules={[
                { required: true, message: "Product name can't be empty." }
              ]}
            >
              <Input placeholder="Please enter Product Name" />
            </Item>
            <Item name="chineseName" label="Chinese Name">
              <Input placeholder="Please enter Product Chinese Name" />
            </Item>
            <Item name="koreanName" label="Korean Name">
              <Input placeholder="Please enter Product Korean Name" />
            </Item>
            <Item
              label="Product Pictures"
              style={{
                border: '1px solid red',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px'
              }}
            >
              <PictureWall
                sku={product.sku}
                storageLocation="productImages"
                onFileChange={(fileList) =>
                  productImageChangeHandler({
                    fileList,
                    storageLocation: 'images'
                  })
                }
              />
            </Item>
            {productImageList.length !== 0 && (
              <Item name="featurePicture" label="Feature Picture">
                <Select>
                  {productImageList.map((file) => (
                    <Option key={file.uid} value={file.url}>
                      {file.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            )}
            <Item
              name="price"
              label="Price"
              rules={[
                { required: true, message: 'Please enter Product Price' }
              ]}
            >
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Please enter Product Price"
              />
            </Item>
            <Item name="discountPrice" label="Discount Price">
              <InputNumber
                min={0}
                style={{ width: '100%' }}
                placeholder="Please enter discount price"
              />
            </Item>
            <Item name="flashSaleLimit" label="Flash Sale Limit">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Please enter Flash Sale Limit"
                precision={0}
                min={1}
              />
            </Item>
            <Item
              name="stock"
              label="Stock"
              rules={[{ required: true, message: 'Please enter Stock Number' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Please enter Stock Number"
              />
            </Item>
            <Tooltip
              placement="top"
              title="Product with higher priority will be displayed first."
            >
              <Item
                name="priority"
                label="Priority"
                rules={[{ required: true, message: 'Please enter Priority' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Please enter Priority"
                />
              </Item>
            </Tooltip>
            <Item name="categories" label="Categories" value={['fruit']}>
              <TreeSelect
                style={{ width: '100%' }}
                multiple
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Fruit..."
                treeDefaultExpandAll
                onChange={categoriesChangeHandler}
              >
                {allCategories ? generateTreeNode(allCategories) : <></>}
              </TreeSelect>
            </Item>
            <Item name="expiryDate" label="Expiry Date">
              <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
            </Item>
            <Item
              name="shortDescription"
              label="Short Description"
              rules={[
                {
                  required: true,
                  message: "Product short description can't be empty."
                }
              ]}
            >
              <Input placeholder="Please enter Product Short Description" />
            </Item>
            <Item
              name="longDescription"
              label="Long Description"
              rules={[{ message: 'Please enter Description' }]}
            >
              <Editor
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </Item>
            <Item
              name="descriptionImages"
              label="Description Images"
              style={{
                border: '1px solid red',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px'
              }}
            >
              <PictureWall
                sku={product.sku}
                storageLocation="productDescriptionImages"
                onFileChange={(fileList, storageLocation) =>
                  descriptionImageChangeHandler({ fileList, storageLocation })
                }
              />
            </Item>
            <div
              style={{
                border: '1px solid red',
                paddingTop: '10px',
                borderRadius: '5px'
              }}
            >
              <Item
                name="archived"
                valuePropName="checked"
                label="Archived Status"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Item>
              <Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 5 }
                }}
              >
                <Typography.Text type="danger">
                  {
                    "ATTENTION: Customers won't be able to see this product if Archived Status switched on."
                  }
                </Typography.Text>
              </Item>
            </div>
          </Form>
        )}
      </Spin>
    </Drawer>
  );
};

ProductEditingDrawer.propTypes = {
  fetchProductDetail: PropTypes.func,
  isFetchingProductDetail: PropTypes.bool,
  isEditingProductDetail: PropTypes.bool,
  product: PropTypes.object,
  saveEdit: PropTypes.func,
  closeDrawer: PropTypes.func,
  visibility: PropTypes.bool,
  allCategories: PropTypes.any,
  generateTreeNode: PropTypes.func,
  updatePreFlashSaleProducts: PropTypes.func
};

export default ProductEditingDrawer;
