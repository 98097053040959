const actions = {
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAIL: 'GET_CUSTOMERS_FAIL',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  EDIT_CUSTOMER_SUCCESS: 'EDIT_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER_FAIL: 'EDIT_CUSTOMER_FAIL',
  GET_SINGLE_CUSTOMER_SUCCESS: 'GET_SINGLE_CUSTOMER_SUCCESS',
  GET_SINGLE_CUSTOMER_FAIL: 'GET_SINGLE_CUSTOMER_FAIL',
  GET_SINGLE_CUSTOMER: 'GET_SINGLE_CUSTOMER',

  getCustomers: ({ searchPattern, pageNumber, batchSize, archived }) => ({
    type: actions.GET_CUSTOMERS,
    searchPattern,
    pageNumber,
    batchSize,
    archived
  }),
  getSingleCustomer: ({ uid }) => ({
    type: actions.GET_SINGLE_CUSTOMER,
    uid
  }),
  editCustomer: ({ uid, data }) => ({
    type: actions.EDIT_CUSTOMER,
    uid,
    data
  }),
};

export default actions;
