import actions from './actions';

export const initCouponsState = {
  customerCoupons: {},
  isFetchingCustomerCoupons: false,
  isEditingCustomerCoupons: false,
};

export default function customerCouponsReducer(state = initCouponsState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_CUTSOMER_COUPONS:
      return {
        ...state,
        isFetchingCustomerCoupons: true,
      };
    case actions.FETCH_CUTSOMER_COUPONS_SUCCESS:
      return {
        ...state,
        isFetchingCustomerCoupons: false,
        customerCoupons: action.customerCoupons,
      };
    case actions.FETCH_CUTSOMER_COUPONS_FAIL:
      return {
        ...state,
        isFetchingCustomerCoupons: false,
      };
    case actions.EDIT_CUSTOMER_COUPONS:
      return {
        ...state,
        isEditingCustomerCoupons: true,
      };
    case actions.EDIT_CUSTOMER_COUPONS_SUCCESS:
      return {
        ...state,
        isEditingCustomerCoupons: false,
      };
    case actions.EDIT_CUSTOMER_COUPONS_FAIL:
      return {
        ...state,
        isEditingCustomerCoupons: false,
      };
    default:
      return state;
  }
}