import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from './actions';
import workspaceApi from '../../apis/Workspace';

function* getData() {
  const { success, data, error } = yield workspaceApi.getWorkspaceData();
  if (success) {
    yield put({ type: actions.FETCH_WORKSPACE_DATA_SUCCESS, data });
  } else {
    yield put({ type: actions.FETCH_WORKSPACE_DATA_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

export default function* workspaceSaga() {
  yield all([
    takeLatest(actions.FETCH_WORKSPACE_DATA, getData)
  ]);
}