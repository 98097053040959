const actions = {
  GET_HOT_SEARCH_KEYWORDS: 'GET_HOT_SEARCH_KEYWORDS',
  GET_HOT_SEARCH_KEYWORDS_SUCCESS: 'GET_HOT_SEARCH_KEYWORDS_SUCCESS',
  GET_HOT_SEARCH_KEYWORDS_FAIL: 'GET_HOT_SEARCH_KEYWORDS_FAIL',

  GET_RECOMMENDATION: 'GET_RECOMMENDATION',
  GET_RECOMMENDATION_SUCCESS: 'GET_RECOMMENDATION_SUCCESS',
  GET_RECOMMENDATION_FAIL: 'GET_RECOMMENDATION_FAIL',

  UPDATE_RECOMMENDATION: 'UPDATE_RECOMMENDATION',
  UPDATE_RECOMMENDATION_SUCCESS: 'UPDATE_RECOMMENDATION_SUCCESS',
  UPDATE_RECOMMENDATION_FAIL: 'UPDATE_RECOMMENDATION_FAIL',

  getHotSearchKeywords: ({ startDate, endDate, limit }) => ({
    type: actions.GET_HOT_SEARCH_KEYWORDS,
    startDate,
    endDate,
    limit
  }),

  getRecommendation: () => ({
    type: actions.GET_RECOMMENDATION
  }),

  updateRecommendation: ({ recommendation }) => ({
    type: actions.UPDATE_RECOMMENDATION,
    recommendation
  })
};

export default actions;