const transformPathToRoutes = (path) => {
  const items = path.split('/').filter((item) => item !== '');
  const routes = items.map((item) => {
    return {
      path: item.toLowerCase(),
      breadcrumbName: item.charAt(0).toUpperCase() + item.slice(1),
    };
  });
  return routes;
};

export default transformPathToRoutes;