import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Table,
  PageHeader,
  Spin,
  Card,
  Form,
  DatePicker,
  Row,
  Col,
  Popconfirm,
  message
} from 'antd';
import flashSaleActions from '../../redux/Product/FlashSaleProducts/actions';
import Image from '../../components/FirebaseImage';
import moment from 'moment';

const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard'
  },
  {
    path: 'flashsale',
    breadcrumbName: 'Flash Sale'
  }
];

const FlashSale = (props) => {
  const {
    isFetchingFlashSaleProducts,
    isFetchingPreFlashSaleProducts,
    isFetchingFlashSaleTimeConfig,
    isUpdatingFlashSaleTimeConfig,
    isUpdatingPreFlashSaleProducts,
    isStartingFlashSale,
    startFlashSaleSuccess,
    flashSaleTimeConfig,
    flashSaleProducts,
    preFlashSaleProducts,
    getFlashSaleProducts,
    getPreFlashSaleProducts,
    getFlashSaleTimeConfig,
    updateFlashSaleTimeConfig,
    updatePreFlashSaleProducts,
    startFlashSale
  } = props;

  const { nextStartTime, nextEndTime } = flashSaleTimeConfig;

  const [timeRemainingString, setTimeRemainingString] = useState('');

  useEffect(() => {
    let interval;
    if (nextStartTime) {
      interval = setInterval(() => {
        setTimeRemainingString(countdownRenderer(getDuration(nextStartTime)));
      }, 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [nextStartTime]);

  useEffect(() => {
    getFlashSaleProducts();
    getPreFlashSaleProducts();
    getFlashSaleTimeConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (startFlashSaleSuccess) {
      getFlashSaleProducts();
      getPreFlashSaleProducts();
      getFlashSaleTimeConfig();
    } // eslint-disable-next-line
  }, [startFlashSaleSuccess]);

  const getDuration = (eventTime) => {
    const currentTime = moment().valueOf();
    const diffTime = moment(eventTime).valueOf() - currentTime;
    return moment.duration(diffTime, 'milliseconds');
  };

  const countdownRenderer = (duration) => {
    const now = moment();
    const flashSaleEnds = moment(nextStartTime);
    if (now.isBefore(flashSaleEnds)) {
      return (
        duration.days() +
        ' Days ' +
        ('0' + duration.hours()).slice(-2) +
        ':' +
        ('0' + duration.minutes()).slice(-2) +
        ':' +
        ('0' + duration.seconds()).slice(-2)
      );
    } else {
      return 'Flash Sale is Over.';
    }
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment().endOf('day');
  };

  const disabledRangeTime = () => {
    return {
      disabledHours: () => range(0, 60).splice(20, 4),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56]
    };
  };

  const columns = [
    {
      title: 'Feature Picture',
      dataIndex: 'featurePicture',
      width: '5%',
      render: (data) => (
        <div style={{ height: '10%', textAlign: 'center' }}>
          <Image imageRef={data} width="80%" height="50px" />
        </div>
      )
    },
    {
      title: 'SKU',
      dataIndex: 'sku'
    },
    {
      title: 'Product Name',
      dataIndex: 'name'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (_, record) => {
        return record.discountPrice ? 'Under Discount' : `$${record.price}`;
      }
    },
    {
      title: 'Discount Price',
      dataIndex: 'discountPrice',
      render: (data) => {
        return data ? `$${data}` : 'No Discount';
      }
    },
    {
      title: 'Stock',
      dataIndex: 'stock'
    }
  ];

  const removeFromFlashSale = (record) => {
    const { sku } = record;
    updatePreFlashSaleProducts({ sku, actionType: 'remove' });
  };

  return (
    <Spin
      spinning={
        isFetchingFlashSaleProducts ||
        isFetchingPreFlashSaleProducts ||
        isFetchingFlashSaleTimeConfig ||
        isUpdatingFlashSaleTimeConfig ||
        isUpdatingPreFlashSaleProducts ||
        isStartingFlashSale
      }
    >
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Flash Sale"
        breadcrumb={{ routes }}
      />
      <Card style={{ marginTop: '15px' }} title="Next Flash Sale Operation">
        <Row gutter={16}>
          <Col span={12}>
            {JSON.stringify(flashSaleTimeConfig) !== '{}' && (
              <Form
                {...formItemLayout}
                layout="horizontal"
                initialValues={{
                  timeConfig: [moment(nextStartTime), moment(nextEndTime)]
                }}
                onFinish={(values) => {
                  let [start, end] = values.timeConfig;
                  start = start.format('YYYY-MM-DD HH:00');
                  end = end.format('YYYY-MM-DD HH:00');
                  updateFlashSaleTimeConfig({ start, end });
                }}
              >
                <Form.Item
                  label="Next Time Range"
                  name="timeConfig"
                  rules={[
                    { required: true, message: 'You must select a time range!' }
                  ]}
                >
                  <RangePicker
                    disabledDate={disabledDate}
                    disabledTime={disabledRangeTime}
                    showTime={{
                      format: 'HH:00',
                      hourStep: 6,
                      minuteStep: 60,
                      hideDisabledOptions: true
                    }}
                    format="YYYY-MM-DD HH:00"
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 16, offset: 8 }
                  }}
                >
                  <Button type="primary" htmlType="submit" shape="round">
                    Update Time Config
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
          <Col span={12}>
            <div style={{ marginBottom: 15, fontSize: 16, fontWeight: 'bold' }}>{timeRemainingString}</div>
            <Popconfirm
              title="Are you sure to start next flash sale immediately?"
              onConfirm={startFlashSale}
              onCancel={() => {
                message.info('Operation Cancelled.');
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Start Next Flash Sale</Button>
            </Popconfirm>
          </Col>
        </Row>
      </Card>
      <Table
        title={() => (
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>
            Current Flash Sale Products
          </div>
        )}
        style={{ marginTop: '15px' }}
        rowKey="sku"
        columns={[
          ...columns,
          {
            title: 'Operation',
            dataIndex: 'operation',
            // eslint-disable-next-line
            render: (_, record) => {
              return (
                <Button
                  type="link"
                  onClick={() => {
                    updatePreFlashSaleProducts({
                      sku: record.sku,
                      actionType: 'add'
                    });
                  }}
                >
                  Add to Next Flash Sale
                </Button>
              );
            }
          }
        ]}
        dataSource={flashSaleProducts}
        size="small"
        bordered
        pagination={{ pageSize: 50 }}
      />
      <Table
        title={() => (
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>
            Next Flash Sale Products
          </div>
        )}
        style={{ marginTop: '15px' }}
        rowKey="sku"
        columns={[
          ...columns,
          {
            title: 'Operation',
            dataIndex: 'operation',
            // eslint-disable-next-line
            render: (_, record) => {
              return (
                <Button
                  type="link"
                  danger
                  onClick={() => removeFromFlashSale(record)}
                >
                  Remove From Next Flash Sale
                </Button>
              );
            }
          }
        ]}
        dataSource={preFlashSaleProducts}
        size="small"
        bordered
        pagination={{ pageSize: 50 }}
      />
    </Spin>
  );
};

FlashSale.propTypes = {
  isFetchingFlashSaleProducts: PropTypes.bool,
  isFetchingPreFlashSaleProducts: PropTypes.bool,
  isFetchingFlashSaleTimeConfig: PropTypes.bool,
  isUpdatingFlashSaleTimeConfig: PropTypes.bool,
  isUpdatingPreFlashSaleProducts: PropTypes.bool,
  isStartingFlashSale: PropTypes.bool,
  startFlashSaleSuccess: PropTypes.bool,
  flashSaleTimeConfig: PropTypes.object,
  flashSaleProducts: PropTypes.array,
  preFlashSaleProducts: PropTypes.array,
  getFlashSaleProducts: PropTypes.func,
  getPreFlashSaleProducts: PropTypes.func,
  getFlashSaleTimeConfig: PropTypes.func,
  updateFlashSaleTimeConfig: PropTypes.func,
  updatePreFlashSaleProducts: PropTypes.func,
  startFlashSale: PropTypes.func
};

const {
  getFlashSaleProducts,
  getPreFlashSaleProducts,
  getFlashSaleTimeConfig,
  updateFlashSaleTimeConfig,
  updatePreFlashSaleProducts,
  startFlashSale
} = flashSaleActions;

export default connect(
  (state) => {
    const {
      isFetchingFlashSaleProducts,
      isFetchingPreFlashSaleProducts,
      isFetchingFlashSaleTimeConfig,
      isUpdatingFlashSaleTimeConfig,
      isUpdatingPreFlashSaleProducts,
      isStartingFlashSale,
      startFlashSaleSuccess,
      flashSaleTimeConfig,
      flashSaleProducts,
      preFlashSaleProducts
    } = state.Products.flashSaleProducts;
    return {
      isFetchingFlashSaleProducts,
      isFetchingPreFlashSaleProducts,
      isFetchingFlashSaleTimeConfig,
      isUpdatingFlashSaleTimeConfig,
      isUpdatingPreFlashSaleProducts,
      isStartingFlashSale,
      startFlashSaleSuccess,
      flashSaleTimeConfig,
      flashSaleProducts,
      preFlashSaleProducts
    };
  },
  {
    getFlashSaleProducts,
    getPreFlashSaleProducts,
    getFlashSaleTimeConfig,
    updateFlashSaleTimeConfig,
    updatePreFlashSaleProducts,
    startFlashSale
  }
)(FlashSale);
