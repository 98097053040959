import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader,
  Card,
  Spin,
  Upload,
  Row,
  Col,
  Button,
  message
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import actions from '../../redux/SplashScreen/actions';
import FirebaseImage from '../../components/FirebaseImage';
import { updateSplashScreenRef } from '../../apis/SplashScreen';
import { storage } from '../../utils/firebase';
import { toast } from 'react-toastify';

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard'
  },
  {
    path: 'splashscreen',
    breadcrumbName: 'Splash Screen'
  }
];

const SplashScreenConfig = (props) => {
  const {
    imageUrl,
    isFetchingImageUrl,
    getSplashScreenImage,
    updateSplashScreenImage
  } = props;

  const [fileUploading, setFileUploading] = useState(false);

  useEffect(() => {
    getSplashScreenImage();
    // eslint-disable-next-line
  }, []);

  const uploadButton = (
    <Button>
      <UploadOutlined /> Click to Upload
    </Button>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error('Image must smaller than 4MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const customRequest = async (params) => {
    const { file, onSuccess } = params;
    // Upload banner
    const imageRef = storage.ref().child(`splashscreen/${file.name}`);
    return imageRef
      .put(file)
      .then(async () => {
        const { success, data, error } = await updateSplashScreenRef({
          ref: `splashscreen/${file.name}`
        });
        if (success) {
          updateSplashScreenImage({ ref: data });
        } else {
          toast.error(error.toString(), {
            autoClose: 5000
          });
        }
        return;
      })
      .then(() => {
        onSuccess();
      })
      .catch((err) => console.log(err));
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'uploading') {
      setFileUploading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, () => {
        // setImageUrl(imageUrl);
        setFileUploading(false);
      });
    }

    if (info.file.status === 'removed') {
      // setImageUrl(null);
      setFileUploading(false);
    }

    if (info.file.status === 'error') {
      // setImageUrl(null);
      setFileUploading(false);
      toast.error('Upload Failed With Error:', {
        position: 'top-center'
      });
    }
  };

  return (
    <Spin spinning={isFetchingImageUrl || fileUploading}>
      <PageHeader
        ghost={false}
        breadcrumb={{ routes }}
        onBack={() => window.history.back()}
        title="Splash Screen Configuration"
      />
      <Card>
        <Row gutter={16} style={{ height: '600px' }}>
          <Col span={12}>
            <FirebaseImage imageRef={imageUrl} width="300px" height="580px" />
          </Col>
          <Col span={12}>
            <Upload
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleFileChange}
              customRequest={(params) =>
                customRequest({
                  ...params
                })
              }
            >
              {uploadButton}
            </Upload>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

SplashScreenConfig.propTypes = {
  imageUrl: PropTypes.string,
  isFetchingImageUrl: PropTypes.bool,
  getSplashScreenImage: PropTypes.func,
  updateSplashScreenImage: PropTypes.func
};

const { getSplashScreenImage, updateSplashScreenImage } = actions;

export default connect(
  (state) => ({
    imageUrl: state.SplashScreen.imageUrl,
    isFetchingImageUrl: state.SplashScreen.isFetchingImageUrl
  }),
  { getSplashScreenImage, updateSplashScreenImage }
)(SplashScreenConfig);
