import React from 'react';
import Proptypes from 'prop-types';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './index.css';

const NoSQLPagination = (props) => {
  const { onPrev, onNext, resultLength } = props;
  let { total, pageNumber, batchSize } = props;
  const numberFormatter = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (total) total = numberFormatter(parseInt(total, 10));
  pageNumber = pageNumber === undefined ? 1 : parseInt(pageNumber, 10);
  batchSize = parseInt(batchSize, 10);
  const isFirstPage = pageNumber === 1 ? true : false;
  const isLastPage = batchSize * pageNumber >= total || resultLength < batchSize ? true : false;
  let showStart;
  let showEnd;
  if (total) {
    showStart = numberFormatter(batchSize * (pageNumber - 1) + 1);
    showEnd = numberFormatter(isLastPage ? total : batchSize * pageNumber);
  }
  // const
  return (
    <div className="NoSQLPagination" >
      <Button
        className='prevButton'
        type="link"
        disabled={isFirstPage}
        onClick={onPrev}
      >
        <LeftOutlined /> Prev
      </Button>
      {total ? `${showStart}-${showEnd} of ${total} items  ` : ''}
      <Button
        type="link"
        className='nextButton'
        disabled={isLastPage}
        onClick={onNext}
      >
        Next <RightOutlined />
      </Button>
    </div>
  );
};

NoSQLPagination.propTypes = {
  onPrev: Proptypes.func.isRequired,
  onNext: Proptypes.func.isRequired,
  total: Proptypes.number,
  pageNumber: Proptypes.number,
  batchSize: Proptypes.number,
  resultLength: Proptypes.number,
};

export default NoSQLPagination;
