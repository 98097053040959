import React, { useState, useEffect } from 'react';
import { firestore } from '../../utils/firebase';
import { InputNumber, Button, Spin, message } from 'antd';

const getAccessPoint = async () => {
  try {
    const snapshot = await firestore.doc('configs/deliveryConfig').get();
    return { success: true, accessPoint: snapshot.data().accessPoint };
  } catch (error) {
    return { success: false, error };
  }
};

const updateAccessPoint = async ({ accessPoint }) => {
  try {
    await firestore.doc('configs/deliveryConfig').update({ accessPoint });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

const DeliveryAccessConfig = () => {
  const [accessPoint, setAccessPoint] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function syncAccessPoint() {
      setLoading(true);
      const { success, accessPoint, error } = await getAccessPoint();
      if (success) {
        setAccessPoint(accessPoint);
      } else {
        message.error(
          `Cannot get access point for delivery: ${error.toString()}`
        );
      }
      setLoading(false);
    }
    syncAccessPoint();
  }, []);

  const saveAccessPoint = async () => {
    if (accessPoint === 0) {
      return message.error('Access Point cannot be zero.');
    }
    setLoading(true);
    const { success, error } = await updateAccessPoint({ accessPoint });
    setLoading(false);
    if (success) {
      message.success('Access point updated!');
    } else {
      message.error(
        `Cannot update access point for delivery: ${error.toString()}`
      );
    }
  };

  return (
    <Spin spinning={loading}>
      <div>
        <span>Access Point:</span>
        <InputNumber
          value={accessPoint}
          onChange={setAccessPoint}
          precision={0}
          min={1}
          placeholder="Please enter delivery access point"
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          style={{ marginLeft: '15px' }}
        />
        <Button
          type="primary"
          onClick={saveAccessPoint}
          style={{ marginLeft: '15px' }}
        >
          Save
        </Button>
      </div>
    </Spin>
  );
};


export default DeliveryAccessConfig;