import Joi from "@hapi/joi";
import AddressSchema from "./address";
import DocumentRef from './documentRef';

const STATUS = ["paid", "unpaid", "pending", "processing", "delivered", "delivering"];
const DELIVERY_METHODS = ["pickup", "delivery"];

/* If customer schema needs to import this schema, import this one to
 * avoid recursive validation rules (Hopefully won't happen?) */
export const orderNoCustomerInfo = Joi.object({
  status: Joi.string()
    .valid(...STATUS)
    .required(),
  phoneNumber: Joi.string().required(),
  cart: DocumentRef,
  deliveryMethod: Joi.string()
    .valid(...DELIVERY_METHODS)
    .required(),
  address: AddressSchema,
  deliveryFee: Joi.number().required(),
  paid: Joi.boolean().required(),
  createTime: Joi.string().required(),
  updatedTime: Joi.string().required(),
  updatedBy: Joi.string().required(),
  paymentInfo: Joi.object(),
  refundInfo: Joi.object()
}).required();

export const updateOrderField = Joi.object({
  status: Joi.string().valid(...STATUS).optional(),
  deliveryAddress: Joi.any().optional(),
}).optional()

export default orderNoCustomerInfo.append({
  customer: DocumentRef,
}).required();
