import Joi from "@hapi/joi";

/** 
 * Schema for Firebase DocumentReference class 
 */
export default Joi.object({
  firestore: Joi.object().required(),
  id: Joi.string().required(),
  parent: Joi.object().required(), // The CollectionReference object
  path: Joi.string().required()
}).required();
