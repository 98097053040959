import { firestore } from '../utils/firebase';

export const getSplashScreenRef = async () => {
  try {
    const snapshot = await firestore.doc('configs/splashScreenFile').get();
    return { success: true, data: snapshot.data().location };
  } catch (error) {
    return { success: false, error };
  }
};

export const updateSplashScreenRef = async ({ ref }) => {
  try {
    const snapshot = await firestore.doc('configs/splashScreenFile').get();
    const oldLocation = snapshot.data().location;
    console.log(oldLocation);
    // delete file in storage based on oldLocation
    // await storage.ref().child(oldLocation).delete();
    // update file path in firestore
    await snapshot.ref.update({ location: ref });
    return { success: true, data: ref };
  } catch (error) {
    return { success: false, error };
  }
}
