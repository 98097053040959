import React from 'react';
import { Menu, Layout, Avatar, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { LogoutOutlined, EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Header } = Layout;

const DashboardHeader = (props) => {
  const { userInfo } = props;
  const { name } = userInfo;
  const menu = (
    <Menu>
      <Menu.Item key="changepassword">
        <Link to={'/dashboard/changepassword'}>
          <EyeOutlined style={{...styles.icon, paddingRight: '8px'}} />
          Password
        </Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={props.signOutHandler}>
        <LogoutOutlined style={styles.icon} />
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Header style={styles.header}>
      <Dropdown overlay={menu} trigger={['click']}>
        <Avatar style={styles.avatar} size="large">
          {name[0]}
        </Avatar>
      </Dropdown>
    </Header>
  );
};

const styles = {
  header: {
    background: '#fff',
    textAlign: 'right',
  },
  icon: {
    fontSize: 14,
    lineHeight: 0,
    verticalAlign: 'middle',
  },
  avatar: {
    backgroundColor: '#2599cc',
    verticalAlign: 'middle',
    textTransform: 'capitalize',
    fontSize: 22,
  },
};

DashboardHeader.propTypes = {
  signOutHandler: PropTypes.func,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
  }),
};
export default DashboardHeader;
