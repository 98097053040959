const actions = {
  GET_FLASH_SALE_PRODUCTS: 'GET_FLASH_SALE_PRODUCTS',
  GET_FLASH_SALE_PRODUCTS_SUCCESS: 'GET_FLASH_SALE_PRODUCTS_SUCCESS',
  GET_FLASH_SALE_PRODUCTS_FAIL: 'GET_FLASH_SALE_PRODUCTS_FAIL',

  GET_PRE_FLASH_SALE_PRODUCTS: 'GET_PRE_FLASH_SALE_PRODUCTS',
  GET_PRE_FLASH_SALE_PRODUCTS_SUCCESS: 'GET_PRE_FLASH_SALE_PRODUCTS_SUCCESS',
  GET_PRE_FLASH_SALE_PRODUCTS_FAIL: 'GET_PRE_FLASH_SALE_PRODUCTS_FAIL',

  GET_FLASH_SALE_TIME_CONFIG: 'GET_FLASH_SALE_TIME_CONFIG',
  GET_FLASH_SALE_TIME_CONFIG_SUCCESS: 'GET_FLASH_SALE_TIME_CONFIG_SUCCESS',
  GET_FLASH_SALE_TIME_CONFIG_FAIL: 'GET_FLASH_SALE_TIME_CONFIG_FAIL',

  UPDATE_FLASH_SALE_TIME_CONFIG: 'UPDATE_FLASH_SALE_TIME_CONFIG',
  UPDATE_FLASH_SALE_TIME_CONFIG_SUCCESS:
    'UPDATE_FLASH_SALE_TIME_CONFIG_SUCCESS',
  UPDATE_FLASH_SALE_TIME_CONFIG_FAIL: 'UPDATE_FLASH_SALE_TIME_CONFIG_FAIL',

  UPDATE_PRE_FLASH_SALE_PRODUCTS: 'UPDATE_PRE_FLASH_SALE_PRODUCTS',
  UPDATE_PRE_FLASH_SALE_PRODUCTS_SUCCESS:
    'UPDATE_PRE_FLASH_SALE_PRODUCTS_SUCCESS',
  UPDATE_PRE_FLASH_SALE_PRODUCTS_FAIL: 'UPDATE_PRE_FLASH_SALE_PRODUCTS_FAIL',

  START_FLASH_SALE: 'START_FLASH_SALE',
  START_FLASH_SALE_SUCCESS: 'START_FLASH_SALE_SUCCESS',
  START_FLASH_SALE_FAIL: 'START_FLASH_SALE_FAIL',

  getFlashSaleProducts: () => ({
    type: actions.GET_FLASH_SALE_PRODUCTS
  }),

  getPreFlashSaleProducts: () => ({
    type: actions.GET_PRE_FLASH_SALE_PRODUCTS
  }),

  getFlashSaleTimeConfig: () => ({
    type: actions.GET_FLASH_SALE_TIME_CONFIG
  }),

  updateFlashSaleTimeConfig: ({ start, end }) => ({
    type: actions.UPDATE_FLASH_SALE_TIME_CONFIG,
    start,
    end
  }),

  updatePreFlashSaleProducts: ({ sku, actionType }) => ({
    type: actions.UPDATE_PRE_FLASH_SALE_PRODUCTS,
    sku, 
    actionType
  }),

  startFlashSale: () => ({
    type: actions.START_FLASH_SALE
  })
};

export default actions;
