import { firestore } from '../utils/firebase';

const customerAddressesApi = {
  /** List products by category
   * @param {{categoryName: string, archived: string, startAt: number, batchSize: number}} params
   * @returns {{success: boolean, data: object, error?: object}}
   */
  getCustomerAddresses: async (params) => {
    const { uid } = params;
    try {
      const addressesList = {};
      const snapshot = await firestore
        .collection(`customers/${uid}/addresses`)
        .get();
      snapshot.forEach((doc) => {
        addressesList[doc.id] = doc.data();
      });
      return {
        success: true,
        data: addressesList
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },
}

export default customerAddressesApi;