import { firestore, storage } from '../utils/firebase';

const bannersApi = {
  getBanners: async () => {
    let returnData = [];
    try {
      const snapshot = await firestore.doc(`assets/homepage-recommend-sliders`).get();
      const keys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      const data = snapshot.data();
      const promiseQueue = [];
      const picUrls = {};
      keys.forEach((key) => {
        if (!data[key]) {
          return null;
        }
        if (data[key].ref.match(/^https?:/)) {
          picUrls[key] = data[key].ref;
        } else {
          promiseQueue.push(storage.ref().child(data[key].ref).getDownloadURL());
        }
        returnData.push({ ref: data[key].ref, redirect: data[key].redirect });
      });
      const results = await Promise.all(promiseQueue);
      for (let i = 0; i < returnData.length; i++) {
        returnData[i].url = results[i];
      }
      return { success: true, data: returnData };
    } catch (error) {
      return { success: false, error };
    }
  },

  changeBannerPicture: async (params) => {
    const bannerPath = 'homepage/recommend/sliders';
    const docId = 'homepage-recommend-sliders';
    const { file, index, banners, picturePosition } = params;
    // Upload banner
    const imageRef = storage.ref().child(`banners/${bannerPath}/${file.name}`);
    return imageRef
      .put(file)
      .then(function (snapshot) {
        return Promise.all([
          firestore
            .doc(`assets/${docId}`)
            .update({ [index]: { ref: imageRef.fullPath, redirect: '' } }),
          snapshot.ref.getDownloadURL(),
        ]);
      })
      .then(([, downloadURL]) => {
        const newBanners = [ ...banners ];
        newBanners[picturePosition] = {
          url: downloadURL,
          ref: imageRef.fullPath,
          redirect: '',
        };
        return { success: true, data: newBanners };
      })
      .catch((error) => {
        return { success: false, error };
      });
  },

  changeBannerCategory: async ({ bannerCategory, picturePosition, banners }) => {
    try {
      const docId = 'homepage-recommend-sliders';
      await firestore.doc(`assets/${docId}`).update({
        [picturePosition]: { ref: banners[picturePosition].ref, redirect: bannerCategory },
      });
      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  },

  deleteBannerPicture: async ({ picturePosition }) => {
    const newData = {};
    let index = 0;
    try {
      const snapshot = await firestore.doc(`assets/homepage-recommend-sliders/`).get();
      const data = snapshot.data();
      for (const i of Object.keys(data)) {
        if (parseInt(i, 10) !== picturePosition) {
          newData[index] = data[i];
          index++;
        } else {
          const pathNodes = data[i].ref.split('/');
          const fileName = pathNodes[pathNodes.length - 1];
          const imageRef = storage.ref().child(`banners/homepage/recommend/sliders/${fileName}`);
          imageRef.delete();
        }
      }
      await firestore.doc(`assets/homepage-recommend-sliders`).set(newData);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
};

export default bannersApi;
