import React from 'react';
import { Menu } from 'antd';
import Logo from '../../assets/logos/logo-light.png';
import { NavLink, useRouteMatch } from 'react-router-dom';
import {
  UserOutlined,
  ShopOutlined,
  ContainerOutlined,
  FileAddOutlined,
  BranchesOutlined,
  DollarCircleOutlined,
  BarcodeOutlined,
  CarOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  PictureOutlined,
  AccountBookOutlined,
  UnorderedListOutlined,
  DeleteRowOutlined,
  UserDeleteOutlined,
  DashboardOutlined,
  CalendarOutlined,
  FileSearchOutlined,
  FieldTimeOutlined,
  ScheduleOutlined,
  MessageOutlined,
  HistoryOutlined
} from '@ant-design/icons';

const NavSidebar = () => {
  let match = useRouteMatch();

  // Automatically highlight the chosen page
  const pageMatch = useRouteMatch('/dashboard/:page');
  let page = null;
  if (pageMatch !== null) {
    page = pageMatch.params.page;
  }

  return (
    <>
      <div className="logo" style={{ margin: '13px', textAlign: 'center' }}>
        <img src={Logo} alt="logo" width="96px" />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={['customers']}
        selectedKeys={[page]}
        mode="inline"
      >
        <Menu.Item key="workspace">
          <DashboardOutlined />
          <span>Workspace</span>
          <NavLink
            to={`${match.url}/workspace`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item key="customers">
          <UserOutlined />
          <span>Customers</span>
          <NavLink
            to={`${match.url}/customers`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.SubMenu
          key="sub1"
          title={
            <span>
              <ShopOutlined />
              Products
            </span>
          }
        >
          <Menu.Item key="products">
            <ContainerOutlined />
            <span>Product List</span>
            <NavLink
              to={`${match.url}/products`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          <Menu.Item key="newproduct">
            <BarcodeOutlined />
            <span>Add New Product</span>
            <NavLink
              to={`${match.url}/newproduct`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          <Menu.Item key="productbatchupload">
            <FileAddOutlined />
            <span>Batch Operation</span>
            <NavLink
              to={`${match.url}/productbatchupload`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          <Menu.Item key="flashsale">
            <HistoryOutlined />
            <span>Flash Sale</span>
            <NavLink
              to={`${match.url}/flashsale`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="sub4"
          title={
            <span>
              <ScheduleOutlined />
              Orders
            </span>
          }
        >
          <Menu.Item key="orders">
            <DollarCircleOutlined />
            <span>All Orders</span>
            <NavLink
              to={`${match.url}/orders`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          <Menu.Item key="unprocessed">
            <FieldTimeOutlined />
            <span>Unprocessed</span>
            <NavLink
              to={`${match.url}/unprocessed`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="banner">
          <PictureOutlined />
          <span>Banners</span>
          <NavLink
            to={`${match.url}/banner`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item key="hotsearch">
          <FileSearchOutlined />
          <span>Hot Search</span>
          <NavLink
            to={`${match.url}/hotsearch`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item key="message">
          <MessageOutlined />
          <span>Message</span>
          <NavLink
            to={`${match.url}/message`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item key="splashscreen">
          <PictureOutlined />
          <span>Splash Screen</span>
          <NavLink
            to={`${match.url}/splashscreen`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.SubMenu
          key="sub2"
          title={
            <span>
              <CarOutlined />
              Delivery
            </span>
          }
        >
          <Menu.Item key="deliverybypostcode">
            <ClockCircleOutlined />
            <span>Default Time</span>
            <NavLink
              to={`${match.url}/deliverybypostcode`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          <Menu.Item key="customizedelivery">
            <CalendarOutlined />
            <span>Delivery Slots</span>
            <NavLink
              to={`${match.url}/customizedelivery`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          <Menu.Item key="deliveryfeeupload">
            <DollarOutlined />
            <span>Delivery Fee</span>
            <NavLink
              to={`${match.url}/deliveryfeeupload`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          {/* <Menu.Item key="deliverytime">
            <ClockCircleOutlined />
            <span>Delivery Time</span>
            <NavLink
              to={`${match.url}/deliverytime`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item> */}
        </Menu.SubMenu>
        <Menu.SubMenu
          key="sub3"
          title={
            <span>
              <BranchesOutlined />
              Categories
            </span>
          }
        >
          <Menu.Item key="newcategory">
            <UnorderedListOutlined />
            <span>New Category</span>
            <NavLink
              to={`${match.url}/newcategory`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
          <Menu.Item key="editcategory">
            <DeleteRowOutlined />
            <span>Edit Category</span>
            <NavLink
              to={`${match.url}/editcategory`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="couponstemplate">
          <AccountBookOutlined />
          <span>Coupons Template</span>
          <NavLink
            to={`${match.url}/couponstemplate`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item key="admin">
          <UserDeleteOutlined />
          <span>Admin</span>
          <NavLink
            to={`${match.url}/usermanagement`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
      </Menu>
    </>
  );
};

const styles = {
  navlinkActiveStyle: {
    color: '#fff',
  },
  navlink: {
    color: '#fff',
  },
};

export default NavSidebar;
