import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import userActions from '../../redux/UserManagement/actions';
import PropTypes from 'prop-types';
import { PageHeader, Button, Spin } from 'antd';
import UsersTable from '../../components/UsersTable';
import UserEditViewDrawer from '../../components/UserEditViewDrawer';
import AddNewUserModal from '../../components/AddNewUser';
import { SyncOutlined, PlusOutlined } from '@ant-design/icons';

const UserContainer = (props) => {
  // const, var, let
  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'usermanagement',
      breadcrumbName: 'Users',
    },
  ];

  // props
  const { users, isFetchingUserList, isUpdatingSingleUser } = props.UserManagement;
  const { currentUser, fetchUserList, createUser, updateUser, resetPassword } = props;
  // states
  const [editDrawerVisibility, setEditDrawerVisibility] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  // hook
  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);
  // custom fn
  const titleExtra = [
    <Button
      key="2"
      shape="round"
      type="primary"
      onClick={() => {
        fetchUserList();
      }}
    >
      <SyncOutlined spin={isFetchingUserList} />
      Refresh
    </Button>,
    <Button
      key="1"
      shape="round"
      onClick={() => {
        setModalVisibility(true);
      }}
    >
      <PlusOutlined />
      Add New User
    </Button>,
  ];

  // render
  return (
    <div>
      <PageHeader ghost={false} title="Users" breadcrumb={{ routes }} extra={titleExtra} />
      <Spin spinning={isFetchingUserList}>
        <UsersTable
          usersData={users}
          setSingleUser={(userData) => setSingleUser(userData)}
          openEditDrawer={() => setEditDrawerVisibility(true)}
        />
        <UserEditViewDrawer
          visibility={editDrawerVisibility || isUpdatingSingleUser}
          updateUser={updateUser}
          resetPassword={resetPassword}
          user={singleUser}
          isUpdatingSingleUser={isUpdatingSingleUser}
          closeDrawer={() => {
            setEditDrawerVisibility(false);
          }}
          currentUser={currentUser}
        />
      </Spin>
      <AddNewUserModal
        visibility={modalVisibility}
        closeModal={() => {
          setModalVisibility(false);
        }}
        createUser={createUser}
      />
    </div>
  );
};

// PropTypes
UserContainer.propTypes = {
  UserManagement: PropTypes.object,
  currentUser: PropTypes.object,
  fetchUserList: PropTypes.func,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  resetPassword: PropTypes.func,
};
// mapStateToProps
const mapStateToProps = (state) => {
  const { User, UserManagement } = state || {};
  return {
    UserManagement,
    currentUser: User.userInfo,
  };
};
// mapDispatchToProps
const { fetchUserList, createUser, updateUser, resetPassword } = userActions;
const mapDispatchToProps = {
  fetchUserList,
  createUser,
  updateUser,
  resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
