import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import api from '../../apis/CustomizeDeliveryTime';

function* getSlots({ date }) {
  const { success, error, data } = yield api.getSlotsByDate({ date });
  if (success) { 
    yield put({ type: actions.GET_SLOTS_BY_DATE_SUCCESS, slots: data });
  } else {
    yield put({ type: actions.GET_SLOTS_BY_DATE_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000
    })
  }
}

function* updateSlots({ date, slots }) {
  const { success, error } = yield api.updateSlots({ date, slots });
  if (success) {
    yield put({ type: actions.UPDATE_SLOTS_BY_DATE_POSTCODE_SUCCESS });
    yield put({ type: actions.GET_SLOTS_BY_DATE, date });
  } else {
    yield put({ type: actions.UPDATE_SLOTS_BY_DATE_POSTCODE_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000
    })
  }
}


export default function* deliveryTimeSaga() {
  yield all([
    takeLatest(actions.GET_SLOTS_BY_DATE, getSlots),
    takeLatest(actions.UPDATE_SLOTS_BY_DATE_POSTCODE, updateSlots),
  ]);
}
