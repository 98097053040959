import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import customerCouponsApi from '../../../apis/CustomerCoupons';
import couponsApi from '../../../apis/Coupons';

function* getCustomerCoupons(params) {
  const { success, data, error } = yield customerCouponsApi.getCustomerCoupons({
    uid: params.uid,
  });
  if (success) {
    yield put({ type: actions.FETCH_CUTSOMER_COUPONS_SUCCESS, customerCoupons: data });
  } else {
    yield put({ type: actions.FETCH_CUTSOMER_COUPONS_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

function* assignCustomerCoupons({ uid, coupons }) {
  const { success, error } = yield couponsApi.assignCouponsToCustomer({
    input: coupons,
    customerId: uid,
  });
  if (success) {
    yield put({ type: actions.EDIT_CUSTOMER_COUPONS_SUCCESS });
    yield put({ type: actions.FETCH_CUTSOMER_COUPONS });
    toast.success('Coupon Assigned!', {
      autoClose: 2000,
    });
  } else {
    yield put({ type: actions.EDIT_CUSTOMER_COUPONS_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

export default function* customerCouponsSaga() {
  yield all([
    takeLatest(actions.FETCH_CUTSOMER_COUPONS, getCustomerCoupons),
    takeLatest(actions.EDIT_CUSTOMER_COUPONS, assignCustomerCoupons),
  ]);
}
