/* eslint-disable react/display-name */
import React, { useState, useRef } from 'react';
import { Table, Tag, Button, Typography, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';

function getTime(time) {
  const dateTime = moment(time);
  return dateTime.format('YYYY-MM-DD HH:mm:ss');
}

const UsersTable = (props) => {
  // state, hooks
  const { setSingleUser, openEditDrawer, usersData } = props;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  let searchInput = useRef();

  //custom fn
  const onStatusEdit = (_, record) => {
    openEditDrawer();
    setSingleUser(record);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: '10px' }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  //constant
  const columns = [
    {
      title: 'UID',
      dataIndex: 'uid',
      width: '15%',
      // eslint-disable-next-line react/display-name
      render: (data) => <Typography.Text copyable ellipsis>{data}</Typography.Text>,
      // ...getColumnSearchProps('uid'),
    },
    {
      title: 'Status',
      dataIndex: 'disabled',
      // eslint-disable-next-line react/display-name
      render: (data) => (data ? <Tag color="red">Disabled</Tag> : <Tag color="green">Active</Tag>),
      filters: [
        {
          text: 'Disabled',
          value: true,
        },
        {
          text: 'Active',
          value: false,
        },
      ],
      onFilter: (value, record) => record.disabled === value,
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      ...getColumnSearchProps('displayName'),
    },
    {
      title: 'email',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
    },
    {
      title: 'Created At',
      dataIndex: ['metadata', 'creationTime'],
      render: (data) => getTime(data),
    },
    {
      title: 'Last Sign In',
      dataIndex: ['metadata', 'lastSignInTime'],
      render: (data) => getTime(data),
    },
    // {
    //   title: 'Last Refresh Time',
    //   dataIndex: ['metadata', 'lastRefreshTime'],
    //   render: (data) => getTime(data),
    // },
    {
      title: 'Operation', // eslint-disable-next-line
      render: (_, record) => (
        <Button type="link" onClick={(e) => onStatusEdit(e, record)}>
          View & Edit
        </Button>
      ),
    },
  ];
  // props
  
  // render
  return (
    <div>
      <Table
        rowKey="uid"
        size="small"
        columns={columns}
        dataSource={usersData}
      />
    </div>
  );
};

// PropTypes
UsersTable.propTypes = {
  usersData: PropTypes.array,
  setSingleUser: PropTypes.func,
  openEditDrawer: PropTypes.func,
  getColumnSearchProps: PropTypes.func,
};

export default UsersTable;
