const actions = {
  CREATE_COUPON: 'CREATE_COUPON',
  CREATE_COUPON_SUCCESS: 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAIL: 'CREATE_COUPON_FAIL',

  createCoupon: ({ newCoupon }) => ({
    type: actions.CREATE_COUPON,
    newCoupon,
  }),
};

export default actions;