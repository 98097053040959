import React, { useEffect } from 'react';
import { PageHeader, Card, Spin, List, Input, Button, Form } from 'antd';
import messageActions from '../../redux/Message/actions';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 16 }
};

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard'
  },
  {
    path: 'message',
    breadcrumbName: 'Messaging'
  }
];

const MessageBroadcast = (props) => {
  const {
    isFetchingMessageList,
    isSendingMessage,
    messageList,
    getMessages,
    sendMessage
  } = props;

  useEffect(() => {
    if (messageList.length === 0) {
      getMessages();
    } // eslint-disable-next-line
  }, []);

  const submitMessage = (values) => {
    const { content, title } = values;
    sendMessage({ title, message: content });
  };

  return (
    <Spin spinning={isFetchingMessageList || isSendingMessage}>
      <PageHeader
        ghost={false}
        breadcrumb={{ routes }}
        onBack={() => window.history.back()}
        title="Messaging"
      />
      <Card title="Send a New Message">
        <Form {...layout} name="nest-messages" onFinish={submitMessage}>
          <Form.Item
            name="title"
            label="Message Title"
            rules={[
              {
                required: true,
                message: 'You must enter message title before sending.'
              }
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="content"
            label="Message Content"
            rules={[
              {
                required: true,
                message: 'You must enter message content before sending.'
              }
            ]}
          >
            <Input.TextArea allowClear />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 3 }}>
            <Button type="primary" htmlType="submit">
              Submit & Send
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Messages Sent" style={{ marginTop: '15px' }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 2
          }}
          dataSource={messageList}
          renderItem={(item) => (
            <List.Item>
              <Card
                type="inner"
                hoverable
                title={
                  typeof item.sendAt === 'string'
                    ? item.sendAt
                    : moment(item.sendAt.seconds * 1000).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                }
              >
                <>
                  {item.title && (
                    <>
                      <div>
                        <span
                          style={{
                            float: 'left',
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: 'darkblue'
                          }}
                        >
                          Message Title:
                        </span>
                        <span style={{ float: 'right' }}>{item.title}</span>
                      </div>
                      <br />
                    </>
                  )}

                  <div>
                    <span
                      style={{
                        float: 'left',
                        fontSize: 14,
                        fontWeight: 'bold',
                        color: 'darkblue'
                      }}
                    >
                      Message Content:
                    </span>
                    <span style={{ float: 'right' }}>{item.content}</span>
                  </div>
                </>
              </Card>
            </List.Item>
          )}
        />
      </Card>
    </Spin>
  );
};

MessageBroadcast.propTypes = {};

const { getMessages, sendMessage } = messageActions;

MessageBroadcast.propTypes = {
  isFetchingMessageList: PropTypes.bool,
  isSendingMessage: PropTypes.bool,
  messageList: PropTypes.array,
  getMessages: PropTypes.func,
  sendMessage: PropTypes.func
};

export default connect(
  (state) => {
    const { Message } = state;
    const { isFetchingMessageList, isSendingMessage, messageList } = Message;
    return {
      isFetchingMessageList,
      isSendingMessage,
      messageList
    };
  },
  { getMessages, sendMessage }
)(MessageBroadcast);
