import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  PageHeader,
  Card,
  Form,
  Input,
  Button,
  Upload,
  TreeSelect,
  message,
  Checkbox,
  Radio,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import transformPathToRoutes from '../../utils/transformPathToRoutes';
import categoriesActions from '../../redux/Categories/actions';
import createCategoryAction from '../../redux/Categories/NewCategory/action';
import { storage } from '../../utils/firebase';
import { toast } from 'react-toastify';
import FirebaseImage from '../../components/FirebaseImage';
import generateTreeNode from '../../utils/generateTreeNode';

const { Item } = Form;
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const Categories = (props) => {
  // props
  const { categories, isFetchingCategories, getCategories, createCategory, newCategory } = props;
  const { isAddingNewCategory } = newCategory;

  // state
  const [fileUploading, setFileUploading] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [newId, setNewId] = useState(null);
  const [visible, setVisible] = useState(true);
  const [isRootSelected, setIsRootSelected] = useState(false);
  // useEffect
  useEffect(() => {
    getCategories();
    //eslint-disbale-next-line
  }, [getCategories]);

  // useRef
  const createFormRef = useRef();

  // const, var, miscellaneous
  let match = useRouteMatch();
  const routes = transformPathToRoutes(match.path);
  const rootCategory = [{ id: 'root', name: 'root', image: '', children: categories }];
  const uploadButton = (
    <Button>
      <UploadOutlined /> Click to Upload
    </Button>
  );

  // custom fn

  const handleFileChange = (info) => {
    if (info.file.status === 'uploading') {
      setFileUploading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, () => {
        setFileUploading(false);
      });
    }

    if (info.file.status === 'removed') {
      setFileUploading(false);
    }

    if (info.file.status === 'error') {
      setFileUploading(false);
      toast.error('Upload Failed With Error', {
        position: 'top-center',
      });
    }
  };
  const customRequest = async (params) => {
    const { file, onSuccess } = params;
    // Upload banner
    const imageRef = storage.ref().child(`categories/${file.name}`);
    return imageRef
      .put(file)
      .then(() => {
        // Update local imageRef which is used for firestore category image data field
        setImageRef(`categories/${file.name}`);
        onSuccess();
      })
      .catch((err) => console.log(err));
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const submitCategoryCreateHandler = (values) => {
    const prefix = values['radio-button'] ? values['radio-button'] + '-' : '';
    const newCategory = {
      parentCategory: values.newParent,
      id: prefix + newId,
      name: values.newName.trim(),
      image: imageRef,
      visible: visible,
    };
    // console.log(newCategory);
    createCategory({ id: newCategory.id, newCategory });
    createFormRef.current.resetFields();
    setImageRef(null);
  };
  const removeImage = () => {
    // only remove the image from local state, but image still in storage
    setImageRef(null);
  };
  const syncIdWithName = (e) => {
    const name = e.target.value;
    // remove special characters
    const specialCharsRegex = /[.,/\-()%&\\!@#$^_=+*]/g;
    const id = name
      .trim()
      .replace(specialCharsRegex, '')
      .replace(/[ ]+/g, ' ') // Replace consecutive spaces
      .split(' ')
      .join('-')
      .toLowerCase();
    setNewId(id);
  };

  const handleSelectOption = (value) => {
    setIsRootSelected(value === 'root');
  };

  // render
  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="New Category"
        breadcrumb={{ routes }}
      ></PageHeader>
      <Spin spinning={isFetchingCategories || isAddingNewCategory || fileUploading}>
        <Card title="Create Category" style={{ width: '90%' }}>
          <Form {...formItemLayout} ref={createFormRef} onFinish={submitCategoryCreateHandler}>
            <Item
              label="Parent Category"
              name="newParent"
              rules={[{ required: true, message: 'Parent category cannot be empty.' }]}
              help={
                <div
                  style={{
                    fontSize: '80%',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  }}
                >
                  The maximum depth of nested category is limited to 3.
                </div>
              }
            >
              <TreeSelect
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select parent category"
                treeDefaultExpandAll
                onSelect={(value) => handleSelectOption(value)}
              >
                {categories ? generateTreeNode(rootCategory, 1) : <></>}
              </TreeSelect>
            </Item>
            <Item
              name="newName"
              label="Name"
              rules={[
                { required: true, message: 'Category name is required!' },
                {
                  pattern: '^[a-zA-Z .,/\\-()%&\\\\]*$',
                  message:
                    'Category name can only contain letters, white space and following characters: .,/\\-()%&',
                },
              ]}
            >
              <Input placeholder="Please enter category name" onChange={(e) => syncIdWithName(e)} />
            </Item>
            {isRootSelected ? (
              <Item
                name="radio-button"
                label="ID prefix"
                rules={[{ required: true, message: 'ID prefix is required!' }]}
              >
                <Radio.Group>
                  <Radio value="g">G</Radio>
                  <Radio value="h">H</Radio>
                </Radio.Group>
              </Item>
            ) : null}
            <Item label="Visible">
              <Checkbox
                checked={visible}
                onChange={() => {
                  setVisible(!visible);
                }}
              />
            </Item>
            <Item
              label="Image"
              valuePropName="fileList"
              help={
                <div
                  style={{
                    fontSize: '80%',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  }}
                >
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Resolution of Banner image for parent category should be 520x170
                  <br />
                  Resolution of Tile image for sub-category should be 154x154
                </div>
              }
            >
              <Upload
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleFileChange}
                customRequest={(params) =>
                  customRequest({
                    ...params,
                  })
                }
              >
                {uploadButton}
              </Upload>
              <Button
                disabled={imageRef === null || imageRef === ''}
                danger
                onClick={removeImage}
                style={{ marginLeft: '15px' }}
              >
                Remove Image
              </Button>
            </Item>

            <Item
              wrapperCol={
                imageRef && {
                  span: 12,
                  offset: 6,
                }
              }
            >
              <FirebaseImage imageRef={imageRef} />
            </Item>

            <Item
              wrapperCol={{
                span: 12,
                offset: 6,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ marginRight: '20px' }}>
                Create Category
              </Button>
            </Item>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

Categories.propTypes = {
  getCategories: PropTypes.func,
  categories: PropTypes.any,
  isFetchingCategories: PropTypes.bool,
  createCategory: PropTypes.func,
  newCategory: PropTypes.object,
};

const { getCategories } = categoriesActions;
const { createCategory } = createCategoryAction;
export default connect(
  (state) => {
    const { Categories } = state;
    const {
      categories,
      isFetchingCategories,
      newCategory,
      isDeletingCategory,
      isEditingCategory,
    } = Categories;
    return {
      categories,
      isFetchingCategories,
      newCategory,
      isDeletingCategory,
      isEditingCategory,
    };
  },
  { getCategories, createCategory }
)(Categories);
