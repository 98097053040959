import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { toast } from 'react-toastify';
import productApi from '../../apis/Products';
import newProductSaga from './NewProduct/saga';
import flashSaleSaga from './FlashSaleProducts/saga';

function* fetchProductList(params) {
  const { searchPattern, categories, pageNumber, batchSize, archived } = params;
  const { success, data, error } = yield productApi.getProductList({ searchPattern, categories, pageNumber, batchSize, archived });
  if (success) {
    yield put({ type: actions.FETCH_PRODUCT_LIST_SUCCESS, productList: data });
  } else {
    yield put({ type: actions.FETCH_PRODUCT_LIST_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* fetchProductDetail(params) {
  const { sku } = params;
  const { success, product, error } = yield productApi.getProductDetail({ sku });
  if (success) {
    yield put({ type: actions.FETCH_PRODUCT_DETAIL_SUCCESS, product });
  } else {
    yield put({ type: actions.FETCH_PRODUCT_DETAIL_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* editProductDetail(params) {
  const { sku, product } = params;
  const { success, newProduct, error } = yield productApi.editProductDetail({ sku, product });
  if (success) {
    yield put({ type: actions.EDIT_PRODUCT_DETAIL_SUCCESS, product: newProduct });
    toast.success('Product Details Saved.');
  } else {
    yield put({ type: actions.EDIT_PRODUCT_DETAIL_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000,
    });
  }
}

function* uploadProducts(params) {
  const { newProductList } = params;
  const { success, data, error } = yield productApi.uploadProducts({ newProductList });
  if (success) {
    yield put({ type: actions.UPLOAD_PRODUCTS_SUCCESS, numberOfProductUploaded: data });
    yield put({ type: actions.FETCH_PRODUCT_LIST });
  } else {
    yield put({ type: actions.UPLOAD_PRODUCTS_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    })
  }
}

function* bacthDeleteProducts(params) {
  const { products } = params;
  const { success, data, error } = yield productApi.bacthDeleteProducts({ products });
  if (success) {
    yield put({ type: actions.BATCH_DELETE_PRODUCTS_SUCCESS, productsDeleted: data });
    yield put({ type: actions.FETCH_PRODUCT_LIST });
  } else {
    yield put({ type: actions.BATCH_DELETE_PRODUCTS_FAIL });
    toast.error(error, {
      autoClose: 2000,
    })
  }
}

export default function* productsSaga() {
  yield all([
    newProductSaga(),
    flashSaleSaga(),
    takeLatest(actions.FETCH_PRODUCT_LIST, fetchProductList),
    takeLatest(actions.FETCH_PRODUCT_DETAIL, fetchProductDetail),
    takeLatest(actions.EDIT_PRODUCT_DETAIL, editProductDetail),
    takeLatest(actions.UPLOAD_PRODUCTS, uploadProducts),
    takeLatest(actions.BATCH_DELETE_PRODUCTS, bacthDeleteProducts),
  ]);
}
