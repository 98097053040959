import actions from './actions';

const initState = {
  isFetchingDeliveryCount: false,
  deliveryCount: []
};

function deliveryCountReducer(state = initState, { type, ...action}) {
  switch (type) {
    case actions.GET_DELIVERY_COUNT:
      return {
        ...state,
        isFetchingDeliveryCount: true,
      };
    case actions.GET_DELIVERY_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingDeliveryCount: false,
        deliveryCount: action.deliveryCount
      };
    case actions.GET_DELIVERY_COUNT_FAIL:
      return {
        ...state,
        isFetchingDeliveryCount: false
      };
    default: return state;
  }  
}

export default deliveryCountReducer;