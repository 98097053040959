import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './action';
import categoriesActions from '../actions';
import { toast } from 'react-toastify';
import categoriesApi from '../../../apis/Categories';

function* createCategory({ id, newCategory }) {
  const { success, data, error } = yield categoriesApi.createCategory({ id, newCategory });
  if (success) {
    yield put({ type: actions.CREATE_CATEGORY_SUCCESS, newCategory: data });
    toast.success('New Category Created!', {
      autoClose: 1000
    });
    yield put({ type: categoriesActions.GET_CATEGORIES });
  } else {
    yield put({ type: actions.CREATE_CATEGORY_FAIL });
    toast.error(error.toString(), {
      autoClose: 2000,
    });
  }
}

export default function* newCategorySaga() {
  yield all([takeLatest(actions.CREATE_CATEGORY, createCategory)]);
}
