const actions = {
  GET_SPLASH_SCREEN_IMAGE: 'GET_SPLASH_SCREEN_IMAGE',
  GET_SPLASH_SCREEN_IMAGE_SUCCESS: 'GET_SPLASH_SCREEN_IMAGE_SUCCESS',
  GET_SPLASH_SCREEN_IMAGE_FAIL: 'GET_SPLASH_SCREEN_IMAGE_FAIL',

  UPDATE_SPLASH_SCREEN_IMAGE: 'UPDATE_SPLASH_SCREEN_IMAGE',
  UPDATE_SPLASH_SCREEN_IMAGE_SUCCESS: 'UPDATE_SPLASH_SCREEN_IMAGE_SUCCESS',
  UPDATE_SPLASH_SCREEN_IMAGE_FAIL: 'UPDATE_SPLASH_SCREEN_IMAGE_FAIL',

  getSplashScreenImage: () => ({
    type: actions.GET_SPLASH_SCREEN_IMAGE
  }),

  updateSplashScreenImage: ({ ref }) => ({
    type: actions.UPDATE_SPLASH_SCREEN_IMAGE,
    ref
  })
};

export default actions;