const actions = {
  GET_CUSTOMER_ADDRESSES: 'GET_CUSTOMER_ADDRESSES',
  GET_CUSTOMER_ADDRESSES_SUCCESS: 'GET_CUSTOMER_ADDRESSES_SUCCESS',
  GET_CUSTOMER_ADDRESSES_FAIL: 'GET_CUSTOMER_ADDRESSES_FAIL',

  getCustomerAddresses: ({ uid }) => ({
    type: actions.GET_CUSTOMER_ADDRESSES,
    uid,
  }),
};

export default actions;
