const actions = {
  GET_SLOTS_BY_DATE: 'GET_SLOTS_BY_DATE',
  GET_SLOTS_BY_DATE_SUCCESS: 'GET_SLOTS_BY_DATE_SUCCESS',
  GET_SLOTS_BY_DATE_FAIL: 'GET_SLOTS_BY_DATE_FAIL',

  UPDATE_SLOTS_BY_DATE_POSTCODE: 'UPDATE_SLOTS_BY_DATE_POSTCODE',
  UPDATE_SLOTS_BY_DATE_POSTCODE_SUCCESS: 'UPDATE_SLOTS_BY_DATE_POSTCODE_SUCCESS',
  UPDATE_SLOTS_BY_DATE_POSTCODE_FAIL: 'UPDATE_SLOTS_BY_DATE_POSTCODE_FAIL',

  getSlots: ({ date }) => ({
    type: actions.GET_SLOTS_BY_DATE,
    date,
  }),

  updateSlots: ({ date, slots }) => ({
    type: actions.UPDATE_SLOTS_BY_DATE_POSTCODE,
    date,
    slots,
  }),
};

export default actions;