import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { ChartCard } from 'ant-design-pro/lib/Charts';
import {
  ClockCircleTwoTone,
  DollarCircleTwoTone,
  CarTwoTone,
  AccountBookTwoTone,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'ant-design-pro/dist/ant-design-pro.css';

const StatisticWall = (props) => {
  const { nextSlot, orderCount, todaysRevenue } = props;
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    let timer = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  
  return (
    <Row gutter={16}>
      <Col span={6}>
        <ChartCard
          style={{ backgroundColor: '#deffde', border: '1px solid black', borderRadius: '5px' }}
          title="Time"
          avatar={<ClockCircleTwoTone style={{ width: 56, height: 56, fontSize: 48 }} />}
          total={() => (
            <span dangerouslySetInnerHTML={{ __html: currentTime.format('MM-DD HH:mm:ss') }} />
          )}
          contentHeight={46}
        ></ChartCard>
      </Col>
      <Col span={6}>
        <ChartCard
          title="Delivery"
          style={{ backgroundColor: '#def8ff', border: '1px solid black', borderRadius: '5px' }}
          avatar={<CarTwoTone style={{ width: 56, height: 56, fontSize: 48 }} />}
          total={nextSlot}
          contentHeight={46}
        ></ChartCard>
      </Col>
      <Col span={6}>
        <ChartCard
          title="Processing Orders"
          style={{ backgroundColor: '#ffe8e8', border: '1px solid black', borderRadius: '5px' }}
          avatar={<AccountBookTwoTone style={{ width: 56, height: 56, fontSize: 48 }} />}
          total={() => (
            <span
              dangerouslySetInnerHTML={{
                __html: orderCount && orderCount.processing,
              }}
            />
          )}
          contentHeight={46}
        ></ChartCard>
      </Col>
      <Col span={6}>
        <ChartCard
          title="Today's Revenue"
          style={{ backgroundColor: '#fdffe8', border: '1px solid black', borderRadius: '5px' }}
          avatar={<DollarCircleTwoTone style={{ width: 56, height: 56, fontSize: 48 }} />}
          total={() => <span dangerouslySetInnerHTML={{ __html: todaysRevenue }} />}
          contentHeight={46}
        ></ChartCard>
      </Col>
    </Row>
  );
};

StatisticWall.propTypes = {
  nextSlot: PropTypes.any,
  orderCount: PropTypes.object,
  todaysRevenue: PropTypes.any,
};

export default StatisticWall;
