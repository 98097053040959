import { firestore } from '../utils/firebase';
import moment from 'moment';

const workspaceApi = {
  fetchTodayDeliveryTime: firestore
    .collection('configs/deliveryConfig/deliverySlotsByPostcode')
    .doc(moment().format('YYYY-MM-DD')),

  fetchOrderCount: firestore.doc('counters/orders'),

  // fetchMonthVisit:
  // get visit data from firebase analytics
  
  fetchProductsWithLowStorage: firestore
    .collection('products')
    .where('stock', '<=', 10)
    .limit(20),

  getSalesData:
    firestore
      .collection('sales')
      .where(
        'date',
        '>=',
        moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 30).format('YYYY-MM-DD')
      ),

  getWorkspaceData: () => {
    return Promise.all([
      workspaceApi.fetchTodayDeliveryTime.get(),
      workspaceApi.fetchOrderCount.get(),
      workspaceApi.fetchProductsWithLowStorage.get(),
      workspaceApi.getSalesData.get(),
    ])
      .then((resultList) => {
        const result = {};
        const deliverySlotSnapshot = resultList[0];
        if (deliverySlotSnapshot.exists) result.timeSlots = deliverySlotSnapshot.data();
        result.orderCount = resultList[1].data();
        const lowStockProducts = [];
        resultList[2].forEach((doc) => {
          const data = doc.data();
          if (data.archived === false) lowStockProducts.push(data);
        });
        result.lowStockProducts = lowStockProducts;
        result.salesData = [];
        resultList[3].forEach((doc) => result.salesData.push(doc.data()));
        return result;
      })
      .then((result) => {
        return { success: true, data: result };
      })
      .catch((error) => {
        console.log(error);
        return { success: false, error };
      });

    // return data;
  },
};

export default workspaceApi;
