import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from './actions';
import userApi from '../../apis/Users';
import newUserManagementSaga from './NewUserManagement/saga';

function* fetchUsersList() {
  const { success, data, error } = yield userApi.getUsers();
  if (success) {
    yield put({type: actions.FETCH_USERS_SUCCESS, users: data})
  } else {
    yield put({type: actions.FETCH_USERS_FAIL})
    toast.error(error.toString, {
      autoClose: 2000
    })
  }
}

function* updateUserAuth(params) {
  const { success, error } = yield userApi.updateUser(params);
  if (success) {
    yield put({ type: actions.UPDATE_USER_SUCCESS });
    toast.success("User authority updated.", {
      autoClose: 3000,
    });
    yield put({ type: actions.FETCH_USERS_LIST });
  } else {
    yield put({ type: actions.UPDATE_USER_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000
    })
  }
}

function* resetPassword({ email }) {
  const { success, error } = yield userApi.resetPassword({email});
  if (success) {
    yield put({ type: actions.RESET_PASSWORD_SUCCESS });
    toast.success("Reset password email has been sent. Please check the user's email.", {
      autoClose: 3000,
    });
  } else {
    yield put({ type: actions.RESET_PASSWORD_FAIL });
    toast.error(error.toString(), {
      autoClose: 3000
    })
  }
}


export default function* userManagementSaga() {
  yield all([
    newUserManagementSaga(),
    takeLatest(actions.FETCH_USERS_LIST, fetchUsersList),
    takeLatest(actions.UPDATE_USER, updateUserAuth),
    takeLatest(actions.RESET_PASSWORD, resetPassword)
  ]);
}