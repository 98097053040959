import Joi from "@hapi/joi";

const defaultTimeSlot = Joi.object({
  end: Joi.string().required(),
  lastOrderTime: Joi.string().required(),
  start: Joi.string().required(),
  orderCount: Joi.number().integer().min(0)
});

const dateTimeSlot = Joi.object({
  id: Joi.string().pattern(/^(\d{1,2})-(\d{1,2})-(\d{4})$/).required(),
  timeSlots: Joi.array().items(defaultTimeSlot)
});

const defaultTimeSlots = Joi.array().items(defaultTimeSlot);

const dateTimeSlots = Joi.array().items(dateTimeSlot);

export {
  defaultTimeSlot,
  defaultTimeSlots,
  dateTimeSlot,
  dateTimeSlots
}
