import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, PageHeader, Spin, Select, Input, TreeSelect, Row, Col, Typography, Card } from 'antd';
import { Collapse } from 'react-collapse';
import ProductListComponent from '../../components/ProductList';
import ProductEditingDrawer from '../../components/ProductEditingDrawer/index';
import ProductActions from '../../redux/Product/actions';
import CategoriesActions from '../../redux/Categories/actions';
import flashSaleActions from '../../redux/Product/FlashSaleProducts/actions';
import generateTreeNode from '../../utils/generateTreeNode';
import './index.scss'; //style file

const { Title } = Typography;
const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'products',
    breadcrumbName: 'Products',
  },
];
const ProductList = (props) => {
  const {
    fetchProductList,
    isFetchingProductList,
    fetchProductDetail,
    isFetchingProductDetail,
    editProductDetail,
    isEditingProductDetail,
    product,
    updatePreFlashSaleProducts
  } = props;

  const [showFilter, setShowFilter] = useState(false);
  const { total, pageNumber, products } = props.productsData;
  const { categories, getCategories } = props;
  const [treeSelectValue, setTreeSelectValue] = useState([]);
  const [searchPattern, setSearchPattern] = useState('');
  const [archived, setArchived] = useState('all');

  const [drawerVisibility, setDrawerVisibility] = useState(false);

  const batchSize = 20;
  useEffect(() => {
    if (!products) {
      fetchProductList({ searchPattern: '', pageNumber: 1, batchSize, archived, categories: [] });
      getCategories();
    } // eslint-disable-next-line
  }, []);


  const treeSelectOnChange = (value) => {
    setTreeSelectValue([...value]);
  }

  const searchPatternChangeHandler = (e) => {
    setSearchPattern(e.target.value);
  };

  const searchHandler = () => {
    fetchProductList({
      searchPattern,
      pageNumber: 1,
      batchSize,
      archived,
      categories: treeSelectValue,
    });
  };

  const archivedChangeHandler = (newArchivedStatus) => {
    setArchived(newArchivedStatus);
  };

  const resetSearchHandler = () => {
    fetchProductList({
      searchPattern: '',
      pageNumber: 1,
      batchSize,
      archived: 'all',
      categories: [],
    });
    setSearchPattern(null);
    setArchived('all');
    setTreeSelectValue([]);
  };

  const resetFilterHandler = () => {
    setArchived('all');
    setTreeSelectValue([]);
  };

  const refreshHandler = () => {
    fetchProductList({ searchPattern, pageNumber, batchSize, archived, categories: treeSelectValue });
  };

  const openDrawerHandler = (sku) => {
    fetchProductDetail({ sku });
    setDrawerVisibility(true);
  };

  const saveEditHandler = async (newProduct) => {
    await editProductDetail(newProduct);
    setDrawerVisibility(false);
    // !isEditingProductDetail && fetchProductList({ searchPattern, pageNumber, batchSize, archived, categories });
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Product"
        breadcrumb={{ routes }}
        extra={[
          <Button key="2" onClick={refreshHandler}>
            Refresh
          </Button>,
          <Link to="/dashboard/newproduct" key="1">
            <Button type="primary">Add Product</Button>
          </Link>
        ]}
      ></PageHeader>
      <Spin spinning={isFetchingProductList}>
        <Row style={{ paddingBottom: '15px' }}>
          <Col span={18}>
            <Title style={styles.filterTitle} level={4}>
              Search Products
            </Title>
            <Input
              style={{ width: '40%' }}
              placeholder="Product Name"
              value={searchPattern}
              onChange={searchPatternChangeHandler}
            />
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              onClick={searchHandler}
              shape="round"
            >
              Search
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              htmlType="button"
              onClick={resetSearchHandler}
              shape="round"
            >
              Reset
            </Button>
          </Col>
          <Col span={6} style={{ textAlign: 'right', alignSelf: 'flex-end' }}>
            <Button
              // style={{ float: 'right' }}
              shape="round"
              onClick={() => setShowFilter(!showFilter)}
            >
              {showFilter ? 'Hide Filters' : 'Show Filters'}
            </Button>
          </Col>
        </Row>
        <Collapse
          initialStyle={
            showFilter
              ? { height: 'auto', overflow: 'initial' }
              : { height: '0px', overflow: 'hidden' }
          }
          isOpened={showFilter}
        >
          <Card style={{ marginBottom: 10 }}>
            <Row>
              <Col span={12}>
                <Title style={styles.filterTitle} level={4}>
                  Category Filter
                </Title>
                <TreeSelect
                  style={{ width: '100%' }}
                  multiple
                  value={treeSelectValue}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Fruit..."
                  treeDefaultExpandAll
                  onChange={treeSelectOnChange}
                >
                  {categories ? generateTreeNode(categories) : <></>}
                </TreeSelect>
              </Col>
              <Col span={4}></Col>
              <Col span={8}>
                <Title style={styles.filterTitle} level={4}>
                  Status Filter
                </Title>
                <Select
                  style={{ width: 150 }}
                  onChange={archivedChangeHandler}
                  value={archived}
                >
                  <Select.Option value="all">Show All</Select.Option>
                  <Select.Option value="false">Show Active</Select.Option>
                  <Select.Option value="true">Show Archived</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'flex-end', paddingTop: 10 }}>
              <Col>
                <div style={{ float: 'right' }}>
                  <Button
                    shape="round"
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      fetchProductList({
                        searchPattern,
                        categories: treeSelectValue,
                        pageNumber: 1,
                        batchSize,
                        archived
                      });
                    }}
                  >
                    Apply Filters
                  </Button>
                  <Button shape="round" onClick={resetFilterHandler}>
                    Reset Filters
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Collapse>
        <ProductListComponent
          productsData={props.productsData}
          openDrawer={(sku) => openDrawerHandler(sku)}
          onPrev={() => {
            fetchProductList({
              searchPattern,
              pageNumber: pageNumber - 1,
              batchSize,
              archived,
              categories: treeSelectValue
            });
          }}
          onNext={() => {
            fetchProductList({
              searchPattern,
              pageNumber: pageNumber + 1,
              batchSize,
              archived,
              categories: treeSelectValue
            });
          }}
          total={total}
          pageSize={batchSize}
          pageNumber={pageNumber}
        />

        {drawerVisibility ? (
          <ProductEditingDrawer
            closeDrawer={() => {
              setDrawerVisibility(false);
            }}
            visibility={drawerVisibility || isEditingProductDetail}
            product={product}
            isFetchingProductDetail={isFetchingProductDetail}
            isEditingProductDetail={isEditingProductDetail}
            fetchProductDetail={fetchProductDetail}
            saveEdit={(newProduct) => {
              saveEditHandler(newProduct);
            }}
            allCategories={categories}
            generateTreeNode={generateTreeNode}
            updatePreFlashSaleProducts={updatePreFlashSaleProducts}
          />
        ) : (
          ''
        )}
      </Spin>
    </div>
  );
};

ProductList.propTypes = {
  productList: PropTypes.array,
  isFetchingProductList: PropTypes.bool,
  fetchProductList: PropTypes.func,
  fetchProductDetail: PropTypes.func,
  isFetchingProductDetail: PropTypes.bool,
  editProductDetail: PropTypes.func,
  isEditingProductDetail: PropTypes.bool,
  product: PropTypes.object,
  productsData: PropTypes.object,
  categories: PropTypes.any,
  getCategories: PropTypes.func,
  updatePreFlashSaleProducts: PropTypes.func,
}

const styles = {
  filterTitle: {
    fontSize: 16,
  },
};
const { fetchProductList, fetchProductDetail, editProductDetail } = ProductActions;
const { getCategories } = CategoriesActions;
const { updatePreFlashSaleProducts } = flashSaleActions;
export default connect(
  (state) => {
    const { Products, Categories } = state;
    const { productsData, isFetchingProductList, productDetail } = Products;
    const { categories } = Categories;
    const {
      product,
      isFetchingProductDetail,
      isEditingProductDetail
    } = productDetail;
    return {
      productsData,
      isFetchingProductList,
      product,
      isFetchingProductDetail,
      isEditingProductDetail,
      categories
    };
  },
  {
    fetchProductList,
    fetchProductDetail,
    editProductDetail,
    getCategories,
    updatePreFlashSaleProducts
  }
)(ProductList);
