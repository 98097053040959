import actions from './action';

export const newProductInitState = {
  sku: null,
  skuValidity: null,
  isCheckingSkuValidity: null,
  isAddingProduct: false,
  addedProduct: null,
  isAddProductSuccessful: false,
};

export default function newProductReducer(state = newProductInitState, { type, ...action }) {
  switch (type) {
    case actions.CHECK_SKU:
      return {
        ...state,
        isCheckingSkuValidity: true,
      };
    case actions.CHECK_SKU_SUCCESS:
      return {
        ...state,
        isCheckingSkuValidity: false,
        skuValidity: action.skuValidity,
      };
    case actions.CHECK_SKU_FAIL:
      return {
        ...state,
        isCheckingSkuValidity: false,
      };
    case actions.CHANGE_SKU:
      return {
        ...state,
        sku: action.SKU,
        isCheckingSkuValidity: null,
        skuValidity: null,
      };
    case actions.ADD_PRODUCT:
      return {
        ...state,
        isAddingProduct: true,
        addedProduct: null,
        isAddProductSuccessful: false,
      };
    case actions.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isAddingProduct: false,
        addedProduct: action.product,
        isAddProductSuccessful: true,
      };
    case actions.ADD_PRODUCT_FAIL:
      return {
        ...state,
        isAddingProduct: false,
        addedProduct: null,
        isAddProductSuccessful: false,
      };
    default:
      return state;
  }
}
