import actions from './actions';
import unprocessedOrderReducer, {
  unprocessedOrdersInitState
} from './UnprocessedOrders/reducer';

const initState = {
  ordersData: {},
  isFetchingOrdersList: false,
  singleOrder: {
    isFetchingSingleOrder: false,
    isUpdatingSingleOrder: false,
    orderData: {}
  },
  isDeletingOrder: false,
  orderDeleted: false,
  newOrders: {},
  unprocessedOrder: unprocessedOrdersInitState
};

function ordersReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_ORDERS_LIST:
      return {
        ...state,
        isFetchingOrdersList: true
      };
    case actions.FETCH_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingOrdersList: false,
        ordersData: action.ordersData
      };
    case actions.FETCH_ORDERS_LIST_FAIL:
      return {
        ...state,
        isFetchingOrdersList: false
      };
    case actions.FETCH_SINGLE_ORDER:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          isFetchingSingleOrder: true,
          orderData: {}
        }
      };
    case actions.FETCH_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          isFetchingSingleOrder: false,
          orderData: action.orderData
        }
      };
    case actions.FETCH_SINGLE_ORDER_FAIL:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          isFetchingSingleOrder: false
        }
      };
    case actions.UPDATE_ORDER_STATUS:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          isUpdatingSingleOrder: true
        }
      };
    case actions.UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          isUpdatingSingleOrder: false,
          orderData: action.orderData
        }
      };
    case actions.UPDATE_ORDER_STATUS_FAIL:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          isUpdatingSingleOrder: false
        }
      };
    case actions.DELETE_ORDER:
      return {
        ...state,
        isDeletingOrder: true,
        orderDeleted: false
      };
    case actions.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        isDeletingOrder: false,
        orderDeleted: true,
        singleOrder: {
          ...state.singleOrder,
          orderData: {}
        }
      };
    case actions.DELETE_ORDER_FAIL:
      return {
        ...state,
        isDeletingOrder: false
      };
    case actions.RECEIVE_NEW_ORDER:
      return {
        ...state,
        newOrders: { ...state.newOrders, [action.order.orderId]: action.order }
      };
    case actions.CHECK_NEW_ORDER:
      return {
        ...state,
        newOrders: action.orders
      };
    default:
      return state;
  }
}

export default (state = initState, { type, ...action }) => {
  return {
    ...ordersReducer(state, { type, ...action }),
    unprocessedOrder: unprocessedOrderReducer(state.unprocessedOrder, {
      type,
      ...action
    })
  };
};
