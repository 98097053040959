/* eslint-disable react/display-name */
import React, { useEffect, useState, useMemo } from 'react';
import { PageHeader, Row, Col, Card, Table, Typography, Spin, Empty } from 'antd';
import { ChartCard, Bar, Pie } from 'ant-design-pro/lib/Charts';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../../redux/Workspace/actions';
import StatisticWall from '../../components/StatisticWall';
import BestSellerTable from '../../components/BestSellerTable';
import 'ant-design-pro/dist/ant-design-pro.css';

const Workspace = (props) => {
  const { fetchData, isFetchingData, workspaceData, userInfo, retrivedCurrentUserData } = props;
  const { name } = userInfo;
  const { timeSlots, salesData, lowStockProducts, orderCount } = workspaceData;
  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'workplace',
      breadcrumbName: 'Workplace',
    },
  ];

  const [activeRevenueTab, setActiveRevenueTab] = useState('month');

  const contentTab = [
    {
      key: 'month',
      tab: 'Month',
    },
    {
      key: 'week',
      tab: 'Week',
    },
  ];

  useEffect(() => {
    if (retrivedCurrentUserData && JSON.stringify(userInfo) !== '{}') fetchData();
    //eslint-disable-next-line
  }, [userInfo, retrivedCurrentUserData]);

  const nextSlot = useMemo(() => {
    let slotCount = 0;
    if (timeSlots) {
      const currentTime = moment().format('HH:mm');
      for (const key of Object.keys(timeSlots)) {
        for (const slot of timeSlots[key]) {
          if (currentTime > slot.start) continue;
          else {
            slotCount++;
          }
        }
      }
    }
    if (slotCount > 0) return `${slotCount} Deliver${slotCount > 1 ? 'ies' : 'y'}`;
    return 'No Delivery Today';
  }, [timeSlots]);

  const weeklyRevenue = useMemo(() => {
    const weeklyRevenueList = [];
    const aWeekAgo = moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 7).format('YYYY-MM-DD');
    if (salesData) {
      for (const entry of salesData) {
        if (entry.date >= aWeekAgo)
          weeklyRevenueList.push({
            x: entry.date.substring(5, 10),
            y: entry.revenue,
          });
      }
      return weeklyRevenueList;
    }
    return [];
  }, [salesData]);

  const todaysRevenue = useMemo(() => {
    const today = moment().format('YYYY-MM-DD');
    if (salesData) {
      for (const entry of salesData) {
        if (entry.date === today) return '$' + entry.revenue;
      }
    }
    return '$0';
  }, [salesData]);

  const orderCountPieChartData = useMemo(() => {
    const resultList = [];
    if (orderCount)
      Object.keys(orderCount).forEach((key) => {
        if (key !== 'total') resultList.push({ x: key, y: orderCount[key] });
      });
    return resultList;
  }, [orderCount]);

  const revenueContent = {
    month:
      salesData &&
      salesData.map((obj) => {
        return { x: obj.date.substring(5, 10), y: obj.revenue };
      }),
    week: weeklyRevenue,
  };

  return (
    <>
      <PageHeader ghost={false} title={`Welcome, ${name}!`} breadcrumb={{ routes }}></PageHeader>
      <Spin spinning={isFetchingData}>
        <StatisticWall nextSlot={nextSlot} orderCount={orderCount} todaysRevenue={todaysRevenue} />

        <Row gutter={16} style={{ marginTop: '15px' }}>
          <Col span={24}>
            <Card
              title="Revenue"
              style={{ width: '100%', textAlign: 'center' }}
              tabList={contentTab}
              activeTabKey={activeRevenueTab}
              onTabChange={(key) => {
                setActiveRevenueTab(key, 'key');
              }}
            >
              {revenueContent[activeRevenueTab] && revenueContent[activeRevenueTab].length > 0 ? (
                <Bar height={200} data={revenueContent[activeRevenueTab]} />
              ) : (
                <Empty style={{ height: 200 }} />
              )}
            </Card>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: '15px' }}>
          <Col span={6}>
            <ChartCard title="Orders Count" total={orderCount && orderCount.total}>
              <Pie
                hasLegend
                subTitle="Orders Count"
                total={() => orderCount && orderCount.total}
                data={orderCountPieChartData}
                valueFormat={(val) => <span dangerouslySetInnerHTML={{ __html: val }} />}
                height={200}
              />
            </ChartCard>
          </Col>
          <Col span={10}>
            <Card>
              <BestSellerTable salesData={salesData} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Table
                title={() => <Typography.Title level={4}>Products with low stock</Typography.Title>}
                rowKey="sku"
                size="small"
                columns={[
                  { title: 'Product SKU', dataIndex: 'sku' },
                  { title: 'Name', dataIndex: 'name' },
                  {
                    title: 'Stock',
                    dataIndex: 'stock',
                    sorter: (a, b) => a.stock - b.stock,
                    render: (data) => <div style={{ color: 'red' }}>{data}</div>,
                  },
                ]}
                dataSource={lowStockProducts}
              ></Table>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

Workspace.propTypes = {
  fetchData: PropTypes.func,
  isFetchingData: PropTypes.bool,
  workspaceData: PropTypes.object,
  userInfo: PropTypes.object,
  retrivedCurrentUserData: PropTypes.bool,
};

const { fetchData } = actions;
export default connect(
  (state) => {
    const { Workspace, User } = state;
    const { isFetchingData, workspaceData } = Workspace;
    const { userInfo, retrivedCurrentUserData } = User;
    return { isFetchingData, workspaceData, userInfo, retrivedCurrentUserData };
  },
  { fetchData }
)(Workspace);
