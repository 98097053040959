import { all } from 'redux-saga/effects';
import userSaga from './User/saga';
import customersSaga from './Customers/saga';
import productsSaga from './Product/saga';
import categoriesSaga from './Categories/saga';
import ordersSaga from './Orders/saga';
import deliverySaga from './DeliveryConfig/saga';
import deliveryTimeSlotsSaga from './DeliveryTimeSlots/saga';
import couponsSaga from './Coupon/saga';
import bannersSaga from './Banners/saga';
import userManagementSaga from './UserManagement/saga'
import WorkspaceSaga from './Workspace/saga';
import PostcodeDelivery from './PostcodeDelivery/saga';
import CustomizeDeliveryTime from './EditDeliveryTime/saga';
import hotSearchSaga from './HotSearch/saga';
import deliveryCountSaga from './DeliveryCount/saga';
import messageSaga from './Message/saga';
import SplashScreenSaga from './SplashScreen/saga';

export default function* rootSaga(/** getState */) {
  yield all([
    userSaga(),
    customersSaga(),
    productsSaga(),
    ordersSaga(),
    deliverySaga(),
    deliveryTimeSlotsSaga(),
    couponsSaga(),
    categoriesSaga(),
    bannersSaga(),
    userManagementSaga(),
    WorkspaceSaga(),
    PostcodeDelivery(),
    CustomizeDeliveryTime(),
    hotSearchSaga(),
    deliveryCountSaga(),
    messageSaga(),
    SplashScreenSaga()
  ]);
}
