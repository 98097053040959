import React, { useState, useEffect, useCallback } from 'react';
import Proptypes from 'prop-types';
import { Upload, Modal, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { storage } from '../../utils/firebase';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const PicturesWall = (props) => {
  const { sku, onFileChange, storageLocation } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const retrieveImages = useCallback(async () => {
    const imageFolderRef = storage.ref().child(`${storageLocation}/${sku}/`);
    const promiseQueue = [];
    const fileList = [];
    try {
      const res = await imageFolderRef.listAll();
      res.items.forEach(function (itemRef) {
        const url = itemRef.getDownloadURL();
        promiseQueue.push(url);
        fileList.push({
          uid: itemRef.name,
          name: itemRef.name,
          ref: itemRef,
          status: 'done',
          url,
        });
      });
      const urls = await Promise.all(promiseQueue);
      let i = 0;
      for (i = 0; i < urls.length; i++) {
        fileList[i]['url'] = urls[i];
      }
      // Update file list after fetch
      setFileList(fileList);
      return;
    } catch (error) {
      console.log(error);
    }
  }, [storageLocation, sku]);

  const notifyFileChanges = useCallback(() => {
    const formatedResult = fileList.map((obj) => {
      return obj.ref.fullPath;
    });
    if (onFileChange !== undefined || onFileChange !== null) {
      onFileChange(formatedResult, storageLocation);
    }
    // Cannot add onFileChange to dependency list as it could be arrow
    // functions, which will cause rerender since every arrow function is a
    // new instance though the behaviour is the same
    // eslint-disable-next-line
  }, [fileList, storageLocation]);

  // initialize images
  useEffect(() => {
    retrieveImages();
  }, [retrieveImages]);
  useEffect(() => {
    notifyFileChanges();
  }, [notifyFileChanges]);

  const customRequest = (params) => {
    const { file, onSuccess } = params;
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`${storageLocation}/${sku}/${file.name}`);
    imageRef
      .put(file)
      .then(function () {
        onSuccess();
        retrieveImages();
      })
      .catch((err) => console.log(err));
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ file }) => {
    if (file.status === 'removed') {
      file.ref
        .delete()
        .then(function () {
          // File deleted successfully
          retrieveImages();
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  return (
    <div className="clearfix">
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={customRequest}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Typography.Text type="danger" style={{ paddingLeft: '15px' }}>
        ATTENTION: Uploading/Deleting images is effective immediately.
      </Typography.Text>
    </div>
  );
};

PicturesWall.propTypes = {
  sku: Proptypes.string.isRequired,
  onFileChange: Proptypes.func,
  storageLocation: Proptypes.string,
};
export default PicturesWall;
