import { firestore, firebase } from '../utils/firebase';
import { updateOrderField } from '../schemas/order';
import Axios from '../utils/axios';
import moment from 'moment';
let paginationCache = {};

const increment = firebase.firestore.FieldValue.increment;
const pageJumpValidator = (previousRequestCache, currentRequest) => {
  if (
    previousRequestCache.pageNumber !== undefined &&
    previousRequestCache.batchSize === currentRequest.batchSize
  ) {
    if (
      previousRequestCache.pageNumber - currentRequest.pageNumber === 1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === -1 ||
      previousRequestCache.pageNumber - currentRequest.pageNumber === 0
    ) {
      return true;
    }
  }
  return false;
};
const getOrderItem = async (params) => {
  const { id } = params;
  const itemList = [];
  const items = await firestore.collection(`orders/${id}/orderItems`).get();
  if (items.empty) {
    return null;
  }
  items.forEach((item) => {
    itemList.push({ id: item.id, ...item.data() });
  });

  return itemList;
};

const orderApi = {
  getOrderList: async (params) => {
    try {
      let totalRange = false;
      let { status, pageNumber, batchSize, searchFieldsValues } = params;
      const defaultValue = {
        pageNumber: 1,
        batchSize: 20,
        status: 'all'
      };
      const orderList = [];
      pageNumber = pageNumber || defaultValue.pageNumber;
      batchSize = batchSize || defaultValue.batchSize;
      status = status || defaultValue.status;
      let totalNumberOfRecords = undefined;

      let query = firestore.collection('orders');
      if (status !== 'all') {
        query = query.where('status', '==', status);
      }
      if (searchFieldsValues.maxPrice) {
        totalRange = true;
        query = query.where('total', '<=', searchFieldsValues.maxPrice);
      }
      if (searchFieldsValues.minPrice) {
        totalRange = true;
        query = query.where('total', '>=', searchFieldsValues.minPrice);
      }
      if (searchFieldsValues.phoneNumber) {
        query = query.where(
          'deliveryAddress.contactNumber',
          '==',
          searchFieldsValues.phoneNumber
        );
      }
      if (totalRange) query = query.orderBy('total');
      query = query.orderBy('deliveryTime', 'desc').orderBy('createdAt', 'desc');

      if (pageNumber > 1) {
        if (!pageJumpValidator(paginationCache, params)) {
          return {
            success: false,
            error: new Error('Error: Pagination Sequence Incorrect')
          };
        }
        if (pageNumber - paginationCache.pageNumber === 1) {
          query = query
            .startAfter(paginationCache.lastItemRef)
            .limit(batchSize);
        } else if (pageNumber - paginationCache.pageNumber === -1) {
          query = query
            .endBefore(paginationCache.firstItemRef)
            .limitToLast(batchSize);
        } else {
          query = query.startAt(paginationCache.firstItemRef).limit(batchSize);
        }
      } else {
        query = query.limit(batchSize);
      }

      const orderCollection = await query.get();
      if (orderCollection.empty) {
        return {
          success: true,
          data: {
            total: 0,
            pageNumber: 1,
            batchSize: paginationCache.batchSize,
            orders: []
          }
        };
      }

      let customersDataPromiseList = [];
      orderCollection.forEach((order) => {
        let orderInfo = order.data();
        customersDataPromiseList.push(
          orderInfo.customer
            .get()
            .then((customerSnapshot) => {
              orderInfo['customer'] = customerSnapshot.data();
            })
            .then(() => {
              orderList.push(orderInfo);
            })
            .catch((e) => {
              console.log(e);
            })
        );
      });

      await Promise.all(customersDataPromiseList);
      // update pagination cache
      const docRefs = orderCollection.docs;
      if (docRefs.length !== 0) {
        paginationCache = {
          firstItemRef: docRefs[0],
          lastItemRef: docRefs.slice(-1)[0],
          pageNumber,
          batchSize
        };
      }

      const counter = await firestore.doc('counters/orders').get();
      if (
        !searchFieldsValues.maxPrice &&
        !searchFieldsValues.minPrice &&
        !searchFieldsValues.phoneNumber
      ) {
        totalNumberOfRecords = counter.get(status === 'all' ? 'total' : status);
      }
      return {
        success: true,
        data: {
          total: totalNumberOfRecords,
          pageNumber: paginationCache.pageNumber,
          batchSize: paginationCache.batchSize,
          orders: orderList
        }
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  updateOrder: async (params) => {
    try {
      const { id, data } = params;
      const { error, value } = updateOrderField.validate(data);
      console.log(value);
      if (error) {
        console.log(error);
        return {
          success: false,
          error
        };
      }

      if (!id) {
        return {
          success: false,
          error: new Error(`Invalid input orderId with ${id}`)
        };
      }
      const order = await firestore.collection('orders').doc(id).get();
      if (!order.exists) {
        return {
          success: false,
          error: new Error(`Order with id ${id} does not existed`)
        };
      }
      await order.ref.update(value);
      const orderData = order.data();
      const orderDate = moment(orderData.createdAt.seconds * 1000).format(
        'YYYY-MM-DD'
      );
      const oldStatus = orderData.status;
      const orderItems = await getOrderItem({ id });
      if ('status' in value) {
        const newStatus = value.status;
        const salesEntry = await firestore.doc(`sales/${orderDate}`).get();
        const oldSalesData = salesEntry.data();
        if (
          !['paid', 'processing', 'delivering', 'delivered'].includes(newStatus)
        ) {
          value['paid'] = false;
          if (
            ['paid', 'processing', 'delivering', 'delivered'].includes(
              oldStatus
            )
          ) {
            //reduce sales revenue; deduct sales items
            const reducer = (acc, cur) => {
              acc[cur.id] -= cur.quantity;
              return acc;
            };
            await salesEntry.ref.set({
              date: orderDate,
              revenue: oldSalesData.revenue - orderData.total,
              productsSold: {
                ...orderItems.reduce(reducer, oldSalesData.productsSold)
              }
            });
          }
        } else if (
          ['paid', 'processing', 'delivering', 'delivered'].includes(
            newStatus
          ) &&
          !['paid', 'processing', 'delivering', 'delivered'].includes(oldStatus)
        ) {
          value['paid'] = true;
          // update sales: increase revenue, add items sold count
          const reducer = (acc, cur) => {
            if (acc[cur.id]) {
              acc[cur.id] += cur.quantity;
            } else {
              acc[cur.id] = cur.quantity;
            }
            return acc;
          };
          if (salesEntry.exists) {
            await salesEntry.ref.set({
              date: orderDate,
              revenue: oldSalesData.revenue + orderData.total,
              productsSold: {
                ...orderItems.reduce(reducer, oldSalesData.productsSold)
              }
            });
          } else {
            await firestore.doc(`sales/${orderDate}`).set({
              date: orderDate,
              revenue: orderData.total,
              productsSold: {
                ...orderItems.reduce(reducer, {})
              }
            });
          }
        }
        //Update counter
        firestore
          .doc('counters/orders')
          .get()
          .then((counter) => {
            counter.ref.update({
              [oldStatus]: increment(-1),
              [newStatus]: increment(1)
            });
          })
          .catch((error) => {
            return {
              success: false,
              error
            };
          });
      }
      const currentData = await firestore.collection('orders').doc(id).get();
      return {
        success: true,
        data: currentData.data()
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error
      };
    }
  },

  getOrder: async (params) => {
    try {
      const { id } = params;
      if (!id || typeof id !== 'string') {
        return {
          success: false,
          error: new Error(`Invalid input id of ${id}`)
        };
      }

      const order = await firestore.collection('orders').doc(id).get();
      if (!order.exists) {
        return {
          success: false,
          error: new Error(`Order with id ${id} does not existed`)
        };
      }
      let orderInfo = order.data();
      await orderInfo.customer
        .get()
        .then((customerSnapshot) => {
          orderInfo['customer'] = customerSnapshot.data();
        })
        .catch((e) => {
          console.log(e);
        });
      let orderItems = await getOrderItem({ id });
      return {
        success: true,
        data: {
          id: order.id,
          order: {
            ...orderInfo,
            orderItems
          }
        }
      };
    } catch (error) {
      return {
        success: false,
        error
      };
    }
  },

  deleteOrder: async ({ order }) => {
    const orderUrl =
      'https://asia-northeast1-helen-ecommerce.cloudfunctions.net/resources/orders';
    // const orderUrl = 'http://localhost:5001/helen-ecommerce/asia-northeast1/resources/orders';
    try {
      const result = await Axios.delete(`${orderUrl}/${order.orderId}`);
      if (result.data.success) {
        return { success: true };
      }
    } catch (error) {
      return { success: false, error };
    }
  },

  getUnprocessedOrderDetails: async (daysFromNow) => {
    const startTimeString =
      moment().add(daysFromNow, 'days').format('YYYY-MM-DD') + ' 00:00';
    const endTimeString =
      moment().add(daysFromNow, 'days').format('YYYY-MM-DD') + ' 23:59';
    try {
      const deliveryOrders = await firestore
        .collection('orders')
        .where('status', '==', 'paid')
        .where('deliveryTime', '>=', startTimeString)
        .where('deliveryTime', '<=', endTimeString)
        .orderBy('deliveryTime')
        .orderBy('createdAt', 'desc')
        .limit(20)
        .get();
      if (deliveryOrders.empty) {
        // do nothing here, return empty array
        return { success: true, data: [] };
      }
      const promiseList = [];
      deliveryOrders.forEach((order) => {
        let orderData = order.data();
        const { orderId } = orderData;
        promiseList.push(
          getOrderItem({ id: orderId })
            .then((orderItems) => {
              orderData = {
                ...orderData,
                orderItems
              };
              return orderData;
            })
            .catch((error) => {
              console.log(error);
            })
        );
      });
      const orders = await Promise.all(promiseList);
      return { success: true, data: orders };
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  },

  batchUpdateOrders: async (orders) => {
    try {
      // const updateList = [];
      const orderCount = orders.length;
      const updateList = orders.map((order) =>
        firestore
          .doc(`orders/${order.orderId}`)
          .update({ status: 'processing' })
      );
      await Promise.all([
        ...updateList,
        firestore.doc('counters/orders').update({
          paid: increment(0 - orderCount),
          processing: increment(orderCount)
        })
      ]);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },

  getDeliveryCountByDate: async () => {
    try {
      const todayDateString = moment().format('YYYY-MM-DD');
      const deliverySnapshot = await firestore
        .collection('deliveryByDate')
        .where(firebase.firestore.FieldPath.documentId(), '>=', todayDateString)
        .get();
      const data = [];
      deliverySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), date: doc.id });
      });
      return { success: true, data };
    } catch (error) {
      return { success: false, error };
    }
  }
};

export default orderApi;
