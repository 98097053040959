import React from "react";
import PropTypes from 'prop-types';
import logoLightImg from "../../assets/logos/logo-light.png";
import logoDarkImg from "../../assets/logos/logo-dark.png";

const Logo = props => {
  return (
    <div style={styles.logoContainer} className="logo">
      <img style={{...styles.imgStyle, ...props.style}} src={props.dark? logoDarkImg : logoLightImg} alt="logo" width="200px" />
    </div>
  );
};
const styles = {
  imgStyle: {
    borderRadius: 10,
  },
  logoContainer: {
    textAlign: 'center',
    margin: '-3px 0px'
  }
}

Logo.propTypes = {
  dark: PropTypes.bool,
  style: PropTypes.object
}

export default Logo;