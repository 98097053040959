import React, { useEffect } from 'react';
import NavSidebar from '../NavSidebar';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import orderActions from '../../redux/Orders/actions';
import './index.css';
import { firestore } from '../../utils/firebase';
import Notification from '../../containers/NewOrderNotification';
import DashboardHeader from '../../components/DashboardHeader';
import Customers from '../../containers/Customers/index';
import AddNewProduct from '../../containers/AddNewProduct';
import Products from '../../containers/Products';
// import Categories from '../../containers/Categories';
import BannerManagement from '../../containers/BannerPictures/index';
import DeliveryFeeUpload from '../../containers/DeliveryFeeUpload';
import ProductBatchUpload from '../../containers/ProductBatchUpload';
// import DeliveryTimeSlots from '../../containers/DeliveryTimeSlots';
import CouponsContainer from '../../containers/Coupons';
import EditCategory from '../../containers/EditCategory';
import AddNewCategory from '../../containers/AddNewCategory';
import OrdersContainer from '../../containers/Orders';
import UserContainer from '../../containers/Users';
import PropTypes from 'prop-types';
import ChangePassword from '../../containers/ChangePassword';
import Workspace from '../../containers/Workplace';
import PageNotFound from '../Exception/404';
import PostcodeDelivery from '../../containers/DeliveryByPostcode';
import CustomizeDelivery from '../../containers/CustomizeDeliveryTime';
import HotSearch from '../../containers/HotSearch';
import UnprocessedOrders from '../../containers/UnprocessedOrders';
import MessageBroadcast from '../../containers/MessageBroadcast';
import FlashSale from '../../containers/FlashSale';
import SplashScreen from '../../containers/SplashScreen';
const { Content, Footer, Sider } = Layout;

const Dashboard = (props) => {
  const doc = firestore.doc('counters/orders');
  const { userInfo, signOutHandler, receiveNewOrder } = props;

  let orderCount;

  // const [orderCount, setOrderCount] = useState(-1);

  useEffect(() => {
    const getTotal = async () => {
      const snapshot = await doc.get();
      const { paid } = snapshot.data(); //eslint-disable-next-line
      if (userInfo && JSON.stringify(userInfo) !== '{}') orderCount = paid;
    };

    getTotal();
  }, [userInfo]);

  useEffect(() => {
    let subscribe;
    if (userInfo && JSON.stringify(userInfo) !== '{}') {
      subscribe = doc.onSnapshot(async (snapshot) => {
        const { paid } = snapshot.data();
        if (orderCount && paid > orderCount) {
          //get order details
          const query = firestore.collection('orders').where('status', '==', 'paid').orderBy('createdAt', 'desc').limit(1);
          const orderSnapshot = await query.get();
          orderSnapshot.forEach(async (document) => {
            const order = document.data();
            const { orderId } = order;
            const [customerSnapshot, itemsSnapshot] = await Promise.all([
              order.customer.get(),
              firestore.collection(`orders/${orderId}/orderItems`).get(),
            ]);
            order.customer = customerSnapshot.data();
            const orderItems = [];
            !itemsSnapshot.empty &&
              itemsSnapshot.forEach((item) => orderItems.push({ id: item.id, ...item.data() }));
            order.orderItems = orderItems;
            receiveNewOrder({ order });
          });
        }
        //eslint-disable-next-line
        orderCount = paid;
      });
    }
    return () => {
      if (subscribe) return subscribe();
    };
  }, [orderCount, userInfo]);

  let match = useRouteMatch();
  return JSON.stringify(userInfo) !== '{}' ? (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <NavSidebar />
      </Sider>
      <Layout className="site-layout">
        <DashboardHeader userInfo={userInfo} signOutHandler={signOutHandler} />
        <Notification />
        <Content style={{ margin: '10px 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Switch>
              {/* <Route path={`${match.url}/testplayground`} component={TestPlayground} /> */}
              <Route path={`${match.url}/customers`} component={Customers} />
              <Route path={`${match.url}/products`} component={Products} />
              <Route path={`${match.url}/newproduct`} component={AddNewProduct} />
              <Route path={`${match.url}/orders`} component={OrdersContainer} />
              <Route path={`${match.url}/unprocessed`} component={UnprocessedOrders} />
              <Route path={`${match.url}/editcategory`} component={EditCategory} />
              <Route path={`${match.url}/newcategory`} component={AddNewCategory} />
              <Route path={`${match.url}/banner`} component={BannerManagement} />
              <Route path={`${match.url}/deliveryfeeupload`} component={DeliveryFeeUpload} />
              <Route path={`${match.url}/flashsale`} component={FlashSale} />
              <Route path={`${match.url}/productbatchupload`} component={ProductBatchUpload} />
              <Route path={`${match.url}/couponstemplate`} component={CouponsContainer} />
              <Route path={`${match.url}/usermanagement`} component={UserContainer} />
              <Route path={`${match.url}/changepassword`} component={ChangePassword} />
              <Route path={`${match.url}/workspace`} component={Workspace} />
              <Route path={`${match.url}/deliverybypostcode`} component={PostcodeDelivery} />
              <Route path={`${match.url}/customizedelivery`} component={CustomizeDelivery} />
              <Route path={`${match.url}/hotsearch`} component={HotSearch} />
              <Route path={`${match.url}/message`} component={MessageBroadcast} />
              <Route path={`${match.url}/splashscreen`} component={SplashScreen} />
              <Route path={`${match.url}`} render={() => <Redirect to="workspace" />} />
              <Route render={() => <PageNotFound />} />
            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>AIBuild © 2020</Footer>
      </Layout>
    </Layout>
  ) : (
    <Redirect to={'/login'} />
  );
};

Dashboard.propTypes = {
  userInfo: PropTypes.any,
  signOutHandler: PropTypes.func,
  receiveNewOrder: PropTypes.func,
};

const { receiveNewOrder } = orderActions;

export default connect(null, { receiveNewOrder })(Dashboard);
