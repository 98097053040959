import Axios from '../utils/axios';
import { searchClient } from '../config';
import { firestore } from '../utils/firebase';
import moment from 'moment';

const apiKey = searchClient['X-Algolia-API-Key'];
const appId = searchClient.appId;

export const getHotSearch = async ({ startDate, endDate, limit }) => {
  startDate = startDate || moment().add(-7, 'days').format('YYYY-MM-DD');
  endDate = endDate || moment().format('YYYY-MM-DD');
  limit = limit || 30;
  const SEARCH_URL = `https://analytics.algolia.com/2/searches?index=PRODUCTS_prod&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;

  const response = await Axios.get(SEARCH_URL, {
    headers: {
      'X-Algolia-API-Key': apiKey,
      'X-Algolia-Application-Id': appId
    }
  });
  if (response.status === 200) 
    return { success: true, data: response.data };
  else {
    return { success: false, error: response.data };
  }
};

export const updateKeywords = async ({ recommendation }) => {
  try {
    const snapshot = await firestore.doc('configs/hotSearchKeywords').get();
    await snapshot.ref.update({ words: recommendation });
    return { success: true, data: recommendation };
  } catch (error) {
    return { success: false, error };
  }
}; 

export const getKeywords = async () => {
  try {
    const snapshot = await firestore.doc('configs/hotSearchKeywords').get();
    const { words } = snapshot.data();
    return { success: true, data: words };
  } catch (error) {
    return { success: false, error };
  }
}
