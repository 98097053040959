import React, { useEffect, useState } from 'react';
import { Checkbox, Button } from 'antd';
import PropTypes from 'prop-types';

const BatchCloseSlots = (props) => {
  const { slots, updateSlots, date } = props;
  const [slotDelivery, setSlotDelivery] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState({});

  const generateSlotsArray = (slots) => {
    const result = {};
    for (const postcode of Object.keys(slots)) {
      for (const slot of slots[postcode]) {
        const label = (
          <span style={{ color: slot.available ? 'green' : 'red' }}>
            {postcode}
          </span>
        );
        if (result[slot.start + '-' + slot.end])
          result[slot.start + '-' + slot.end].push({ label, value: postcode });
        else result[slot.start + '-' + slot.end] = [{ label, value: postcode }];
      }
    }
    return result;
  };

  useEffect(() => {
    setSelectedSlots({});
    if (JSON.stringify(slots) !== '{}')
      setSlotDelivery(generateSlotsArray(slots));
  }, [slots]);

  const selectOnChange = (checkedValues, postcode) => {
    const newSelectedSlots = { ...selectedSlots };
    if (checkedValues.length === 0) delete newSelectedSlots[postcode];
    else newSelectedSlots[postcode] = checkedValues;
    setSelectedSlots(newSelectedSlots);
  };

  const setAvailable = (status) => {
    if (JSON.stringify(selectedSlots) === '{}') return;
    const slotsDraft = { ...slots };
    Object.keys(selectedSlots).forEach((timeRange) => {
      const [start, end] = timeRange.split('-');
      for (const postcode of selectedSlots[timeRange]) {
        for (let i in slotsDraft[postcode]) {
          if (
            slotsDraft[postcode][i].start === start &&
            slotsDraft[postcode][i].end === end
          ) {
            slotsDraft[postcode][i].available = status;
            break;
          }
        }
      }
    });
    setSelectedSlots({});
    updateSlots({ date, slots: slotsDraft });
  };

  return (
    <>
      {slotDelivery &&
        Object.keys(slotDelivery).map((key) => (
          <div key={key} style={{ marginTop: 15 }}>
            <div style={{ marginBottom: 15 }}>
              <span style={{ fontSize: 22, fontWeight: 'bold' }}>{key}</span>
              <Button
                shape="round"
                size="small"
                style={{ float: 'right' }}
                onClick={() => {
                  const newSelectedSlots = { ...selectedSlots };
                  newSelectedSlots[key] = [];
                  setSelectedSlots(newSelectedSlots);
                }}
              >
                Unselect All
              </Button>
              <Button
                shape="round"
                type="primary"
                size="small"
                style={{ float: 'right', marginRight: 15 }}
                onClick={() => {
                  const newSelectedSlots = { ...selectedSlots };
                  newSelectedSlots[key] = slotDelivery[key].map(
                    (entry) => entry.value
                  );
                  setSelectedSlots(newSelectedSlots);
                }}
              >
                Select All
              </Button>
            </div>
            <Checkbox.Group
              value={selectedSlots[key]}
              options={slotDelivery[key]}
              onChange={(checkedValues) => selectOnChange(checkedValues, key)}
            />
          </div>
        ))}
      {JSON.stringify(selectedSlots) !== '{}' && (
        <div style={{ marginTop: 15 }}>
          <Button type="primary" danger onClick={() => setAvailable(false)}>
            Close Selected
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            type="primary"
            onClick={() => setAvailable(true)}
          >
            Open Selected
          </Button>
        </div>
      )}
    </>
  );
};

BatchCloseSlots.propTypes = {
  slots: PropTypes.object,
  updateSlots: PropTypes.func,
  date: PropTypes.string
};

export default BatchCloseSlots;
