import React from 'react';
import { Alert, Modal, Button, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

const DeliveryFeeMessage = (props) => {
  const {
    errorMessage,
    feeObjects,
    feeUploaded,
    resetFeeObjects,
    resetErrorMessage,
    modalVisibility,
    closeModal,
  } = props;
  return (
    <>
      <div style={{ paddingTop: '15px' }}>
        {feeObjects.length > 0 && (
          <Alert
            message={`${feeObjects.length} delivery fee${
              feeObjects.length === 1 ? ' is' : 's are'
            } ready to upload:`}
            type="success"
            closable
            showIcon
            description={feeObjects.map((feeObject) => feeObject.postcode).join(', ')}
          />
        )}
      </div>
      <div style={{ paddingTop: '15px' }}>
        {JSON.stringify(errorMessage) !== '[]' && (
          <Alert
            message="Data validation error:"
            type="error"
            closable
            showIcon
            description={
              errorMessage.length !== 0 &&
              errorMessage.map((message) => (
                <div key={message}>
                  <Typography.Text type="danger">{message}</Typography.Text>
                </div>
              ))
            }
          />
        )}
      </div>
      <Modal
        visible={modalVisibility}
        onCancel={() => {
          resetFeeObjects();
          resetErrorMessage();
          closeModal();
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={() => {
              resetFeeObjects();
              resetErrorMessage();
              closeModal();
            }}
          >
            Upload More
          </Button>,
          <Button key="goBack" onClick={() => window.history.back()}>
            Go Back
          </Button>,
        ]}
      >
        {feeUploaded > 0 ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CheckCircleFilled style={{ color: '#0afa0a', fontSize: '128px' }} />
            <div style={{ paddingTop: '15px', fontWeight: 'bold', fontSize: '16px' }}>
              {`${feeUploaded} delivery fee updated!`}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              paddingTop: '15px',
              fontWeight: 'bold',
              fontSize: '16px',
              textAlign: 'center',
            }}
          >
            No post area delivery fee is updated!
          </div>
        )}
      </Modal>
    </>
  );
};

DeliveryFeeMessage.propTypes = {
  feeObjects: PropTypes.array,
  errorMessage: PropTypes.array,
  resetFeeObjects: PropTypes.func,
  resetErrorMessage: PropTypes.func,
  modalVisibility: PropTypes.bool,
  closeModal: PropTypes.func,
  resetFeeUploaded: PropTypes.func,
  feeUploaded: PropTypes.number,
};

export default DeliveryFeeMessage;
