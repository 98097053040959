import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Switch, InputNumber, Spin, Button } from 'antd';

const DeliveryToUpstairs = (props) => {
  const { config, loading, updateConfig } = props;
  const { Item } = Form;
  const formRef = useRef();

  useEffect(() => {
    if (!loading) {
      formRef.current.resetFields();
    }
  }, [loading]) 

  return (
    <Spin spinning={loading}>
      <Form ref={formRef} initialValues={config} onFinish={updateConfig}>
        <Item
          name="deliveryToUpstairs"
          label="Allow Delivery To Upstairs"
          rules={[{ required: true }]}
          valuePropName="checked"
        >
          <Switch />
        </Item>
        <Item
          name="deliveryToUpstairsFee"
          label="Delivery To Upstairs Fee"
          rules={[
            { required: config.deliveryToUpstairs, message: 'Fee is required.' }
          ]}
        >
          <InputNumber
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          />
        </Item>
        <Item>
          <Button type="primary" htmlType="submit" shape="round">
            Save
          </Button>
        </Item>
      </Form>
    </Spin>
  );
};

DeliveryToUpstairs.propTypes = {
  loading: PropTypes.bool,
  config: PropTypes.object,
  updateConfig: PropTypes.func
};

export default DeliveryToUpstairs;
