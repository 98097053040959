const actions = {
  GET_BANNERS: 'GET_BANNERS',
  GET_BANNERS_SUCCESS: 'GET_BANNERS_SUCCESS',
  GET_BANNERS_FAIL: 'GET_BANNERS_FAIL',

  EDIT_BANNER_PICTURE: 'EDIT_BANNER_PICTURE',
  EDIT_BANNER_PICTURE_SUCCESS: 'EDIT_BANNER_PICTURE_SUCCESS',
  EDIT_BANNER_PICTURE_FAIL: 'EDIT_BANNER_PICTURE_FAIL',

  EDIT_BANNER_CATEGORY: 'EDIT_BANNER_CATEGORY',
  EDIT_BANNER_CATEGORY_SUCCESS: 'EDIT_BANNER_CATEGORY_SUCCESS',
  EDIT_BANNER_CATEGORY_FAIL: 'EDIT_BANNER_CATEGORY_FAIL',

  DELETE_BANNER: 'DELETE_BANNER',
  DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
  DELETE_BANNER_FAIL: 'DELETE_BANNER_FAIL',

  getBanners: () => ({
    type: actions.GET_BANNERS,
  }),

  changeImage: (params) => ({
    type: actions.EDIT_BANNER_PICTURE,
    ...params,
  }),

  changeCategory: (params) => ({
    type: actions.EDIT_BANNER_CATEGORY,
    ...params,
  }),

  deleteBanner: ({ picturePosition }) => ({
    type: actions.DELETE_BANNER,
    picturePosition,
  }),
};

export default actions;