const actions = {
  //check sku validity asynchronous function
  CHECK_SKU: 'CHECK_SKU',
  CHECK_SKU_SUCCESS: 'CHECK_SKU_SUCCESS',
  CHECK_SKU_FAIL: 'CHECK_SKU_FAIL',

  //set redux sku frontend local function
  CHANGE_SKU: 'CHANGE_SKU',

  ADD_PRODUCT: 'ADD_PRODUCT',
  ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAIL: 'ADD_PRODUCT_FAIL',

  setSku: ({ SKU }) => ({
    type: actions.CHANGE_SKU,
    SKU,
  }),

  addProduct: ({ product }) => ({
    type: actions.ADD_PRODUCT,
    product,
  }),
};

export default actions;
