import actions from './actions';
import { toast } from 'react-toastify';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getSplashScreenRef } from '../../apis/SplashScreen';

function* getSplashScreenUrl() {
  const { success, data, error } = yield getSplashScreenRef();
  if (success) {
    yield put({ type: actions.GET_SPLASH_SCREEN_IMAGE_SUCCESS, url: data });
  } else {
    toast.error(error.toString(), {
      autoClose: 5000
    });
    yield put({ type: actions.GET_SPLASH_SCREEN_IMAGE_FAIL });
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(actions.GET_SPLASH_SCREEN_IMAGE, getSplashScreenUrl),
  ]);
}
