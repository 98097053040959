import React from 'react';
import moment from 'moment';
import { Alert, Modal, Button, Typography, List, Card } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

const BatchUploadMessage = (props) => {
  const {
    productObjects,
    errorMessage,
    setModalVisibility,
    setProductObjects,
    resetBatchOperationResults,
    productsUploaded,
    modalVisibility,
    productsDeleted,
    actionType,
    invalidProductObjects,
  } = props;

  const undefinedText = (
    <Typography.Text type="danger" strong>
      Undefined
    </Typography.Text>
  );

  const invalidText = (
    <Typography.Text type="danger" strong>
      Invalid
    </Typography.Text>
  );

  const generateCardItems = (product) => {
    const {
      sku,
      name,
      price,
      priority,
      stock,
      categories,
      isChild,
      type,
      shortDescription,
      longDescription,
      archived,
      discountPrice,
      expiryDate,
    } = product;

    const getExpiryDate = () => {
      if (!expiryDate || !expiryDate.seconds) {
        return undefinedText;
      }
      const str = moment(expiryDate.seconds * 1000).format('DD/MM/YYYY');
      return str === 'Invalid date' ? invalidText : str;
    }

    const getDiscountPrice = () => {
      if (discountPrice !== undefined ) {
        return (
          <>
            Discount Price: {!isNaN(discountPrice) ? discountPrice : invalidText}
            <br />
          </>
        );
      }
      return '';
    }

    return actionType === 'upload' ? (
      <>
        Sku: {(sku ? ( sku.match("^[0-9a-zA-Z-_.]{1,}$") ? sku : invalidText ) : undefinedText )}
        <br />
        Name: {name ? name : undefinedText}
        <br />
        Price: {price !== undefined ? price : undefinedText}
        <br />
        {getDiscountPrice()}
        Priority: {priority !== undefined ? priority : undefinedText}
        <br />
        Stock: {stock !== undefined ? stock : undefinedText}
        <br />
        Categories: {categories ? categories.join(', ') : undefinedText}
        <br />
        Is Child Product: {isChild !== undefined ? (isChild ? 'YES' : 'NO') : undefinedText}
        <br />
        Type: {type ? type : undefinedText}
        <br />
        Short Description: {shortDescription ? shortDescription : undefinedText}
        <br />
        Long Description: {longDescription ? longDescription : undefinedText}
        <br />
        Archived: {archived !== undefined ? (archived ? 'YES' : 'NO') : undefinedText}
        <br />
        Expiry Date: {getExpiryDate()}
      </>
    ) : (
      <>Sku: {sku ? name : undefinedText}</>
    );
  };

  return (
    <>
      <div style={{ paddingTop: '15px' }}>
        {productObjects.length > 0 && (
          <Alert
            message={`${productObjects.length} product${
              productObjects.length === 1 ? ' is' : 's are'
            } ready to ${actionType}:`}
            type="success"
            closable
            showIcon
            description={productObjects.map((product) => product.sku).join(', ')}
          />
        )}
      </div>
      <div style={{ paddingTop: '15px' }}>
        {JSON.stringify(errorMessage) !== '[]' && (
          <Alert
            message="Data validation error:"
            type="error"
            closable
            showIcon
            description={
              errorMessage.length !== 0 &&
              errorMessage.map((message) => (
                <div key={message}>
                  <Typography.Text type="danger">{message}</Typography.Text>
                </div>
              ))
            }
          />
        )}
      </div>
      {JSON.stringify(errorMessage) !== '[]' && (
        <List
          style={{ paddingTop: '20px' }}
          grid={{ gutter: 16, column: 4 }}
          dataSource={invalidProductObjects}
          renderItem={(item) => (
            <List.Item key={item.sku}>
              <Card title={item.sku}>{generateCardItems(item, actionType)}</Card>
            </List.Item>
          )}
        />
      )}
      <Modal
        visible={(productsUploaded > 0 || productsDeleted.length > 0) && modalVisibility}
        onCancel={() => {
          setModalVisibility(false);
          setProductObjects([]);
          resetBatchOperationResults();
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={() => {
              setModalVisibility(false);
              setProductObjects([]);
            }}
          >
            Upload/Delete More
          </Button>,
          <Button key="goBack" onClick={() => window.history.back()}>
            Go Back
          </Button>,
        ]}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CheckCircleFilled style={{ color: '#0afa0a', fontSize: '128px' }} />
          {actionType === 'upload' ? (
            <div
              style={{ paddingTop: '15px', fontWeight: 'bold', fontSize: '16px' }}
            >{`${productsUploaded} Product${productsUploaded > 1 ? 's' : ''} Uploaded!`}</div>
          ) : (
            <div style={{ paddingTop: '15px', fontWeight: 'bold', fontSize: '16px' }}>
              {productsDeleted.length ? (
                <>
                  <div>{`${productsDeleted.length} Product${
                    productsDeleted.length > 1 ? 's' : ''
                  } Deleted!`}</div>
                  {productsDeleted.length < productObjects.length && (
                    <div>
                      <Typography.Text>The following products are not deleted:</Typography.Text>
                      <div>
                        {productObjects
                          .filter((entry) => !productsDeleted.includes(entry.sku))
                          .map((entry) => (
                            <div style={{ fontWeight: 'normal', color: '#fa0a0a' }} key={entry.sku}>
                              {entry.sku}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                `No Product Deleted!`
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

BatchUploadMessage.propTypes = {
  productObjects: PropTypes.array,
  errorMessage: PropTypes.array,
  setModalVisibility: PropTypes.func,
  setProductObjects: PropTypes.func,
  resetBatchOperationResults: PropTypes.func,
  productsUploaded: PropTypes.number,
  modalVisibility: PropTypes.bool,
  productsDeleted: PropTypes.array,
  actionType: PropTypes.string,
  invalidProductObjects: PropTypes.array,
};

export default BatchUploadMessage;
