import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import couponsActions from '../../redux/Coupon/actions';
import newCouponActions from '../../redux/Coupon/NewCoupon/actions';
import {
  Button,
  Select,
  PageHeader,
  Spin,
  Modal,
  Form,
  Input,
  InputNumber,
  Card,
  Switch,
  Row,
  Col
} from 'antd';
import CouponTable from '../../components/CouponTable';
const { Item } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const CouponsContainer = (props) => {
  const {
    couponsData,
    isFetchingCoupons,
    isDeletingCoupon,
    newCoupon,
    fetchCouponList,
    deleteCoupon,
    createCoupon,
    isFetchingInvitationCouponConfig,
    isUpdatingInvitationCouponConfig,
    invitationCouponConfig,
    getInvitationCouponConfig,
    updateInvitationCouponConfig
  } = props;
  const { isCreatingCoupon } = newCoupon;
  const configFormRef = useRef();
  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard'
    },
    {
      path: 'couponstemplate',
      breadcrumbName: 'Coupons Templates'
    }
  ];

  useEffect(() => {
    configFormRef.current.resetFields();
  }, [invitationCouponConfig]);

  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    fetchCouponList();
    getInvitationCouponConfig();
  }, [fetchCouponList, getInvitationCouponConfig]);

  const saveInvitationCouponConfig = (values) => {
    const { coupon, toInvitee, toInviter } = values;
    updateInvitationCouponConfig({ code: [coupon], toInvitee, toInviter });
  };

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Coupons Templates"
        breadcrumb={{ routes }}
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => {
              setModalVisibility(true);
            }}
          >
            Add New Coupon
          </Button>,
          <Button
            key="2"
            onClick={() => {
              fetchCouponList();
            }}
          >
            Refresh
          </Button>
        ]}
      ></PageHeader>
      <Spin
        spinning={
          isFetchingCoupons ||
          isDeletingCoupon ||
          isCreatingCoupon ||
          isFetchingInvitationCouponConfig ||
          isUpdatingInvitationCouponConfig
        }
      >
        <CouponTable coupons={couponsData} deleteCoupon={deleteCoupon} />
        <Card
          title="Invitation Coupon Configuration"
          extra={[
            <Button
              form="configForm"
              key="submit"
              htmlType="submit"
              type="primary"
              shape="round"
            >
              Save
            </Button>
          ]}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form
                {...formItemLayout}
                ref={configFormRef}
                id="configForm"
                initialValues={{
                  ...invitationCouponConfig,
                  coupon:
                    invitationCouponConfig.coupon &&
                    invitationCouponConfig.coupon[0]
                }}
                onFinish={saveInvitationCouponConfig}
              >
                <Item name="coupon" label="Coupon">
                  <Select>
                    {couponsData.map((coupon) => (
                      <Option key={coupon.code} value={coupon.code}>
                        {coupon.code}
                      </Option>
                    ))}
                  </Select>
                </Item>
                <Item
                  name="toInviter"
                  label="Assign to inviter"
                  valuePropName="checked"
                >
                  <Switch />
                </Item>
                <Item
                  name="toInvitee"
                  label="Assign to invitee"
                  valuePropName="checked"
                >
                  <Switch />
                </Item>
              </Form>
            </Col>
          </Row>
        </Card>
        <Modal
          title="Create a New Coupon"
          visible={modalVisibility}
          onOk={() => {}}
          onCancel={() => {
            setModalVisibility(false);
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setModalVisibility(false);
              }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              form="newCouponForm"
              htmlType="submit"
            >
              Submit
            </Button>
          ]}
        >
          <Form
            {...formItemLayout}
            id="newCouponForm"
            onFinish={(values) => {
              console.log(values);
              setModalVisibility(false);
              createCoupon({ newCoupon: values });
            }}
          >
            <Item
              name="code"
              label="Coupon Code"
              rules={[
                { required: true, message: 'Coupon code cannot be empty!' }
              ]}
            >
              <Input placeholder="Please enter a coupon code" />
            </Item>
            <Item
              name="discountAmount"
              label="Discount Amount"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Please enter a number"
                min={0}
              />
            </Item>
            <Item
              name="discountType"
              label="Discount Type"
              rules={[
                {
                  required: true,
                  messgae: 'Coupon discount type cannot be empty!'
                }
              ]}
            >
              <Select placeholder="Please select a discount type">
                <Select.Option value="fix">Fix</Select.Option>
                <Select.Option value="percent">Percent</Select.Option>
              </Select>
            </Item>
            <Item name="ttl" label="Period of Validity">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Please enter number of days"
                min={1}
              />
            </Item>
          </Form>
        </Modal>
      </Spin>
    </>
  );
};

CouponsContainer.propTypes = {
  couponsData: PropTypes.array,
  isFetchingCoupons: PropTypes.bool,
  isDeletingCoupon: PropTypes.bool,
  newCoupon: PropTypes.object,
  fetchCouponList: PropTypes.func,
  deleteCoupon: PropTypes.func,
  createCoupon: PropTypes.func,
  isFetchingInvitationCouponConfig: PropTypes.bool,
  isUpdatingInvitationCouponConfig: PropTypes.bool,
  invitationCouponConfig: PropTypes.object,
  getInvitationCouponConfig: PropTypes.func,
  updateInvitationCouponConfig: PropTypes.func
};

const {
  fetchCouponList,
  deleteCoupon,
  getInvitationCouponConfig,
  updateInvitationCouponConfig
} = couponsActions;
const { createCoupon } = newCouponActions;
export default connect(
  (state) => {
    const { Coupons } = state;
    const {
      couponsData,
      isFetchingCoupons,
      isDeletingCoupon,
      newCoupon,
      isFetchingInvitationCouponConfig,
      isUpdatingInvitationCouponConfig,
      invitationCouponConfig
    } = Coupons;
    return {
      couponsData,
      isFetchingCoupons,
      isDeletingCoupon,
      newCoupon,
      isFetchingInvitationCouponConfig,
      isUpdatingInvitationCouponConfig,
      invitationCouponConfig
    };
  },
  {
    fetchCouponList,
    deleteCoupon,
    createCoupon,
    getInvitationCouponConfig,
    updateInvitationCouponConfig
  }
)(CouponsContainer);
