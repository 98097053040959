/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react';
import { PageHeader, Button, message, Typography, Spin, Select, Card } from 'antd';
import moment from 'moment';
import csvToJson from 'csvtojson/v2';
import ProductSchema from '../../schemas/product';
import { connect } from 'react-redux';
import productActions from '../../redux/Product/actions';
import categoriesActions from '../../redux/Categories/actions';
import PropTypes from 'prop-types';
import BatchUploadMessage from '../../components/BatchUploadMessage/index';
import BatchUploadTemplate from '../../assets/BatchUploadTemplate.zip';
import DownloadProductCSV from '../../components/DownloadProductCSV';


function removeEmpty(object) {
  const keys = Object.keys(object).filter((key) => object[key] && (object[key] !== ''));
  const newObject = {};
  for (const key of keys) {
    newObject[key] = object[key];
  }
  return newObject;
}

function checkCategories(product, categories) {
  const getCategoryData = (key, data) => {
    for (let entry of data) {
      if (entry.id === key) return entry;
    }
    for (let entry of data) {
      if (entry.children.length > 0) {
        const subresult = getCategoryData(key, entry.children);
        if (subresult) return subresult;
        continue;
      }
    }
    return null;
  };
  const invalidCategories = [];
  for (const category of product.categories) {
    if (!getCategoryData(category, categories)) invalidCategories.push(category);
  }
  if (invalidCategories.length !== 0) {
    console.log(invalidCategories);
    return { invalidCategories };
  }
  return { invalidCategories: [] };
}

function formatProductObject(object) {
  const arrays = ['categories', 'images', 'productDescriptionImages'];
  for (let key of arrays) {
    if (object[key]) {
      if (object[key].includes(';'))
        object[key] = object[key].split(';').filter((str) => str !== '');
      else object[key] = [object[key]];
    } else {
      object[key] = [];
    }
  }
  object.archived = false;
  object.type = 'simple';
  object.isChild = false;
  object.price = object.price && parseFloat(object.price);
  object.priority = object.priority && parseInt(object.priority);
  object.stock = object.stock && parseInt(object.stock);
  if (object.discountPrice) {
    object.discountPrice = object.discountPrice && parseFloat(object.discountPrice);
  }
  if (object.flashSaleLimit) {
    object.flashSaleLimit =
      object.flashSaleLimit && parseInt(object.flashSaleLimit);
  }
  if (object.expiryDate) {
    const date = moment(object.expiryDate + ' 23:59:59', 'DD/MM/YYYY HH:mm:ss');
    object.expiryDate = {
      seconds: parseInt(date.format('X')),
      nanoseconds: 0,
    };
  }
  return object;
}

async function csvJSON(csv) {
  const result = [];
  const records = await csvToJson().fromString(csv);
  records.forEach((obj) => result.push(removeEmpty(obj)));
  return result;
}

const ProductBatchUpload = (props) => {
  const {
    uploadProducts,
    isValidatingProducts,
    isUploadingProducts,
    productsUploaded,
    validateProducts,
    validateProductsSuccess,
    validateProductsFail,
    getCategories,
    isFetchingCategories,
    categories,
    batchDeleteProducts,
    isDeletingBatchProducts,
    productsDeleted,
    resetBatchOperationResults,
  } = props;
  useEffect(() => {
    getCategories(); //eslint-disable-next-line
  }, [getCategories]);
  let fileReader;

  const fileRef = useRef();

  const [productObjects, setProductObjects] = useState([]);
  const [invalidProductObjects, setInvalidProductObjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [actionType, setActionType] = useState('upload');

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'products',
      breadcrumbName: 'Products',
    },
    {
      path: 'productbatchupload',
      breadcrumbName: 'Product Batch Upload',
    },
  ];

  const fileErrorHandler = (product, error) => {
    validateProductsFail();
    const newErrorMessage = errorMessage;
    newErrorMessage.push(`${product.sku}: ${error}`);
    setErrorMessage(newErrorMessage);
    const newInvalidObjects = invalidProductObjects;
    newInvalidObjects.push(product);
    setInvalidProductObjects(newInvalidObjects);
  };

  const handleFileRead = async () => {
    const jsonobj = await csvJSON(fileReader.result);
    try {
      const productList = [];
      const productLoaded = {};
      if (actionType === 'upload') {
        validateProducts();
        for (let product of jsonobj) {
          product = formatProductObject(product);
          const { error: validationError, value } = ProductSchema.validate(product, {
            abortEarly: false,
          });
          if (validationError) {
            fileErrorHandler(product, validationError);
            continue;
          }
          if (!product.sku.match('^[0-9a-zA-Z-_.]{1,}$')) {
            fileErrorHandler(
              product,
              'Product SKU should only contain number, letter and "._-" symbols.'
            );
            continue;
          }
          const { invalidCategories } = checkCategories(product, categories);
          if (invalidCategories.length > 0) {
            fileErrorHandler(product, `Category "${invalidCategories.join(', ')}" invalid.`);
            continue;
          }
          if (productLoaded[product.sku]) {
            fileErrorHandler(product, `Duplicated SKU not allowed!`);
            continue;
          } else {
            productLoaded[product.sku] = product;
          }
          productList.push(value);
        }
        if (jsonobj.length === productList.length) validateProductsSuccess();
        setProductObjects(productList);
      } else {
        let index = 1;
        for (let product of jsonobj) {
          if (product.sku) {
            if (productLoaded[product.sku]) {
              fileErrorHandler(product, `Duplicated SKU not allowed!`);
              continue;
            } else {
              productList.push(product);
            }
          } else {
            const newErrorMessage = errorMessage;
            newErrorMessage.push(`Product ${index}: SKU is required!`);
            setErrorMessage(newErrorMessage);
            const newInvalidObjects = invalidProductObjects;
            newInvalidObjects.push(product);
            setInvalidProductObjects(newInvalidObjects);
            continue;
          }
          index++;
        }
        setProductObjects(productList);
      }
    } catch (error) {
      message.error(`File format error: ${error.message}`);
      validateProductsFail();
      setProductObjects([]);
      setInvalidProductObjects([]);
    }
  };

  const handleFileChosen = (file) => {
    try {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProductUpload = () => {
    setErrorMessage([]);
    fileRef.current.value = null;
    setInvalidProductObjects([]);
    resetBatchOperationResults();
    setModalVisibility(true);
    console.log(productObjects);
    if (actionType === 'upload') uploadProducts({ newProductList: productObjects });
    else batchDeleteProducts({ products: productObjects });
  };

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Product Batch Operation"
        breadcrumb={{ routes }}
        extra={[
          <Button style={{ float: 'right' }} key="downloadTemplate" type="primary" shape="round">
            <a href={BatchUploadTemplate} download="ProductsBatchUpload.zip">
              Download Template
            </a>
          </Button>,
        ]}
      ></PageHeader>

      <Card title="Upload Product List">
        <Spin
          spinning={
            isValidatingProducts ||
          isUploadingProducts ||
          isFetchingCategories ||
          isDeletingBatchProducts
          }
        >
          <Typography.Text strong style={{ paddingRight: '10px' }}>
          Select Operation Type:
          </Typography.Text>
          <Select
            defaultValue={actionType}
            onChange={(value) => {
              setActionType(value);
              setProductObjects([]);
              setInvalidProductObjects([]);
              setErrorMessage([]);
              fileRef.current.value = null;
            }}
            style={{ width: '200px' }}
          >
            <Select.Option key="upload" value="upload">
            Upload
            </Select.Option>
            <Select.Option key="delete" value="delete">
            Delete
            </Select.Option>
          </Select>
          <Typography.Text strong style={{ paddingLeft: '20px' }}>
          Click 'Chose file' to upload a CSV file:
          </Typography.Text>
          <input
            ref={fileRef}
            type="file"
            id="file"
            accept=".csv"
            style={{ paddingLeft: '15px' }}
            onChange={(e) => {
              handleFileChosen(e.target.files[0]);
              setErrorMessage([]);
            }}
            onClick={(event) => {
              event.target.value = null;
              setErrorMessage([]);
              setProductObjects([]);
              setInvalidProductObjects([]);
            }}
          />
          <BatchUploadMessage
            productObjects={productObjects}
            errorMessage={errorMessage}
            setModalVisibility={setModalVisibility}
            setProductObjects={setProductObjects}
            resetBatchOperationResults={resetBatchOperationResults}
            productsUploaded={productsUploaded}
            modalVisibility={modalVisibility}
            productsDeleted={productsDeleted}
            actionType={actionType}
            invalidProductObjects={invalidProductObjects}
          />
        </Spin>
        <Button
          type="primary"
          onClick={handleProductUpload}
          disabled={JSON.stringify(errorMessage) !== '[]' || productObjects.length === 0}
        >
        Submit
        </Button>
      </Card>
      <Card title="Generate Product List" style={{ marginTop: '20px' }}>
        <DownloadProductCSV categories={categories} />
      </Card>
    </>
  );
};

ProductBatchUpload.propTypes = {
  validateProducts: PropTypes.func,
  validateProductsSuccess: PropTypes.func,
  validateProductsFail: PropTypes.func,
  uploadProducts: PropTypes.func,
  isValidatingProducts: PropTypes.bool,
  isUploadingProducts: PropTypes.bool,
  productsUploaded: PropTypes.number,
  getCategories: PropTypes.func,
  categories: PropTypes.any,
  isFetchingCategories: PropTypes.bool,
  batchDeleteProducts: PropTypes.func,
  isDeletingBatchProducts: PropTypes.bool,
  productsDeleted: PropTypes.array,
  resetBatchOperationResults: PropTypes.func,
};

const {
  validateProducts,
  validateProductsSuccess,
  validateProductsFail,
  uploadProducts,
  batchDeleteProducts,
  resetBatchOperationResults,
} = productActions;

const { getCategories } = categoriesActions;
export default connect(
  (state) => {
    const { Products, Categories } = state;
    const {
      isValidatingProducts,
      isUploadingProducts,
      productsUploaded,
      isDeletingBatchProducts,
      productsDeleted,
    } = Products;
    const { categories, isFetchingCategories } = Categories;
    return {
      isValidatingProducts,
      isUploadingProducts,
      productsUploaded,
      categories,
      isFetchingCategories,
      isDeletingBatchProducts,
      productsDeleted,
    };
  },
  {
    validateProducts,
    validateProductsSuccess,
    validateProductsFail,
    uploadProducts,
    getCategories,
    batchDeleteProducts,
    resetBatchOperationResults,
  }
)(ProductBatchUpload);
